import { Button, Flex, FormLabel, Input, Tooltip, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { cambioDatos } from "../../middlewares/users.middleware";
import { handleErrors } from "../../utils/functions/handleErrors";
import { StatusEnum, notify } from "../../utils/functions/notify";

interface Props {
    name: string;
    label?: string;
    type?: string;
    noHead?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    style?: React.CSSProperties;
    inputStyles?: React.CSSProperties;
    defaultValue?: any;
    isChaging?: boolean;
    handleSolicitudCambio: ({ key, value } : { key: string, value: any}) => Promise<void>;
}

export const InputCambioDatos = ({
    name,
    noHead,
    label,
    isDisabled = false,
    defaultValue,
    type = 'text',
    placeholder = '',
    style,
    inputStyles = {},
    isChaging = true,
    handleSolicitudCambio
}: Props) => {
    const toast = useToast();
    const [value, setValue] = useState<any>(defaultValue);

    useEffect(() => {
        defaultValue &&
            setValue(defaultValue);
    }, [defaultValue]);


    function onChange(event: any) {
        const value = event.target.value;
        setValue(value);
    }

    const solicitudCamioDatos = async () => {
        handleSolicitudCambio({ 
            key: name, 
            value: value 
        })
        .then(() => setValue(defaultValue))
    }

    return (
        <Flex style={style} w="100%" direction="column" gap="5px">
            {!noHead && (
                <Flex>
                    <FormLabel
                        color="#67748E"
                        fontSize="14px"
                        fontWeight="600"
                        letter-spacing="-0.387px"
                    >
                        {label}
                    </FormLabel>
                </Flex>
            )}

            <Flex gap="5px">
                <Input
                    color="dark_blue"
                    fontSize="14px"
                    fontWeight="600"
                    border="1px solid"
                    borderColor="light_grey"
                    _focusVisible={{ border: "none", boxShadow: "none" }}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    isDisabled={isDisabled || !isChaging}
                    style={inputStyles}
                    _disabled={{ color: "dark_blue", cursor: "not-allowed" }}
                />

                {isChaging &&
                    <Tooltip
                        label="Escriba el cambio que desea realizar y solicítelo"
                    >
                        <Button
                            onClick={solicitudCamioDatos}
                            w="fit-content"
                            p="10px 25px"
                            color="purewhite"
                            bg="secondary"
                            _hover={{ bg: "secondary_variant" }}
                            fontSize="14px"
                            fontWeight="500"
                            rounded="5px"
                            isDisabled={value === defaultValue}
                        >
                            Solicitar cambio
                        </Button>
                    </Tooltip>
                }
            </Flex>
        </Flex>
    )
} 