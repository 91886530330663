// Chakra UI
import { Flex, Grid, Text, useToast } from '@chakra-ui/react';
// Components
import { CardCurso } from '../Components/Card/CardCurso';
// Interfaces
import { CursosInt } from '../../../interfaces/CoursesInt';
// Axios
import { CardSinInformacion, TypeCardSinInformacion } from '../../../shared/components/Customs/CardSinInformacion';
import { useEffect } from 'react';
import { handleUnauthorized } from '../../../shared/utils/functions/handleErrors';
import { useAuthContex } from '../../../shared/context/user.context';
import { useNavigate } from 'react-router-dom';
import { useCursos } from '../../../shared/middlewares/courses.middleware';
import { SkeletonAprendizajeCursos } from '../Components/utils/Skeletons/SkeletonAprendizajeCursos';

export const AprendizajeCursos
 = () => {
    const { logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { cursos, error, status, errorsList, loading } = useCursos();

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast,
            })
        }
    }, [error])

    return (
        (loading) ? <SkeletonAprendizajeCursos />
            :
            <Flex
                direction="column"
                gap="30px"
                px="50px"
                pb="40px"
            >
                {(cursos?.length > 0) ?
                    <Flex
                        gap="40px"
                        direction="column"
                    >
                        <>
                            <Text
                                fontSize="28px"
                                fontWeight="600"
                                color="font"

                            >
                                Cursos
                            </Text>
                            <Flex

                            >
                                <Grid
                                    gridTemplateColumns="repeat(3, 1fr)"
                                    gap="40px"
                                    w="100%"
                                    pr="40px"
                                >
                                    {cursos?.map((curso: CursosInt, index: number) => (
                                        <CardCurso
                                            key={index}
                                            curso={curso}
                                            grupoSlug={undefined}
                                        />
                                    ))

                                    }
                                </Grid>
                            </Flex>
                        </>
                    </Flex>
                    :
                    <CardSinInformacion type={TypeCardSinInformacion?.CURSOS} />
                }
            </Flex>
    );
}