import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { DrawerAlert } from "../../Drawer/DrawerAlert";
import { useState } from "react";
import { useAuthContex } from "../../../context/user.context";
import userPerfil from "../../../../assets/img/Avatar.png"
import { HiOutlineBell, HiOutlineCalendar } from "react-icons/hi";
import { UserActions } from "../../Nav/UserActions";


interface Props {
    title: string;
}

export const TopbarMenu = ({ title }: Props) => {
    const { user } = useAuthContex();
    const [openCalendar, setOpenCalendar] = useState<boolean>(false)
    const [openAlert, setOpenAlert] = useState<boolean>(false)

    return (
        <>
            <Box
                p="15px 50px"
                display="inline-block"
            >
                <Text
                    fontSize="18px"
                    fontWeight="500"
                    color="font"
                    display="inline-block"
                >
                    {title}
                </Text>
            </Box>

            <Flex gap="20px" alignItems="center" p={"15px 70px"}>
                <Icon cursor="pointer" _hover={{ color: "black" }} onClick={() => setOpenCalendar(true)} as={HiOutlineCalendar} color="font" boxSize="18px" />

                <Flex
                    position="relative"
                >
                    <Icon cursor="pointer" _hover={{ color: "black" }} onClick={() => setOpenAlert(true)} as={HiOutlineBell} color="font" boxSize="18px" />
                </Flex>

                <Flex
                    alignItems="center"
                    gap="15px"
                >
                    <Image
                        src={user?.avatar?.url || userPerfil}
                        boxSize="40px"
                        rounded="16px"
                        objectFit="cover"
                    />

                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {user?.nombre}
                    </Text>

                    <UserActions />
                </Flex>

            </Flex>

            <DrawerAlert isOpen={openCalendar} setIsOpen={setOpenCalendar} callComponent="calendar" />
            <DrawerAlert isOpen={openAlert} setIsOpen={setOpenAlert} callComponent="alert" />
        </>
    );
}