import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { PreguntaForoInt } from "../../../interfaces/ForoInt"
import { format, formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import { CustomAvatar } from "../../../shared/components/Avatar/CustomAvatar";
import { truncate } from "lodash";
import { BiChat } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import MarkdownPreview from '@uiw/react-markdown-preview';

interface Props {
    pregunta: PreguntaForoInt | undefined;
    isTrucate?: boolean;
    enableHover?: boolean
}

export const CardPreguntas = ({
    enableHover = false,
    pregunta,
    isTrucate = true,
}: Props) => {
    const navigate = useNavigate();

    const handleClick = (temaId: string | undefined, preguntaId: string | undefined) => {
        if (isTrucate && temaId && preguntaId) {
            navigate(`/foro/${temaId}/pregunta/${preguntaId}`);
        }
    }


    return (
        <Flex
            direction="column"
            bg="purewhite"
            rounded="5px"
            p="30px 30px"
            gap="15px"
            w="100%"
            h="fit-content"
            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
            cursor={isTrucate ? "pointer" : "default"}
            onClick={() => handleClick(pregunta?.temaId, pregunta?.id)}
            _hover={enableHover ? { boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)' } : {}}
            transition=" 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
        >

            <Flex
                gap="15px"
            >
                <CustomAvatar
                    name={pregunta?.user?.username || ""}
                    src={pregunta?.user?.avatar?.url}
                    size="40px"
                    rounded="full"
                />
                <Flex
                    direction="column"
                    gap="20px"
                    w="100%"
                >
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Flex
                            direction="column"
                            gap="7px"
                        >
                            <Text
                                color="font"
                                fontSize="13px"
                                fontStyle="normal"
                                fontWeight="400"
                                lineHeight="normal"
                            >
                                {pregunta?.user?.username}
                            </Text>

                            <Text
                                color="secondary_variant_font"
                                fontSize="11px"
                                fontWeight="400"
                                lineHeight="normal"
                            >
                                {pregunta?.createdAt && format(new Date(pregunta?.createdAt), "dd/MM/yyyy HH:mm")}
                            </Text>

                        </Flex>


                        <Flex
                            color="font"
                            fontSize="12px"
                            fontWeight="400"
                            gap="10px"
                        >
                            <Text
                                p="5px 10px"
                                rounded="full"
                                bg="border"
                            >
                                {pregunta?.tipo}
                            </Text>
                        </Flex>
                    </Flex>

                    <Text
                        fontSize="18px"
                        fontWeight="700"
                        color="black"
                    >
                        {pregunta?.titulo}
                    </Text>

                </Flex>
            </Flex>

            {isTrucate
                ?
                <Box
                    ml="55px"
                >
                    <MarkdownPreview
                        source={truncate(pregunta?.contenido, { length: 140 }) || ''}
                        style={{
                            color: "#252F40",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "25px",
                            background: "#FFFFFF",
                            fontFamily: "Inter",
                        }}
                    />
                </Box>
                :
                <Box
                    ml="55px"
                >
                    <MarkdownPreview
                        source={pregunta?.contenido || ''}
                        style={{
                            color: "#252F40",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "25px",
                            background: "#FFFFFF",
                            fontFamily: "Inter"
                        }}
                    />
                </Box>
            }

            <Flex
                alignItems="center"
                justifyContent="space-between"
            >

                {isTrucate &&
                    <Flex alignItems="center" gap="5px" pr="15px">
                        <Icon as={BiChat} color="secondary_variant_font" boxSize="20px" mt="1px" />

                        <Text
                            color="secondary_variant_font"
                            fontSize="21px"
                            fontWeight="400"
                            letterSpacing="0.48px"
                        >
                            {pregunta?.meta?.totalRespuestas || 0}
                        </Text>
                    </Flex>
                }
            </Flex>
        </Flex>
    );
}