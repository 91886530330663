import {
    Flex,
    Icon,
    Button,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    Text,
} from '@chakra-ui/react';
import { PopoverTrigger } from '@chakra-ui/react';
import { BiX } from 'react-icons/bi';
import { TimePicker_TimeItem } from './TimePickerItem';
// export const PopoverTrigger: React.FC<{ children: React.ReactNode }> = OrigPopoverTrigger;

export const TimePicker = ({
    value,
    min,
    max,
    placeholder,
    onChange = () => { },
}: {
    value: string;
    min?: string;
    max?: string;
    placeholder?: string;
    onChange: (value: string) => void;
}) => {
    const hours = () => {
        const res = [];

        for (let i = 6; i <= 20; i++) {
            res.push(`${i}:00`);
            // res.push(`${i}:15`);
            res.push(`${i}:30`);
            // res.push(`${i}:45`);
        }

        return res;
    };

    return (
        <Popover>
            {({ onClose, isOpen }) => (
                <>
                    <PopoverTrigger>
                        <Button
                            w="100%"
                            h="40px"
                            bg="purewhite"
                            p="0px 12px"
                            rounded="8px"
                            fontSize="16px"
                            fontWeight="medium"
                            border="1px solid"
                            borderColor="border"
                            color={value ? 'black' : 'fail'}
                        >
                            {value || placeholder}
                        </Button>
                    </PopoverTrigger>

                    <PopoverContent 
                        maxW="200px" 
                        bg="purewhite"
                    >
                        <PopoverArrow 
                            bg="purewhite"
                        />

                        <PopoverHeader 
                            display="flex" 
                            alignItems="center" 
                            justifyContent="space-between"
                        >
                            <Text fontWeight="500">Elige una hora</Text>

                            <Icon as={BiX} boxSize="22px" cursor="pointer" onClick={onClose} />
                        </PopoverHeader>

                        <PopoverBody p="0px">
                            <Flex 
                                maxH="200px" 
                                overflow="overlay" 
                                direction="column"
                                className='scroll-bar'
                            >
                                {hours().map((time: string) => (
                                    <TimePicker_TimeItem
                                        key={time}
                                        min={min}
                                        max={max}
                                        time={time}
                                        scrollOnOpen={
                                            // Si hay un valor seleccionado, abrimos el popover por este valor
                                            value
                                                ? time === value
                                                : // Si no hay valor pero sí un mínimo, abrimos por el mínimo.
                                                min
                                                    ? time === min
                                                    : // Si no hay valor pero sí un máximo, abrimos por el máximo.
                                                    max
                                                        ? time === max
                                                        : false
                                        }
                                        isPopoverOpen={isOpen}
                                        onPopoverClose={onClose}
                                        onChange={onChange}
                                    />
                                ))}
                            </Flex>
                        </PopoverBody>
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
};