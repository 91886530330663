// Chakra UI
import { Box, ChakraProvider, Flex, useDisclosure } from '@chakra-ui/react'
import theme from './theme';
// Prime React
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
// React
import { BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useState } from 'react';
// Interfaces
import { UpdateUserInt, UserInt } from './interfaces/UserInt';
// Components
import { RouterController } from './shared/components/RouterController/RouterController';
import { ProgressController } from './shared/controllers/ProgressController';
// Context
import { AuthContext } from './shared/context/user.context';
import { notify, StatusEnum } from './shared/utils/functions/notify';
import { Sidebar } from './shared/components/Sidebar/Sidebar';
import { useSWRConfig } from 'swr';
import ScrollToTop from './shared/helpers/ScrollToTop';
import { UrlContext, UrlInt } from './shared/context/url.context';
import { UrlTypeEnum } from './shared/utils/Types/UrlTypeEnum';
import { ModalFundae } from './shared/components/Modals/ModalFundae';
import { isRoleAllowed } from './shared/utils/functions/validateRol';
import { UserRolEnum } from './shared/utils/Types/RolEnum';
import { Topbar } from './shared/components/Topbar/Topbar';
import { useMatriculasAlumno } from './shared/middlewares/matriculas.middleware';
import { OutWindowController } from './shared/controllers/OutWindowController';
import * as Sentry from "@sentry/browser";
import { MatriculasInt } from './interfaces/MatriculasInt';

function App() {
  const { mutate } = useSWRConfig();

  const clearCache = async () => mutate(
    () => true,
    undefined,
    false
  )

  useEffect(() => {
    const url: UrlInt = window.origin;

    setUrl(url)
  }, [])

  const login = (
    jwt: string,
    user: UserInt,
    navigate: (path: string) => void
  ) => {

    const perfilUser: UserInt = {
      auth: true,
      tracking: true,
      id: user?.id,
      email: user?.email,
      username: user?.username ? user.username : '',
      avatar: {
        url: user?.avatar?.url ? user.avatar?.url : '',
      },
      nombre: user?.nombre,
      apellidos: user?.apellidos,
      localidad: user?.localidad ? user.localidad : '',
      telefono: user?.telefono ? user.telefono : '',
      dni: user?.dni ? user.dni : '',
      role: {
        nombre: user?.role?.nombre ? user?.role?.nombre : ''
      },
      matriculas: user?.matriculas ? user?.matriculas : [],
      empresa: user?.empresa ? user?.empresa : {},
      horarioLaboral: user?.horarioLaboral ? user?.horarioLaboral : null,
      tutorFreelance: user?.tutorFreelance ? user?.tutorFreelance : null,
    }

    localStorage.setItem('token', jwt)
    localStorage.setItem('perfil', JSON.stringify(perfilUser))

    setUser(perfilUser);

    navigate('/')
  };

  const logout = async (
    message: string,
    navigate: (path: string) => void,
    toast: any,
  ) => {
    await clearCache();

    localStorage.removeItem('token');
    localStorage.removeItem('perfil');

    setUser({
      auth: false,
      tracking: false,
      id: null,
      email: null,
      username: null,
      avatar: null,
      nombre: null,
      apellidos: null,
      localidad: null,
      telefono: null,
      dni: null,
      role: null,
      matriculas: [],
      empresa: {},
      horarioLaboral: null,
      tutorFreelance: null
    });

    navigate('/login')
    notify(toast, StatusEnum.info, message)
  };

  const refreshUser = async (updateUser: UpdateUserInt) => {
    for (const [key, value] of Object.entries(updateUser)) {
      if (key) {
        setUser((prev: UserInt) => ({
          ...prev,
          [key]: value
        }));

        localStorage.setItem('perfil', JSON.stringify(user))
      }
    }
  };

  const perfilUser: UserInt = JSON.parse(localStorage.getItem('perfil') || '{}');
  const [user, setUser] = useState<UserInt>({
    auth: localStorage.getItem('token') ? true : false,
    tracking: perfilUser?.tracking ? perfilUser.tracking : true,
    id: perfilUser?.id ? perfilUser.id : null,
    email: perfilUser?.email ? perfilUser.email : null,
    username: perfilUser?.username ? perfilUser.username : null,
    avatar: perfilUser?.avatar ? {
      url: perfilUser.avatar?.url
    } : null,
    nombre: perfilUser?.nombre ? perfilUser.nombre : null,
    apellidos: perfilUser?.apellidos ? perfilUser.apellidos : null,
    localidad: perfilUser?.localidad ? perfilUser.localidad : null,
    telefono: perfilUser?.telefono ? perfilUser.telefono : null,
    dni: perfilUser?.dni ? perfilUser.dni : null,
    role: perfilUser?.role ? {
      nombre: perfilUser?.role?.nombre
    } : null,
    matriculas: perfilUser?.matriculas ? perfilUser?.matriculas : [],
    empresa: perfilUser?.empresa ? perfilUser?.empresa : {},
    horarioLaboral: perfilUser?.horarioLaboral ? perfilUser?.horarioLaboral : null,
    tutorFreelance: perfilUser?.tutorFreelance ? perfilUser?.tutorFreelance : null
  });

  const { isOpen: isOpenFundae, onClose: onCloseFundae, onOpen: onOpenFundae } = useDisclosure();

  useEffect(() => {
    localStorage.setItem('perfil', JSON.stringify(user))

  }, [user])

  const [url, setUrl] = useState<UrlInt>()

  const themeChakra = theme({
    urlType: url === UrlTypeEnum.PLATAFORMA ? "plataforma" : "campus",
    empresa: user?.empresa?.config
  })

  useEffect(() => {
    if (isRoleAllowed([UserRolEnum.ALUMNO], user?.role?.nombre) && user?.auth)
      user?.matriculas?.map((matricula: MatriculasInt) => {
        if (matricula?.grupo?.fundae === true) {
          user?.horarioLaboral === null || user?.horarioLaboral === undefined
            ? onOpenFundae()
            : null
        }
      })
  }, [user])

  const { matriculas } = useMatriculasAlumno({ user: user });

  useEffect(() => {
    if (!matriculas || matriculas?.data?.length === 0) return

    refreshUser({
      matriculas: matriculas?.data
    })
  }, [matriculas])

  useEffect(() => {
    if (user?.email && user?.id && user?.username)
      Sentry.setUser({
        id: user?.id,
        username: user?.username,
        email: user?.email,
      })
  }, [user?.email, user?.id, user?.username])

  return (
    <Router basename='/'>
      <AuthContext.Provider value={{ user, setUser, login, logout, refreshUser }} >
        <UrlContext.Provider value={url}>
            <ProgressController>
              <ChakraProvider theme={themeChakra}>
                <OutWindowController>
                  <ScrollToTop />

                  <Flex>
                    {user.auth && <Sidebar />}

                    <Box flex="1">
                      {user.auth &&
                        <Topbar />
                      }

                      <Box
                        overflow="auto"
                        w="100%"
                        maxH={user.auth ? "calc(100vh - 80px)" : "100vh"}
                        css={{
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: "#e6e6ea",
                            borderRadius: '20px',
                          },
                        }}
                      >
                        <RouterController />
                      </Box>
                    </Box>
                  </Flex>
                  <ModalFundae isOpen={isOpenFundae} onClose={onCloseFundae} />
                </OutWindowController>
              </ChakraProvider>
            </ProgressController>
        </UrlContext.Provider>
      </AuthContext.Provider>
    </Router>
  )
}

export default App
