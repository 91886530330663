import { Flex, Img, Text } from '@chakra-ui/react'
import { truncate } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { PreguntaForoInt } from '../../../interfaces/ForoInt';

interface Props {
    avatar: any;
    nombre: string;
    descripcion: string;
    tiempo: any;
    pregunta: PreguntaForoInt;
}

export default function CardForo({ avatar, nombre, descripcion,  tiempo, pregunta }: Props) {
    const navigate = useNavigate();


    return (
        <Flex
            flex="1"
            cursor="pointer"
            _hover={{ borderColor: "secondary_variant" }}
            onClick={() => navigate(`/foro/${pregunta?.temaId}/pregunta/${pregunta?.id}`)}
            justifyContent="center"
            align="center"
            gap="10px"
        >

            <Flex
            flex="1"
            gap="15px"
            minW="146px">
            <Img
                w="36px"
                h="36px"
                objectFit="cover"
                rounded="full"
                src={avatar} />

                    <Flex
                        direction="column"
                    >
                        <Text
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                            whiteSpace="nowrap"
                            
                        >
                            {truncate(nombre, { length: 25 })}
                        </Text>

                        <Text
                            color="dark_grey"
                            fontWeight="400"
                            fontSize="12px"
                            whiteSpace="nowrap"
                            
                        >
                            {truncate(pregunta?.titulo, { length: 25 })}
                        </Text>
                    </Flex>
                </Flex>

                <Flex
                flex="1"
                justify="end"
                >
                    <Text
                        color="dark_grey"
                        fontSize="12px"
                        fontWeight="400"
                        align="end"
                    >
                        {tiempo}
                    </Text>
                    </Flex>
                
        </Flex>
    )
}
