import { Flex, Skeleton } from "@chakra-ui/react";

export const SkeletonPanel = () => {

    return (
        <Flex direction="column" w="75%">
            <Flex
                p="10px 15px"
                alignItems="center"
                gap="10px"
                h="100px"
                borderBottom="1px solid"
                borderColor="light_grey"
            >
                <Flex
                    direction="column"
                    gap="7px"
                >
                    <Skeleton
                        h="30px"
                        w="450px"
                        rounded="5px"
                    />

                    <Skeleton
                        h="17px"
                        w="250px"
                        rounded="5px"
                    />
                </Flex>
            </Flex>

            <Flex flex="1" />

            <Flex
                p="20px"
                gap="5px"
                direction="column"
                borderTop="1px solid"
                borderColor="light_grey"
            >
                <Skeleton
                    h="40px"
                    w="50%"
                    rounded="5px"
                />

                <Skeleton
                    h="155px"
                    rounded="5px"
                />
                <Flex
                    w="100%"
                    justify="flex-end"
                >
                    <Skeleton
                        h="40px"
                        w="160px"
                        rounded="5px"
                    />
                </Flex>
                
            </Flex>
        </Flex>
    );
};