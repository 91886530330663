import React, { useState, useEffect } from 'react';
// import { Editor } from "primereact/editor";
import { BiClipboard } from 'react-icons/bi';
import { Spinner, Icon, useToast, Flex, FormLabel, border } from '@chakra-ui/react';
import { AiOutlineFileSync, AiOutlineCloudSync } from 'react-icons/ai';
import { StatusEnum, notify } from '../../utils/functions/notify';
import { CustomQuill } from '../Customs/CustomQuill';

type InformationTextEditorProps = {
    name: string;
    label?: string;
    placeholder?: string;
    allowCopy?: boolean;
    dataToSync?: any;
    defaultValue?: string;
    isDisabled?: boolean;
    style?: React.CSSProperties;
    updateValue: (e?: any) => void | any;
    noHead?: boolean;
    editorStyle?: React.CSSProperties;
};

export const InformationTextEditor = ({
    name,
    label,
    style,
    isDisabled,
    placeholder,
    dataToSync = '',
    defaultValue = '',
    allowCopy = false,
    updateValue,
    noHead = false,
    editorStyle,
}: InformationTextEditorProps) => {
    const toast = useToast();

    const [value, setValue] = useState<string>(defaultValue);
    const [valueToSync, setValueToSync] = useState<string>(dataToSync);

    const [update, setUpdate] = useState<'idle' | 'editing' | 'loading'>('idle');

    useEffect(() => {
        setValueToSync(dataToSync);
    }, [dataToSync]);

    const onChange = (e: string) => {
        setValue(e);
    };

    const onBlur = () => {
        setUpdate('loading');

        updateValue({ [name]: value })
            .then((res: any) => {
                setUpdate('idle');
            });
    }

    const syncData = () => {
        if (value) {
            notify(toast, StatusEnum.error, '¡Borra toda la información actual para sincronizar!');
        } else {
            setUpdate('loading');

            if (updateValue)
                updateValue({ [name]: valueToSync }).then((res: any) => {
                    setUpdate('idle');
                });
        }
    };

    return (
        <Flex
            direction="column"
            fontSize="14px"
            style={style}
        >
            {!noHead && (
                <Flex as="label">
                    <FormLabel mb="10px" display="flex">
                        {label}

                        <Flex w="fit-content" align="center" gap="4px">
                            {update === 'editing' ? (
                                <Icon ml="2" as={AiOutlineCloudSync} color={value === defaultValue ? 'gray_5' : 'primary'} />
                            ) : update === 'loading' ? (
                                <Spinner ml="2" boxSize="14px" />
                            ) : null}

                            {allowCopy && value && update === 'idle' && (
                                <Icon
                                    as={BiClipboard}
                                    title="Pulsa para copiar"
                                    className="clipboard-button"
                                    data-clipboard-text={value}
                                    onClick={() => notify(toast, StatusEnum.success, 'Texto copiado')}
                                />
                            )}

                            {valueToSync && (
                                <Icon
                                    as={AiOutlineFileSync}
                                    ml="8px"
                                    boxSize="22px"
                                    cursor="pointer"
                                    onClick={syncData}
                                    title="Pulsa para sincronizar la información"
                                />
                            )}
                        </Flex>
                    </FormLabel>
                </Flex>
            )}

            <CustomQuill
                value={value}
                setValue={onChange}
                onBlur={onBlur}
                editorStyle={{
                    minHeight: '350px',
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #e2e8f0",
                    color: "#3D3F65", 
                    fontSize: "16px", 
                    borderRadius: "5px"
                }}
                placeholder={placeholder}
            />

            {/* <Editor
                value={defaultValue}
                onBlur={onBlur}
                onTextChange={(e: any) => onChange(e.htmlValue)}
                style={{
                    ...editorStyle,
                    height: '320px',
                    backgroundColor: "#FFFFFF",
                }}
            /> */}
        </Flex>
    );
};