interface Props {
  empresa: {
    main: string,
    secondary: string,
  } | null;
  urlType: "plataforma" | "campus";
}

export const SetColors = ({ empresa, urlType }: Props) => {
  
  const colors = {
    main: (!empresa?.main || urlType !== "plataforma") ? '#0F1122' : empresa?.main,
    secondary: (!empresa?.secondary || urlType !== "plataforma") ? '#8694FF' : empresa?.secondary,
    main_variant: '#EAECFF',
    secondary_variant: "#C7CDFF",
    bg_color: '#F2F2FD',
    border: '#E2E8F0',
    gradient: '--Linear-2, linear-gradient(135deg, #21D4FD 0%, #4FD1C5 100%)',
    dark_blue: '#00152D',
    grey: '#F1F3F6',
    light_grey: '#EAEAEA',
    dark_grey: '#959596',
    pureblack: '#000',
    white: '#F8F8F8',
    purewhite: '#FFFFFF',
    disable: '#A0AEC0',
    success: "#76E874",
    success_variant: "#14804A",
    success_bg: "#E1FCEF",
    fail: "#F24343",
    fail_bg: "#FFDBE3",
    blue: "#4FD1C5",
    blue_bg: "#C1FFF9",
    font: "#3D3F65",
    warning:"#FFF7B4",
    pending: "#EFC569",
    warning_variant:"#867800",
    secondary_variant_font: '#959596',
    main_shadow: '0px 4px 15px 0px rgba(199, 205, 255, 0.60)',


    "chakra-body-bg": {
      _light: "#F2F2FD",
      _dark: "#1a202c",
    },
  }

  return colors;
}
