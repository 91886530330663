import { UpdateProgressInt } from "../../interfaces/ProgressInt";
import useSWRImmutable from 'swr/immutable'
import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

const { tenant, client } = await tenantInstance();

const ENDPOINT_PROGRESOS = 'progresos'

export const getProgresos = async (endpoint: string) => await _axios.get(endpoint).then((response: any) => response)

export const useProgresos = ({
    cursoId,
    matriculaId
}: {
    cursoId: string,
    matriculaId: string
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_PROGRESOS + `?curso=${cursoId}&matricula=${matriculaId}`
    const { isLoading, data, mutate } = useSWRImmutable(
        (cursoId && matriculaId)
            ? endpoint
            : null,
        getProgresos
    );

    return {
        progreso: data?.data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const uploadProgress = async (data: UpdateProgressInt) => {
    const { tenant, client } = await tenantInstance();

    return await _axios.post(
        `${tenant}/${client}/progresos`,
        data,
        {}
    );
};