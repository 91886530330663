export enum LessonTypeEnum {
  VIDEO = 'video',
  MARKDOWN = 'markdown',
  DIAPOSITIVA = 'diapositiva',
  ENTREGABLE = 'entregable',
  RECURSO = 'recurso',
  ZOOM = 'zoom',
  AUTOCORREGIBLE = 'autocorregible',
  LIVECODER = 'livecoder',
  ENUNCIADO = 'enunciado',
}

export enum LessonModeEnum {
  BLOQUEADA = 'blocked',
  CARGANDO = 'loading',
  SIGUIENTE = 'next',
  END = 'end'
}