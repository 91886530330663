// Chraka UI
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Icon, Text, Spinner, Skeleton } from "@chakra-ui/react";
// Icons
import { BiCheck, BiX, BiPlay, BiDotsHorizontalRounded } from "react-icons/bi";
// Interfaces
import { ModulosInt, LeccionInt } from "../../../../interfaces/CoursesInt";
// Lodash
import { truncate } from "lodash";
// React
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { AccordeonHeader } from "./AccordeonHeader";
import { useProgressContex } from "../../../../shared/context/progress.context";
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../shared/utils/Types/RolEnum";
import { useAuthContex } from "../../../../shared/context/user.context";
import { sortByOrder, validateProgressModule } from "../../../../shared/utils/functions/validateProgress";
import { LessonTypeEnum } from "../../../../shared/utils/Types/LessonEnum";
import { EntregablesInt } from "../../../../interfaces/Entregables";
import { EntregableEstadoEnum } from "../../../../shared/utils/Types/EntregableEstadoEnum";

interface Props {
    dataModulos: ModulosInt[] | undefined;
    width?: string;
    maxHeight?: string;
    leccionActual?: any
}

export const AccordeonModules = ({
    dataModulos,
    leccionActual
}: Props) => {
    const { cursoProgress } = useProgressContex();
    const { user } = useAuthContex();
    const navigate = useNavigate();
    const [inTop, setInTop] = useState<boolean>(false);
    const [indexExpanded, setIndexExpanded] = useState<number>(-1)
    const [search] = useSearchParams()
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""

    const handleChangeExpanded = (expandedIndex: number) => setIndexExpanded(expandedIndex)

    const handleScroll = (e: any) => {
        const element = e.target.scrollTop;

        if (element > 0) setInTop(true);
        else setInTop(false);
    }

    const checkLessonComplete = (leccionId: string) => {
        if (Reflect.has(cursoProgress?.data || {}, leccionId)) {
            if (cursoProgress?.data[leccionId]?.estado === "finalizacion") {
                return true
            }
        }

        return false
    };

    const checkLessonStart = (leccionId: string) => {
        if (Reflect.has(cursoProgress?.data || {}, leccionId)) {
            return true
        }

        return false
    };

    const checkEntrega = (entregables: EntregablesInt[] | undefined) => {
        if (!entregables || entregables?.length === 0) return { color: "light_grey", icon: BiCheck, colorIcon: "transparent"}

        const entregablesCompletos = entregables.filter(entrega => entrega.estado === EntregableEstadoEnum.CORRECTO)
        const entregablesPendientes = entregables.filter(entrega => entrega.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION)
        const entregablesSuspendidos = entregables.filter(entrega => entrega.estado === EntregableEstadoEnum.ERROR)

        if(entregablesCompletos?.length > 0) return { color: "success", icon: BiCheck, colorIcon: "purewhite" }
        else if(entregablesPendientes?.length > 0 && entregablesCompletos?.length === 0) return { color: "pending", icon: BiDotsHorizontalRounded, colorIcon: "purewhite" }
        else if(entregablesSuspendidos?.length > 0 && entregablesCompletos?.length === 0 && entregablesPendientes?.length === 0) return { color: "fail", icon: BiX, colorIcon: "purewhite" }
    }

    return (
        <Flex
            display="flex"
            direction="column"
            w="100%"
            maxH="calc(100vh - 150px)"
            transition="all 200ms"
            bg="purewhite"
            rounded="5px"
            boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
        >
            <AccordeonHeader
                inTop={inTop}
            />

            <Accordion
                overflow="auto"
                className="scroll-bar"
                onScroll={handleScroll}
                allowToggle
                py="15px"
                index={indexExpanded}
                onChange={handleChangeExpanded}
            >
                {sortByOrder(dataModulos)?.map((modulo: ModulosInt, index: number) => (
                    <AccordionItem
                        key={index}
                        border="none"
                        isDisabled={!open}
                        w="100%"
                    >
                        <AccordionButton
                            _disabled={{ opacity: "1", cursor: "not-allowed" }}
                            display="flex"
                            p="20px 30px"
                            justifyContent="space-between"
                            // _expanded={{ borderRadius: "12px 12px 0 0", borderBottom:"1px solid", borderColor:"light_grey" }}
                            bgColor={modulo?.id === leccionActual?.modulo?.id ? "main_variant" : ""}
                        >
                            <Flex
                                alignItems="center"
                                gap="10px"
                            >
                                {/* <CircularProgress
                                    orden={modulo?.orden}
                                    progress={validateProgressModule(cursoProgress, modulo)}
                                /> */}

                                <Flex gap="30px" boxSize="20px">
                                    {validateProgressModule(cursoProgress, modulo) > 99 && (
                                        <Icon
                                            color="#fff"
                                            bg="secondary"
                                            boxSize="20px"
                                            rounded="full"
                                            as={BiCheck}
                                        />
                                    )}
                                </Flex>

                                <Flex
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    p="5px"
                                    // bg={leccionSeleccionada === modulo.slug ? 'secondary_variant' : 'transparent'}
                                    rounded="12px"
                                >
                                    <Text
                                        color="dark_blue"
                                        fontSize="16px"
                                        fontWeight="500"
                                        align="start"
                                    >
                                        {truncate(modulo?.nombre, { length: 50 })}
                                    </Text>
                                </Flex>
                            </Flex>

                            <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel
                            display="flex"
                            borderRadius="0 0 12px 12px"
                        >
                            <Flex
                                flexDirection="column"
                                w="100%"
                            >
                                {sortByOrder(modulo?.lecciones)?.map((leccion: LeccionInt, index: number) => (
                                    <Flex
                                        key={index}
                                        justifyContent="space-between"
                                        py="12px"
                                        px="10px"
                                        mb="5px"
                                        cursor="pointer"
                                        _hover={{ bg: "main_variant" }}
                                        rounded="12px"
                                        gap="20px"
                                        // border={leccionSeleccionada === leccion.slug ? '1px solid #EFC569' : '1px solid transparent'}
                                        bgColor={leccion?.id === leccionActual?.id ? "main_variant" : ""}
                                        onClick={() => {
                                            // setLeccionSeleccionada(leccion.slug);
                                            navigate(`/leccion/${leccion?.slug}${addRedirect}`);
                                        }}

                                    >
                                        <Flex
                                            alignItems="center"
                                            gap="10px"
                                        >
                                            {isRoleAllowed([UserRolEnum.ALUMNO], user?.role?.nombre) &&
                                                <Flex
                                                    ml="20px"
                                                    align="center"
                                                    rounded="full"
                                                    bg={
                                                        checkLessonComplete(leccion?.id)
                                                            ? "success"
                                                            // (leccion?.tipo === LessonTypeEnum.ENTREGABLE 
                                                            //     ? checkEntrega(leccion?.entregables)?.color
                                                            //     : "success"
                                                            // )
                                                            : "light_grey"
                                                    }
                                                >
                                                    <Icon
                                                        as={
                                                            checkLessonComplete(leccion?.id)
                                                                ? BiCheck
                                                                // (leccion?.tipo === LessonTypeEnum.ENTREGABLE 
                                                                //     ? checkEntrega(leccion?.entregables)?.icon
                                                                //     : BiCheck
                                                                // )
                                                                : BiCheck
                                                        }
                                                        boxSize="24px"
                                                        color={
                                                            checkLessonComplete(leccion?.id)
                                                                ? "purewhite"            
                                                                // (leccion?.tipo === LessonTypeEnum.ENTREGABLE 
                                                                //     ? checkEntrega(leccion?.entregables)?.colorIcon
                                                                //     : "purewhite"
                                                                // )
                                                                : "transparent"
                                                        }
                                                    />
                                                </Flex>

                                            }

                                            <Text
                                                color="font"
                                                fontSize="14px"
                                                fontWeight="500"
                                                letterSpacing="-0.14px"
                                            >
                                                {leccion?.nombre}
                                            </Text>
                                        </Flex>

                                        <Flex
                                            alignItems="center"
                                            gap="15px"
                                        >
                                            {checkLessonStart(leccion?.id) &&
                                                <Icon
                                                    as={BiPlay} // : BiLockAlt 
                                                    boxSize="24px"
                                                    mt="2px"
                                                />
                                            }
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Flex>
    )
};