import { Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { NotasInt } from "../../../interfaces/NotasInt";
import { CardNotas } from "../components/CardNotas";
import { useEffect, useState } from "react";
import { truncate } from "lodash";
import { useCursosNotas, useNotaId } from "../../../shared/middlewares/notas.middleware";
import { NotaItem } from "../components/NotaItem";
import { SkeletonNotas } from "../components/utils/SkeletonNotas";

interface Tags {
    nombre: string;
    id: string;
    query: string;
}

interface Props {
    notas: NotasInt[] | undefined;
    query: string;
    setQuery: (query: string) => void;
}

export const InformationNotas = ({ notas, setQuery }: Props) => {
    const { notasId } = useParams()
    const [tags, setTags] = useState<Tags[]>()
    const { cursos } = useCursosNotas();
    const { nota, loading, Refresh } = useNotaId(notasId)

    useEffect(() => {
        const tagsList: Tags[] = []

        if (!cursos) return setTags(tagsList)

        cursos?.map((curso: any) => {
            return tagsList.push({
                nombre: curso?.leccion?.modulo?.curso?.nombre,
                id: curso?.leccion?.modulo?.cursoId,
                query: `?curso=${curso?.leccion?.modulo?.cursoId}`
            })
        })

        tagsList.push({
            nombre: "Todas",
            id: "",
            query: ""
        })

        const tags = tagsList.filter((value, index, self) => index === self.findIndex((t) => (t.id === value.id)));

        setTags(tags)
    }, [cursos])

    return (
        loading ? <SkeletonNotas />
            :
            <Flex
                w="100%"
                gap="40px"
                direction="column"
            >
                <Text
                    fontSize="28px"
                    fontWeight="600"
                    color="font"
                >
                    {nota?.leccion?.modulo?.curso?.nombre}
                </Text>

                <Flex
                    w="100%"
                    gap="20px"
                >
                    <Flex
                        direction="column"
                        gap="40px"
                    >

                        <Flex
                            gap="20px"
                        >
                            {tags?.map((tag: Tags, index: number) => (
                                <Text
                                    p="5px 10px"
                                    rounded="100px"
                                    bg={"main"}
                                    color="purewhite"
                                    fontSize="14px"
                                    fontWeight="400"
                                    key={index}
                                    cursor="pointer"
                                    onClick={() => setQuery(tag?.query)}
                                >
                                    {/* {truncate(tag?.nombre, { length: 15, omission: "..." })} */}
                                    {tag?.nombre}
                                </Text>
                            ))}
                        </Flex>

                        <Flex
                            direction="column"
                            gap="40px"
                            overflow="auto"
                            className="scroll-bar"
                            maxH="calc(100vh - 200px)"
                            pr="5px"
                            h="100%"
                        >
                            {notas?.map((nota: NotasInt, index: number) => (
                                <CardNotas
                                    key={index}
                                    nota={nota}
                                    notaId={notasId}
                                    setRefresh={Refresh}
                                />
                            ))}
                        </Flex>
                    </Flex>
                    <Flex flex="1">
                        <NotaItem
                            nota={nota}
                            setRefresh={Refresh}
                        />
                    </Flex>
                </Flex>


            </Flex>
    );
}