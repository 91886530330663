import { Flex, Icon, Text } from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa"
import { PreguntaForoInt } from "../../../../interfaces/ForoInt";
import { useNavigate } from "react-router-dom";
import { formatDistance } from "date-fns";
import { es } from "date-fns/locale";

interface Props {
    pregunta: PreguntaForoInt;
}

export const CardNotificaciones = ({ pregunta }: Props) => {
    const navigate = useNavigate();


    return (
        <Flex
            onClick={() => navigate(`/foro/${pregunta?.temaId}/pregunta/${pregunta?.id}`)}
            bg="purewhite"
            borderRadius="10px"
            w="100%"
        >
            <Flex
                w="100%"
                borderRadius="10px"
                bg="purewhite"
                borderWidth="1px"
                borderColor="grey"
                p="20px"
                justifyContent="space-between"
                boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.40);"
                _hover={{ backgroundColor: "main_variant" }}
                cursor="pointer"
            >
                <Flex
                    direction="column"
                    gap="5px"
                >
                    <Flex alignItems="center" gap="6px">
                        <Flex>
                            <Icon as={FaCircle} color="main" boxSize="10px" />
                        </Flex>

                        <Text
                            color="font"
                            fontSize="16px"
                            fontWeight="500"
                            letterSpacing="-0.15px"
                        >
                            {pregunta?.tema?.titulo || "Titulo del Tema"}
                        </Text>
                    </Flex>

                    <Text
                        color="secondary_variant_font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {pregunta?.titulo}
                    </Text>

                    <Flex
                        color="dark_grey"
                        fontSize="12px"
                        fontWeight="400"
                        gap="5px"
                    >
                        <Text>{pregunta?.user?.username}</Text>
                        ·
                        <Text
                        >
                            {pregunta?.createdAt && formatDistance(new Date(pregunta?.createdAt), new Date(), { locale: es })}
                        </Text>
                        ·
                        <Text>{pregunta?.meta?.totalRespuestas} respuestas</Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}