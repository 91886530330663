import { Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Text, useToast } from '@chakra-ui/react'
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { FormInput } from '../../../shared/components/FormElements/FormInput';
import { FormTextEditor } from '../../../shared/components/FormElements/FormTextEditor';
import { StatusEnum, notify } from '../../../shared/utils/functions/notify';
import { addNota } from '../../../shared/middlewares/notas.middleware';

interface Props {
    onClose: () => void;
    isOpen: boolean;
    leccionId: string | undefined;
}

export default function NotasModal({ isOpen, onClose, leccionId }: Props) {
    const toast = useToast();

    const validationSchema = Yup.object().shape({
        titulo: Yup.string()
            .required("Debe establecer un titulo"),
        contenido: Yup.string()
            .required("Debe establecer un contenido")
    });

    const initialValues = {
        titulo: null,
        contenido: null
    };

    const submitForm = async (values: any) => {
        if(!leccionId) return

        const nota = {
            titulo: values?.titulo,
            contenido: values?.contenido,
            leccionId: leccionId
        }

        addNota(nota)
        .then(() => {
            notify(toast, StatusEnum.success, "Nota creada")
            handleClose();
        })
        .catch(() => notify(toast, StatusEnum.error, "Error al crear nota"))
    };

    const handleClose = () => {
        onClose();
    }

    return (
        <Modal 
            size="3xl" 
            onClose={handleClose} 
            isOpen={isOpen}
        >
            <ModalContent
                style={{
                    position: "absolute",
                    right: 495,
                    top: 250
                }}
                bgColor="purewhite"
                borderWidth="1px"
                borderColor="light_grey"
            >

                <ModalHeader>
                    <Text color="font" fontSize="16px" fontWeight="500px" lineHeight="normal"  mb="-10px" ml="5px">
                        Crear nota
                    </Text>
                </ModalHeader>

                <Formik
                        onSubmit={(values, { resetForm }) => {
                            submitForm(values);
                            resetForm();
                        }}
                        enableReinitialize 
                        initialValues={initialValues} 
                        validationSchema={validationSchema}
                    >
                    {(formik) => {
                        const { handleSubmit } = formik;


                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                            >

                                <ModalBody p="10px 30px">

                                    <Flex direction="column" gap="20px" >
                                        <FormInput placeholder='Título' name='titulo' />

                                        <FormTextEditor height="200px" label='' name='contenido' onChange={formik.setFieldValue} />
                                    </Flex>

                                </ModalBody>

                                <ModalFooter gap="20px">
                                    <Button
                                        color="font"
                                        bgColor="purewhite"
                                        rounded="10px"
                                        fontSize="15px"
                                        fontWeight="400"
                                        lineHeight="normal"
                                        onClick={handleClose}
                                    >
                                        Cancelar
                                    </Button>
                                    
                                    <Button
                                        type='submit'
                                        color="purewhite"
                                        bgColor="main"
                                        rounded="10px"
                                        fontSize="15px"
                                        fontWeight="400"
                                        lineHeight="normal"
                                        _hover={{ color: "black", bgColor: "light_grey" }}
                                    >
                                        Guardar
                                    </Button>
                                </ModalFooter>

                            </FormikForm>
                        );
                    }}
                </Formik>
            </ModalContent>

        </Modal>
    )
}
