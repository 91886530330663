import { Button, Flex, Icon, Input, InputGroup, InputLeftElement, Text } from "@chakra-ui/react";
import { BiPlusCircle, BiSearch } from "react-icons/bi";
import { useState } from "react";
import { ChatsItem } from "../components/ChatsItem";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../shared/utils/Types/RolEnum";
import { useAuthContex } from "../../../shared/context/user.context";

interface Props {
    allChats: any;
    selected: any;
    onOpen: () => void;
}

export const Chats = ({ allChats, selected, onOpen }: Props) => {
    const { user } = useAuthContex();
    const [search, setSearch] = useState<string>('');

    return (
        <Flex
            direction="column"
            borderRight="1px solid"
            borderColor=" light_grey"
            w="25%"
            maxW={"347.5px"}
        >
            <Flex
                direction="column"
                h="100px"
                borderBottom="1px solid"
                borderColor="light_grey"
                p="12px 24px"
                gap="8px"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text
                        color="font"
                        fontSize="16px"
                        fontWeight="400"
                    >
                        Dudas
                    </Text>

                    {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                        <Button
                            bg="secondary"
                            fontSize="16px"
                            fontWeight="500"
                            color="purewhite"
                            rounded="5"
                            p="6px 12px"
                            h="fit-content"
                            _hover={{ bg: "#C7CDFF" }}
                            onClick={onOpen}
                        >
                            Nueva duda
                        </Button>
                    }
                </Flex>

                <Flex mb="5px">
                    <InputGroup w="100%" bg="inherit" rounded="8px">
                        <InputLeftElement mt="1px">
                            <Icon as={BiSearch} color="dark_grey" boxSize="18px" />
                        </InputLeftElement>

                        <Input
                            color="dark_grey"
                            fontSize="13px"
                            fontWeight="500"
                            lineHeight="12.417px"
                            letterSpacing="-0.061px"
                            border="1px solid"
                            borderColor="light_grey"
                            value={search}
                            placeholder="Buscar mensaje"
                            _placeholder={{
                                color: "dark_grey",
                                fontSize: "13px",
                                fontWeight: "500",
                                lineHeight: "12.417px",
                                letterSpacing: "-0.061px",
                            }}
                            onChange={(e: any) => setSearch(e.target.value)}
                        />
                    </InputGroup>
                </Flex>
            </Flex>

            <Flex direction="column" overflow="auto" className="scroll-bar">
                {allChats?.length == 0 ?
                    <Flex display="column" mt="20px">
                        <Text 
                        textAlign="center"
                        color="font"
                        >
                            No hay mensajes
                        </Text>
                    </Flex>
                    :
                    allChats && allChats?.map((chat: any, index: number) => (
                        <ChatsItem
                            key={index}
                            selected={selected}
                            chat={chat}
                        />
                    ))}
            </Flex>
        </Flex>
    );
};