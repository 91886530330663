import { Badge, Flex } from "@chakra-ui/react";
import { ColumnProps, CustomTable } from "../../shared/components/CustomTable/CustomTable";
import { FiltersTable } from "../../shared/components/TableElements/FiltersTable";
import { useEffect, useState } from "react";
import TopFacturacion from "./components/TopFacturacion";
import { useLiquidaciones } from "../../shared/middlewares/facturacion.middlewares";
import { CardSinInformacion, TypeCardSinInformacion } from "../../shared/components/Customs/CardSinInformacion";
import { format } from "date-fns";


export const Facturacion = () => {
    const [page, setPage] = useState<number>(1)
    const [queryTable, setQueryTable] = useState<string>(`?page=${page}`);
    const [query, setQuery] = useState<string>("");
    const { liquidaciones, loading } = useLiquidaciones(queryTable + query)
    const [statusData, setStatusData] = useState<"loading" | "idle" | "success">("loading")

    useEffect(() => {
        if (loading) setStatusData("loading")
        if (!loading && liquidaciones?.data && liquidaciones?.data?.length > 0) setStatusData("success")
        if (!loading && liquidaciones?.data && liquidaciones?.data?.length === 0) setStatusData("idle")
    }, [liquidaciones, loading])

    const columns: ColumnProps[] = [
        {
            header: 'Nº de Factura',
            field: 'numeroFactura',
            key: 'numeroFactura',
            sortable: true,
            body: (rowData: any) => (
                <Flex>
                    {rowData?.numeroFactura || "--"}
                </Flex>
            )
        },
        {
            header: 'Pagado',
            field: 'liquidada',
            key: 'liquidada',
            sortable: true,
            body: (rowData: any) => (
                <Flex>
                    <Badge
                        color="purewhite"
                        bg={rowData?.liquidada ? "success" : "fail"}
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="5px 10px"
                    >
                        {rowData?.liquidada ? "Si" : "No"}
                    </Badge>
                </Flex>
            )
        },
        {
            header: 'Empresa Factura',
            field: 'formacion',
            key: 'formacion',
            sortable: true,
            body: (rowData: any) => (
                <Flex>
                    <Badge
                        color="purewhite"
                        bg={rowData?.empresaFactura?.nombre === "Procademy" ? "secondary" : "#081338"}
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="5px 10px"
                    >
                        {rowData?.empresaFactura?.nombre}
                    </Badge>
                </Flex>
            )
        },
        {
            header: 'Importe total',
            field: 'importeTotal',
            key: 'importeTotal',
            sortable: true,
            body: (rowData: any) => (
                <Flex>
                    {rowData?.meta?.importeTotal}€
                </Flex>
            )
        },
        {
            header: 'Fecha de pago',
            field: 'fechaPago',
            key: 'fechaPago',
            sortable: true,
            body: (rowData: any) => (
                <Flex>
                    {rowData?.fechaPago ? format(new Date(rowData?.fechaPago), "dd/MM/yyyy") : "--"}
                </Flex>
            )
        },
    ]

    if (statusData === "idle") return <Flex p="10px 50px"><CardSinInformacion type={TypeCardSinInformacion.LIQUIDACIONES} /></Flex>

    return (
        <Flex
            direction="column"
            p="10px 50px"
            gap="30px"
        >
            <TopFacturacion
                liquidaciones={liquidaciones?.data}
            />

            <FiltersTable
                query={query}
                setQuery={setQuery}
                filterElements={[
                    {
                        label: "Fechas",
                        type: "fecha"
                    },
                ]}
            />

            <CustomTable
                data={liquidaciones?.data || []}
                columns={columns}
                isLoading={loading}
                currentPage={page}
                onPageChange={setPage}
                onQueryChange={setQueryTable}
                total={liquidaciones?.meta?.total || 0}
                height="calc(100vh - 380px)"
                defaultSortBy="createdAt"
                defaultOrder={1}
                skeletonHeight={40}
                emptyMessage="No hay liquidaciones pendientes"
            />
        </Flex>
    )
};