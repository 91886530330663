import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Icon, Img, Skeleton, Text } from "@chakra-ui/react"
import { CursosInt, LeccionInt, ModulosInt } from "../../../../interfaces/CoursesInt"
import { useProgressContex } from "../../../../shared/context/progress.context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BiCheck, BiCheckbox, BiCheckboxChecked, BiPencil, BiPlay } from "react-icons/bi";
import { BarProgress } from "../../../../shared/components/Progress/BarProgress";
import { BsArrowRight, BsCheck, BsPencilSquare, BsPlayCircle } from "react-icons/bs";
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../shared/utils/Types/RolEnum";
import { useAuthContex } from "../../../../shared/context/user.context";
import { LessonTypeEnum } from "../../../../shared/utils/Types/LessonEnum";
import { useEffect, useState } from "react";
import { obtenerNumeroVimeo } from "../../../../shared/utils/functions/getVimeo";
import { sortByOrder, validateProgressModule } from "../../../../shared/utils/functions/validateProgress";
import CardHeaderCursoFinalizado from "../../Components/Card/CardCursoFinalizado";
import { HiOutlineCloudDownload, HiOutlinePencilAlt, HiOutlinePresentationChartBar } from "react-icons/hi";

interface Props {
    curso: CursosInt | undefined;
}

export const TabContenido = ({ curso }: Props) => {

    const { user } = useAuthContex();
    const { cursoProgress } = useProgressContex();
    const progress = Math.round(cursoProgress?.porcentajeCurso || 0)
    const navigate = useNavigate();
    const [thumbnail, setThumbnail] = useState();
    const [leccionActual, setLeccionActual] = useState<LeccionInt>();
    const [search] = useSearchParams()
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""

    const checkLessonComplete = (leccionId: string) => {

        if (Reflect.has(cursoProgress?.data || {}, leccionId)) {
            if (cursoProgress?.data[leccionId]?.estado === "finalizacion") {
                return true
            }
        }

        return false
    };

    const checkLessonStart = (leccionId: string) => {
        if (Reflect.has(cursoProgress?.data || {}, leccionId)) {
            return true
        }

        return false
    };

    const onContinueLeccion = (): { redirectLeccion: string | undefined, modulo: ModulosInt } => {

        let leccionSlug;
        let selectedModulo: any;

        curso?.modulos?.find((modulo: any) =>
            modulo.lecciones?.find((leccion: any) => {
                if (!checkLessonComplete(leccion?.id)) {
                    leccionSlug = leccion?.slug;
                    selectedModulo = modulo

                    return true;
                }

                return false;
            })
        );

        return {
            redirectLeccion: leccionSlug,
            modulo: selectedModulo
        }
    }

    useEffect(() => { obtenerNumeroVimeo(onContinueLeccion, setThumbnail) }, [curso]);

    useEffect(() => {
        const leccion = onContinueLeccion().modulo?.lecciones?.find(
            (leccion: LeccionInt) => leccion.slug === onContinueLeccion().redirectLeccion
        );
        setLeccionActual(leccion)
    }, [curso, onContinueLeccion().redirectLeccion])


    return (
        <Flex
            display="flex"
            direction="column"
            transition="all 200ms"
            gap="40px"
            mt="40px"
        >
            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                (progress > 0 ?
                    !onContinueLeccion().modulo ?
                        <CardHeaderCursoFinalizado
                            image={curso && curso?.imagen?.url}
                        />
                        :
                        <Flex
                            w="100%"
                            direction="column"
                            gap="20px"
                            bg="purewhite"
                            p="30px"
                            rounded="5px"
                        >
                            <Text
                                fontSize="18px"
                                fontWeight="600"
                                color="font"
                            >
                                {onContinueLeccion()?.modulo ? "Continúa por donde lo dejaste" : ""}
                            </Text>

                            <Flex
                                gap="30px"
                            >

                                <Img
                                    rounded="5px"
                                    boxSize="100px"
                                    w="40%"
                                    maxW="650px"
                                    minW="500px"
                                    maxH="260px"
                                    height="80%"
                                    objectFit="cover"
                                    src={curso?.imagen?.url}
                                />

                                <Flex
                                    direction="column"
                                    gap="15px"
                                >
                                    <Text
                                        fontSize="16px"
                                        fontWeight="500"
                                        color="dark_grey"
                                    >
                                        Módulo: {onContinueLeccion().modulo?.nombre}
                                    </Text>

                                    <Flex
                                        alignItems="center"
                                        gap="10px"
                                    >
                                        <Icon
                                            as={BsPlayCircle}
                                            boxSize="24px"
                                            color="secondary"
                                        />
                                        <Text
                                            fontSize="18px"
                                            fontWeight="600"
                                            color="font"
                                        >
                                            {leccionActual?.nombre}
                                        </Text>
                                    </Flex>

                                    <BarProgress
                                        height="5px"
                                        progress={0}
                                    />

                                    <Button
                                        bg="secondary"
                                        w="fit-content"
                                        color="purewhite"
                                        fontSize="16px"
                                        fontWeight="500"
                                        p="10px 16px"
                                        rounded="5px"
                                        h="fit-content"
                                        _hover={{ bg: "secondary_variant" }}
                                        onClick={() => navigate(`/leccion/${onContinueLeccion().redirectLeccion}${addRedirect}`)}
                                        mt="auto"
                                    >
                                        Continuar
                                    </Button>
                                </Flex>

                            </Flex>
                        </Flex>
                    :
                    <Flex
                        w="100%"
                        direction="column"
                        gap="20px"
                        bg="purewhite"
                        p="30px"
                        rounded="5px"
                        mt="40px"
                    >
                        <Text
                            fontSize="18px"
                            fontWeight="600"
                            color="font"
                        >
                            Comienza una nueva lección
                        </Text>

                        <Flex
                            gap="30px"
                        >

                            <Img
                                rounded="5px"
                                boxSize="100px"
                                w="40%"
                                maxW="650px"
                                minW="500px"
                                maxH="260px"
                                height="80%"
                                objectFit="cover"
                                src={curso?.imagen?.url}
                            />


                            <Flex
                                direction="column"
                                gap="15px"
                                w="100%"
                            >
                                <Text
                                    fontSize="16px"
                                    fontWeight="500"
                                    color="dark_grey"
                                >
                                    Módulo: {curso?.modulos[0]?.nombre}
                                </Text>

                                <Flex
                                    alignItems="center"
                                    gap="10px"
                                >
                                    <Icon
                                        as={BsPlayCircle}
                                        boxSize="24px"
                                        color="secondary"
                                    />
                                    <Text
                                        fontSize="18px"
                                        fontWeight="600"
                                        color="font"
                                    >
                                        {curso?.modulos[0]?.lecciones[0]?.nombre}
                                    </Text>
                                </Flex>

                                <BarProgress
                                    height="5px"
                                    progress={0}
                                />

                                <Text
                                    color="dark_grey"
                                    fontSize="16px"
                                    fontWeight="500"
                                >
                                    {curso?.meta?.totalLecciones} lecciones
                                </Text>

                                <Text
                                    fontSize="14px"
                                    color="font"
                                    fontFamily="400"
                                >
                                    {leccionActual?.descripcion}
                                </Text>

                                <Button
                                    bg="secondary"
                                    w="fit-content"
                                    color="purewhite"
                                    fontSize="16px"
                                    fontWeight="500"
                                    p="10px 16px"
                                    rounded="5px"
                                    h="fit-content"
                                    // rightIcon={<Icon as={BsArrowRight} />}
                                    _hover={{ bg: "secondary_variant" }}
                                    onClick={() => navigate(`/leccion/${curso?.modulos[0]?.lecciones[0]?.slug}${addRedirect}`)}
                                    mt="auto"
                                >
                                    Comenzar
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                )
            }

            <Accordion
                allowMultiple
            >
                {sortByOrder(curso?.modulos)?.map((modulo: ModulosInt, index: number) => (
                    <AccordionItem
                        key={index}
                        border={index === 0 ? "none" : ""}
                    >
                        <AccordionButton
                            p="30px 20px"
                            display="flex"
                            justifyContent="space-between"
                            bg="purewhite"
                            gap="20px"
                            w="100%"
                        >

                            <Flex
                                alignItems="center"
                                gap="10px"
                                w="70%">
                                <AccordionIcon />
                                <Text
                                    color="font"
                                    fontSize="18px"
                                    fontWeight="600"
                                >
                                    {modulo?.nombre}
                                </Text>
                            </Flex>

                            <Flex
                                alignItems="center"
                                gap="10px"
                                w="50%"
                            >
                                <Text
                                    color="dark_grey"
                                    fontSize="16px"
                                    fontWeight="400"
                                    whiteSpace="nowrap"
                                >
                                    {modulo?.lecciones?.length} clases
                                </Text>
                                <Text
                                    color="dark_grey"
                                >
                                    ·
                                </Text>
                                <Text
                                    color="dark_grey"
                                    fontSize="16px"
                                    fontWeight="400"
                                    whiteSpace="nowrap"
                                >
                                    {modulo?.lecciones[0]?.duracion}
                                </Text>

                                <Flex
                                    mx="10px"
                                    w="100%"
                                >
                                    <BarProgress
                                        height="6px"
                                        progress={validateProgressModule(cursoProgress, modulo)}
                                    />
                                </Flex>

                                <Text
                                    color="dark_grey"
                                    fontSize="16px"
                                    fontWeight="400"
                                    minW="50px"
                                >
                                    {validateProgressModule(cursoProgress, modulo)?.toFixed(0)} %
                                </Text>

                                <Flex
                                    gap="30px"
                                    boxSize="20px"
                                >
                                    {validateProgressModule(cursoProgress, modulo) > 99 && (
                                        <Icon
                                            color="#fff"
                                            bg="secondary"
                                            boxSize="20px"
                                            rounded="full"
                                            as={BiCheck}
                                        />
                                    )}
                                </Flex>
                                    
                                    
                            


                            </Flex>

                        </AccordionButton>

                        <AccordionPanel
                            display="flex"
                            bg="purewhite"
                        >
                            <Flex
                                flexDirection="column"
                                w="100%"
                            >
                                {sortByOrder(modulo?.lecciones)?.map((leccion: LeccionInt, index: number) => (
                                    <Flex
                                        key={index}
                                        justifyContent="space-between"
                                        p="10px 20px"
                                        mb="10px"
                                        cursor="pointer"
                                        // _hover={{ bg: "secondary_variant", borderRadius: "10px" }}
                                        onClick={() => navigate(`/leccion/${leccion?.slug}${addRedirect}`)}
                                    >
                                        <Flex
                                            alignItems="center"
                                            gap="10px"
                                        >
                                            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                                                <Icon
                                                    color={
                                                        checkLessonComplete(leccion?.id)
                                                            ? "#8694FF"
                                                            : "font"
                                                    }
                                                    as={
                                                        leccion?.tipo === LessonTypeEnum.VIDEO
                                                            ? BsPlayCircle
                                                            : leccion?.tipo === LessonTypeEnum.ENTREGABLE
                                                                ? HiOutlinePencilAlt
                                                                : leccion?.tipo === LessonTypeEnum.RECURSO
                                                                    ? HiOutlineCloudDownload
                                                                    : HiOutlinePresentationChartBar
                                                    }
                                                    boxSize="20px"
                                                />
                                            }

                                            <Text
                                                color={
                                                    checkLessonStart(leccion?.id)
                                                        ? "main"
                                                        : ""
                                                }
                                                fontSize="17px"
                                                fontWeight="500"
                                            >
                                                {leccion?.nombre}
                                            </Text>
                                        </Flex>

                                        {/* <Flex
                                            alignItems="center"
                                            gap="15px"
                                        >
                                            <Icon
                                                color={"success"}
                                                as={BsCheck}
                                                boxSize="20px"
                                                mt="2px"
                                            />

                                            <Text
                                                color="#959596"
                                                fontSize="17px"
                                                fontWeight="normal"
                                            >
                                                1h 30m
                                            </Text>
                                        </Flex> */}
                                    </Flex>
                                ))}
                            </Flex>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Flex>
    )
}