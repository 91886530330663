// Chakra UI
import { Box, Flex, FormLabel, Input, Checkbox, Button, Text, Link, Image, InputGroup, InputRightElement, Icon, useDisclosure } from '@chakra-ui/react'
// Img
import logoProcademy from '../../../assets/img/LogoProcademyBlack.png'
// React
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
// Toast
import { useToast } from '@chakra-ui/react'
import { notify } from '../../../shared/utils/functions/notify'
import { StatusEnum } from '../../../shared/utils/functions/notify'
// Middlewares
import { getToken, loadContext } from '../../../shared/middlewares/token.middleware'
// Axios
import { AxiosResponse } from 'axios'
import { useAuthContex } from '../../../shared/context/user.context'
import { UserRolEnum } from '../../../shared/utils/Types/RolEnum'
import { isRoleAllowed } from '../../../shared/utils/functions/validateRol'
import { RecoveryModal } from '../components/RecoveryModal'
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup"
import { FormInput } from '../../../shared/components/FormElements/FormInput'
import { UserInt } from '../../../interfaces/UserInt'

export const Login = () => {
    const { login, setUser, logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const [search, setSearch] = useSearchParams();

    useEffect(() => {
        if (search.has("loginById") && search.has("token")) {
            logout("Sesión anterior finalizada", navigate, toast)

            const token = search.get("token")
            const isLoginById = search.get("loginById")

            if (isLoginById === "true") handleLoginById(token as string)

            return
        }
    }, [])

    const handleLoginById = (token: string) => {
        loadContext(token).then((response: any) => {
            const user = response?.data?.data;

            const perfilUser: UserInt = {
                auth: true,
                tracking: false,
                id: user?.id,
                email: user?.email,
                username: user?.username ? user.username : '',
                avatar: {
                    url: user?.avatar?.url ? user.avatar?.url : '',
                },
                nombre: user?.nombre,
                apellidos: user?.apellidos,
                localidad: user?.localidad ? user.localidad : '',
                telefono: user?.telefono ? user.telefono : '',
                dni: user?.dni ? user.dni : '',
                role: {
                    nombre: user?.role?.nombre ? user?.role?.nombre : ''
                },
                matriculas: user?.matriculas ? user?.matriculas : [],
                empresa: user?.empresa ? user?.empresa : {},
                horarioLaboral: user?.horarioLaboral ? user?.horarioLaboral : null,
                tutorFreelance: user?.tutorFreelance ? user?.tutorFreelance : null
            }

            localStorage.setItem('token', token)
            localStorage.setItem('perfil', JSON.stringify(perfilUser))

            setUser(perfilUser);

            let curso = null

            if(search.has("curso")) curso = search.get("curso")

            search.delete("loginById")
            search.delete("token")
            search.delete("curso")
            setSearch(search)

            if(curso) {
                navigate(`/aprendizaje/${curso}`)
            } else {
                navigate('/perfil')
            }
        })
    }

    useEffect(() => {
        if (search.has("plazoVencido")) {
            onOpen()
        }

        search.delete("plazoVencido")
        setSearch(search)
    }, [])

    const initialValues = {
        userId: null,
        password: null
    };

    const validationSchema = Yup.object().shape({
        userId: Yup.string()
            .required("El campo es obligatorio")
            .typeError('El campo es obligatorio'),
        password: Yup.string()
            // .matches(passwordRules, { message: "La contraseña debe ser válida" })
            .required('El campo password es obligatorio')
            .typeError('El campo password es obligatorio'),
    });

    const submitForm = (values: any) => {
        const userLogin = {
            userId: values.userId,
            password: values.password
        }

        getToken(userLogin)
            .then((response: AxiosResponse) => {
                const token = response?.data?.token?.token

                if (isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR, UserRolEnum.ALUMNO], response?.data?.data?.role?.nombre)) {
                    login(token, response?.data?.data, navigate)
                } else if (isRoleAllowed([UserRolEnum.SUPERVISOR], response?.data?.data?.role?.nombre)) {
                    return window.location.href = `${import.meta.env.VITE_BASE_ADMIN}/login?loginByCampus=true&token=${token}`;
                } else {
                    notify(toast, StatusEnum.warning, "No tiene autorizacion para ingresar!")
                }
            })
            .catch((error) => {
                if (error?.response?.data?.data?.responseText)
                    notify(toast, StatusEnum.error, "La contraseña no es válida")
                else notify(toast, StatusEnum.error, "Se ha producido un error en el acceso. Contacte con soporte")
            })
    };

    return (
        <Flex flex="1" flexDirection="column" pt="5%" pb="2%" backgroundColor="#FFFFFF">
            <Image
                src={logoProcademy}
                alt='img/logo'
                objectFit="contain"
                w="200px"
                p="0 3%"
                ml="40px"
            />

            <Box w="50%" m="auto" ml="45%">
                <Text fontWeight="700" lineHeight="normal" fontSize="26px" color="main">Comienza</Text>

                <Text mt="20px" fontWeight="400" lineHeight="normal" fontSize="14px" color="secondary_variant_font">Introduzca su email y contraseña</Text>

                <Formik
                    onSubmit={(values) => submitForm(values)}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit } = formik;

                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                            >
                                <Box mt="7">
                                    <Box>
                                        <FormInput
                                            name='userId'
                                            placeholder="Usuario / Email"
                                            label='Usuario / Email'
                                        />
                                    </Box>

                                    <Box mt="5px">
                                        <FormInput
                                            type="password"
                                            name='password'
                                            placeholder='Contraseña'
                                            label='Contraseña'
                                        />
                                    </Box>

                                    <Flex justifyContent="space-between" alignItems="center" mt="8">
                                        <Checkbox size='md' fontSize="8px" colorScheme='orange'>Recuérdame</Checkbox>

                                        <Text
                                            _hover={{ textDecoration: "underline" }}
                                            cursor="pointer"
                                            color="secondary_variant_font"
                                            fontSize="12px"
                                            fontWeight="600"
                                            onClick={() => onOpen()}
                                        >
                                            ¿Has olvidado la contraseña?
                                        </Text>
                                    </Flex>

                                    <Button type='submit' background='secondary' color="#FFFFFF" mt="8" w="100%" fontWeight="500">Iniciar sesión</Button>
                                </Box>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Box>

            <RecoveryModal
                isOpen={isOpen}
                onClose={onClose}
            />
        </Flex>
    );
};