import { Flex, Skeleton } from "@chakra-ui/react"

export const SkeletonNotas = () => {
    return (
        <Flex
            w="100%"
            gap="20px"
        >
            <Flex
                direction="column"
                gap="25px"
            >
                <Flex
                    gap="10px"
                >
                    {[0, 1, 2, 3].map((s: any) => (
                        <Skeleton
                            key={s}
                            rounded="100px"
                            h="30px"
                            w="59px"
                        />
                    ))}
                </Flex>

                <Flex
                    direction="column"
                    gap="25px"
                    overflow="auto"
                    className="scroll-bar"
                    maxH="calc(100vh - 200px)"
                    pr="5px"
                >
                    {[0, 1, 2, 3, 4].map((s: any) => (
                        <Skeleton
                            key={s}
                            h="152px"
                            w="322px"
                            rounded="10px"
                        />
                    ))}
                </Flex>
            </Flex>

            <Flex flex="1">
                <Flex
                    direction="column"
                    w="100%"
                    bg="purewhite"
                    p="40px 40px 20px 40px"
                    rounded="10px"
                    boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                    gap="25px"
                    h="calc(100vh - 140px)"
                >
                    <Skeleton
                        h="30px"
                        w="120px"
                        rounded="5px"
                    />

                    <Flex
                        gap="50px"
                    >
                        <Flex
                            direction="column"
                            gap="15px"
                            color="font"
                            fontSize="14px"
                            fontWeight="600"
                        >
                            <Skeleton
                                h="21px"
                                w="52px"
                                rounded="5px"
                            />

                            <Skeleton
                                h="21px"
                                w="52px"
                                rounded="5px"
                            />

                            <Skeleton
                                h="21px"
                                w="52px"
                                rounded="5px"
                            />
                        </Flex>

                        <Flex
                            direction="column"
                            gap="15px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            <Skeleton
                                h="21px"
                                w="200px"
                                rounded="5px"
                            />

                            <Skeleton
                                h="21px"
                                w="200px"
                                rounded="5px"
                            />

                            <Skeleton
                                h="21px"
                                w="200px"
                                rounded="5px"
                            />
                        </Flex>
                    </Flex>

                    <Flex
                        gap="10px"
                        rounded="5px"
                    >
                        <Skeleton
                            h="42px"
                            w="86px"
                            rounded="5px"
                        />

                        <Skeleton
                            h="42px"
                            w="56px"
                            rounded="5px"
                        />
                    </Flex>

                    <Skeleton
                        h="500px"
                        w="100%"
                        rounded="5px"
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}