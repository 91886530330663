import { Box, Flex, FormLabel, Text, Icon } from "@chakra-ui/react";
import { FilterSelect } from "../FiltersElements/FilterSelect";
import { useEffect, useState } from "react";
import { FilterInput } from "../FiltersElements/FilterInput";
import { BiChevronDown, BiChevronUp, BiTrashAlt } from "react-icons/bi";
import { EntregableEstadoEnum } from "../../utils/Types/EntregableEstadoEnum";
import { getCursos, getLoadCursos } from "../../middlewares/courses.middleware";
import { getLecciones } from "../../middlewares/lessons.middleware";
import { getGrupos, getLoadGrupos } from "../../middlewares/grupos.middleware";
import { FilterAsyncSelect } from "../FiltersElements/FilterAsyncSelect";
import tenantInstance from "../../services/tenantInstance.service";
import { useSearchParams } from "react-router-dom";

interface FilterInt {
    type: "estadoEntrega" | "estadoGrupo" | "nombre" | "fecha" | "curso" | "leccion" | "grupo" | "pagado" | "formacion";
    label: string;
}

interface Props {
    query: string;
    setQuery: (query: string) => void;
    filterElements: FilterInt[];
    allowToggle?: boolean;
}

export const FiltersTable = ({
    query,
    setQuery,
    filterElements = [],
    allowToggle = false,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [isOpen, setIsOpen] = useState<boolean>(allowToggle ? false : true);
    const [filtrosActivos, setFiltrosActivos] = useState<string[]>([])
    const [search, setSearch] = useSearchParams();
    const [typing, setTyping] = useState<boolean>(false);
    const [typingTimeout, setTypingTimeout] = useState<any>();

    const [estadoEntrega, setEstadoEntrega] = useState<{ value: string, label: string }>();
    const [estadoGrupo, setEstadoGrupo] = useState<{ value: string, label: string }>();
    const [nombre, setNombre] = useState<string>("")
    const [pagado, setPagado] = useState<{ value: boolean | string, label: string }>()
    const [formacion, setFormacion] = useState<{ value: boolean | string, label: string }>()
    const [fechas, setFechas] = useState<{ desde: string, hasta: string }>({ desde: "", hasta: "" })
    const [curso, setCurso] = useState<{ value: number, label: string }>();
    const [leccion, setLeccion] = useState<{ value: number, label: string }>();
    const [grupo, setGrupo] = useState<{ value: number, label: string }>();

    useEffect(() => {
        if (query === "") {
            setFiltrosActivos([])
            setEstadoEntrega(undefined)
            setEstadoGrupo(undefined)
            setFormacion(undefined)
            setCurso(undefined)
            setLeccion(undefined)
            setNombre("")
            setGrupo(undefined)
            setFechas({ desde: "", hasta: "" })
            setPagado(undefined)
        }
    }, [query])

    const handleInput = (setState: any) => (e: any) => {
        setTyping(true);

        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(setTimeout(() => setTyping(false), 500));

        if (e.target.value === "" || e.target.value?.length == 0) {
            if (e.target.name === "nombre") {
                setSearch(search)
            } else {
                if (search.has(`${e.target.name}`)) {
                    search.delete(`${e.target.name}`)
                    setSearch(search)
                }
            }
        }

        setState(e.target.value);
    }

    const handleSelect = (setState: any) => (values: any) => {
        setTyping(true);

        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(setTimeout(() => setTyping(false), 500));

        setState(values);
    }

    useEffect(() => {
        if ((
            nombre !== undefined ||
            formacion !== undefined ||
            pagado !== undefined ||
            grupo !== undefined ||
            curso !== undefined ||
            leccion !== undefined ||
            estadoEntrega !== undefined ||
            estadoGrupo !== undefined ||
            (fechas?.desde !== undefined && fechas?.hasta !== undefined)

        ) && !typing) updateSearchParams();

    }, [nombre, grupo, curso, pagado, formacion, leccion, estadoEntrega, estadoGrupo, fechas, typing]);

    useEffect(() => {
        updateQuery();
    }, [search])

    const updateSearchParams = () => {

        if (nombre !== undefined) {
            if (nombre) search.set(`nombreCompleto`, nombre)
        }

        if (grupo && grupo?.value !== undefined) {
            if (grupo) search.set(`grupo`, `${grupo?.value}`)
        }

        if (curso && curso?.value !== undefined) {
            if (curso) search.set(`curso`, `${curso?.value}`)
        }

        if (leccion && leccion?.value !== undefined) {
            if (leccion) search.set(`leccion`, `${leccion?.value}`)
        }

        if (estadoEntrega && estadoEntrega?.value !== undefined) {
            if (estadoEntrega?.value !== "todos") search.set(`entregableEstado`, `${estadoEntrega?.value}`)
        }

        if (estadoGrupo && estadoGrupo?.value !== undefined) {
            if (estadoGrupo?.value !== "todos") search.set(`estado`, `${estadoGrupo?.value}`)
        }

        if (pagado && pagado?.value !== undefined) {
            if (pagado?.value !== "todos") search.set(`pagado`, `${pagado?.value}`)
        }

        if (formacion && formacion?.value !== undefined) {
            if (formacion?.value !== "todos") search.set(`procademy`, `${formacion?.value}`)
        }

        if (fechas?.desde !== undefined && fechas?.hasta !== undefined) {
            if (fechas?.desde && fechas?.hasta) {
                search.set('fechaInicio', `${fechas?.desde}`)
                search.set('fechaFin', `${fechas?.hasta}`)
            }
        }

        setSearch(search);
    }

    const updateQuery = () => {
        let newQuery = "";

        if (search.has("nombre")) newQuery += `&nombre=${search.get("nombre")}`;

        if (search.has("nombreCompleto")) newQuery += `&nombreCompleto=${search.get("nombreCompleto")}`;

        if (search.has("grupo")) newQuery += `&grupo=${search.get("grupo")}`;

        if (search.has("curso")) newQuery += `&curso=${search.get("curso")}`;

        if (search.has("leccion")) newQuery += `&leccion=${search.get("leccion")}`;

        if (search.has("entregableEstado")) newQuery += `&entregableEstado=${search.get("entregableEstado")}`;

        if (search.has("estado")) newQuery += `&estado=${search.get("estado")}`;

        if (search.has("pagado")) newQuery += `&pagado=${search.get("pagado")}`;

        if (search.has("procademy")) newQuery += `&procademy=${search.get("procademy")}`;

        if (search.has("fechaInicio") && search.get("fechaFin"))
            newQuery += `&fechaInicio=${search.get("fechaInicio")}&fechaFin=${search.get("fechaFin")}`;

        setQuery(newQuery);
    };

    useEffect(() => {
        setLoading(true)
        const filters: string[] = []

        if (query.includes("nombre")) filters.push("Nombre")
        if (query.includes("grupo")) filters.push("Grupo")
        if (query.includes("curso")) filters.push("Curso")
        if (query.includes("leccion")) filters.push("Leccion")
        if (query.includes("entregableEstado")) filters.push("Estado Entrega")
        if (query.includes("estado")) filters.push("Estado Grupo")
        if (query.includes("fecha")) filters.push("Fechas")
        if (query.includes("procademy")) filters.push("Formacion")
        if (query.includes("pagado")) filters.push("Pagado")

        setFiltrosActivos(filters)

        const timeout = setTimeout(() => { setLoading(false) }, 50)

        return () => clearTimeout(timeout)
    }, [query])

    const loadCursos = async (value: string) => {
        const { tenant, client } = await tenantInstance()

        let query = ""

        if (value && value !== "") query = `?nombre=${value}`

        const { data } = await getLoadCursos(query)

        const cursos = data?.data?.data.map((curso: any) => ({
            value: curso.id,
            label: curso.nombre,
        }));

        return cursos;
    }

    const loadLecciones = async (value: string) => {
        let query = ""

        if (value && value !== "") query = query + `?nombre=${value}`

        const { data } = await getLecciones(query)

        const lecciones = data?.data?.data.map((leccion: any) => ({
            value: leccion.id,
            label: leccion.nombre,
        }));

        return lecciones;
    }

    const loadGrupos = async (value: string) => {
        let query = ``

        if (value && value !== "") query = `?nombre=${value}`

        const { data } = await getLoadGrupos(query)

        const grupos = data?.data?.data.map((grupo: any) => ({
            value: grupo.id,
            label: grupo.nombre,
        }));

        return grupos;
    }

    return (
        <Flex
            bg="#FFFFFF"
            borderWidth="1px"
            p="20px"
            borderRadius="12px"
            boxSize="100%"
            direction="column"
            gap="15px"
            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
            borderColor="#E2E8F0"
            onClick={() => !isOpen ? setIsOpen(true) : null}
            cursor={!isOpen ? "pointer" : "default"}
        >
            <Flex
                alignItems="center"
                justifyContent="space-between"
            >
                <Flex gap="10px" alignItems="flex-end">
                    <Text fontSize="18px" fontStyle="normal">
                        Filtros de búsqueda
                    </Text>

                    {!loading &&
                        filtrosActivos?.map((filtro: any, index: number) => (
                            <Text
                                key={index}
                                color="black"
                                bg="secondary_variant"
                                fontSize="13px"
                                fontWeight="500"
                                p="2px 5px"
                                rounded="5px"
                            >
                                {filtro}
                            </Text>
                        ))
                    }
                </Flex>

                {allowToggle &&
                    <Icon
                        as={isOpen ? BiChevronUp : BiChevronDown}
                        transition="2s"
                        boxSize="25px"
                        cursor="pointer"
                        onClick={() => setIsOpen((prev: boolean) => !prev)}
                    />
                }
            </Flex>

            <Flex
                w="100%"
                justifyContent="space-between"
                gap="30px"
                transition="2s"
                display={isOpen ? "flex" : "none"}
            >

                {filterElements?.map((filter: FilterInt, index: number) => {

                    if (filter?.type === "nombre")
                        return (
                            <Box flex="1" key={index}>
                                <FormLabel
                                    color="dark_blue"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    {filter?.label}
                                </FormLabel>

                                <FilterInput
                                    value={nombre}
                                    placeholder={filter?.label}
                                    onChange={handleInput(setNombre)}
                                />
                            </Box>
                        )

                    if (filter?.type === "grupo")
                        return (
                            <Box flex="1" key={index}>
                                <FormLabel
                                    color="dark_blue"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    {filter?.label}
                                </FormLabel>

                                <FilterAsyncSelect
                                    value={grupo}
                                    onChange={handleSelect(setGrupo)}
                                    loadOptions={loadGrupos}
                                    defaultValue={grupo && { value: grupo.value, label: grupo.label }}
                                    placeholder="Grupo"
                                />
                            </Box>
                        )

                    if (filter?.type === "curso")
                        return (
                            <Box flex="1" key={index}>
                                <FormLabel
                                    color="dark_blue"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    {filter?.label}
                                </FormLabel>

                                <FilterAsyncSelect
                                    value={curso}
                                    onChange={handleSelect(setCurso)}
                                    loadOptions={loadCursos}
                                    defaultValue={curso && { value: curso.value, label: curso.label }}
                                    placeholder="Seleccione el curso"
                                />
                            </Box>
                        )

                    if (filter?.type === "leccion")
                        return (
                            <Box flex="1" key={index}>
                                <FormLabel
                                    color="dark_blue"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    {filter?.label}
                                </FormLabel>

                                <FilterAsyncSelect
                                    value={leccion}
                                    onChange={handleSelect(setLeccion)}
                                    loadOptions={loadLecciones}
                                    defaultValue={leccion && { value: leccion.value, label: leccion.label }}
                                    placeholder="Leccion"
                                />
                            </Box>
                        )

                    if (filter?.type === "estadoEntrega")
                        return (
                            <Box flex="1" key={index}>
                                <FormLabel
                                    color="dark_blue"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    {filter?.label}
                                </FormLabel>

                                <FilterSelect
                                    value={estadoEntrega}
                                    onChange={handleSelect(setEstadoEntrega)}
                                    placeholder="Seleccione una opcion"
                                    defaultValue={{ value: "todos", label: "Todos" }}
                                    options={[
                                        { value: "todos", label: "Todos" },
                                        { value: EntregableEstadoEnum.PENDIENTE_CORRECCION, label: "Entregado" },
                                        { value: EntregableEstadoEnum.CORRECTO, label: "Correcto" },
                                        { value: EntregableEstadoEnum.ERROR, label: "Incorrecto" },
                                    ]}
                                />
                            </Box>
                        )

                    if (filter?.type === "estadoGrupo")
                        return (
                            <Box flex="1" key={index}>
                                <FormLabel
                                    color="dark_blue"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    {filter?.label}
                                </FormLabel>

                                <FilterSelect
                                    value={estadoGrupo}
                                    onChange={handleSelect(setEstadoGrupo)}
                                    placeholder="Seleccione una opcion"
                                    defaultValue={{ value: "todos", label: "Todos" }}
                                    options={[
                                        { value: "todos", label: "Todos" },
                                        { value: "0", label: "Activo" },
                                        { value: "2", label: "Inactivo" },
                                    ]}
                                />
                            </Box>
                        )

                    if (filter?.type === "fecha")
                        return (
                            <Box flex="1" key={index}>
                                <FormLabel
                                    color="dark_blue"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    {filter?.label}
                                </FormLabel>

                                <Flex alignItems="center" gap="5px">
                                    <FilterInput
                                        value={fechas?.desde}
                                        onChange={(e: any) => handleInput(setFechas((prev: any) => ({ ...prev, desde: e.target.value })))}
                                        placeholder="Desde"
                                        type="date"
                                    />
                                    -
                                    <FilterInput
                                        value={fechas?.hasta}
                                        onChange={(e: any) => handleInput(setFechas((prev: any) => ({ ...prev, hasta: e.target.value })))}
                                        placeholder="Hasta"
                                        type="date"
                                    />

                                    <Icon
                                        as={BiTrashAlt}
                                        color="dark_grey"
                                        boxSize="18px"
                                        mb="2px"
                                        textTransform="uppercase"
                                        cursor="pointer"
                                        _hover={{ color: "active" }}
                                        onClick={() => {
                                            if (search.has("fechaInicio") && search.has("fechaFin")) {
                                                search.delete("fechaInicio")
                                                search.delete("fechaFin")
                                                setSearch(search)
                                            }

                                            setFechas({ desde: "", hasta: "" })
                                        }
                                        }
                                    />
                                </Flex>
                            </Box>
                        )
                    if (filter?.type === "pagado")
                        return (
                            <Box flex="1" key={index}>
                                <FormLabel
                                    color="dark_blue"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    {filter?.label}
                                </FormLabel>

                                <FilterSelect
                                    value={pagado}
                                    onChange={handleSelect(setPagado)}
                                    placeholder="Seleccione una opcion"
                                    defaultValue={{ value: "todos", label: "Todos" }}
                                    options={[
                                        { value: "todos", label: "Todos" },
                                        { value: true, label: "Si" },
                                        { value: false, label: "No" },
                                    ]}
                                />
                            </Box>
                        )

                    if (filter?.type === "formacion")
                        return (
                            <Box flex="1" key={index}>
                                <FormLabel
                                    color="dark_blue"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    {filter?.label}
                                </FormLabel>

                                <FilterSelect
                                    value={formacion}
                                    onChange={handleSelect(setFormacion)}
                                    placeholder="Seleccione una opcion"
                                    defaultValue={{ value: "todos", label: "Todos" }}
                                    options={[
                                        { value: "todos", label: "Todos" },
                                        { value: true, label: "Procademy" },
                                        { value: false, label: "Imagina" },
                                    ]}
                                />
                            </Box>
                        )
                })}
            </Flex>
        </Flex>
    )
};