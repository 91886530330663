import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";
import { JornadaLaboral } from "../utils/Types/HorarioLaboral";

export const getTutoresAlumno = async (query = "") => {
    const { tenant, client } = await tenantInstance()

    return await _axios.get(`${tenant}/${client}/users/tutoresRelacionadosAlumno${query}`).then((response: any) => response)
}

export const getUsersInactividad = async (track = false) => {
    const { tenant, client } = await tenantInstance()

    return await _axios.get(
        `${tenant}/${client}/users/inactividad`,
        {
            headers: track ? { "track": true } : {}
        }
    ).then((response: any) => response)
}

export const updateUser = async (user: any) => {
    const { tenant, client } = await tenantInstance()

    return await _axios.put(
        `${tenant}/${client}/users/update`,
        user,
        {}
    );
};

export const cambioDatos = async (value: any) => {
    const { tenant, client } = await tenantInstance()

    return await _axios.post(
        `${tenant}/${client}/users/cambioDatos`,
        value,
        {}
    ).then((response: any) => response);
};

export const updateHorarioLaboral = async (data: { horarioLaboral: JornadaLaboral }) => {
    const { tenant, client } = await tenantInstance()

    return await _axios.put(`${tenant}/${client}/users/update`, data, {}).then((response: any) => response);
}

export const uploadAvatarUser = async (files: File[]) => {
    const { tenant, client } = await tenantInstance()

    const formData = new FormData()

    formData.append('avatar', files[0]);

    return await _axios.put(
        `${tenant}/${client}/users/update`,
        formData,
        {}
    ).then((response: any) => response);
};

export const removeAvatarUser = async () => {
    const { tenant, client } = await tenantInstance()

    return await _axios.put(
        `${tenant}/${client}/users/update?borraAvatar=true`,
        {},
        {}
    ).then((response: any) => response);
};

export const getGuiaDidactica = async (matriculaId: string) => {
    const { tenant, client } = await tenantInstance()

    return await _axios.post(
        `${tenant}/${client}/guiaDidactica`,
        {
            matriculaId: matriculaId
        },
        {}
    ).then((response: any) => response);
};

export const getDiploma = async (matriculaId: string) => {
    const { tenant, client } = await tenantInstance()

    return await _axios.post(
        `${tenant}/${client}/diploma`,
        {
            matriculaId: matriculaId
        },
        {}
    ).then((response: any) => response);
};