import { Flex, Grid, Skeleton } from "@chakra-ui/react";

export const SekeletonForo = () => {
    return (
        <Flex
            direction="column"
            w="100%"
            gap="38px"
            
        >
            <Flex
                direction="column"
            >
                <Skeleton
                    h="40px"
                    w="200px"
                    rounded="5px"
                />

                <Flex
                    gap="10px"
                    mt="30px"
                >
                    {[{}, {}].map((_tag: any, i: number) => (
                        <Skeleton
                            key={i}
                            h="30px"
                            rounded="100px"
                            w="80px"
                        />
                    ))}
                </Flex>
            </Flex>

            <Flex
                gap="40px"
            >
                <Flex
                    w="1237px"
                    direction="column"
                    gap="20px"
                >
                    <Grid
                        w="100%"
                        gap="40px"
                        gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
                    >
                        {[{}, {}, {}, {}, {}, {}].map((_item: any, index: number) => (
                            <Skeleton
                                key={index}
                                w="100%"
                                h="300px"
                                rounded="5px"
                                boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                                transition=" 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
                            />
                        ))}
                    </Grid>
                </Flex>
            </Flex>
        </Flex>
    );
}