import { Flex, Img, Text } from "@chakra-ui/react";

interface Props {
    image: any
}

export default function CardHeaderCursoFinalizado({ image }: Props) {
    return (
        <Flex
            w="100%"
            gap="20px"
            bg="purewhite"
            p="30px"
            rounded="5px"
            mt="40px"
        >
            <Flex gap="30px" w="40%">
                <Img
                    rounded="5px"
                    w="100%"
                    height="100%"
                    objectFit="cover"
                    src={image}
                />
            </Flex>
            <Flex
                fontSize="18px"
                fontWeight="600"
                color="font"
                align="center"
            >

                Este curso ha sido finalizado
            </Flex>
        </Flex>
    );
}
