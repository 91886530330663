import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import CardProgreso from "./CardProgreso";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Matriculas } from "../../../Matriculas/Matriculas";

interface Props {
    button?: boolean;
    stats?: any;
}


export default function CardProgresos({ button, stats }: Props) {
    const navigate = useNavigate();

    return (
        <Flex
            direction="column"
            gap="20px"
            bg="purewhite"
            p="30px"
            rounded="5px"
            h="fit-content"
        >
            <Text
                fontSize="16px"
                color="font"
                fontWeight="700"
            >
                Tu progreso
            </Text>

            <Flex
                gap="14px"
            >
                <Flex
                    direction="column"
                    gap="14px"
                >
                    <CardProgreso
                        label="Cursos activos"
                        value={stats?.length || 0}
                    />
                    <CardProgreso
                        label="Cursos terminados"
                        value={stats ? stats?.filter((matricula:any)=>{
                            
                            if(matricula.progresoLecciones === 100){
                                return true
                            }else{
                                return false
                            }
                            
                        }).length : "0"}
                    />
                </Flex>

                <Flex
                    direction="column"
                    gap="14px"
                >
                    <CardProgreso
                        label="Horas dedicadas"
                        value={stats ? (stats?.reduce(( horas:any , matriculaActual:any ) => horas + matriculaActual?.tiempoAcceso,0) / 3600).toFixed(0) : "0"}
                    />

                    <CardProgreso
                        label="Nota media general"
                        value={stats ? (stats?.reduce(( notas:any, matriculaActual:any ) => notas + matriculaActual?.puntuacionMedia,0) / stats?.length).toFixed(0) : "0"}
                    />
                </Flex>
            </Flex>

            {button && (
                <Button
                    bg="main"
                    color="purewhite"
                    fontWeight="500"
                    rounded="5px"
                    _hover={{ bg: "secondary_variant" }}
                    _active={{ bg: "secondary_variant" }}
                    rightIcon={<Icon as={HiOutlineArrowRight} />}
                    maxW="130px"
                    mx="auto"
                    onClick={() => navigate("/dashboard")}
                >
                    Ir al panel
                </Button>
            )}
        </Flex>
    )
}
