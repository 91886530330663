import { format, intervalToDuration } from "date-fns";
import { DiaTypeEnum, JornadaLaboral } from "../Types/HorarioLaboral";

export const getHorasTotales = (jornadaLaboral: JornadaLaboral) => {
    let horasTotales = 0;

    const dateNow = format(new Date(), "MM-dd-yyyy")
    const dias: DiaTypeEnum[] = [
        DiaTypeEnum.LUNES,
        DiaTypeEnum.MARTES,
        DiaTypeEnum.MIERCOLES,
        DiaTypeEnum.JUEVES,
        DiaTypeEnum.VIERNES,
        DiaTypeEnum.SABADO,
        DiaTypeEnum.DOMINGO
    ]

    dias?.map((dia: DiaTypeEnum) => {
        for (let i = 0; i < jornadaLaboral[dia]?.length; i++) {
            const horaInicio = jornadaLaboral[dia][i]?.inicio
            const horaFin = jornadaLaboral[dia][i]?.fin

            const fechaInicio: any = new Date(dateNow + " " + horaInicio + ":00");
            const fechaFin: any = new Date(dateNow + " " + horaFin + ":00");


            try {
                const { hours, minutes } = intervalToDuration({
                    start: fechaInicio,
                    end: fechaFin
                })

                if (hours === undefined || hours === null || minutes === undefined || minutes === null) return 0

                if (minutes === 30)
                    horasTotales = horasTotales + hours + 0.5
                else
                    horasTotales = horasTotales + hours
            } catch (e) {
                return 0
            }
        }
    })

    return Number(horasTotales)
}