import { Badge, Flex, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { ColumnProps, CustomTable } from "../../shared/components/CustomTable/CustomTable";
import { useEffect, useState } from "react";
import { EntregablesInt } from "../../interfaces/Entregables";
import { CorreccionDrawer } from "./components/CorreccionDrawer";
import { useEntregables } from "../../shared/middlewares/entregables.middleware";
import { format } from "date-fns";
import { EntregableEstadoEnum } from "../../shared/utils/Types/EntregableEstadoEnum";
import { BiEdit } from "react-icons/bi";
import { FiltersTable } from "../../shared/components/TableElements/FiltersTable";
import { handleUnauthorized } from "../../shared/utils/functions/handleErrors";
import { useAuthContex } from "../../shared/context/user.context";
import { useNavigate } from "react-router-dom";
import { CardSinInformacion, TypeCardSinInformacion } from "../../shared/components/Customs/CardSinInformacion";

export const Correcciones = () => {
    const { logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [entregableId, setEntregableId] = useState<string>()
    const [page, setPage] = useState<number>(1)
    const [queryTable, setQueryTable] = useState<string>(`?page=${page}`);
    const [query, setQuery] = useState<string>("");
    const { entregables, loading, Refresh, error, status, errorsList } = useEntregables(queryTable + query)
    const [statusData, setStatusData] = useState<"loading" | "idle" | "success">("loading")

    
    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

        useEffect(() => {
        if (loading) setStatusData("loading")
        if (!loading && entregables?.data && entregables?.data?.length > 0) setStatusData("success")
        if (!loading && entregables?.data && entregables?.data?.length === 0) setStatusData("idle")
    }, [entregables, loading])
    
    const onRowClick = (data: any) => {
        setEntregableId(data?.data?.id)

        onOpen()
    }

    const columns: ColumnProps[] = [
        {
            header: 'Estado de Entrega',
            field: 'estado',
            key: 'estadoEntrega',
            sortable: true,
            body: (rowData: EntregablesInt) => (
                <Flex>
                    <Badge
                        w="fit-content"
                        textAlign="center"
                        fontFamily="inter"
                        p="5px 10px"
                        rounded="5px"
                        fontSize="14px"
                        fontWeight="700"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        color={
                            rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail'
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success'
                                        : 'main'
                        }
                        bg={
                            rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail_bg'
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_bg'
                                        : 'secondary_variant'
                        }
                    >
                        {
                            rowData?.estado === EntregableEstadoEnum.ERROR ? 'incorrecta'
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'correcto'
                                        : "pendiente"
                        }
                    </Badge>
                </Flex>
            ),
        },
        {
            header: 'alumno',
            field: 'alumno',
            key: 'alumno',
            sortable: true,
            body: (rowData: EntregablesInt) => (
                <Flex direction="column">
                    <Text
                        color="dark_blue"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {rowData?.user?.nombre} {rowData?.user?.apellidos}
                    </Text>
                    <Text
                        color="#7A7D81"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {rowData?.matricula?.grupo?.nombre}
                    </Text>
                </Flex>
            ),
        },
        {
            header: 'Contenido',
            field: 'contenido',
            key: 'contenido',
            body: (rowData: any) => (
                <Flex direction="column">
                    <Text
                        color="dark_blue"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {rowData?.leccion?.nombre}
                    </Text>
                    <Text
                        color="#7A7D81"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {rowData?.curso?.nombre}
                    </Text>
                </Flex>
            ),
        },
        {
            header: 'Estado de Grupo',
            field: 'estado',
            key: 'estadoGrupo',
            body: (rowData: any) => (
                <Flex>
                    <Badge
                        color="#FFFFFF"
                        bg={rowData?.matricula?.estado == "0" ? "main" : "fail"}
                        fontSize="11px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="3px 7px"
                    >
                        {rowData?.matricula?.estado == "0" ? "Activo" : "Inactivo"}
                    </Badge>
                </Flex>
            ),
        },
        {
            header: 'Fecha de entrega',
            field: 'createdAt',
            key: 'createdAt',
            sortable: true,
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                    gap="5px"
                    color="dark_blue"
                    fontSize="14px"
                    fontWeight="400"
                >
                    <Text>{format(new Date(rowData?.createdAt), 'dd/MM/yyy HH:mm')}</Text>
                </Flex>
            )
        },
        {
            header: '',
            field: '',
            key: '',
            body: () => (
                <Flex>
                    <Icon
                        boxSize="22px"
                        as={BiEdit}
                    />
                </Flex>
            )
        },
    ]

        if (statusData === "idle") 
        return (
            <Flex 
                p="10px 50px"
            >
                <CardSinInformacion type={TypeCardSinInformacion.CORRECCIONES} />
            </Flex>
        )

    return (
        <Flex
            direction="column"
            p="10px 50px"
            gap="30px"
        >
            <FiltersTable
                query={query}
                setQuery={setQuery}
                filterElements={[
                    {
                        label: "Estado Entrega",
                        type: "estadoEntrega",
                    },
                    {
                        label: "Alumno",
                        type: "nombre"
                    },
                    {
                        label: "Grupo",
                        type: "grupo"
                    },
                    {
                        label: "Curso",
                        type: "curso"
                    },
                    {
                        label: "Leccion",
                        type: "leccion"
                    },
                    {
                        label: "Estado Grupo",
                        type: "estadoGrupo",
                    },
                    {
                        label: "Fecha de Entrega",
                        type: "fecha",
                    },
                ]}
            />

            <CustomTable
                data={entregables?.data || []}
                columns={columns}
                isLoading={loading}
                currentPage={page}
                onPageChange={setPage}
                onQueryChange={setQueryTable}
                total={entregables?.meta?.total || 0}
                height="calc(100vh - 380px)"
                defaultSortBy="createdAt"
                defaultOrder={1}
                skeletonHeight={40}
                onRowClick={onRowClick}
            />

            <CorreccionDrawer
                isOpen={isOpen}
                onClose={onClose}
                id={entregableId}
                setRefresh={Refresh}
            />
        </Flex>
    )
};