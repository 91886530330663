import { Box, Button, Flex, Text, Tooltip, useToast } from "@chakra-ui/react"
import { useAuthContex } from "../../shared/context/user.context";
import { useEffect, useState } from "react";
import { StatusEnum, notify } from "../../shared/utils/functions/notify";
import { cambioDatos, removeAvatarUser, uploadAvatarUser } from "../../shared/middlewares/users.middleware";
import { AvatarUploader } from "../../shared/components/Inputs/AvatarUploader";
import { UserRolEnum } from "../../shared/utils/Types/RolEnum";
import { InformationInput } from "../../shared/components/Information/InformationInput";
import { useNavigate } from "react-router-dom";
import { InputCambioDatos } from "../../shared/components/Customs/InputCambioDatos";
import { JornadaLaboral } from "../../shared/utils/Types/HorarioLaboral";
import { HorarioLaboralSelect } from "../../shared/components/Customs/HorarioSelect/HorarioLaboralSelect";
import { getHorasTotales } from "../../shared/utils/functions/getHorasTotales";
import { handleErrors } from "../../shared/utils/functions/handleErrors";
import { isRoleAllowed } from "../../shared/utils/functions/validateRol";
import { useMatriculasAlumno } from "../../shared/middlewares/matriculas.middleware";
import { loadContext } from "../../shared/middlewares/token.middleware";

export const Perfil = () => {
    const { user, refreshUser } = useAuthContex();
    const toast = useToast();
    const [isUploadingAvatar, setIsUploadingAvatar] = useState<boolean>(false);
    const [horasTotal, setHorasTotal] = useState<number>(0);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [jornadaLaboral, setJornadaLaboral] = useState<JornadaLaboral>()
    const { campusFUNDAE } = useMatriculasAlumno({ user: user });

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (token) {
        loadContext(token).then((response: any) => {
          const userData = response?.data?.data;
          if (userData && JSON.stringify(userData) !== JSON.stringify(user)) {
            refreshUser({
              horarioLaboral: userData.horarioLaboral,
              nombre: userData.nombre,
              apellidos: userData.apellidos,
              telefono: userData.telefono,
              localidad: userData.localidad,
              email: userData.email,
            });
          }
        });
      }
    }, []);

    useEffect(() => {
        if (!user?.horarioLaboral) return

        setJornadaLaboral(user?.horarioLaboral)
    }, [user])

    const handleRemove = async () => {
        if (!user?.id) return;

        setIsUploadingAvatar(true);

        await removeAvatarUser()
            .then(async () => {
                refreshUser({
                    avatar: null
                })
            })
            .catch((err: Error) => console.error({ err }));

        setIsUploadingAvatar(false);
    };

    const handleUpload = (acceptedFiles: File[]) => {
        setIsUploadingAvatar(true);

        if (!user?.id) return

        uploadAvatarUser(acceptedFiles)
            .then((response: any) => {
                refreshUser({
                    avatar: {
                        url: response?.data?.data?.avatar?.url
                    }
                });

                notify(toast, StatusEnum.success, "¡Avatar subido correctamente!")
            })
            .catch((error) => {
                console.log(error)
                notify(toast, StatusEnum.error, "Error al subir el avatar, vuelva a intentarlo")
            })

        setIsUploadingAvatar(false);
    };

    useEffect(() => {
        if (jornadaLaboral) {
            const horasTotales = getHorasTotales(jornadaLaboral)
            setHorasTotal(horasTotales)
        }
    }, [jornadaLaboral])


    const handleSolicitudCambio = async ({ key, value }: { key: string, value: any }) => {
        cambioDatos({
            [key]: value
        })
            .then(() => {
                notify(toast, StatusEnum.info, "¡Solicitud enviada correctamente!")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => notify(toast, StatusEnum.error, error?.message))
            })
    }

    return (
        <Flex
            px="70px"
            direction="column"
            gap="40px"
        >
            <Text
                fontSize="28px"
                fontWeight="600"
                color="font"
            >
                Configuración
            </Text>

            <Flex
                gap="40px"
            >
                <Flex
                    direction={!campusFUNDAE ? "row" : "column"}
                    gap="40px"
                    flex="1"
                >
                    <Flex
                        p="30px"
                        boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                        bg="purewhite"
                        rounded="5px"
                        direction="column"
                        gap="20px"
                        w="100%"
                    >
                        <Text
                            color="font"
                            fontSize="16px"
                            fontWeight="600"
                            mb="10px"
                            align="center"
                        >
                            Información del perfil
                        </Text>

                        <Flex
                        >
                            <AvatarUploader
                                size="80px"
                                onUpload={handleUpload}
                                onDelete={handleRemove}
                                src={user?.avatar?.url}
                                isUploading={isUploadingAvatar}
                                allowGif={user?.role?.nombre === UserRolEnum.ADMIN}
                                name={user?.username || 'Avatar del usuario'}
                            />


                            <Flex
                                direction="column"
                                gap="5px"
                                w="100%"
                            >

                                <InputCambioDatos
                                    name="username"
                                    label="Nombre de usuario"
                                    defaultValue={user?.username}
                                    isDisabled={true}
                                    isChaging={false}
                                    handleSolicitudCambio={handleSolicitudCambio}
                                />

                            </Flex>
                        </Flex>

                        <InputCambioDatos
                            name="nombre"
                            label="Nombre"
                            defaultValue={user?.nombre}
                            handleSolicitudCambio={handleSolicitudCambio}
                        />

                        <InputCambioDatos
                            name="apellidos"
                            label="Apellidos"
                            defaultValue={user?.apellidos}
                            handleSolicitudCambio={handleSolicitudCambio}
                        />

                        <InputCambioDatos
                            name="telefono"
                            label="Teléfono"
                            defaultValue={user?.telefono}
                            handleSolicitudCambio={handleSolicitudCambio}
                        />

                        <InputCambioDatos
                            name="localidad"
                            label="Localidad"
                            defaultValue={user?.localidad}
                            handleSolicitudCambio={handleSolicitudCambio}
                        />
                    </Flex>

                    <Flex
                        padding="30px"
                        boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                        bg="purewhite"
                        rounded="12px"
                        direction="column"
                        gap="20px"
                        w="100%"
                    >
                        <Text
                            color="font"
                            fontSize="16px"
                            fontWeight="600"
                            mb="10px"
                            align="center"
                        >
                            Información de la cuenta
                        </Text>

                        <InputCambioDatos
                            name="email"
                            label="Correo electrónico"
                            defaultValue={user?.email}
                            handleSolicitudCambio={handleSolicitudCambio}
                        />

                        <Text
                            color="dark_grey"
                            fontSize="12px"
                            fontWeight="500"
                        >
                            Contacta con nosotros para cambiar el correo electrónico
                        </Text>

                        <InformationInput
                            name="contraseña"
                            label="Contraseña"
                            type="password"
                            updateValue={() => null}
                            defaultValue={"0000000000"}
                            isDisabled={true}
                        />

                        <Button
                            h="fit-content"
                            w="fit-content"
                            color="purewhite"
                            bg="secondary"
                            _hover={{ bg: "variant" }}
                            fontSize="14px"
                            fontWeight="500"
                            rounded="5px"
                            p="10px"
                        >
                            Cambiar contraseña
                        </Button>
                    </Flex>
                </Flex>
                {
                    campusFUNDAE &&

                    <>

                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                            <Flex
                                direction="column"
                                gap="40px"
                                flex="1"
                            >
                                <Flex
                                    padding="30px"
                                    boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                                    bg="purewhite"
                                    rounded="12px"
                                    direction="column"
                                    gap="10px"
                                    w="100%"
                                >
                                    <Text
                                        color="font"
                                        fontSize="16px"
                                        fontWeight="600"
                                        mb="10px"
                                        align="center"
                                    >
                                        Información jornada laboral
                                    </Text>

                                    <Box>
                                        <HorarioLaboralSelect
                                            label=""
                                            sublabel="ATENCIÓN: El horario laboral se modificará una vez que se haya confirmado"
                                            onChangeSelect={setJornadaLaboral}
                                            defaultValueSelect={jornadaLaboral}
                                            setIsChecked={setIsChecked}
                                            isChecked={isChecked}
                                        />
                                    </Box>

                                    <Tooltip
                                        label={user?.horarioLaboral === jornadaLaboral 
                                            ? "No se ha realizado ningún cambio" 
                                            : horasTotal > 40 ?  "El total de horas de tu jornada es más de 40h contacta con tu supervisor para que solicite el cambio"
                                            :"Debe confirmar el total de horas para poder solicitar el cambio"
                                        }
                                        isDisabled={isChecked && horasTotal < 40}
                                    >
                                        <Button
                                            w="fit-content"
                                            color="purewhite"
                                            bg="secondary"
                                            _hover={{ bg: "variant" }}
                                            fontSize="14px"
                                            fontWeight="500"
                                            rounded="5px"
                                            p="10px 25px"
                                            isDisabled={!isChecked || user?.horarioLaboral === jornadaLaboral || horasTotal > 40}
                                            onClick={() => {
                                                handleSolicitudCambio({ key: "horarioLaboral", value: jornadaLaboral })
                                                user?.horarioLaboral && setJornadaLaboral(user?.horarioLaboral)
                                            }}
                                        >
                                            Solicitar cambio
                                        </Button>
                                    </Tooltip>
                                </Flex>
                            </Flex>
                        }
                    </>
                }

            </Flex>
        </Flex>
    )
}