import { Flex, Skeleton } from "@chakra-ui/react"

export const SkeletonEntregables = () => {
    return (
        <Flex gap="40px" px="50px" direction="column">

            <Flex
                w="100%"
            >

                <Skeleton
                    rounded="10px"
                    w="150px"
                    h="40px"
                />
            </Flex>
            
            <Flex
                w="100%"
                gap="40px"
            >
                

                <Flex
                    gap="30px"
                    direction="column"
                    w="79%"
                >
                    <Flex
                        gap="20px"
                        w="100%"
                        pb="5px"
                        justify="space-between"
                    >

                        <Skeleton
                            w="410px"
                            h="51px"
                            rounded="5px"
                        />

                        <Flex
                            gap="25px"
                        >
                            <Skeleton
                                w="240px"
                                h="50px"
                                rounded="5px"
                            />
                            <Skeleton
                                w="270px"
                                h="50px"
                                rounded="5px"
                            />
                        </Flex>

                    </Flex>

                    <Skeleton
                        w="100%"
                        h="400px"
                        rounded="5px"
                    />


                </Flex>

                <Flex
                    flex="1"
                >
                    <Skeleton h="200px" w="100%" rounded="5px"/>
                </Flex>

            </Flex>

        </Flex>
    )
}