import { Flex } from "@chakra-ui/react";
import SelectorCurso from "./SelectorCurso";
import { CardInfo } from "../../../shared/components/Cards/CardInfo";
import { EntregablesInt } from "../../../interfaces/Entregables";
import { EntregableEstadoEnum } from "../../../shared/utils/Types/EntregableEstadoEnum";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";

interface Props {
    entregables: any;
    loading: boolean;
    onSelectMatricula: (matricula: MatriculasInt) => void;
    matriculas: MatriculasInt[];
    selectedMatricula: MatriculasInt | undefined;
}

export default function TopEntregables({ entregables, onSelectMatricula, matriculas, selectedMatricula }: Props) {

    // Funcion de filtro para mostrar en las cards la informacion de cada entregables
    const handleEntregablesFilter = (
        entregables: EntregablesInt[],
        filterType: "incorrectos" | "pendientes" | "correctos" | "entregados"
    ): any => {
        let entregablesFilter: EntregablesInt[] = [];

        if (entregables?.length == 0) return entregablesFilter

        if (filterType === "pendientes") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.PENDIENTE_ENTREGA)
        if (filterType === "incorrectos") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.ERROR)
        if (filterType === "entregados") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION)
        if (filterType === "correctos") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.CORRECTO)

        return entregablesFilter
    }

    const cardsItem: { label: string, info: string }[] = [
        {
            label: "Puntuación media",
            info: (!selectedMatricula?.puntuacionMedia || isNaN(selectedMatricula?.puntuacionMedia))
                ? "-"
                : `${selectedMatricula?.puntuacionMedia}`,
        },
        {
            label: "Entregables aprobados",
            info: `${handleEntregablesFilter(entregables, "correctos")?.length}`
        },

    ]

    
    return (
        <Flex
            w="100%"
            h="100%"
            gap="40px"
        >
            <SelectorCurso
                onSelectMatricula={onSelectMatricula}
                matriculas={matriculas}
                selectedMatricula={selectedMatricula}
            />

            <Flex
                h="100%"
                gap="25px"
            >
                {cardsItem?.map((card: any, index: number) => (
                    index !== 1 ?
                        <CardInfo
                            key={index}
                            label={card?.label}
                            info={card?.info !== undefined ? Number(card?.info)?.toFixed(0).toString() : ''}
                            bg="purewhite"
                        />
                        :
                        <CardInfo
                            infoNotColored
                            key={index}
                            label={card?.label}
                            info={card?.info !== undefined ? Number(card?.info)?.toFixed(0).toString() : ''}
                            bg="purewhite"
                        />
                ))}
            </Flex>
        </Flex>
    )
}
