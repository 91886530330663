import { Flex, Skeleton } from "@chakra-ui/react";

export const SkeletonInformation = () => {
    return (
        <Flex
            w="100%"
            h="100vh"
            p="40px 50px 70px"
            gap="40px"
        >
            <Flex
                marginTop="120px"
                direction="column"
                w="77%"
                gap="40px"
            >
                <Skeleton rounded="8px" h="340px"/>

                <Skeleton rounded="8px" flex="1"/>

            </Flex>

            <Flex
                marginTop="120px"
                direction="column"
                w="23%"
                gap="40px"
            >
                <Skeleton rounded="5px" h="210px"/>

                <Skeleton rounded="8px" h="305px"/>

                <Skeleton rounded="8px" h="180px"/>
                
            </Flex>

        </Flex>
    );
}