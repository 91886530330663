import { OutWindowModal } from "../../hooks/Components/OutWindowModal";
import { useOutWindow } from "../../hooks/useOutWindow/useOutWindow";
import { OutWindowContext } from "../context/outWindow.context";
import { useUrl } from "../context/url.context";
import { useAuthContex } from "../context/user.context";
import { UserRolEnum } from "../utils/Types/RolEnum";
import { isRoleAllowed } from "../utils/functions/validateRol";

export const OutWindowController = ({ children }: any) => {
    const { user } = useAuthContex();
    const { outWindow, onClose, setTimeModal } = useOutWindow(user?.auth);
    const url = useUrl()    
    const demoUrl = "https://demo.campus.proacademy.app"

    return (
        <OutWindowContext.Provider value={{ outWindow, onClose, setTimeModal }}>
            {children}

            {(isRoleAllowed([UserRolEnum.ALUMNO], user?.role?.nombre) && url !== demoUrl) &&
                <OutWindowModal isOpen={outWindow} onClose={onClose} />
            }
        </OutWindowContext.Provider>
    );
}