import { Flex, Grid, Skeleton } from "@chakra-ui/react"

export const SkeletonAprendizajeCursos = () => {
    return (
        <Flex
            h="100vh"
            w="100%"
            flexWrap='wrap'
            gap="40px"
            p="80px 50px"
        >
            {[1, 2, 3, 4].map((item) => (
                <Skeleton height="660px" w="410px" borderRadius="8px" />
            ))}
        </Flex>
    )
}