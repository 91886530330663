import { Button, Flex, useToast } from '@chakra-ui/react';
import Form from '@rjsf/chakra-ui';
import validator from '@rjsf/validator-ajv8';
import { schema } from './utils/SchemaCuestionario';
import { uiSchema } from './ui/UiCuestionario';
import "./ui/cuestionarioStyles.scss"
import { addCuestionarioMatricula, useMatriculasAlumno } from '../../shared/middlewares/matriculas.middleware';
import { useAuthContex } from '../../shared/context/user.context';
import { MatriculasInt } from '../../interfaces/MatriculasInt';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleErrors } from '../../shared/utils/functions/handleErrors';
import { StatusEnum, notify } from '../../shared/utils/functions/notify';

export const Cuestionario = () => {
    const { user } = useAuthContex();
    const toast = useToast();
    const navigate = useNavigate();
    const [search] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(false)
    const [matricula, setMatricua] = useState<MatriculasInt>()
    const { matriculas } = useMatriculasAlumno({ user: user });

    useEffect(() => {
        if(!search.has("grupo") || !search.has("curso")) return;

        matriculas?.data?.map((matricula: MatriculasInt) => {
            if (
                matricula?.grupo?.slug === search.get("grupo") &&
                matricula?.grupo?.curso?.slug === search.get("curso")
            ) {
                setMatricua(matricula)
            }
        })
    }, [matriculas])

    const handleSubmit = (data: any) => {
        const newData = {
            ...data,
            fechaRealizacion: new Date()
        }

        if(!data || !matricula?.id) return;
        setLoading(true)

        addCuestionarioMatricula({
            id: matricula?.id,
            data: newData
        })
        .then(() => {
            notify(toast, StatusEnum.success, "Cuestionario enviado con éxito")

            const curso = search.get("curso") || matricula?.grupo?.curso?.slug
            const grupo = search.get("grupo") || matricula?.grupo?.slug

            navigate(`/aprendizaje/${curso}?grupo=${grupo}`)
        })
        .catch((error: any) => {
            const errors = handleErrors(
                error?.response?.data?.errors,
                error?.response?.status
            )

            errors?.map((error: any) => notify(toast, StatusEnum.error, error?.message))
        })
        .finally(() => setLoading(false))
    }
    
    return (
        <Flex
            w='100%'
            p="5% 15%"
            justify='center'
            direction='column'
        >
            <Form
                className='form-cuestionario'
                schema={schema}
                uiSchema={uiSchema}
                validator={validator}
                onSubmit={(e: any) => handleSubmit(e?.formData)}
            >
                <Flex>
                    <Button
                        isLoading={loading}
                        m="auto"
                        w="fit-content"
                        h="fit-content"
                        bg="secondary"
                        p="10px 25px"
                        color="purewhite"
                        fontSize="16px"
                        fontWeight="500"
                        _hover={{ bg: "secondary_variant" }}
                        type='submit'
                    >
                        Enviar
                    </Button>
                </Flex>
            </Form>
        </Flex>
    )
}