import { Box, Button, Flex, Icon, Text, useToast } from "@chakra-ui/react"
import { NotasInt } from "../../../interfaces/NotasInt"
import { format } from "date-fns";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useState } from "react";
import { InformationTextEditor } from "../../../shared/components/Information/InformationTextEditor";
import { StatusEnum, notify } from "../../../shared/utils/functions/notify";
import { updateNota } from "../../../shared/middlewares/notas.middleware";
import { BiTime } from "react-icons/bi";

interface Props {
    nota: NotasInt | undefined;
    setRefresh: () => void;
}

export const NotaItem = ({ nota, setRefresh }: Props) => {
    const toast = useToast();
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const updateValue = (value: any) => {
        if (!nota?.id) return;

        return updateNota({
            id: nota?.id,
            data: value
        })
            .then(() => {
                setIsEdit(false)
                setRefresh()
                notify(toast, StatusEnum.success, "Campo actualizado")
            })
            .catch(() => notify(toast, StatusEnum.error, "Error al actualizar"));
    };

    return (
        <Flex
            direction="column"
            w="100%"
            bg="purewhite"
            p="30px"
            rounded="5px"
            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
            gap="25px"
            h="calc(100vh - 140px)"
        >
            <Flex
                gap="20px"
                alignItems="center"
                justifyContent="space-between"
            >
                <Flex
                    gap="20px"
                    alignItems="center"
                >
                    <Text
                        color="font"
                        fontSize="21px"
                        fontWeight="600"
                    >
                        {nota?.titulo}
                    </Text>

                    <Text
                        bg="secondary_variant"
                        p="5px 10px"
                        rounded="full"
                        fontSize="12px"
                        fontWeight="400"
                    >
                        {nota?.leccion?.nombre}
                    </Text>
                    

                    <Flex
                        alignItems="center"
                        gap="5px"
                        color="dark_grey"
                    >
                        <Icon boxSize="16px" as={BiTime} />

                        <Text
                            color="secondary_variant_font"
                            fontSize="16px"
                            fontWeight="400"
                        >
                            {nota?.createdAt && format(new Date(nota?.createdAt), "dd/MM/yy")}
                        </Text>
                    </Flex>

                </Flex>

                <Flex
                    gap="10px"
                >
                    <Button
                        p="6px 12px"
                        h="fit-content"
                        color={!isEdit ? "purewhite" : "secondary_variant_font"}
                        bg={!isEdit ? "main" : "purewhite"}
                        border="1px solid"
                        borderColor={!isEdit ? "main" : "secondary_variant_font"}
                        _hover={!isEdit ?
                            { bg: "secondary_variant", color: "main" } :
                            { bg: "grey" }
                        }
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="28px"
                        onClick={() => setIsEdit(false)}
                    >
                        Vista previa
                    </Button>

                    <Button
                        p="6px 12px"
                        h="fit-content"
                        color={isEdit ? "purewhite" : "secondary_variant_font"}
                        bg={isEdit ? "main" : "purewhite"}
                        border="1px solid"
                        borderColor={isEdit ? "main" : "secondary_variant_font"}
                        _hover={isEdit ?
                            { bg: "secondary_variant", color: "main" } :
                            { bg: "grey" }
                        }
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="28px"
                        onClick={() => setIsEdit(true)}
                    >
                        Editar nota
                    </Button>
                </Flex>
            </Flex>

            {!isEdit ?
                <Box
                    border="1px solid"
                    borderColor="light_grey"
                    h="100%"
                    rounded="5px"
                    p="20px"
                >
                    <MarkdownPreview
                        source={nota?.contenido || ''}
                        style={{
                            color: "#3D3F65",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "28px",
                            background: "#FFFFFF",
                            fontFamily: "Inter"
                        }}
                    />
                </Box>
                :
                <InformationTextEditor
                    updateValue={updateValue}
                    name="contenido"
                    defaultValue={nota?.contenido}
                    noHead={true}
                />
            }
        </Flex>
    )
}