import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useMensajes } from "../../shared/middlewares/messages.middleware";
import { useAuthContex } from "../../shared/context/user.context";
import { useEffect } from "react";
import { CardSinInformacion, TypeCardSinInformacion } from "../../shared/components/Customs/CardSinInformacion";
import { Mensajeria } from "./views/Mensajeria";
import { handleUnauthorized } from "../../shared/utils/functions/handleErrors";
import { useMatriculasAlumno } from "../../shared/middlewares/matriculas.middleware";
import { SkeletonChats } from "./components/utils/SkeletonChats";
import { SkeletonPanel } from "./components/utils/SkeletonPanel";

export const Mensajes = () => {
    const { user, logout } = useAuthContex();
    const navigate = useNavigate();
    // const location = useLocation();
    const toast = useToast();
    const { onOpen, isOpen, onClose } = useDisclosure();
    const { matriculas, loading: loadingMatriculas } = useMatriculasAlumno({ user: user })
    const { mensajes, Refresh, loading, error, status, errorsList } = useMensajes({
        query: "?sortBy=updatedAt&order=desc&limit=100"
    })
    // const [onNavigation, setOnNavigation] = useState<boolean>(true)

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

    // useEffect(() => {
    //     if (!location.pathname.startsWith("/mensajeria/"))
    //         if (mensajes) {
    //             if (matriculas?.data?.length > 0 || isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR], user?.role?.nombre))
    //                 mensajes?.length > 0
    //                     ? navigate(`/mensajeria/${mensajes[0]?.id}`)
    //                     : navigate(`/mensajeria/sin-mensajes`)

    //             onNavigation && setOnNavigation(false)
    //         }
    // }, [mensajes, location.pathname, onNavigation])


    return (
        <Flex
            direction="column"
            w="100%"
            px="50px"
            h="100%"
        >

            <Routes>
                <Route
                    index
                    element={loading || loadingMatriculas ?
                        <Flex
                            direction="column"
                            pt="20px"
                        >
                            <Flex
                                direction="column"
                                h="85vh"
                                borderRadius="5px"
                                w="100%"
                            >
                                <Flex
                                    bg="purewhite"
                                    h="100%"
                                    borderRadius="20px"
                                >
                                    <SkeletonChats />

                                    <SkeletonPanel />
                                </Flex>
                            </Flex>
                        </Flex>
                        :
                        (matriculas?.data?.length !== 0
                            ?
                            <Mensajeria
                                mensajes={mensajes}
                                loading={loading}
                                Refresh={Refresh}
                                isOpen={isOpen}
                                onClose={onClose}
                                onOpen={onOpen}
                            />
                            : <CardSinInformacion type={TypeCardSinInformacion.MENSAJES} />
                        )
                    }
                />

                <Route
                    path="/:mensajeId"
                    element={
                        <Mensajeria
                            mensajes={mensajes}
                            loading={loading}
                            Refresh={Refresh}
                            isOpen={isOpen}
                            onClose={onClose}
                            onOpen={onOpen}
                        />
                    }
                />
            </Routes>
        </Flex>
    );
};