import { Button, Flex, Icon, Spinner, Text, useToast } from "@chakra-ui/react";
import { CursosInt } from "../../../../interfaces/CoursesInt";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { BsBarChartFill, BsClockFill, BsFileEarmarkArrowDownFill, BsFillArrowDownCircleFill, BsFillArrowDownLeftCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { getGuiaDidactica } from "../../../../shared/middlewares/users.middleware";
import { StatusEnum, customNotify, notify } from "../../../../shared/utils/functions/notify";
import { BiDownload } from "react-icons/bi";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";

interface Props {
    curso: CursosInt | undefined;
    matricula: MatriculasInt | undefined;
}

export const CardResumen = ({ curso, matricula }: Props) => {
    const toast = useToast();

    const handleGuiaDidactica = () => {
        if (!matricula?.id) return;

        customNotify({
            toast: toast,
            status: StatusEnum.info,
            title:
                <Flex
                    alignItems="center"
                    gap="15px"
                >
                    <Text
                        fontWeight="500"
                    >
                        Generando Guía Didáctica
                    </Text>

                    <Spinner />
                </Flex>
        })

        getGuiaDidactica(matricula?.id)
            .then((response: any) => {
                toast.closeAll()

                customNotify({
                    toast: toast,
                    status: StatusEnum.success,
                    title:
                        <Flex
                            alignItems="center"
                            gap="15px"
                        >
                            <Text
                                fontWeight="500"
                            >
                                Guía Didáctica generada correctamente
                            </Text>

                            <Button
                                h="fit-content"
                                w="fit-content"
                                p="5px 15px"
                                fontSize="13px"
                                fontWeight="500px"
                                border="1px solid"
                                borderColor="purewhite"
                                color="purewhite"
                                leftIcon={<Icon as={BiDownload} boxSize="20px" />}
                                bg="inherit"
                                rounded="5px"
                                _hover={{ bg: "inherit" }}
                                _active={{ transform: "scale(0.9)" }}
                                onClick={() => {
                                    window.open(response?.data?.data, '_blank')
                                    toast.closeAll()
                                }}
                            >
                                Descargar
                            </Button>
                        </Flex>
                })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )
    
                errors?.map((error: any) => notify(toast, StatusEnum.error, error?.message))

                toast.closeAll()
            })
    }

    const getCvTutor = () => {
        if (!matricula?.tutor) return;

        customNotify({
            toast: toast,
            status: StatusEnum.success,
            title:
                <Flex
                    alignItems="center"
                    gap="15px"
                >
                    <Text
                        fontWeight="500"
                    >
                        Reseña Profesional generada correctamente
                    </Text>

                    <Button
                        h="fit-content"
                        w="fit-content"
                        p="5px 15px"
                        fontSize="13px"
                        fontWeight="500px"
                        border="1px solid"
                        borderColor="purewhite"
                        color="purewhite"
                        leftIcon={<Icon as={BiDownload} boxSize="20px" />}
                        bg="inherit"
                        rounded="5px"
                        _hover={{ bg: "inherit" }}
                        _active={{ transform: "scale(0.9)" }}
                        onClick={() => {
                            window.open(matricula?.tutor?.cv?.url, '_blank')
                            toast.closeAll()
                        }}
                    >
                        Descargar
                    </Button>
                </Flex>
        })
    }

    return (
        <Flex
            rounded="5px"
            p="30px"
            bg="purewhite"
            gap="40px"
            direction="column"
            mt="40px"
        >
            <Flex
                direction="column"
                gap="30px"
            >
                <Text
                    fontSize="18px"
                    fontWeight="600"
                    color="font"
                    as="h2"
                >
                    Resumen del curso
                </Text>

                <MarkdownPreview
                    source={curso?.descripcion || ''}
                    style={{
                        color: "#202020",
                        width: '100%',
                        maxWidth: '100%',
                        background: "inherit",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                    }}
                />
            </Flex>

            <Flex
                gap="20px"
                justifyContent="space-between"
            >
                <Flex
                    gap="20px"
                >
                    <Flex
                        gap="5px"
                        alignItems="center"
                    >
                        <Icon as={BsClockFill} boxSize="20px" color="secondary" />
                        <Text
                            fontSize="16px"
                            fontWeight="500"
                        >
                            {curso?.duracion}h lectivas
                        </Text>
                    </Flex>

                    <Flex
                        gap="5px"
                        alignItems="center"
                    >
                        <Icon as={BsFileEarmarkArrowDownFill} boxSize="20px" color="secondary" />
                        <Text
                            fontSize="16px"
                            fontWeight="500"
                        >
                            {curso?.meta?.totalEntregables} entregables
                        </Text>
                    </Flex>

                    {/* <Flex
                        gap="5px"
                        alignItems="center"
                    >
                        <Icon as={BsBarChartFill} boxSize="20px" color="secondary" />
                        <Text
                            fontSize="16px"
                            fontWeight="500"
                        >
                            Nivel: Iniciación
                        </Text>
                    </Flex> */}
                </Flex>

                <Flex
                    gap="20px"
                >
                    <Button
                        gap="5px"
                        alignItems="center"
                        cursor="pointer"
                        onClick={getCvTutor}
                        _hover={{bg: "secondary_variant"}}
                        isDisabled={matricula?.tutor?.cv === null}
                    >
                        <Icon as={BsFillArrowDownCircleFill} boxSize="20px" color="secondary" />
                        <Text
                            fontSize="16px"
                            fontWeight="500"
                        >
                            Descargar Reseña Profesional
                        </Text>
                    </Button>

                    <Button
                        gap="5px"
                        alignItems="center"
                        cursor="pointer"
                        onClick={handleGuiaDidactica}
                        _hover={{bg: "secondary_variant"}}
                    >
                        <Icon as={BsFillArrowDownCircleFill} boxSize="20px" color="secondary" />
                        <Text
                            fontSize="16px"
                            fontWeight="500"
                        >
                            Descargar Guía Didáctica
                        </Text>
                    </Button>

                </Flex>

            </Flex>
        </Flex>
    );
}