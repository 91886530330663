import { Flex } from "@chakra-ui/react";
import { CardInfo } from "../../../shared/components/Cards/CardInfo";
import { EntregablesInt } from "../../../interfaces/Entregables";
import { EntregableEstadoEnum } from "../../../shared/utils/Types/EntregableEstadoEnum";

interface Props {
  liquidaciones: any[];
}

export default function TopFacturacion({ liquidaciones }: Props) {

  const handleFacturasFilter = (liquidaciones: any[]) => {
    let facturasFilter: any[] = [];
    let total = 0;

    if (!liquidaciones || liquidaciones?.length === 0)     
      return {
        pendientes: facturasFilter?.length,
        total: total
      }

    facturasFilter = liquidaciones?.filter((liquidacion: any) => liquidacion?.liquidada === false)

    for(let i = 0; i < facturasFilter?.length; i++){
      total = total + facturasFilter[i]?.meta?.importeTotal
    }

    return {
      pendientes: facturasFilter?.length,
      total: (total)?.toFixed(0)
    }
  }

  const cardsItem: { label: string, info: string }[] = [
    {
      label: "Importe pendiente total",
      info: `${handleFacturasFilter(liquidaciones)?.total} €`

    },
    {
      label: "Facturas pendientes",
      info: `${handleFacturasFilter(liquidaciones)?.pendientes}`
    },
  ]

  return (
    <Flex w="100%" h="100%">


      <Flex h="100%" gap="25px">
        {cardsItem?.map((card: any, index: number) => (
          <CardInfo
            key={index}
            label={card?.label}
            info={card?.info !== undefined ? card?.info : '--'}
            bg="purewhite"
          />

        ))}
      </Flex>
    </Flex>
  )
}
