import { Image } from '@chakra-ui/react';
import AvatarDefault from "../../../assets/img/Avatar.png"

interface AvatarProps {
  src?: string | null;
  name: string;
  size?: string;
  rounded?: string;
  isHover?: boolean;
}

export const CustomAvatar = ({
  src,
  name,
  size,
  rounded,
  isHover = true,
  ...props
}: AvatarProps) => {

  return (
    <Image
      bg="transparent"
      _hover={isHover ? { boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.3)" } : {}}
      transition="0.5s ease"
      fit="cover"
      alt={name}
      src={src || AvatarDefault}
      {...props}
      minW={size}
      minH={size}
      boxSize={size}
      rounded={rounded}
    />
  );
};