import { Flex, Skeleton } from "@chakra-ui/react";

export const SekeletonPregunta = () => {
    return (
        <Flex
            direction="column"
            w="100%"
            gap="38px"
        >

            <Flex
                gap="20px"
                direction="column">

                <Skeleton
                    h="40px"
                    w="200px"
                    rounded="5px"
                />

                <Skeleton
                    h="40px"
                    w="103.22px"
                    rounded="5px"
                />
            </Flex>

            <Skeleton
                w="100%"
                h="199px"
                rounded="5px"
            />


            <Skeleton
                h="31.5px"
                w="115px"
                rounded="5px"
            />


            <Skeleton
                w="100%"
                h="300px"
                rounded="5px"
            />



        </Flex>
    );
}