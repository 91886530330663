import React, { useContext } from "react";
import { UpdateUserInt, UserContextInt, UserInt } from "../../interfaces/UserInt";

export const AuthContext = React.createContext<UserContextInt>({
    user: {
        auth: localStorage.getItem('token') ? true : false,
        tracking: true,
        id: null,
        email: null,
        username: null,
        avatar: null,
        nombre: null,
        apellidos: null,
        localidad: null,
        dni: null,
        telefono: null,
        role: null,
        matriculas: [],
        empresa: null,
        horarioLaboral: null,
        tutorFreelance: null,
    },
    setUser: ( action: any ) => {},
    login: ( jwt: string, user: UserInt, navigate: (path: string) => void ) => {},
    logout: ( message: string, navigate: (path: string) => void, toast: any ) => {},
    refreshUser: (updateUser: UpdateUserInt) => {}
});

export const useAuthContex = () => {
    return useContext(AuthContext);
}