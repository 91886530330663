import { useCallback, useState } from 'react';

import { Flex, Icon, Center, Spinner, IconButton, Skeleton, Button } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { passTypeFile } from '../../utils/functions/typeFileUploader';
import { CustomAvatar } from '../Avatar/CustomAvatar';
import { BiCamera, BiImageAlt, BiUpload } from 'react-icons/bi';
import { HiOutlineCamera } from 'react-icons/hi';

interface Props {
    size: string;
    name: string;
    src?: string | null;
    allowGif?: boolean;
    isUploading?: boolean;
    onDelete?: (e?: any) => any;
    onUpload: (...args: any[]) => any;
}

export const AvatarUploader = ({ size, name, src, allowGif, isUploading, onUpload, onDelete }: Props) => {
    const [isHovered, setIsHovered] = useState(false);
    const onDrop = useCallback((file: any) => {
        onUpload(file);

    }, []);

    const acceptedFormats = allowGif ? ['.png', '.jpg', '.jpeg', '.gif'] : ['.png', '.jpg', '.jpeg'];

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: passTypeFile(acceptedFormats),
    });

    return (
        <Flex>


            <Flex
                cursor="pointer"
                rounded="5px"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}

            >
                <Flex {...getRootProps()} gap="10px">

                    <input {...getInputProps()} />
                    {isUploading ? (
                        <Skeleton minW={size} minH={size} boxSize={size} rounded="5px" />
                    ) : (
                        <Flex
                            mr="20px"
                        >
                            <CustomAvatar
                                name={name}
                                src={src}
                                size={size}
                                rounded="full"
                            />

                            <Icon
                                bg="purewhite"
                                rounded="full"
                                p="2px" 
                                pos="relative"
                                boxSize="23px"
                                right="25px"
                                top="55px"
                                color="font"
                                border="1px solid"
                                borderColor="dark_grey"
                                as={HiOutlineCamera}
                            />
                        </Flex>

                    )}

                </Flex>


            </Flex>

        </Flex>

    );
};


