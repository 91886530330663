import { CursosInt, LeccionInt, ModulosInt } from "../../../interfaces/CoursesInt";

// FIXME: ESTA ES LA FUNCION QUE QUERIAS REVISAR @JUAN
// export const obtenerNumeroVimeo = async (curso: CursosInt | undefined, setThumbnail: (img: any) => void) => {
//     if (!curso) return;

//     const url = curso?.modulos;

//     const foundModule = url?.find((modulo) =>
//         modulo.lecciones?.some((leccion) => leccion.tipo === "video")
//     );

//     const foundVideoLeccion = foundModule?.lecciones?.find(
//         (leccion) => leccion.tipo === "video"
//     );

//     const contenidoVideo = foundVideoLeccion?.contenido;

//     if (contenidoVideo) {
//         const match = contenidoVideo.match(/\/(\d+)$/);

//         if (match) {
//             const numeroFinal = match[1];

//             const apiUrl = `${import.meta.env.VITE_URL_VIMEO_API}${numeroFinal}`;

//             try {
//                 const response = await fetch(apiUrl, {
//                     headers: {
//                         Authorization: `Bearer d440518350ead472fab0b41882993076`
//                     },
//                 });

//                 if (response.ok) {
//                     const data = await response.json();

//                     const thumbnail = data?.pictures?.sizes[5]?.link_with_play_button

//                     setThumbnail(thumbnail);


//                 } else {
//                     console.error('Error al hacer la solicitud a la API de Vimeo:', response.statusText);
//                 }
//             } catch (error) {
//                 console.error('Error en la solicitud a la API de Vimeo:', error);
//             }
//         } else {
//             console.error('No se pudo extraer el número de la URL de Vimeo.');
//         }
//     }
// };


// TODO: ESTA ES LA FUNCION MODIFICADA @AGUSTIN
export const obtenerNumeroVimeo = async (
    onContinueLeccion: () => { modulo: ModulosInt, redirectLeccion: string | undefined }, 
    setThumbnail: (img: any) => void
) => {
    const modulo = onContinueLeccion().modulo;

    const foundVideoLeccion = modulo?.lecciones?.find(
        (leccion: LeccionInt) => leccion.slug === onContinueLeccion().redirectLeccion
    );

    const contenidoVideo = foundVideoLeccion?.contenido;

    if (contenidoVideo) {
        const match = contenidoVideo.match(/\/(\d+)$/);
        
        if (match) {
            const numeroFinal = match[1];

            const apiUrl = `${import.meta.env.VITE_URL_VIMEO_API}${numeroFinal}`;

            try {
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer d440518350ead472fab0b41882993076`
                    },
                });

                if (response.ok) {
                    const data = await response.json();

                    const thumbnail = data?.pictures?.sizes[3]?.link_with_play_button

                    setThumbnail(thumbnail);


                } else {
                    console.error('Error al hacer la solicitud a la API de Vimeo:', response.statusText);
                }
            } catch (error) {
                console.error('Error en la solicitud a la API de Vimeo:', error);
            }
        } else {
            // console.error('No se pudo extraer el número de la URL de Vimeo.');
        }
    }
};