import { Flex, Text, Image } from '@chakra-ui/react';

interface Props {
    mensaje?: string;
    src?: string;
    titulo?:string;
}

export default function CardSinActividad({
    mensaje,
    src = "",
    titulo
}: Props) {
    return (
        <Flex
            flex="1"
            flexDirection="column"
            alignItems="center"
            justify="center"
            gap="20px"
            mb="50px"
            maxW="334px"
        >
            <Image src={src} />

            <Flex
                gap="10px"
                flexDirection="column"
                
                >

                <Text
                    align="center"
                    fontSize="21px"
                    fontWeight="600"
                    color="font"
                    lineHeight="25.41px">
                    {titulo}
                </Text>

            <Text
                 align="center"
                 fontSize="14px"
                 fontWeight="400"
                 color="font"
                 lineHeight="16.94px"
                 
                 >
            
                {mensaje}
            </Text>
            </Flex>
        </Flex>
    );
}
