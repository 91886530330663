import { Flex } from "@chakra-ui/react"
import { CardResumen } from "../../Components/utils/CardResumen"
import { CardRequisitos } from "../../Components/utils/CardRequisitos"
import { CursosInt } from "../../../../interfaces/CoursesInt"
import Notificaciones from "../../Components/utils/Notificaciones"
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol"
import { UserRolEnum } from "../../../../shared/utils/Types/RolEnum"
import { useAuthContex } from "../../../../shared/context/user.context"
import Mensajes from "../../Components/utils/Mensajes"
import { MatriculasInt } from "../../../../interfaces/MatriculasInt"


interface Props {
    curso?: CursosInt | undefined;
    matricula?: MatriculasInt | undefined;
    pregunta?: any;
}

export const TabInformacion = ({ curso, matricula, pregunta }: Props) => {
    const { user } = useAuthContex();

    return (
        <Flex
            gap="40px"
            direction="column"
        >
            <CardResumen curso={curso} matricula={matricula} />

            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <>
                    <CardRequisitos
                        matricula={matricula}
                    />
                </>
            }

            <Flex
                flex="1"
                gap="30px"
            >
                <Flex
                    flex="1"
                    h="fit-content"
                >
                    <Notificaciones
                        preguntas={pregunta}
                    />
                </Flex>

                <Flex flex="1">
                    <Mensajes cursoId={curso?.id} />
                </Flex>
            </Flex>
        </Flex>
    )
}