// Chakra UI
import { Flex, Icon, Text, useToast } from "@chakra-ui/react";
// Icons
import { BiChevronDown, BiUserCircle } from "react-icons/bi"
import { BiCog } from "react-icons/bi"
import { AiOutlinePoweroff } from "react-icons/ai"
// Hooks
import { useAuthContex } from "../../context/user.context";
// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


export const UserActions = () => {
    const { logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const [modal, setModal] = useState<boolean>(false)

    const closeModal = (event: any, isButton = false) => {
        if (modal !== false) {
            setModal(false)
        }

        if (modal !== true && isButton) {
            setModal(true)
        }

        event.stopPropagation();
    }

    useEffect(() => {
        document.body.addEventListener('click', closeModal);

        return () => document.body.removeEventListener('click', closeModal);
    }, [modal])

    return (
        <Flex>
            <Flex
                gap="10px"
                alignItems="center"
                pos="relative"
                rounded="15px"
                _hover={{
                    bgColor: "#C7CDFF",
                    boxShadow: '0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)'
                }}
            >

                <Icon
                    onClick={(e: any) => closeModal(e, true)}
                    color="font"
                    as={BiChevronDown}
                    boxSize="24px"
                    cursor="pointer"
                />


                <Flex
                    pos="absolute"
                    top="40px"
                    right="10px"
                    borderRadius="14px"
                    boxShadow="rgba(0, 0, 0, 0.25) 0px 4px 29px 0px"
                    zIndex="999"
                    opacity={modal ? "1" : "0"}
                    visibility={modal ? "visible" : "hidden"}
                    flexDirection="column"
                    bg="purewhite"
                    w="200px"
                >
                    {/* <Flex flex="1" flexDirection="column" p="22px 18px" gap="10px">
                        <Text fontSize="13px" fontWeight="medium" textAlign="left">
                            Tema de la interfaz
                        </Text>

                        <Switch />
                    </Flex> */}

                    <Flex
                        flex="1"
                        flexDirection="column"
                        p="22px 18px"
                        gap="15px"
                    >

                        <Flex alignItems="center" gap="10px" cursor="pointer" onClick={() => navigate("/perfil")}>
                            <Icon as={BiUserCircle} color="secondary_variant_font" w="16px" h="16px" />
                            <Text fontWeight="normal" fontSize="16px">Perfil</Text>
                        </Flex>

                        <Flex alignItems="center" gap="10px" cursor="pointer" onClick={() => logout("Sesión cerrada correctamente", navigate, toast)}>
                            <Icon as={AiOutlinePoweroff} color="secondary_variant_font" w="16px" h="16px" />
                            <Text fontWeight="normal" fontSize="16px">Cerrar sesión</Text>
                        </Flex>

                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};