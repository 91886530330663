import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, ModalHeader, Text, Flex, Textarea, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FileUploader } from "../../../shared/components/Inputs/FileUploader";
import { uploadAdjuntoEntregables } from "../../../shared/middlewares/entregables.middleware";
import { StatusEnum, notify } from "../../../shared/utils/functions/notify";
import { FileLoad } from "../../../shared/components/Customs/FileLoad";
import { ModeEnumEntregable } from "../../../shared/utils/Types/EntregableEstadoEnum";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    entregable?: any;
    setEntregable: any;
    realizarEntrega: any;
    refreshEntregable: (texto?: string | undefined) => Promise<any>;
    setMode: (estado: ModeEnumEntregable) => void;
}

export const EntregarTareaModal = ({
    isOpen,
    onClose,
    setEntregable,
    realizarEntrega,
    refreshEntregable,
    setMode
}: Props) => {
    const toast = useToast();
    const [value, setValue] = useState<string>('');
    const [files, setFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [opacity, setOpacity] = useState<string>("0")
    const [translate, setTranslate] = useState<string>("translateX(-50%)")

    useEffect(() => { setFiles([]) }, []);
    useEffect(() => { setValue("") }, []);

    useEffect(() => {
        if (files?.length > 0) {
            setOpacity("1")
            setTranslate("translateX(0%)")
        } else {
            setOpacity("0")
            setTranslate("translateX(-50%)")
        }

    }, [files])

    const handleOnDeleteFile = (file: File) => setFiles(files.filter((f) => f.name !== file.name && f.size !== file.size && f.type !== file.type));

    const handleOnLoadedFiles = (files: File[]) => setFiles(files);

    const handleGuardarCambios = async () => {
        refreshEntregable(value).then((response: any) => {
            const entregableId = response?.data?.data?.id;

            setIsUploading(true);

            const entregableData = {
                id: entregableId,
                adjunto: files[0],
            }

            uploadAdjuntoEntregables(entregableData)
                .then((response: any) => {
                    setEntregable(response?.data?.data);
                    // setMode(ModeEnumEntregable.ENTREGA)
                    notify(toast, StatusEnum.success, 'Se ha realizado la entrega correctamente.');

                    setFiles([]);
                    setValue("");
                })
                .catch(() => {
                    notify(toast, StatusEnum.error, 'Por favor, pruebe de nuevo o contacte con soporte si el error persiste.');
                });

            setIsUploading(false);

            realizarEntrega();

        })
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="md"
            isCentered
        >
            <ModalOverlay />

            <ModalContent
                rounded="15px"
                p="20px 10px"
            >
                <ModalHeader
                    display="flex"
                    flexDir="column"
                    gap="15px"
                    mb="10px"
                >
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="600"
                        lineHeight=" 96.9%"
                    >
                        Adjuntar entrega
                    </Text>

                    <Text
                        color="secondary_variant_font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        Rellene la siguiente información para realizar su entrega
                    </Text>
                </ModalHeader>

                <ModalBody>
                    <Flex gap="20px" direction="column" mb="30px">
                        <Text
                            color="secondary_variant_font"
                            fontSize="16px"
                            fontWeight="700"
                            lineHeight=" 96.9%"
                        >
                            Comentarios
                        </Text>

                        <Textarea
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                            minH="150px"
                            resize="none"
                            bg="white"
                            border="1px solid grey"
                        />
                    </Flex>

                    <Flex gap="20px" direction="column">
                        <Text
                            color="secondary_variant_font"
                            fontSize="16px"
                            fontWeight="700"
                            lineHeight=" 96.9%"
                        >
                            Archivos
                        </Text>

                        <Flex
                            w="100%"
                            gap="15px"
                            transform={translate}
                            transition="all 700ms ease"
                        >
                            <Flex
                                w="50%"
                                opacity={opacity}
                                transition="all 1s ease"
                            >
                                <FileLoad
                                    files={files}
                                    isUploading={isUploading}
                                />
                            </Flex>

                            <Flex w="50%">
                                <FileUploader
                                    files={files}
                                    maxFiles={1}
                                    maxSize="50MB"
                                    isUploading={isUploading}
                                    supportedFormats={['.zip', '.rar']}
                                    onDeleteFile={handleOnDeleteFile}
                                    onLoadedFiles={(files) => handleOnLoadedFiles(files)}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </ModalBody>

                <ModalFooter gap="15px">
                    <Button
                        color="font"
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="96.9%"
                        bg="transparent"
                        border="1px solid"
                        borderColor="light_grey"
                        p="10px"
                        flex="1"
                        onClick={() => {
                            onClose();
                            setValue("")
                            setFiles([]);
                        }}
                    >
                        Cancelar
                    </Button>

                    <Button
                        color="white"
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="96.9%"
                        bg="main"
                        p="10px"
                        flex="1"
                        isDisabled={files.length === 0}
                        onClick={() => {
                            onClose();
                            handleGuardarCambios();
                        }}
                    >
                        Adjuntar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}