//  TODO: LOCAL PRODUCCION
// export enum UrlTypeEnum {
//     PLATAFORMA = "http://imagina-platform.localtest.me:9200",
// }

// TODO: LOCAL DESARROLLO
// export enum UrlTypeEnum {
//     PLATAFORMA = "http://imaginabeta-platform.localtest.me:9200",
// }

// TODO: PRODUCCION
export enum UrlTypeEnum {
    PLATAFORMA = "https://campus-platform.imaginaformacion.com",
}