import { Button, Flex, Text, Tooltip } from "@chakra-ui/react";
import { CheckItems } from "./CheckItems";
import { fmtSnds, fmtSndsHourMin } from "../../../../shared/utils/functions/formatters";
import { useNavigate } from "react-router-dom";
import { DowloadDiploma } from "../../../../shared/components/Buttons/DowloadDiploma";

interface Props {
    matricula?: any;
}

export const CardRequisitos = ({ matricula }: Props) => {
    const timeToProgress = (time: number): number => {
        if (!time) return 0;

        const timeArray = fmtSnds(time)?.split(':');
        const hours = Number(timeArray[0]);
        const minutes = Number(`0.${timeArray[1]}`);

        const totalTime = (hours + minutes)

        return totalTime
    }

    return (
        <Flex
            w="100%"
            bg="#FFF"
            p="30px"
            borderRadius="10px"
            gap="50px"
            direction="column"
        >
            <Flex
                direction="column"
                gap="50px"
            >
                <Text
                    color="font"
                    fontSize="18px"
                    fontWeight="600"
                >
                    Requisitos para superar el curso
                </Text>

                <Flex
                    gap="30px"
                    direction="column"
                >
                    <CheckItems
                        label="Tener más de un 75 de nota media"
                        value={matricula?.puntuacionMedia?.toFixed(0)}
                        expected={100}
                        type="total"
                        isCheck={matricula?.puntuacionMedia >= 75}
                        checkValue={75}
                        progress={matricula?.puntuacionMedia}
                    />

                    <CheckItems
                        label="Realizar el 75% de las actividades"
                        value={matricula?.progresoLecciones?.toFixed(0)}
                        type="porcentaje"
                        isCheck={matricula?.progresoLecciones >= 75}
                        checkValue={75}
                        progress={matricula?.progresoLecciones}
                    />

                    <CheckItems
                        label="Completar el 75% de las ejercicios"
                        value={matricula?.progresoEntregables?.toFixed(0)}
                        type="porcentaje"
                        isCheck={matricula?.progresoEntregables >= 75}
                        checkValue={75}
                        progress={matricula?.progresoEntregables}
                    />

                </Flex>
            </Flex>

            <Flex
                direction="column"
                gap="30px"
            >
                <Text
                    color="font"
                    fontSize="18px"
                    fontWeight="600"
                >
                    Recomendaciones
                </Text>

                <CheckItems
                    label={`Tener un tiempo de conexión superior a ${(matricula?.grupo?.curso?.duracion * 75) / 100}h`}
                    value={fmtSndsHourMin(matricula?.tiempoAcceso?.toFixed(0))}
                    type="hora"
                    isCheck={parseInt(fmtSnds(matricula?.tiempoAcceso)) >= (matricula?.grupo?.curso?.duracion * 75) / 100}
                    progress={timeToProgress(matricula?.tiempoAcceso)}
                    checkValue={(matricula?.grupo?.curso?.duracion * 75) / 100}
                    max={(matricula?.grupo?.curso?.duracion * 75) / 100}
                />

                <Text
                    fontSize="12px"
                    fontStyle="italic"
                    fontWeight="400"
                >
                    *IMPORTANTE: El tiempo de conexión únicamente <Text as="span" fontWeight="700">será contabilizado dentro de las lecciones</Text> del curso, en cualquier otro lugar del campus no se añadirá.
                </Text>
            </Flex>

            <DowloadDiploma
                matricula={matricula}
            />
        </Flex>
    );
}
