import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useToast, Flex } from "@chakra-ui/react";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useAuthContex } from "../../../shared/context/user.context";
import { CarpetaMensajeria, EstadoMensaje } from "../../../shared/utils/Types/MensajeriaEnum";
import { createChat, createMessage } from "../../../shared/middlewares/messages.middleware";
import { AxiosResponse } from "axios";
import { StatusEnum, notify } from "../../../shared/utils/functions/notify";
import { FormInput } from "../../../shared/components/FormElements/FormInput";
import { FormTextEditor } from "../../../shared/components/FormElements/FormTextEditor";
import { useEffect, useState } from "react";
import { LeccionInt } from "../../../interfaces/CoursesInt";
import { getLecciones } from "../../../shared/middlewares/lessons.middleware";
import { FormSelect } from "../../../shared/components/FormElements/FormSelect";
import { CustomSelect } from "../../../shared/components/Inputs/CustomSelect";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { useNavigate } from "react-router-dom";
import { useMatriculasAlumno } from "../../../shared/middlewares/matriculas.middleware";
import { EstadoTypeEnum } from "../../../shared/utils/Types/EstadoTypeEnum";
import { UserRolEnum } from "../../../shared/utils/Types/RolEnum";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefreshChats: (action: boolean) => void;
}

// FIXME: CUANDO AGREGAMOS UNA CAPTURA DE PANTALLA LA IMAGEN PISA EL MODAL

export const NewChatModal = ({ isOpen, onClose, setRefreshChats }: Props) => {
    const { user } = useAuthContex();
    const toast = useToast();
    const navigate = useNavigate();
    const { matriculas } = useMatriculasAlumno({ user: user });
    const [optGrupos, setOptGrupos] = useState<{
        value: string | undefined;
        label: string;
        matriculaId: string | undefined;
        tutorId: string | undefined;
        cursoId: string | undefined;
    }[]>([])
    const [grupo, setGrupo] = useState<{
        value: string | undefined;
        label: string;
        matriculaId: string | undefined;
        tutorId: string | undefined;
        cursoId: string | undefined;
    }>()
    const [optLecciones, setOptLecciones] = useState<{ value: string, label: string }[]>([])


    useEffect(() => {
        const newOptions: {
            value: string | undefined;
            label: string;
            matriculaId: string | undefined;
            tutorId: string | undefined;
            cursoId: string | undefined;
        }[] = []

        matriculas?.data?.map((matricula: MatriculasInt) => {
            if (!matricula || (matricula?.estado === EstadoTypeEnum.INACTIVO && user?.role?.nombre === UserRolEnum.ALUMNO)) return

            return newOptions.push({
                value: matricula?.grupoId,
                label: matricula?.grupo?.curso?.nombre + " " + "(" + matricula?.grupo?.nombre + ")",
                matriculaId: matricula?.id,
                tutorId: matricula?.tutorId,
                cursoId: matricula?.grupo?.cursoId
            })
        })

        setOptGrupos(newOptions)

    }, [matriculas])

    const validationSchema = Yup.object().shape({
        leccionId: Yup.string()
            .required("Debe seleccionar una lección"),
        asunto: Yup.string()
            .required("Debe establecer un asunto"),
        mensaje: Yup.string()
            .required("Debe escribir un mensaje")
    });

    const initialValues = {
        leccionId: null,
        asunto: null,
        mensaje: null
    };

    const submitForm = async (values: any) => {
        if (!user?.id) return;

        const newChat = {
            matriculaId: grupo?.matriculaId,
            receptorId: grupo?.tutorId,
            leccionId: values.leccionId,
            emisorId: user?.id,
            asunto: values.asunto,
            carpeta: CarpetaMensajeria.ENTRADA
        }

        createChat(newChat)
            .then((response: AxiosResponse) => {

                user?.id &&
                    createMessage({
                        conversacionId: response?.data?.data?.id,
                        emisorId: user?.id,
                        receptorId: grupo?.tutorId,
                        texto: values.mensaje,
                        estado: EstadoMensaje.NO_LEIDO
                    })
                        .then(() => {
                            navigate(`/mensajeria/${response?.data?.data?.id}`)
                        })
                        .finally(() => {
                            setRefreshChats(true);
                            handleClose();
                        })
                // FIXME: REVISAR CIERRE DE FORMULARIO Y NAVEGACION DESPUES DE LIMPIEZA
            })
            .catch(() => notify(toast, StatusEnum.error, "Error al iniciar conversacion, vuelva a intentarlo si el error persiste contacte con soporte"))
    };

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) keyEvent.stopPropagation();
    }

    useEffect(() => {
        if (!grupo?.cursoId) return
        const newOptions: { value: string, label: string }[] = []

        getLecciones(`?curso=${grupo?.cursoId}&limit=50&sortBy=createdAt&order=asc`)
            .then((response: AxiosResponse) => {
                const lecciones = response?.data?.data?.data;

                lecciones && lecciones?.map((leccion: LeccionInt) => {
                    if (!leccion.id) return

                    return newOptions.push({ 
                        value: leccion?.id, 
                        label: leccion?.nombre // + " " + "(" + leccion?.modulo?.nombre + ")",
                    })
                })

                setOptLecciones(newOptions)
            })

    }, [grupo])

    const handleClose = () => {
        setGrupo(undefined)
        onClose();
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="4xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                bg="purewhite"
                px="24px"
            >
                <ModalHeader
                    color="main"
                    fontSize="24px"
                    fontWeight="600"
                    lineHeight="140%"
                    textAlign="start"
                    p="30px 24px"
                >
                    Nueva duda
                </ModalHeader>

                <Formik
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values);
                        resetForm();
                    }}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit, values } = formik;

                        return (
                            <FormikForm onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                                <ModalBody>
                                    <Flex
                                        direction="column"
                                        gap="15px"
                                    >
                                        <CustomSelect
                                            value={grupo}
                                            setValue={setGrupo}
                                            label="Grupo"
                                            placeholder="Elige el grupo sobre el que vas a hablar"
                                            options={optGrupos}
                                        />

                                        <Flex
                                            cursor={!grupo?.cursoId ? "not-allowed" : "default"}
                                        >
                                            <FormSelect
                                                name='leccionId'
                                                isDisabled={!grupo?.cursoId}
                                                label="Lección"
                                                placeholder="Elige la lección de la que trate tu duda"
                                                options={optLecciones}
                                            />
                                        </Flex>

                                        <FormInput
                                            name="asunto"
                                            label="Asunto"
                                            placeholder="¿De qué trata tu mensaje?"
                                        />

                                        <FormTextEditor
                                            name="mensaje"
                                            label="Mensaje"
                                            placeholder="Escriba aqui..."
                                            height="150px"
                                            onChange={formik.setFieldValue}
                                        />
                                    </Flex>
                                </ModalBody>

                                <ModalFooter
                                    gap="20px"
                                    justifyContent="end"
                                    mb="10px"
                                >
                                    <Button
                                        onClick={handleClose}
                                        p="15px 20px"
                                        h="fit-content"
                                        w="fit-content"
                                        bg="transparent"
                                        color="main"
                                        fontSize="16px"
                                        fontWeight="500"
                                        border="1px solid"
                                        borderColor="main"
                                        _hover={{ bg: "secondary_variant" }}
                                    >
                                        Cancelar
                                    </Button>

                                    <Button
                                        p="15px 20px"
                                        h="fit-content"
                                        w="fit-content"
                                        bg="main"
                                        color="purewhite"
                                        fontSize="16px"
                                        fontWeight="500"
                                        type="submit"
                                        _hover={{ bg: "secondary_variant" }}
                                    >
                                        Enviar duda
                                    </Button>
                                </ModalFooter>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </ModalContent>
        </Modal >
    );
};