import { UserRolEnum } from './../utils/Types/RolEnum';
import _axios from "../services/http.service";
import useSWR from "swr";
import tenantInstance from "../services/tenantInstance.service";
import { UserInt } from "../../interfaces/UserInt";

const { tenant, client } = await tenantInstance();

const ENDPOINT_GRUPOS = "grupos"
const ENDPOINT_GRUPOS_TUTOR = "gruposTutor"

export const getLoadGrupos = async (query?: string) => await _axios.get(`${tenant}/${client}/` + ENDPOINT_GRUPOS + query).then((response: any) => response)

// Middlewares GET y Hooks SWR => GRUPOS
export const getGrupos = async (endpoint: string) => await _axios.get(endpoint).then((response: any) => response)

export const useGrupos = (query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_GRUPOS + query
    const { isLoading, data, mutate } = useSWR(endpoint, getGrupos);
        
    return {
        grupos: data?.data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const useGruposTutor = ({
    user,
    query = "",
} : {
    user: UserInt | null;
    query?: string;
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_GRUPOS_TUTOR + query
    const { isLoading, data, mutate, error } = useSWR(
        (user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) ? endpoint : null, 
        getGrupos
    );
        
    return {
        grupos: data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}
