import useSWR from "swr";
import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

const { tenant, client } = await tenantInstance();
const ENDPOINT = "cursos"

export const getLoadCursos = async (query?: string) => await _axios.get(`${tenant}/${client}/` + ENDPOINT + query).then((response: any) => response)

// Middlewares y Hooks generales y SWR => CURSOS
export const getCursos = async (endpoint: string) => await _axios.get(`${tenant}/${client}/` + endpoint).then((response: any) => response)

export const useCursos = (query = "") => {
    const endpoint =  ENDPOINT + query
    const { isLoading, data, mutate, error } = useSWR(endpoint, getCursos);

    return {
        cursos: data?.data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

export const useCursoSlug = (slug: string | undefined) => {
    const endpoint =  ENDPOINT + `/${slug}`
    const { isLoading, data, mutate, error } = useSWR(slug ? endpoint : null, getCursos);
        
    return {
        curso: data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}