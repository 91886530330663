export const validateProgressModule = (cursoProgress: any, modulo: any) => {
    const totalLecciones = modulo?.lecciones?.length;
    let completed = 0;

    modulo?.lecciones?.forEach((leccion: any) => {
        if (Reflect.has(cursoProgress?.data || {}, leccion?.id)) {
            if (cursoProgress?.data[leccion?.id]?.estado === "finalizacion") {
                completed = completed + 1
            }
        }
    });

    if (completed > 0 && totalLecciones) {
        return (completed / totalLecciones) * 100

    } else {
        return 0;
    }
};


export const sortByOrder = (data: any[] | undefined) => {
    if (!data) return []

    const sorted = data.sort((a, b) => (a.orden > b.orden) ? 1 : (a.orden < b.orden) ? -1 : 0);

    return sorted
};

export const sortByOrderLecciones = (data: any[] | undefined) => {
    if (!data) return [];

    const sorted = data.sort((a, b) => {

        const numberA = parseInt(a.label.match(/\d+/)?.[0] || '0', 10);
        const numberB = parseInt(b.label.match(/\d+/)?.[0] || '0', 10);

        return numberA - numberB;
    });

    return sorted;
};

