import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './css/styles.css'
import * as Sentry from "@sentry/react";
import { ColorModeScript } from '@chakra-ui/react'

if(import.meta.env.VITE_SENTRY_APIKEY)
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_APIKEY,
    integrations:[
      new Sentry.BrowserTracing({
        tracePropagationTargets: [window.location.origin, /^https:\/\/campus.imaginaformacion\.com/]
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [
          window.location.origin,
          /^https:\/\/campus.imaginaformacion\.com/
        ],
        maskAllText: false,
      })
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ColorModeScript/>
    <App />
  </React.StrictMode>,
)
