import { Flex, Skeleton } from "@chakra-ui/react";

export const SkeletonCards = () => {
    return (

            <Flex
                direction="column"
                gap="40px"
                mt="30px"
            >
                <Skeleton
                    h="40px"
                    w="324px"
                    rounded="5px"
                />

                <Skeleton
                    h="280px"
                    w="324px"
                    rounded="5px"
                />

                <Skeleton
                    h="320px"
                    w="324px"
                    rounded="5px"
                />

        </Flex>
    );
}