import { Flex, Image, Box, Text, Icon } from "@chakra-ui/react"
import { truncate } from "lodash";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../../shared/components/Customs/CardSinInformacion";

interface Props {
    anuncio?: any;
    translateAnuncios?: any;
    setTranslateAnuncios?: (translate: any) => void;
    index?: number;
    maxTranslate?: any;
    isEmpty?: boolean;
    countAnuncion: number;
}


export default function cardAnuncio({
    anuncio,
    translateAnuncios,
    setTranslateAnuncios = () => { },
    maxTranslate = 0,
    index = 0,
    isEmpty = false,
    countAnuncion
}: Props) {

    const handleClickLeftArrow = () => {
        if (index === 0) {
            setTranslateAnuncios(maxTranslate)
        } else {
            setTranslateAnuncios(translateAnuncios + (100))
        }
    }

    const handleClickRightArrow = () => {
        if (translateAnuncios === maxTranslate) {
            setTranslateAnuncios(0)
        }
        else {
            setTranslateAnuncios(translateAnuncios - (100))
        }
    }

    return (
        isEmpty ?
            <Flex
                direction="column"
                rounded="8px"
                bg='linear-gradient(#FFF 0%, #FFF 100%) padding-box, 
                linear-gradient(#4E5FE4 0%, #8694FF 100%) border-box'
                borderWidth="2px"
                borderColor="transparent"
                padding="30px"
                gap="30px"
                flex="1"
                w="100%"
            >
                <Flex align="center" gap="16px">
                    <Icon
                        cursor="pointer"
                        color="secondary"
                        boxSize="24px"
                        as={HiChevronLeft}
                        display={countAnuncion > 1 ? "flex" : "none"}
                    />

                    <Flex
                        w="100%"
                        bg="secondary"
                        justify="center"
                        borderRadius="32px"
                        align="center"
                        minH="35px"
                        shadow="0px 4px 15px 0px rgba(199,205,255,0.6)"
                    >
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            lineHeight="121%"
                            color="purewhite"
                        >
                            ANUNCIOS
                        </Text>
                    </Flex>

                    <Icon
                        cursor="pointer"
                        color="secondary"
                        boxSize="24px"
                        as={HiChevronRight}
                        display={countAnuncion > 1 ? "flex" : "none"}
                    />
                </Flex>

                <CardSinInformacion
                    type={TypeCardSinInformacion.ANUNCIOS}
                    isRow
                    isCard
                />
            </Flex>

            :

            <Flex
                direction="column"
                bgGradient="linear(to-r, #4E5FE4, #8694FF)"
                padding="30px"
                gap="30px"
                flex="1"
                w="100%"
            >
                <Flex align="center" gap="16px">
                    <Icon
                        cursor="pointer"
                        color="purewhite"
                        boxSize="24px"
                        as={HiChevronLeft}
                        onClick={handleClickLeftArrow}
                        display={countAnuncion > 1 ? "flex" : "none"}
                    />

                    <Flex
                        w="100%"
                        bg="purewhite"
                        justify="center"
                        borderRadius="32px"
                        align="center"
                        minH="35px"
                        shadow="0px 4px 15px 0px rgba(199,205,255,0.6)"
                    >
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            lineHeight="121%"
                            color="secondary"
                        >
                            {(`NUEVO ANUNCIO EN ${anuncio?.curso?.nombre}`).toUpperCase()}
                        </Text>
                    </Flex>

                    <Icon
                        cursor="pointer"
                        color="purewhite"
                        boxSize="24px"
                        as={HiChevronRight}
                        onClick={handleClickRightArrow}
                        display={countAnuncion > 1 ? "flex" : "none"}
                    />
                </Flex>

                <Flex gap="30px">
                    <Box w={{ "2xl": "100%", xl: "50%" }} h="190px" maxW="350px">
                        <Image
                            w="100%"
                            h="100%"
                            objectFit="cover"
                            borderRadius="8px"
                            src={anuncio?.imagen?.url}
                        />
                    </Box>

                    <Flex direction="column" gap="20px" flex="1">
                        <Text
                            fontSize="26px"
                            fontWeight="600"
                            textColor="purewhite"
                            lineHeight="114%"
                        >
                            {anuncio.titulo}
                        </Text>

                        <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="purewhite"
                            lineHeight="121%"
                        >
                            {truncate(anuncio.contenido, { length: window.innerWidth < 1650 ? 80 : 500, omission: "..." })}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
    );
}