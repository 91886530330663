import { Flex, Text, Image, Box, Card } from "@chakra-ui/react";
import { CardNotificaciones } from "./CardNotificaciones";
import { PreguntaForoInt } from "../../../../interfaces/ForoInt";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../../shared/components/Customs/CardSinInformacion";

interface Props {
  preguntas: any;
}

export default function Notificaciones({ preguntas }: Props) {


  return (
    <Flex
      w="100%"
      bg="#FFF"
      p="30px"
      borderRadius="8px"
      gap="20px"
      direction="column"
      minH="300px"
    >
      <Text
        color="font"
        fontSize="18px"
        fontWeight="600"
      >
        Notificaciones en foro
      </Text>

      {!preguntas || preguntas?.length === 0 ?
        <CardSinInformacion type={TypeCardSinInformacion.ACTIVIDADES} isCard/>
        :
        preguntas?.map((pregunta: PreguntaForoInt, index: number) => (
          <CardNotificaciones
            key={index}
            pregunta={pregunta}
          />
        ))
      }
    </Flex>

  )
}
