import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Button,
    Text,
    Box,
    useToast,
    Flex,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Icon,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { useLeccionSlug } from '../../../shared/middlewares/lessons.middleware';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
// import { Editor } from "primereact/editor";
import { updateEntregables, useEntregableId, useEntregablesLeccion } from '../../../shared/middlewares/entregables.middleware';
import { BiDownload, BiFile } from 'react-icons/bi';
import { FormInput } from '../../../shared/components/FormElements/FormInput';
import { FileUploader } from '../../../shared/components/Inputs/FileUploader';
import { FileLoad } from '../../../shared/components/Customs/FileLoad';
import { EntregablesInt } from '../../../interfaces/Entregables';
import { formatDate } from '../../../shared/utils/functions/formatDate';
import { truncate } from 'lodash';
import { addCorreccion, updateCorreccion, uploadCorreccionEntregables } from '../../../shared/middlewares/correcciones.middleware';
import { StatusEnum, notify } from '../../../shared/utils/functions/notify';
import { EntregableEstadoEnum } from '../../../shared/utils/Types/EntregableEstadoEnum';
import { AxiosResponse } from 'axios';
import { CustomQuill } from '../../../shared/components/Customs/CustomQuill';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    id: string | undefined;
    setRefresh: () => void;
}

export const CorreccionDrawer = ({ isOpen, onClose, id, setRefresh }: Props) => {
    const toast = useToast();
    const { entregable } = useEntregableId(id);
    const { entregables } = useEntregablesLeccion({
        leccionId: entregable?.leccionId,
        matriculaId: entregable?.matriculaId
    })
    const { leccion } = useLeccionSlug({ slug: entregable?.leccion?.slug });
    const [comentario, setComentario] = useState<string>("")
    const [files, setFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [opacity, setOpacity] = useState<string>("0")
    const [translate, setTranslate] = useState<string>("translateX(-50%)")

    useEffect(() => { setFiles([]) }, []);
    useEffect(() => { setComentario("") }, []);

    useEffect(() => {
        if (files?.length > 0) {
            setOpacity("1")
            setTranslate("translateX(0%)")
        } else {
            setOpacity("0")
            setTranslate("translateX(-50%)")
        }

    }, [files])

    const handleOnDeleteFile = (file: File) => {
        setFiles(files.filter((f) => f.name !== file.name && f.size !== file.size && f.type !== file.type));
    };

    const handleOnLoadedFiles = (files: File[]) => {
        setFiles(files);
    };

    const validationSchema = Yup.object().shape({
        puntuacion: Yup.number()
            .min(0, "La puntuacion minima es 0")
            .max(100, "La puntuacion maxima es 100")
            .required('La puntuacion es obligatoria')
            .typeError('La puntuacion es obligatoria')
    });

    const initialValues = {
        puntuacion: null,
    };

    const submitForm = async (values: any) => {
        if (!id) return
        setIsLoading(true)

        const newCorreccion = {
            entregableId: id,
            texto: comentario,
            puntuacion: values.puntuacion,
            correccionVista: true
        }

        if (!entregable?.correccion) {
            addCorreccion(newCorreccion)
                .then((response: AxiosResponse) => {
                    uploadCorreccionEntregables({
                        id: response?.data?.data?.id,
                        adjunto: files[0]
                    })

                    updateEntregables({
                        id: newCorreccion.entregableId,
                        data: {
                            estado: values.puntuacion < 75 ? EntregableEstadoEnum.ERROR : EntregableEstadoEnum.CORRECTO
                        }
                    })
                        .then(() => {
                            setRefresh()
                            handleClose();
                        })
                        .catch(() => notify(toast, StatusEnum.error, "Error al actualizar estado"))
                })
                .catch(() => notify(toast, StatusEnum.error, "Error al enviar correccion, vuelva a intentarlo"))
                .finally(() => setIsLoading(false))

        } else {
            updateCorreccion({
                id: entregable?.correccion?.id,
                data: {
                    texto: comentario,
                    puntuacion: values.puntuacion,
                }
            })
                .then((response: AxiosResponse) => {
                    uploadCorreccionEntregables({
                        id: response?.data?.data?.id,
                        adjunto: files[0]
                    })

                    updateEntregables({
                        id: id,
                        data: {
                            estado: values.puntuacion < 75 ? EntregableEstadoEnum.ERROR : EntregableEstadoEnum.CORRECTO
                        }
                    })
                        .then(() => {
                            setRefresh()
                            handleClose();
                        })
                        .catch(() => notify(toast, StatusEnum.error, "Error al actualizar estado"))
                })
                .catch(() => notify(toast, StatusEnum.error, "Error al actualizar correccion, vuelva a intentarlo"))
                .finally(() => setIsLoading(false))
        }
    };

    const handleFilter = (data: EntregablesInt[], entregableId: string | undefined) => {
        if (!entregableId) return []
        
        return data?.filter((entregable: EntregablesInt) => entregable?.id !== entregableId)
    }

    const handleClose = () => {
        setFiles([]);
        setComentario("")
        onClose();

    }

    const filterByMatricula = (entregables: EntregablesInt[], matriculaId: string | undefined) => {
        if (!entregables || entregables?.length === 0 || !matriculaId) return []

        return entregables?.filter((entregable: EntregablesInt) => entregable?.matriculaId === matriculaId)
    }

    return (
        <Drawer
            isOpen={isOpen}
            onClose={handleClose}
            placement='right'
        >
            <DrawerOverlay />

            <DrawerContent
                maxW="60vw"
                minW="60vw"
                w="60vw"
            >
                <DrawerHeader
                    textAlign="start"
                    mt="20px"
                    ml="20px"
                    color="dark_blue"
                    fontSize="20px"
                    fontWeight="600"
                    lineHeight="140%"
                >
                    Corrección de ejercicio
                </DrawerHeader>

                <DrawerBody
                    color="dark_blue"
                    fontSize="14px"
                    fontWeight="500"
                    className='scroll-bar'
                >
                    <Flex gap="30px" border="1px solid #EAEAEA" p="20px" rounded="10px" w="50%" maxW="100%">
                        <Flex direction="column" gap="5px">
                            <Text><Text mr="5px" as="strong">Ejercicio:</Text>{entregable?.leccion?.nombre}</Text>
                            <Text><Text mr="5px" as="strong">Alumno:</Text>{entregable?.user?.nombre} {entregable?.user?.apellidos}</Text>
                        </Flex>

                        <Flex direction="column" gap="5px">
                            <Text><Text mr="5px" as="strong">Curso:</Text>{leccion?.modulo?.curso?.nombre}</Text>
                            <Text><Text mr="5px" as="strong">Grupo:</Text>{entregable?.matricula?.grupo?.nombre}</Text>
                        </Flex>

                    </Flex>

                    {leccion?.descripcion && leccion?.descripcion !== "" &&                    
                    <Flex
                        mx="auto"
                        mt="40px"
                        overflow="hidden"
                        rounded="10px"
                    >
                        <iframe width="100%" height="500" allow='fullscreen'
                            src={leccion?.descripcion}
                        />
                    </Flex>
                    }

                    <Flex mt="20px" >
                        <Accordion w="100%" allowToggle>
                            <AccordionItem
                                border="none"
                            >
                                <AccordionButton
                                    bg="purewhite"
                                    borderRadius="10px"
                                    display="flex"
                                    justifyContent="space-between"
                                    p="15px 20px"
                                >
                                    <Text
                                        color="dark_blue"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        Instrucciones de entrega
                                    </Text>
                                    <AccordionIcon boxSize="25px" color="dark_grey" />
                                </AccordionButton>

                                <AccordionPanel>
                                    <Box>
                                        <MarkdownPreview
                                            source={leccion?.contenido || ''}
                                            style={{
                                                color: "#252F40",
                                                backgroundColor: "#fff",
                                                padding: "10px",
                                                width: '100%',
                                                maxWidth: '100%',
                                                background: "inherit",
                                                fontSize: "14px"
                                            }}
                                        />
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>

                        </Accordion>
                    </Flex>

                    <Flex
                        direction="column"
                        mt="25px"
                        gap="15px"
                    >
                        <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="dark_blue"
                        >
                            Adjunto:
                        </Text>

                        <Flex
                            direction="column"
                            p="12px"
                            bg="#F1F3F6"
                            rounded="5px"
                            gap="10px"
                        >
                            <Flex w="100%" p="10px 15px" bg="#AFB8CF" borderRadius="5px" alignItems="center" gap="10px">
                                <Icon color="black" boxSize="22px" as={BiFile} />

                                <Flex w="100%" alignItems="center">
                                    <Text
                                        w="100%"
                                        color="#00152D"
                                        fontSize="13px"
                                        fontWeight="500"
                                    >
                                        {entregable?.adjunto?.name}
                                    </Text>

                                    <Button
                                        fontSize="13px"
                                        fontWeight="500"
                                        letterSpacing="-0.1px"
                                        color="#FFF"
                                        bg="inherit"
                                        border="1px solid #FFF"
                                        h="fit-content"
                                        p="7px 14px"
                                        leftIcon={
                                            <Icon as={BiDownload} boxSize="18px" />
                                        }
                                        onClick={() => location.href = entregable?.adjunto?.url}
                                    >
                                        Descargar
                                    </Button>
                                </Flex>
                            </Flex>

                            <Text
                                color="#00152D"
                                fontSize="13px"
                                fontWeight="400"
                                letterSpacing="-0.12px"
                                px="10px"
                            >
                                {entregable?.texto}
                            </Text>
                        </Flex>
                    </Flex>

                    <Box borderBottom="2px dashed #AFB8CF" mt="35px" mb="25px" />

                    <Box
                        mb="30px"
                    >

                        <Formik
                            onSubmit={(values, { resetForm }) => {
                                submitForm(values);
                                resetForm();
                            }}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        >
                            {(formik) => {
                                const { handleSubmit } = formik;

                                return (
                                    <FormikForm onSubmit={handleSubmit}>
                                        <Flex
                                            gap="10px"
                                            w="100%"
                                            mt="10px"
                                            direction="column"
                                        >
                                            <Flex
                                                w="50%"
                                                gap="20px"
                                                alignItems="center"
                                            >
                                                <Flex
                                                    flex="1"
                                                    direction="column"
                                                    gap="10px"
                                                >
                                                    <Text
                                                        color="dark_blue"
                                                        fontSize="16px"
                                                        fontWeight="500"
                                                    >
                                                        Puntuacion:
                                                    </Text>

                                                    <FormInput
                                                        name='puntuacion'
                                                        placeholder='--'
                                                    />
                                                </Flex>

                                                <Flex
                                                    mt="15px"
                                                    gap="15px"
                                                >
                                                    {[25, 50, 75, 100]?.map((option: any, i: number) => (
                                                        <Text
                                                            key={i}
                                                            border="1px solid #AFB8CF"
                                                            rounded="5px"
                                                            p="5px 10px"
                                                            color="#00152D"
                                                            fontSize="13px"
                                                            fontWeight="600"
                                                            cursor="pointer"
                                                            _hover={{ color: "main", borderColor: "main" }}
                                                            onClick={() => formik.setFieldValue("puntuacion", option)}
                                                        >
                                                            {option}
                                                        </Text>
                                                    ))}
                                                </Flex>
                                            </Flex>


                                            <Flex gap="20px" direction="column">
                                                <Text
                                                    color="dark_blue"
                                                    fontSize="16px"
                                                    fontWeight="500"
                                                >
                                                    Archivo:
                                                </Text>

                                                <Flex
                                                    w="100%"
                                                    gap="15px"
                                                    transform={translate}
                                                    transition="all 1s ease"
                                                >
                                                    <Flex
                                                        w="50%"
                                                        opacity={opacity}
                                                        transition="all 1s ease"
                                                    >
                                                        <FileLoad
                                                            files={files}
                                                            isUploading={isUploading}
                                                        />
                                                    </Flex>

                                                    <Flex w="50%">
                                                        <FileUploader
                                                            files={files}
                                                            maxFiles={1}
                                                            maxSize="50MB"
                                                            isUploading={isUploading}
                                                            supportedFormats={['.zip', '.rar']}
                                                            onDeleteFile={handleOnDeleteFile}
                                                            onLoadedFiles={(files) => handleOnLoadedFiles(files)}
                                                        />
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>

                                        <Flex
                                            direction="column"
                                            mt="20px"
                                            gap="10px"
                                        >
                                            <Text
                                                color="dark_blue"
                                                fontSize="16px"
                                                fontWeight="500"
                                            >
                                                Comentarios:
                                            </Text>

                                            <CustomQuill
                                                value={comentario}
                                                setValue={setComentario}
                                                editorStyle={{
                                                    minHeight: '200px', 
                                                    backgroundColor: "#fff",
                                                }}
                                            />

                                            {/* <Editor
                                                value={comentario}
                                                onTextChange={(e: any) => setComentario(e.htmlValue)}
                                                style={{
                                                    height: '200px', backgroundColor: "#fff",
                                                }}
                                            /> */}
                                        </Flex>

                                        {filterByMatricula(entregables, entregable?.matriculaId)?.length > 0 &&
                                            <Accordion
                                                allowToggle
                                                mt="30px"
                                            >
                                                <AccordionItem
                                                    border="none"
                                                >
                                                    <AccordionButton
                                                        bg="grey"
                                                        borderRadius="10px"
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        p="15px 20px"
                                                    >
                                                        <Text
                                                            color="font"
                                                            fontSize="14px"
                                                            fontWeight="600"
                                                        >
                                                            Otros ejercicios enviados
                                                        </Text>
                                                        <AccordionIcon boxSize="25px" color="dark_grey" />
                                                    </AccordionButton>

                                                    <AccordionPanel
                                                        display="flex"
                                                        borderRadius="0 0 12px 12px"
                                                    >
                                                        <Flex
                                                            direction="column"
                                                            w="100%"
                                                            gap="20px"
                                                        >
                                                            {filterByMatricula(entregables, entregable?.matriculaId)?.map((entregable: EntregablesInt, index: number) => (
                                                                <Flex
                                                                    key={index}
                                                                    border="1px solid"
                                                                    borderColor="border"
                                                                    p="15px"
                                                                    rounded="10px"
                                                                    direction="column"
                                                                    w="100%"
                                                                    gap="30px"
                                                                >
                                                                    <Flex
                                                                        direction="column"
                                                                        gap="5px"
                                                                    >
                                                                        <Text
                                                                            color="font"
                                                                            fontSize="14px"
                                                                            fontWeight="500"
                                                                        >
                                                                            <Text as="strong" mr="5px">
                                                                                Fecha de entrega:
                                                                            </Text>
                                                                            {formatDate(entregable?.createdAt, true)}
                                                                        </Text>

                                                                        <Flex
                                                                            direction="column"
                                                                            gap="5px"
                                                                        >
                                                                            <Text as="strong" mr="5px">Entrega: </Text>
                                                                            <Flex w="50%" p="5px 10px" bg="purewhite" borderRadius="5px" alignItems="center" gap="10px">
                                                                                <Icon color="black" boxSize="18px" as={BiFile} />

                                                                                <Flex justifyContent="space-between" w="100%" alignItems="center">
                                                                                    <Text
                                                                                        color="#00152D"
                                                                                        fontSize="12px"
                                                                                        fontWeight="500"
                                                                                    >
                                                                                        {truncate(entregable?.adjunto?.name, { length: 25 })}
                                                                                    </Text>

                                                                                    <Button
                                                                                        letterSpacing="-0.1px"
                                                                                        bg="inherit"
                                                                                        border="1px solid"
                                                                                        borderColor="font"
                                                                                        h="fit-content"
                                                                                        p="3px 7px"
                                                                                        onClick={() => null}
                                                                                    >
                                                                                        <Icon as={BiDownload} boxSize="18px" />
                                                                                    </Button>
                                                                                </Flex>
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Flex>

                                                                    {entregable?.correccion ?
                                                                        <Flex
                                                                            direction="column"
                                                                            gap="10px"
                                                                        >
                                                                            <Text
                                                                                color="font"
                                                                                fontSize="14px"
                                                                                fontWeight="500"
                                                                            >
                                                                                <Text as="strong" mr="5px">
                                                                                    Fecha de correccion:
                                                                                </Text>
                                                                                {formatDate(entregable?.correccion?.createdAt, true)}
                                                                            </Text>

                                                                            <Flex
                                                                                direction="column"
                                                                                gap="5px"
                                                                            >
                                                                                <Text
                                                                                    as="strong"
                                                                                >
                                                                                    Comentario:
                                                                                </Text>

                                                                                <Box>
                                                                                    <MarkdownPreview
                                                                                        source={entregable?.correccion?.texto || 'No se hizo comentario'}
                                                                                        style={{
                                                                                            color: "#252F40",
                                                                                            width: '100%',
                                                                                            fontSize: "14px",
                                                                                            fontWeight: "400",
                                                                                            fontFamily: "Inter",
                                                                                            backgroundColor: "#fff",
                                                                                            
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Flex>

                                                                            <Flex
                                                                                gap="10px"
                                                                                alignItems="center"
                                                                            >
                                                                                <Text
                                                                                    as="strong"
                                                                                >
                                                                                    Puntuacion:
                                                                                </Text>

                                                                                <Text
                                                                                    color={entregable?.correccion?.puntuacion < 75 ? "fail" : "success"}
                                                                                    border="1px solid"
                                                                                    borderColor={entregable?.correccion?.puntuacion < 75 ? "fail" : "success"}
                                                                                    rounded="5px"
                                                                                    p="5px 10px"
                                                                                    fontWeight="600"
                                                                                    fontSize="13px"
                                                                                >
                                                                                    {entregable?.correccion?.puntuacion} / 100
                                                                                </Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                        :
                                                                        <Text
                                                                            as="strong"
                                                                        >
                                                                            No hay correccion de la entrega
                                                                        </Text>
                                                                    }
                                                                </Flex>
                                                            ))}
                                                        </Flex>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        }

                                        <Flex
                                            position="absolute"
                                            top="25px"
                                            right="25px"
                                        >

                                            <Button
                                                color="purewhite"
                                                fontSize="14px"
                                                fontWeight="500"
                                                bg="font"
                                                p="10px 20px"
                                                type="submit"
                                                isLoading={isLoading}
                                            >
                                                Enviar corrección
                                            </Button>
                                        </Flex>
                                    </FormikForm>
                                )
                            }}
                        </Formik>
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer >
    );
}