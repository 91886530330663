import { Flex, Skeleton } from "@chakra-ui/react"

export const SkeletonAprendizajeMatriculas = () => {
    return (
        <Flex h="100vh" w="100%" gap="40px" p="80px 50px">
            <Flex direction="column" w="75%" gap="20px">

                <Skeleton height="750px" borderRadius="8px" />

            </Flex>

            <Flex direction="column" w="75%" gap="20px">

                <Skeleton height="750px" borderRadius="8px" />

            </Flex>

            <Flex direction="column" w="45%" gap="40px">

                <Skeleton height="25%" borderRadius="8px" />

                <Skeleton height="25%" borderRadius="8px" />

                <Skeleton height="50%" borderRadius="8px" />

                <Skeleton height="25%" borderRadius="8px" />

            </Flex>
        </Flex>
    )
}