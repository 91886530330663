import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useToast, Icon } from '@chakra-ui/react';
import { useAuthContex } from '../../../shared/context/user.context';
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '../../../shared/components/FormElements/FormInput';
import { FormTextEditor } from '../../../shared/components/FormElements/FormTextEditor';
import { useEffect, useState } from 'react';
import { FormSelect } from '../../../shared/components/FormElements/FormSelect';
import { TipoPreguntaForo } from '../../../shared/utils/Types/PreguntasTypes';
import { TemaForoInt } from '../../../interfaces/ForoInt';
import { AxiosResponse } from 'axios';
import { addForoPregunta, getForoTemasByGrupo } from '../../../shared/middlewares/foro.middlewate';
import { StatusEnum, notify } from '../../../shared/utils/functions/notify';
import { GrupoInt, MatriculasInt } from '../../../interfaces/MatriculasInt';
import { CustomSelect } from '../../../shared/components/Inputs/CustomSelect';
import { BsXLg } from 'react-icons/bs';
import { EstadoTypeEnum } from '../../../shared/utils/Types/EstadoTypeEnum';

interface Props {
    matriculas: MatriculasInt[];
    temas: TemaForoInt[];
    isOpen: boolean;
    onClose: () => void;
    selectedGrupo: GrupoInt | undefined;
    Refresh: () => void;
}

export const NuevaPreguntaModal = ({ temas, isOpen, onClose, matriculas, selectedGrupo, Refresh }: Props) => {
    const params = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const { user } = useAuthContex();
    const [optTemas, setOptTemas] = useState<{ value: string, label: string }[]>([])
    const [optGrupos, setOptGrupos] = useState<{ value: string, label: string }[]>([])
    const [grupo, setGrupo] = useState<{ value: string, label: string }>()
    const [temaSelect, setTemaSelect] = useState<TemaForoInt>()

    useEffect(() => {
        const temaSelect = temas?.find((tema: TemaForoInt) => tema?.id == params["*"]);

        setTemaSelect(temaSelect)
    }, [params, isOpen])

    useEffect(() => {
        temaSelect
            ? setGrupo({ value: temaSelect?.grupoId, label: temaSelect?.grupo?.curso?.nombre + " " + "(" + temaSelect?.grupo?.nombre + ")" || "" })
            : (selectedGrupo) && (selectedGrupo?.id && selectedGrupo?.nombre)
                ? setGrupo({ value: selectedGrupo?.id, label: selectedGrupo?.curso?.nombre + " " + "(" + selectedGrupo?.nombre + ")" })
                : setGrupo(undefined)
    }, [temaSelect, selectedGrupo, isOpen])

    const initialValues = {
        temaId: temaSelect ? temaSelect?.id : null,
        tipo: null,
        titulo: null,
        userId: user?.id,
        fijado: false,
        contenido: null,
    };

    const validationSchema = Yup.object({
        temaId: Yup.string()
            .required('Debe seleccionar un tema')
            .typeError('Debe seleccionar un tema'),
        tipo: Yup.string()
            .required('Debe seleccionar un tipo')
            .typeError('Debe seleccionar un tipo'),
        titulo: Yup.string()
            .required('El titulo es obligatorio')
            .typeError('El titulo es obligatorio'),
        contenido: Yup.string()
            .required('El contenido es obligatorio')
            .typeError('El contenido es obligatorio'),
    });

    const handleSubmit = (values: any) => {

        if (!user?.id) return;

        const newPregunta = {
            temaId: values.temaId,
            tipo: values.tipo,
            titulo: values.titulo,
            contenido: values.contenido,
            userId: values.userId,
            fijado: values.fijado,
        }

        addForoPregunta(newPregunta)
            .then((response: any) => {
                navigate(`/foro`);
                notify(toast, StatusEnum.success, `Pregunta publicada correctamente`)
            })
            .catch(() => notify(toast, StatusEnum.error, 'Error al publicar la pregunta. Por favor, contacte con soporte.'))
            .finally(() => {
                Refresh()
                handleClose();
            })
    };

    useEffect(() => {
        if (!matriculas) return
        const newOptions: { value: string, label: string }[] = []

        matriculas.map((matricula: MatriculasInt) => {
            if (!matricula?.grupoId || !matricula?.grupo?.nombre || matricula?.estado === EstadoTypeEnum.INACTIVO) return
            return newOptions.push({
                value: matricula.grupoId,
                label: matricula?.grupo?.curso?.nombre + " " + "(" + matricula?.grupo?.nombre + ")"
            })
        })

        setOptGrupos(newOptions)
    }, [matriculas, isOpen])

    useEffect(() => {
        if (!grupo?.value) return
        const newOptions: { value: string, label: string }[] = []

        getForoTemasByGrupo(grupo?.value)
            .then((response: AxiosResponse) => {
                const temas = response?.data?.data?.data;

                temas.map((tema: TemaForoInt) => {
                    if (!tema.id) return
                    return newOptions.push({ value: tema?.id, label: tema?.titulo })
                })

                setOptTemas(newOptions)
            })

    }, [grupo, isOpen])

    const handleClose = () => {
        onClose();
        setGrupo(undefined)
        setTemaSelect(undefined)
        setOptGrupos([])
        setOptTemas([])
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            closeOnOverlayClick={false}
            size="4xl"
            motionPreset='slideInBottom'
            isCentered
            
        >
            <ModalOverlay />
            <ModalContent
                bg="purewhite"
                borderRadius="15px"
            >
                
                <ModalHeader
                    textColor="secondary"
                    fontSize="21px"
                    fontWeight="600"
                    lineHeight="25.41px"
                    textAlign="start"
                    py="30px"
                    px="34px"
                    borderBottomWidth="1px"
                    borderColor="#EAEAEA"
                >
                    Nuevo post 
                </ModalHeader>

        
                <Flex
                    gap="40px"
                    justifyContent="center"
                    w="100%"
                    px="12px" 
                    py="12px"               >
                    <Flex
                        direction="column"
                        bg="purewhite"
                        rounded="5px"
                        w="100%"
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;
                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody>
                                            <Flex
                                                gap="15px"
                                                direction="column"
                                            >

                                                <Flex
                                                    gap="20px"
                                                >
                                                    <Flex 
                                                    flex="1">
                                                        <CustomSelect
                                                            placeholder='Selecciona grupo'
                                                            options={optGrupos}
                                                            label='Grupo'
                                                            setValue={(e: any) => {
                                                                setGrupo(e);
                                                            }}
                                                            value={grupo}
                                                        />
                                                    </Flex>

                                                    <Flex flex="1">
                                                        <FormSelect
                                                            name='temaId'
                                                            label='Categoría'
                                                            placeholder='Selecciona tema'
                                                            defaultValue={temaSelect ? { value: temaSelect?.id, label: temaSelect?.titulo } : undefined}
                                                            options={optTemas}
                                                            isDisabled={!grupo?.value}
                                                        />
                                                    </Flex>
                                                </Flex>

                                                <FormSelect
                                                    name='tipo'
                                                    label='Tipo de pregunta'
                                                    placeholder="Selecciona el tipo de pregunta"
                                                    options={[
                                                        { value: TipoPreguntaForo.ANUNCIO, label: "Anuncio" },
                                                        { value: TipoPreguntaForo.AYUDA, label: "Ayuda" },
                                                        { value: TipoPreguntaForo.NOTIFICACION_ERROR, label: "Error" },
                                                        { value: TipoPreguntaForo.PROYECTO, label: "Proyecto" },
                                                        { value: TipoPreguntaForo.SUGERENCIA, label: "Sugerencia" },
                                                    ]}
                                                />

                                                <FormInput
                                                    label='Titulo'
                                                    placeholder='Escribe un título'
                                                    name='titulo'
                                                    style={{ marginBottom: "-30px" }}
                                                />


                                                <FormTextEditor
                                                    name="contenido"
                                                    label="Contenido"
                                                    onChange={formik.setFieldValue}
                                                    height="auto"
                                                />

                                                <Flex
                                                    justifyContent="end"
                                                    gap="20px"
                                                >
                                                    <Button
                                                        bg="purewhite"
                                                        color="secondary"
                                                        border="1px solid"
                                                        borderColor="secondary"
                                                        _disabled={{ bg: "secondary_variant", cursor: "not-allowed" }}
                                                        fontSize="16px"
                                                        fontWeight="400"
                                                        letterSpacing="0.24px"
                                                        p="12px 20px"
                                                        rounded="5px"
                                                        _hover={{ bg: "secondary_variant" }}
                                                        onClick={handleClose}
                                                    >
                                                        Cancelar
                                                    </Button>

                                                    <Button
                                                        type='submit'
                                                        bg="secondary"
                                                        color="purewhite"
                                                        _disabled={{ bg: "secondary_variant", cursor: "not-allowed" }}
                                                        fontSize="16px"
                                                        fontWeight="400"
                                                        letterSpacing="0.24px"
                                                        p="12px 20px"
                                                        rounded="5px"
                                                        isDisabled={!values.titulo || !values.contenido}
                                                        _hover={{ bg: "secondary_variant" }}
                                                    >
                                                        Publicar post
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </ModalBody>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    );
};