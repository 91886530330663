import { useEffect, useState } from "react";
import { ProgressContext } from "../context/progress.context";
import { useProgresos } from "../middlewares/progress.middleware";
import { isRoleAllowed } from "../utils/functions/validateRol";
import { UserRolEnum } from "../utils/Types/RolEnum";
import { useAuthContex } from "../context/user.context";

export const ProgressController = ({ children, ...props }: any) => {
  const { user } = useAuthContex();

  const [cursoProgress, setCursoProgress] = useState<any>({
    matriculaId: null,
    cursoId: null,
    cursoNombre: null,
    porcentajeCurso: null,
    totalLecciones: null,
    data: null,
  })

  const { progreso, Refresh } = useProgresos({
    cursoId: cursoProgress?.cursoId,
    matriculaId: cursoProgress?.matriculaId
  })

  const refreshProgress = () => Refresh();

  useEffect(() => {

    if (isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre))
      if (progreso)
        setCursoProgress((prev: any) => ({
          ...prev,
          porcentajeCurso: (progreso[0]?.leccionesCompletadas / prev?.totalLecciones) * 100,
          data: progreso[0]?.progresoLecciones,
        }))

  }, [progreso])

  return (
    <ProgressContext.Provider value={{ cursoProgress, setCursoProgress, refreshProgress }} >
      {children}
    </ProgressContext.Provider>
  );
}