import { Badge, Box, Flex, Text, Tooltip, useToast } from "@chakra-ui/react";
import { ColumnProps, CustomTable } from "../../../shared/components/CustomTable/CustomTable";
import { FiltersTable } from "../../../shared/components/TableElements/FiltersTable";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useAsignaciones } from "../../../shared/middlewares/facturacion.middlewares";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../shared/components/Customs/CardSinInformacion";
import { useAuthContex } from "../../../shared/context/user.context";
import { useNavigate } from "react-router-dom";
import { handleUnauthorized } from "../../../shared/utils/functions/handleErrors";


export const AsignacionesTable = () => {
    const { logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const [page, setPage] = useState<number>(1)
    const [queryTable, setQueryTable] = useState<string>(`?page=${page}`);
    const [query, setQuery] = useState<string>("");
    const { asignaciones, loading, error, status, errorsList } = useAsignaciones(queryTable + query)
    const [statusData, setStatusData] = useState<"loading" | "idle" | "success">("loading")

    useEffect(() => {
        if (loading) setStatusData("loading")
        if (!loading && asignaciones?.data && asignaciones?.data?.length > 0) setStatusData("success")
        if (!loading && asignaciones?.data && asignaciones?.data?.length === 0) setStatusData("idle")
    }, [asignaciones, loading])

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])



    const columns: ColumnProps[] = [
        {
            header: 'Creación',
            field: 'createdAt',
            key: 'createdAt',
            sortable: true,
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                    gap="5px"
                    color="dark_blue"
                    fontSize="14px"
                    fontWeight="400"
                >
                    {rowData?.createdAt && <Text>{format(new Date(rowData?.createdAt), 'dd/MM/yyy HH:mm')}</Text>}
                </Flex>
            )
        },
        {
            header: 'alumno',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <Text
                    color="dark_blue"
                    fontSize="14px"
                    fontWeight="500"
                >
                    {rowData?.matricula?.user?.nombre} {rowData?.matricula?.user?.apellidos}
                </Text>
            )
        },
        {
            header: 'Grupo',
            field: 'estado',
            key: 'estado',
            sortable: true,
            body: (rowData: any) => (
                <Flex direction="column" alignItems="flex-start">
                    <Badge
                        color={
                            rowData?.matricula?.estado == "2" ? "dark_blue" :
                                rowData?.matricula?.estado == "1" ? "purewhite"
                                    : "#FFF"
                        }
                        bg={
                            rowData?.matricula?.estado == "2" ? "#E6E6EA" :
                                rowData?.matricula?.estado == "1" ? "black"
                                    : "main"
                        }
                        fontSize="11px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="2px 5px"
                    >
                        {
                            rowData?.matricula?.estado == "2" ? "Inactivo" :
                                rowData?.matricula?.estado == "1" ? "Próximo"
                                    : "Activo"
                        }
                    </Badge>

                    {(rowData?.matricula?.fechaInicio && rowData?.matricula?.fechaFin) &&
                        <Flex
                            alignItems="center"
                            gap="5px"
                            color="dark_blue"
                            fontSize="13px"
                            fontWeight="400"
                        >
                            <Text>{format(new Date(rowData?.matricula?.fechaInicio), 'dd/MM/yyy')}</Text>
                            <Text mb="2px">-</Text>
                            <Text>{format(new Date(rowData?.matricula?.fechaFin), 'dd/MM/yyy')}</Text>
                        </Flex>
                    }
                </Flex>
            )
        },
        {
            header: 'curso',
            field: 'curso',
            key: 'curso',
            sortable: true,
            body: (rowData: any) => (
                <Flex direction="column">
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {rowData?.matricula?.grupo?.nombre}
                    </Text>
                    <Text
                        color="#7A7D81"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {rowData?.matricula?.grupo?.curso?.nombre}
                    </Text>
                </Flex>
            )
        },
        {
            header: 'Empresa Factura',
            field: 'formacion',
            key: 'formacion',
            sortable: true,
            body: (rowData: any) => (
                <Flex>
                    <Badge
                        color="purewhite"
                        bg={rowData?.empresaFactura?.nombre === "Procademy" ? "secondary" : "#081338"}
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="5px 10px"
                    >
                        {rowData?.empresaFactura?.nombre}
                    </Badge>
                </Flex>
            )
        },
        {
            header: 'Pagado',
            field: 'liquidada',
            key: 'liquidada',
            sortable: true,
            body: (rowData: any) => (
                <Flex>
                    <Badge
                        color="purewhite"
                        bg={rowData?.liquidada ? "success" : "fail"}
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="5px 10px"
                    >
                        {rowData?.liquidada ? "Si" : "No"}
                    </Badge>
                </Flex>
            )
        },
        {
            header: 'Importe',
            field: 'importe',
            key: 'importe',
            sortable: true,
            body: (rowData: any) => (
                <Flex>
                    {rowData?.importe}€
                </Flex>
            )
        }
    ];

    if (statusData === "idle") return <Flex p="10px 50px"><CardSinInformacion type={TypeCardSinInformacion.MATRICULAS} /></Flex>

    return (
        <Flex
            direction="column"
            p="10px 50px"
            gap="30px"
        >
            <FiltersTable
                query={query}
                setQuery={setQuery}
                filterElements={[
                    {
                        label: "Estado",
                        type: "estadoGrupo",
                    },
                    {
                        label: "Fechas",
                        type: "fecha"
                    },
                    {
                        label: "Pagado",
                        type: "pagado"
                    },
                    {
                        label: "Formación",
                        type: "formacion"
                    }
                ]}
            />

            <CustomTable
                data={asignaciones?.data || []}
                columns={columns}
                isLoading={loading}
                currentPage={page}
                onPageChange={setPage}
                onQueryChange={setQueryTable}
                total={asignaciones?.meta?.total || 0}
                height="calc(100vh - 380px)"
                defaultSortBy="createdAt"
                defaultOrder={1}
                skeletonHeight={40}
            />
        </Flex>
    )
};