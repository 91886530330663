import { useState, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import "./CustomTable.scss"

export interface ColumnProps {
    key: string;
    field: string;
    header: string;
    sortable?: boolean;
    options?: { label: string; value: any }[];
    body?: (data: any) => JSX.Element | undefined;
    loadOptions?: (search: string) => Promise<{ label: string; value: string }[] | undefined>;
    style?: React.CSSProperties;
}

interface Props {
    columns: ColumnProps[];
    data?: any[];
    total?: number;
    isLoading?: boolean;
    onRowClick?: (row: any) => void;
    onPageChange?: (page: number) => void;
    onQueryChange?: (string: string) => void;
    currentPage?: number;
    tableWidth?: string;
    emptyMessage?: string;
    height?: string;
    skeletonHeight?: number;
    defaultSortBy?: string;
    defaultOrder?: 0 | 1 | -1 | null | undefined;
    totalMatriculas?: number;
}

export const CustomTable = ({
    data,
    total,
    columns,
    isLoading = true,
    onRowClick = (e?: any) => { },
    onPageChange = (e?: any) => { },
    onQueryChange = (e?: any) => { },
    currentPage = 1,
    tableWidth = "100%",
    emptyMessage = "No hay datos para mostrar",
    height = "calc(100vh - 220px)",
    defaultSortBy = "id",
    defaultOrder = 0,
    skeletonHeight = 35
}: Props) => {
    const [sortBy, setSortBy] = useState<string | undefined>(defaultSortBy);
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>(defaultOrder);
    const [limit, setLimit] = useState<10 | 15 | 20 | 25>(10);


    useEffect(() => {
        onQueryChange && onQueryChange(`?page=${currentPage}&sortBy=${sortBy || defaultSortBy}&order=${sortOrder === -1 ? "desc" : "asc"}`);

    }, [currentPage, sortBy, sortOrder]);

    const bodyTemplate = () => {
        return <Skeleton height={`${skeletonHeight}px`}></Skeleton>
    }

    const getColumns = () => {
        return columns.map((c: ColumnProps) => (
            <Column
                {...c}
                body={
                    isLoading
                        ? bodyTemplate
                        : c.body
                }
            />
        ))
    }

    const getSkeleton = () => {
        return [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map((c: any, index: number) => (
            <Column body={bodyTemplate} key={index} />
        ))
    }

    const onPage = (event: any) => {
        onPageChange(event?.page + 1)
        onRow(event)
    };

    const onSort = (event: any) => {
        setSortBy(event?.sortField)
        setSortOrder(event?.sortOrder)
    };

    const onRow = (event: any) => {
        setLimit(event?.rows)
        let rows: string;

        if (sortBy?.includes(`limit=10`))
            rows = sortBy?.replace("10", event?.rows)
        else if (sortBy?.includes(`limit=15`))
            rows = sortBy?.replace("15", event?.rows)
        else if (sortBy?.includes(`limit=20`))
            rows = sortBy?.replace("20", event?.rows)
        else if (sortBy?.includes(`limit=25`))
            rows = sortBy?.replace("25", event?.rows)
        else
            rows = sortBy + `&limit=${event?.rows}`

        setSortBy(rows)
    }

    return (
        <Flex
            className='CustomTable'
            w={tableWidth}
            bg="#FFF"
            direction="column"
            justify="space-between"
            border="1px solid #E2E8F0"
            rounded="12px"
            overflow="hidden"
            pos="relative"
            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
        >
            {!isLoading ?
                <DataTable
                    emptyMessage={emptyMessage}
                    lazy
                    first={Number(`${currentPage}0`) - 10}
                    rows={limit}
                    paginator
                    paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    onRowClick={onRowClick}
                    totalRecords={total}
                    value={data}
                    tableStyle={{ position: "relative" }}
                    onPage={onPage}
                    onSort={onSort}
                    sortField={sortBy}
                    sortOrder={sortOrder}
                >
                    {getColumns()}
                </DataTable>
                :
                <DataTable
                    emptyMessage={emptyMessage}
                    lazy
                    first={Number(`${currentPage}0`) - 10}
                    rows={10}
                    paginator
                    rowsPerPageOptions={[10, 15, 20, 25]}
                    paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    totalRecords={total}
                    value={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                    tableStyle={{ position: "relative" }}
                >
                    {getSkeleton()}
                </DataTable>
            }

            {!isLoading && !!total &&
                <Text position="absolute" left="20px" bottom="25px" fontSize="13px" color="#dark_blue" textTransform="uppercase">
                    <Text as="strong">{total}</Text> resultados encontrados
                </Text>
            }
        </Flex>
    )
}