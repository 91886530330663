import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Heading, Icon, Text, Tooltip, useToast } from "@chakra-ui/react";
import { LeccionInt } from "../../../interfaces/CoursesInt"
import MarkdownPreview from '@uiw/react-markdown-preview';
import { BiUpload } from "react-icons/bi";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../shared/utils/Types/RolEnum";
import { useAuthContex } from "../../../shared/context/user.context";
import { EntregableHeader } from "./EntregableHeader";
import { useOutWindowContext } from "../../../shared/context/outWindow.context";
import { StatusEnum, notify } from "../../../shared/utils/functions/notify";

interface Props {
    leccion: LeccionInt | null;
    estado: any;
    entregable: any
    endPrev: boolean;
    onOpen: () => void;
}

export const EnunciadoItem = ({ leccion, endPrev, onOpen, estado, entregable }: Props) => {
    const { user } = useAuthContex();
    const toast = useToast();
    const { setTimeModal } = useOutWindowContext();

    return (
        <Flex
            direction="column"
        >
            <Flex
                fontSize="15px"
                direction="column"
                gap="30px"
            >

                {leccion?.descripcion &&
                    <Flex
                        w="100%"
                    >
                        <iframe width="100%" height="600" allowFullScreen
                            src={leccion?.descripcion}
                        />
                    </Flex>
                }

                <EntregableHeader
                    estado={estado}
                    leccion={leccion}
                    entregable={entregable}
                />

                <Accordion
                    defaultIndex={[0]}
                    allowToggle
                >
                    <AccordionItem
                        border="none"
                    >
                        <AccordionButton
                            bg="purewhite"
                            borderRadius="10px"
                            display="flex"
                            justifyContent="space-between"
                            p="20px"
                            _expanded={{ borderRadius: "12px 12px 0 0" }}
                        >
                            <Text
                                color="dark_blue"
                                fontSize="20px"
                                fontWeight="600"
                                lineHeight="140%"
                            >
                                Instrucciones de entrega
                            </Text>
                            <AccordionIcon boxSize="32px" color="dark_grey" />
                        </AccordionButton>

                        <AccordionPanel
                            bg="purewhite"
                            display="flex"
                            borderRadius="0 0 12px 12px"
                        >
                            <MarkdownPreview
                                source={leccion?.contenido || ''}
                                style={{
                                    color: "#252F40",
                                    padding: "10px",
                                    fontSize: '15px',
                                    width: '100%',
                                    maxWidth: '100%',
                                    background: "#FFF"
                                }}
                            />
                        </AccordionPanel>
                    </AccordionItem>

                </Accordion>
            </Flex>

            {isRoleAllowed([UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <Flex
                    bg="purewhite"
                    rounded="5px"
                    gap="50px"
                    mt="30px"
                >

                    <Flex
                        direction="column"
                        p="40px"
                    >
                        <Heading
                            color="font"
                            fontSize="20px"
                            fontWeight="600"
                            mb="30px"
                        >
                            Entrega del ejercicio
                        </Heading>

                        <Text
                            color="#6E727E"
                            fontSize="16px"
                            fontWeight="500"
                            letterSpacing="-0.16px"
                            mb="20px"
                            w="80%"
                        >
                            Cuando comiences el ejercicio se te mostrarán las especificaciones para la realización y entrega del mismo.
                            Debe adjuntar un archivo para responder al enunciado propuesto.
                            La entrega del ejercicio deberá realizarse a través de una carpeta .zip empaquetada.
                        </Text>

                        <Flex
                            gap="20px"
                            mt="20px"
                            justifyContent="flex-end"
                        >
                            <Tooltip
                                rounded="5px"
                                padding="10px"
                                label={
                                    <Text
                                        textAlign="center"
                                        fontSize="16px"

                                    >
                                        Si vas a realizar ejercicios fuera de la plataforma pulse aquí para aumentar tu control de inactividad de 10 a 30 minutos. ¡Atención, si actuliza la plataforma el tiempo se restablecerá!
                                    </Text>
                                }
                                hasArrow
                                placement="bottom"
                            >
                                <Button
                                    p="10px"
                                    bg="secondary"
                                    _disabled={{ bg: "main", cursor: "not-allowed" }}
                                    color="purewhite"
                                    borderRadius="5px"
                                    fontSize="18px"
                                    fontWeight="500"
                                    onClick={() => {
                                        setTimeModal(1800000)
                                        notify(toast, StatusEnum.success, "El tiempo de inactividad se ha aumentado a 30 minutos")
                                    }}
                                >
                                    Aumentar inactividad
                                </Button>
                            </Tooltip>

                            <Tooltip
                                rounded="5px"
                                padding="10px"
                                label={
                                    <Text
                                        textAlign="center"
                                        fontSize="16px"
                                    >
                                        {!endPrev
                                            ? "Debes finalizar el ejercicio anterior para poder subir uno nuevo"
                                            : "Cuando realices la entrega del ejercicio tu control de inactividad se restablecerá a 10 minutos."
                                        }
                                    </Text>
                                }
                                hasArrow
                                placement="bottom"
                            >
                                <Button
                                    p="10px"
                                    bg="secondary"
                                    _disabled={{ bg: "main", cursor: "not-allowed" }}
                                    color="purewhite"
                                    borderRadius="5px"
                                    fontSize="18px"
                                    fontWeight="500"
                                    rightIcon={
                                        <Icon as={BiUpload} boxSize="22px" mb="1px" />
                                    }
                                    isDisabled={!endPrev}
                                    onClick={() => {
                                        setTimeModal(600000)
                                        onOpen();
                                    }}
                                >
                                    Subir ejercicio
                                </Button>
                            </Tooltip>
                        </Flex>
                    </Flex>
                </Flex>
            }
        </Flex>
    )
};