import _axios, { TRACK_HEADERS } from "../services/http.service";
import useSWR from "swr";
import tenantInstance from "../services/tenantInstance.service";

const { tenant, client } = await tenantInstance();

const ENDPOINT_LECCIONES = "lecciones"

// Middlewares Especificos sin SWR => LECCIONES
export const getLecciones = async (query = "") => {
    
    return await _axios.get(`${tenant}/${client}/${ENDPOINT_LECCIONES}${query}`).then((response: any) => response)
}

// Middlewares y Hooks generales y SWR => LECCIONES
export const getlessonBySlug = async (
    endpoint: string, 
    track: { 
        track: boolean, 
        matricula: string | undefined 
    } = {
            track: false,
            matricula: undefined
        }
) => 
await _axios.get(
    endpoint, 
    (track?.track && track?.matricula !== undefined) 
    ? { headers: { "track": true, "matricula": track?.matricula }}  
    : {}
)
.then((response: any) => response);

export const useLeccionSlug = ({ 
    slug, 
    ignoreRequest = false,
    track = {track: false, matricula: undefined},
} : { 
    slug: string | undefined;
    ignoreRequest?: boolean;
    track?: { track: boolean, matricula: string | undefined };
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_LECCIONES + `/${slug}`
    const { isLoading, data, mutate } = useSWR((slug && !ignoreRequest) ? [endpoint, track] : null, ([url, track]) => getlessonBySlug(url, track), {
        refreshInterval: 600000 // 10 minutos - se usan 10 minutos para que no revalide cuando sale inactividad antes de que se cierre la sesión automaticamente
    });
        
    return {
        leccion: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}