import { Flex, useDisclosure } from "@chakra-ui/react";
import { EnunciadoItem } from "../Components/EnunciadoItem";
import { CorreccionItem } from "../Components/CorreccionItem";
import { LeccionInt } from "../../../interfaces/CoursesInt";
import { EntregableEstadoEnum, ModeEnumEntregable } from "../../../shared/utils/Types/EntregableEstadoEnum";
import { useEffect, useState } from "react";
import { uploadEntregables, useEntregablesLeccion } from "../../../shared/middlewares/entregables.middleware";
import { EntregablesInt } from "../../../interfaces/Entregables";
import { useProgressContex } from "../../../shared/context/progress.context";
import { EntregarTareaModal } from "../Components/EntregarTareaModal";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";

interface Props {
  leccion: LeccionInt | null;
  setEstadoEntregable: (estado: EntregableEstadoEnum) => void;
  endPrev: boolean;
  matriculaActual: MatriculasInt | undefined;
}

export const EntregableLesson = ({ leccion, setEstadoEntregable, endPrev, matriculaActual }: Props) => {
  const { cursoProgress } = useProgressContex();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { entregables } = useEntregablesLeccion({
    leccionId: leccion?.id,
    matriculaId: matriculaActual?.id || ""
  })
  const [entregable, setEntregable] = useState<EntregablesInt | null>(null)
  const [mode, setMode] = useState<ModeEnumEntregable>(ModeEnumEntregable.ENUNCIADO);
  const [estado, setEstado] = useState<EntregableEstadoEnum>(entregable?.estado || EntregableEstadoEnum.PENDIENTE_ENTREGA);

  useEffect(() => {
    // Establecemos el estado a su estado su original.
    setEntregable(null)
    setMode(ModeEnumEntregable.ENUNCIADO);
    setEstado(EntregableEstadoEnum.PENDIENTE_ENTREGA);
    setEstadoEntregable(EntregableEstadoEnum.PENDIENTE_ENTREGA)

    if (leccion?.tipo === "entregable")
      if (entregables?.length > 0) {
        const _estado = entregables[0]?.estado || EntregableEstadoEnum.PENDIENTE_ENTREGA;

        setEntregable(entregables[0])
        setEstado(_estado)
        setEstadoEntregable(_estado)
      }

  }, [leccion?.id, entregables])

  useEffect(() => {

    if (entregables?.length > 0) {
      if (entregable?.estado === EntregableEstadoEnum.PENDIENTE_ENTREGA) setMode(ModeEnumEntregable.ENTREGA);
      if (entregable?.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION) setMode(ModeEnumEntregable.CORRECCION);
      if (entregable?.estado === EntregableEstadoEnum.ERROR) setMode(ModeEnumEntregable.FINALIZADO);
      if (entregable?.estado === EntregableEstadoEnum.CORRECTO) setMode(ModeEnumEntregable.FINALIZADO);
    } 

  }, [entregables, entregable]);


  const refreshEntregable = async (texto?: string | undefined) => {
    if (!leccion?.id) return;

    const entregableData = {
      leccionId: leccion?.id,
      cursoId: leccion?.modulo?.cursoId,
      moduloId: leccion?.moduloId,
      matriculaId: cursoProgress?.matriculaId,
      texto: texto ? texto : `Sin comentarios de la lección ${leccion?.nombre}`,
      fechaEntrega: new Date(),
      estado: EntregableEstadoEnum.PENDIENTE_ENTREGA,
    }

    return await uploadEntregables(entregableData)

  };

  const realizarEntrega = async () => {
    if (!leccion) return

    setEstado(EntregableEstadoEnum.PENDIENTE_CORRECCION)
    setEstadoEntregable(EntregableEstadoEnum.PENDIENTE_CORRECCION)

    setMode(ModeEnumEntregable.CORRECCION);
  };

  const handleFilter = (data: EntregablesInt[], entregableId: string | undefined) => {
    if (!entregableId) return []

    return data?.filter((entregable: EntregablesInt) => entregable?.id !== entregableId)
  }


  return (
    <Flex
      boxSize="100%"
      direction="column"
    >

      {(mode === ModeEnumEntregable.ENUNCIADO || mode === ModeEnumEntregable.ENTREGA) &&
        <EnunciadoItem
          estado={estado}
          leccion={leccion}
          entregable={entregable}
          endPrev={endPrev}
          onOpen={onOpen}
        />
      }

      {(mode === ModeEnumEntregable.CORRECCION || mode === ModeEnumEntregable.FINALIZADO) &&
        <CorreccionItem
          estado={estado}
          leccion={leccion}
          entregable={entregable}
          entregables={handleFilter(entregables, entregable?.id)}
          onOpen={onOpen}
        /> 
      }

      <EntregarTareaModal
        isOpen={isOpen}
        onClose={onClose}
        refreshEntregable={refreshEntregable}
        setMode={setMode}
        setEntregable={setEntregable}
        realizarEntrega={realizarEntrega}
      />
    </Flex>
  );
};