import { Image, Link as LinkChakra } from "@chakra-ui/react";
import { Link, } from "react-router-dom";
import LogoMorado from "../../../../assets/img/LogoMorado.svg"
import { UrlTypeEnum } from "../../../utils/Types/UrlTypeEnum";

interface Props {
    url: string | undefined;
    iconoEmpresa: string;
}

export const LogoEmpresa = ({ url, iconoEmpresa }: Props) => {

    if (url === UrlTypeEnum.PLATAFORMA && iconoEmpresa)
        return (
            <LinkChakra
                as={Link}
                to="/"
                bg="purewhite"
                p="5px"
                rounded="14px"
            >
                <Image
                    h="41px"
                    w="41px"
                    src={`data:image/svg+xml;utf8,${iconoEmpresa}`}
                    alt="procademyLogo"
                    objectFit="cover"
                />
            </LinkChakra>
        )
    else (url !== UrlTypeEnum.PLATAFORMA)
        return (
            <LinkChakra
                as={Link}
                to="/"
            >
                <Image
                    h="100%"
                    src={LogoMorado}
                    alt="procademyLogo"
                    objectFit="cover"
                />
            </LinkChakra>
        )
}