// Chakra UI
import { Flex, useToast } from "@chakra-ui/react";
// React
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// Components
import { useAuthContex } from "../../context/user.context";
import { TopbarMenu } from "./views/TopbarMenu";
import { TopbarLeccion } from "./views/TopbarLeccion";
import { useOutWindowContext } from "../../context/outWindow.context";
import { getUsersInactividad } from "../../middlewares/users.middleware";

// interface Props {
//     outWindow: boolean;
//     onClose: () => void;
// }

export const Topbar = () => {
    const { outWindow, onClose } = useOutWindowContext();
    const { logout } = useAuthContex();
    const location = useLocation();
    const navigate = useNavigate()
    const toast = useToast();
    const tenMin = 600000;

    const pathLeccion = location.pathname.includes("/leccion/");

    const [title, setTitle] = useState<string>("")

    useEffect(() => {
        if(location.pathname.startsWith("/dashboard")) setTitle("Resumen")
        if(location.pathname.startsWith("/aprendizaje")) setTitle("Cursos")
        if(location.pathname.startsWith("/entregables")) setTitle("Entregables")
        if(location.pathname.startsWith("/correcciones")) setTitle("Correcciones")
        if(location.pathname.startsWith("/foro")) setTitle("Foro")
        if(location.pathname.startsWith("/mensajeria")) setTitle("Mensajería")
        if(location.pathname.startsWith("/notas")) setTitle("Mis notas")
        if(location.pathname.startsWith("/matriculas")) setTitle("Matrículas")
        if(location.pathname.startsWith("/facturacion")) setTitle("Facturación")
        if(location.pathname.startsWith("/perfil")) setTitle("Perfil")
        if(location.pathname.startsWith("/cuestionario")) setTitle("")

    }, [location.pathname])

    const inactivityDisconnect = async() => {
        await getUsersInactividad();

        logout("Sesión cerrada por inactividad", navigate, toast);
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;

        if (outWindow) {
            timeout = setTimeout(() => {
                inactivityDisconnect();
                onClose();
            }, tenMin);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null
            }
        };
    }, [outWindow])

    return (
        <Flex
            
            alignItems="center"
            justifyContent="space-between"
            bg="bg_color"
        >
            {pathLeccion
                ? <TopbarLeccion />
                : <TopbarMenu title={title} />
            }
        </Flex>
    );
};