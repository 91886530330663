// React
import { Link as LinkRouter } from 'react-router-dom'
// Chakra UI
import { Link, Icon, Text, Flex, Box } from "@chakra-ui/react";

interface Props {
    path: string;
    icon: any;
    label: string;
    active?: boolean;
}

export const NavLink = ({ path, icon, label, active = false }: Props) => {

    return (
        <Link
            as={LinkRouter}
            to={path}
            textDecoration="none"
            _hover={{
                bgColor: "#C7CDFF",
                boxShadow: '0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)'
            }}
            rounded="15px"
            w="100%"
            minW="180px"
        >
            <Flex
                p="10px"
                gap="10px"
                color="purewhite"
                direction="row"
                alignItems="center"
          
                rounded="15px"
                outline={active ? "1px solid #C7CDFF" : "none"}
            >
                <Icon
                    as={icon}
                    boxSize="24px"
                    color="purewhite"
                />

                <Text
                    color="purewhite"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="500"
                    alignItems="center"
                >
                    {label}
                </Text>
            </Flex>
        </Link>
    );
};