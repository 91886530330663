import { useEffect, useState } from 'react';
import { Flex, Box, Icon } from '@chakra-ui/react';
import { JornadaLaboral, HorarioLaboral, DiaTypeEnum } from '../../../utils/Types/HorarioLaboral';
import { HorarioLaboralItem } from './HorarioLaboralItem';
import { BiCheckSquare, BiSquare } from "react-icons/bi";

const defaultDisponibilidad: JornadaLaboral = {
    [DiaTypeEnum.LUNES]: [],
    [DiaTypeEnum.MARTES]: [],
    [DiaTypeEnum.MIERCOLES]: [],
    [DiaTypeEnum.JUEVES]: [],
    [DiaTypeEnum.VIERNES]: [],
    [DiaTypeEnum.SABADO]: [],
    [DiaTypeEnum.DOMINGO]: [],
};

interface Props {
    label: string;
    sublabel?: string;
    defaultValueSelect?: JornadaLaboral;
    onChangeSelect: (newValue?: JornadaLaboral) => void;
    setIsChecked: (checked: any) => void;
    isChecked: boolean;
}

export const HorarioLaboralSelect = ({
    label,
    sublabel,
    defaultValueSelect,
    onChangeSelect = () => { },
    setIsChecked = (checked: boolean) => { },
    isChecked,
    ...props
}: Props): JSX.Element => {
    const [horarios, setHorario] = useState<JornadaLaboral>(defaultDisponibilidad);

    useEffect(() => {
        if (defaultValueSelect) {
            setHorario({ ...defaultValueSelect });
        }
    }, [defaultValueSelect]);

    const getIfDayIsChecked = (label: DiaTypeEnum): boolean => {
        if (!horarios) return false;
        return horarios[label].length !== 0;
    };

    const updateValue = (label: DiaTypeEnum, newTimes: HorarioLaboral[]): void => {
        const newDisponibilidad: JornadaLaboral = { ...horarios, [label]: newTimes };
        setHorario(newDisponibilidad);
        onChangeSelect(newDisponibilidad);
    };

    return (
        <Flex w="100%" direction="column" gap="30px">
            <Flex direction="column" gap="10px">
                <Box fontSize="18px" fontWeight="600" color="font">
                    {label}
                </Box>

                {sublabel && (
                    <Box fontSize="15px" fontWeight="500" color="font">
                        {sublabel}
                    </Box>
                )}
            </Flex>

            <Flex gap="15px" direction="column">
                <HorarioLaboralItem
                    label="Lunes"
                    times={horarios[DiaTypeEnum.LUNES]}
                    isDefaultChecked={getIfDayIsChecked(DiaTypeEnum.LUNES)}
                    onChange={(newTimes) => updateValue(DiaTypeEnum.LUNES, newTimes)}
                />

                <HorarioLaboralItem
                    label="Martes"
                    times={horarios[DiaTypeEnum.MARTES]}
                    isDefaultChecked={getIfDayIsChecked(DiaTypeEnum.MARTES)}
                    onChange={(newTimes) => updateValue(DiaTypeEnum.MARTES, newTimes)}
                />

                <HorarioLaboralItem
                    label="Miércoles"
                    times={horarios[DiaTypeEnum.MIERCOLES]}
                    isDefaultChecked={getIfDayIsChecked(DiaTypeEnum.MIERCOLES)}
                    onChange={(newTimes) => updateValue(DiaTypeEnum.MIERCOLES, newTimes)}
                />

                <HorarioLaboralItem
                    label="Jueves"
                    times={horarios[DiaTypeEnum.JUEVES]}
                    isDefaultChecked={getIfDayIsChecked(DiaTypeEnum.JUEVES)}
                    onChange={(newTimes) => updateValue(DiaTypeEnum.JUEVES, newTimes)}
                />

                <HorarioLaboralItem
                    label="Viernes"
                    times={horarios[DiaTypeEnum.VIERNES]}
                    isDefaultChecked={getIfDayIsChecked(DiaTypeEnum.VIERNES)}
                    onChange={(newTimes) => updateValue(DiaTypeEnum.VIERNES, newTimes)}
                />

                <HorarioLaboralItem
                    label="Sábado"
                    times={horarios[DiaTypeEnum.SABADO]}
                    isDefaultChecked={getIfDayIsChecked(DiaTypeEnum.SABADO)}
                    onChange={(newTimes) => updateValue(DiaTypeEnum.SABADO, newTimes)}
                />

                <HorarioLaboralItem
                    label="Domingo"
                    times={horarios[DiaTypeEnum.DOMINGO]}
                    isDefaultChecked={getIfDayIsChecked(DiaTypeEnum.DOMINGO)}
                    onChange={(newTimes) => updateValue(DiaTypeEnum.DOMINGO, newTimes)}
                />
            </Flex>

            <Flex alignItems="center" gap="20px">
                <Flex gap="15px" align="center">
                    <Icon
                        as={isChecked ? BiCheckSquare : BiSquare}
                        minW="24px"
                        boxSize="24px"
                        cursor="pointer"
                        color={isChecked ? 'primary' : 'gray_5'}
                        onClick={() => setIsChecked((prev: boolean) => !prev)}
                    />

                    <Box color="font" fontWeight="500" fontSize="15px" lineHeight="17px">
                        Por favor, confirme que el horario sea el correcto.
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};