import { Flex, Icon, Text } from '@chakra-ui/react';
import { CorreccionInt } from "../../../interfaces/Entregables";
import React from 'react';
import { BiCalendar } from 'react-icons/bi';
import { BsBagX, BsCheck, BsSignDeadEnd } from 'react-icons/bs';
import { HiX } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

interface Props {
    correccion?: CorreccionInt;
}

export default function CardActualizacion({ correccion }: Props) {

    const navigate = useNavigate()
    // console.log(correccion);
    const handleClickOnCorreccion = () => {
        const grupoSlug = correccion?.matricula?.grupo?.slug
        navigate(`/leccion/${correccion?.leccion?.slug}?grupo=${grupoSlug}`)
    }
    

    return (
        <Flex
            cursor="pointer"
            _hover={{bg:"main_variant",
                    }}
            transition="all 0.15s ease-in-out"
            direction="column"
            gap="15px"
            p="10px"
            // _hover={{ bg: "#EAECFF" }}
            rounded="5px"
            onClick={handleClickOnCorreccion}
        >
            <Flex
                direction="column"
            >
                <Text
                    fontSize="16px"
                    fontWeight="500"
                    color="font"
                >
                    Nueva corrección
                </Text>
            </Flex>

            <Flex>
                <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="dark_grey"
                    noOfLines={1}
                >
                    {correccion?.texto}
                </Text>
            </Flex>

            <Flex
                justifyContent="space-between"
            >
                <Flex
                    alignItems="center"
                    gap="5px"
                >
                    <Icon
                        as={BiCalendar}
                        boxSize="16px"
                        color="secondary"
                    />
                    <Text
                        fontSize="14px"
                        fontWeight="400"
                        color="dark_grey"
                    >
                         {correccion?.createdAt ? new Date(correccion.createdAt).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: '2-digit' }) : ''}

                    </Text>
                </Flex>

                <Flex
                    alignItems="center"
                    gap="5px"
                >
                
                {correccion?.correccion?.puntuacion >= 75 ?
                    <Icon
                        as={BsCheck}
                        boxSize="20px"
                        color="success"
                    />
                    :
                    <Icon
                        as={HiX}
                        boxSize="20px"
                        color="fail"
                    />

                    }
                    <Text
                        fontSize="14px"
                        fontWeight="400"
                        color="dark_grey"
                    >
                        {correccion?.correccion === null ? 0 : correccion?.correccion?.puntuacion}/100
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
}
