import { Box, Image } from "@chakra-ui/react";

interface Props {
    icon: string | undefined;
    size?: string;
    border?: string;
    rounded?: string;
    padding?: string;
    bg?: string;
    styles?: React.CSSProperties;
}

export const IconBox = ({ 
    icon, 
    size = "75px", 
    border = "1px solid #E6E6EA",
    rounded = "10px",
    padding = "12px",
    bg = "#FFF",
    styles,
}: Props) => {
    const defaultIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 18H6C5.73478 18 5.48043 18.1054 5.29289 18.2929C5.10536 18.4804 5 18.7348 5 19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H21V22H6C5.20435 22 4.44129 21.6839 3.87868 21.1213C3.31607 20.5587 3 19.7956 3 19V4C3 3.46957 3.21071 2.96086 3.58579 2.58579C3.96086 2.21071 4.46957 2 5 2H21V18ZM5 16.05C5.162 16.017 5.329 16 5.5 16H19V4H5V16.05ZM16 9H8V7H16V9Z" fill="black"/>
    </svg>
    `
    return (
        <Image
            src={`data:image/svg+xml;utf8,${icon || defaultIcon}`}
            bg={bg}
            w={size}
            h={size}
            rounded={rounded}
            border={border}
            p={padding}
            style={styles}
        />
    );
}

