import { Flex, Text, Icon, Button } from '@chakra-ui/react';
import { RespuestaForoInt, TemaForoInt } from '../../../interfaces/ForoInt';
import { NuevaRespuesta } from '../Components/NuevaRespuesta';
import { CardPreguntas } from '../Components/CardPreguntas';
import { CardRespuesta } from '../Components/CardRespuesta';
import { useNavigate, useParams } from 'react-router-dom';
import { SekeletonPregunta } from '../Components/utils/SkeletonPregunta';
import { usePreguntaId } from '../../../shared/middlewares/foro.middlewate';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import { GrupoInt, MatriculasInt } from '../../../interfaces/MatriculasInt';
import { useAuthContex } from '../../../shared/context/user.context';
import { UserRolEnum } from '../../../shared/utils/Types/RolEnum';
import { EstadoTypeEnum } from '../../../shared/utils/Types/EstadoTypeEnum';
import { CardSinInformacion, TypeCardSinInformacion } from '../../../shared/components/Customs/CardSinInformacion';

interface Props {
    grupos: GrupoInt[];
    temas: TemaForoInt[];
    matriculas: MatriculasInt[];
    setSelectedGrupo: (grupo: GrupoInt) => void;
    selectedGrupo: GrupoInt | undefined;
    matricula: MatriculasInt | undefined;
    onOpen: () => void;
    cursoId?: string | undefined;
}


export const Pregunta = ({ temas, selectedGrupo, matricula }: Props) => {
    const { temaId, preguntaId } = useParams();
    const { user } = useAuthContex();
    const navigate = useNavigate();
    const { pregunta, loading, Refresh } = usePreguntaId({
        id: preguntaId,
        ignoreRequest: user?.role?.nombre === UserRolEnum.ALUMNO ? (!matricula?.id ? true : false) : false,
        track: user?.role?.nombre === UserRolEnum.ALUMNO ?
            {
                track: user?.tracking ? true : false,
                matricula: matricula?.estado === EstadoTypeEnum.ACTIVO ? matricula?.id : undefined,
            } : undefined
    })

    const tema = temas?.find((t: TemaForoInt) => t?.id == temaId)

    return (
        loading ? <SekeletonPregunta />
            :
            <Flex
                direction="column"
                w="100%"
                gap="20px"
            >
                {selectedGrupo &&
                    <Text
                        color="font"
                        fontSize="28px"
                        fontWeight="700"
                        textTransform="capitalize"
                    >
                        {selectedGrupo?.curso?.nombre}
                    </Text>}

                <Flex>
                    <Button
                        textColor="purewhite"
                        onClick={() => navigate(`/foro/${tema?.id}`)}
                        bg="secondary"
                        justifyContent="left"
                        gap="8px"
                        borderRadius="10px"
                        fontWeight="500"
                    >
                        <Icon
                            as={HiOutlineArrowSmLeft} />
                        Volver
                    </Button>
                </Flex>

                <Flex
                    gap="30px"
                    py="20px"
                >
                    <Flex
                        w="100%"
                        direction="column"
                        gap="40px"
                    >

                        <CardPreguntas
                            isTrucate={false}
                            pregunta={pregunta}
                        />

                        <Flex gap="20px" direction="column">
                            <Flex justifyContent="start" pr="25px">
                                <Text
                                    color="subtitle_font"
                                    fontSize="21px"
                                >
                                    Respuestas
                                </Text>
                            </Flex>

                            <Flex
                                direction="column"
                                gap="24px"
                                w="100%"
                                rounded="12px"
                                h="fit-content"
                            >
                                {(selectedGrupo?.estado === EstadoTypeEnum.ACTIVO || user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) &&
                                    <NuevaRespuesta preguntaId={preguntaId} refresh={Refresh} pregunta={pregunta} />
                                }

                                <Flex direction="column" gap="20px" bg="purewhite" rounded="6px" p="10px">
                                    {pregunta?.respuestas?.length > 0
                                    ? pregunta?.respuestas?.map((respuesta: RespuestaForoInt, index: number) => (
                                        <CardRespuesta respuesta={respuesta} key={index} />
                                    ))
                                    : 
                                    <CardSinInformacion 
                                        type={TypeCardSinInformacion.FORO} 
                                        isCard
                                        titulo='No hay respuestas para esta pregunta'
                                        mensaje='Sé el primero en responder y abre un debate interesante.' 
                                    />
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
    );
};