import { Flex, Text } from '@chakra-ui/react';
import Avatar from "../../../assets/img/Avatar.png";
import CardForo from '../../../shared/components/Cards/CardForo';
import { formatDistance } from 'date-fns';
import { es } from "date-fns/locale";
import CardSinActividad from './CardSinActividad';
import sinActividadForo from "../../../assets/img/sinActividadForo.png"
import { CardSinInformacion, TypeCardSinInformacion } from '../../../shared/components/Customs/CardSinInformacion';

interface Props {
    preguntas?: any;
}

export const CardActividad = ({ preguntas }: Props) => {


    return (
        <Flex
            bg="purewhite"
            rounded="5px"
            p="30px"
            direction="column"
            gap="20px"
        >
            <Text
                color="font"
                fontSize="16px"
                fontWeight="700"
            >
                Actividad en foro
            </Text>

            {preguntas && preguntas?.lenght > 0 ?
                preguntas.map((pregunta: any) => (
                    <CardForo
                        key={pregunta.id}
                        nombre={pregunta.user?.username ?? ""}
                        tiempo={pregunta.createdAt && formatDistance(new Date(pregunta.createdAt), new Date(), { locale: es })}
                        avatar={pregunta.user?.avatar?.url || Avatar}
                        descripcion={pregunta.tema?.descripcion ?? ""}
                        pregunta={pregunta}
                    />
                ))
                :
                <CardSinInformacion type={TypeCardSinInformacion.ACTIVIDADES} isCard />
            }

        </Flex>
    );
};
