import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

export const getAllNotification = async () => {
    const { tenant, client } = await tenantInstance();
    
    return await _axios.get(`${tenant}/${client}/notificaciones`).then((response: any) => response);
};

export const updateReadNotification = async (id: string) => {
    const { tenant, client } = await tenantInstance();
    
    return await _axios.put(
        `${tenant}/${client}/notificaciones/${id}`,
        {
            vista: true
        },
        {}
    ).then((response: any) => response);
};