// Icons
import { BiPlay } from 'react-icons/bi';
// Chakra UI
import { Flex, Icon } from '@chakra-ui/react';
// Components
import { ThumbnailLeccion } from '../Thumbnails/ThumbnailLeccion';
// Interfaces
import { LeccionInt } from '../../interfaces/CoursesInt';
// Enums
import { ThumbnailSizeEnum } from '../../shared/utils/Types/ThumbnailSizeEnum';

interface Porps {
  leccion?: LeccionInt | null; 
  handleOnPlayClick: (e?: any) => void;
}

export const Thumbnail = ({ leccion, handleOnPlayClick }: Porps) => {

  return (
    <Flex position="absolute" zIndex="50" boxSize="100%">
      <Flex boxSize="100%" align="center" justify="center" position="relative">
        <Icon
          as={BiPlay}
          ml="auto"
          color="#fff"
          boxSize="64px"
          zIndex="15"
          rounded="full"
          p="2px 0px 2px 4px"
          cursor="pointer"
          position="absolute"
          bg="rgba(0, 0, 0, 0.6)"
          transition="all 0.5s ease"
          border="1px solid rgba(255, 255, 255, 0.15)"
          _hover={{ bgColor: 'var(--chakra-colors-primary_neon)' }}
          onClick={handleOnPlayClick}
        />

        <ThumbnailLeccion
          leccion={leccion && leccion}
          size={ThumbnailSizeEnum.FULL}
          leccionNumber={leccion?.orden}
          curso={leccion?.modulo?.curso}
          moduloNumber={leccion?.modulo?.orden}
        />
      </Flex>
    </Flex>
  );
};
