import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { ChatInt } from "../../../interfaces/MessagesInt";
import { useAuthContex } from "../../../shared/context/user.context";
import { BsClock } from "react-icons/bs";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { CustomAvatar } from "../../../shared/components/Avatar/CustomAvatar";

interface Props {
    chat: ChatInt;
    selected: ChatInt;
}

export const ChatsItem = ({ chat, selected }: Props) => {
    const { user } = useAuthContex()
    const navigate = useNavigate()

    const dateToTime = (date: Date) => {
        const time = new Date(date).toLocaleTimeString(["es-ES"], { timeZone: 'UTC', hour: "2-digit", minute: "2-digit" })

        return time
    };

    const openChat = () => {
        if (selected?.id !== chat?.id) {
            navigate(`/mensajeria/${chat?.id}`);
        }
    }


    return (
        <Flex
            p="15px 25px"
            gap="15px"
            borderBottom="1px solid"
            borderColor="light_grey"
            cursor="pointer"
            _hover={{ bg: "main_variant" }}
            onClick={openChat}
            pos="relative"
        >
            <Box bg={selected?.id === chat?.id ? "main" : "inherit"} w="3px" bottom="0" top="0" left={0} pos="absolute" />

            <Flex
                direction="column"
                gap="10px"
                w="100%"
            >
                <Flex
                    alignItems="center"
                    gap="10px"
                >
                    <CustomAvatar
                        size="26px"
                        rounded="50px"
                        src={
                            user?.id === chat?.receptorId
                                ? chat?.emisor?.avatar?.url
                                : user?.id === chat?.emisorId
                                    ? chat?.receptor?.avatar?.url
                                    : null
                        }
                        name={
                            (user?.id === chat?.receptorId
                                ? chat?.emisor?.nombre + " " + chat?.emisor?.apellidos 
                                : user?.id === chat?.emisorId
                                    ? chat?.receptor?.nombre + " " + chat?.receptor?.apellidos
                                    : "") || ""
                        }
                    />

                    <Text
                        color="black"
                        fontSize="16px"
                        fontWeight="600"
                    >
                        {
                            user?.id === chat?.receptorId
                                ? chat?.emisor?.nombre + " " + chat?.emisor?.apellidos 
                                : user?.id === chat?.emisorId
                                    ? chat?.receptor?.nombre + " " + chat?.receptor?.apellidos
                                    : null
                        }
                    </Text>
                </Flex>

                <Text
                    color="secondary_variant_font"
                    fontSize="16px"
                    fontWeight="400"
                >
                    {chat?.asunto}
                </Text>

                <Flex
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Flex
                        color="dark_grey"
                        fontSize="12px"
                        fontWeight="400"
                        gap="5px"
                        align="center"
                    >
                        <Icon as={BsClock} boxSize="15px" />

                        <Text
                            borderRight="1px solid"
                            borderColor="dark_grey"
                            pr="5px"
                        >
                            {chat?.createdAt && format(new Date(chat?.createdAt), "dd/MM")}
                        </Text>

                        <Text>
                            {chat?.createdAt && format(new Date(chat?.createdAt), "HH:mm")}
                        </Text>
                    </Flex>

                    {(chat?.meta?.mensajes_count && chat?.meta?.mensajes_count !== "0") &&
                        <Flex
                            color="white"
                            fontSize="12px"
                            fontWeight="500"
                            bg="secondary"
                            rounded="50px"
                            p="3px 9px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {chat?.meta?.mensajes_count}
                        </Flex>
                    }
                </Flex>
            </Flex>
        </Flex>
    );
};