import { CardContador } from "./Cards/CardContador";
import { Flex, Text, Image } from "@chakra-ui/react";
import { EstadoTypeEnum } from "../../../shared/utils/Types/EstadoTypeEnum";
import CardDescarga, { CardDescargaType } from "./Cards/CardDescarga";
import { fmtSnds } from "../../../shared/utils/functions/formatters";
import CardModulo from "./Cards/cardModulo";
import CardObjetivoStats from "./Cards/cardObjetivoStats";
import CardRequisitosRestantes from "./Cards/CardRequisitosRestantes";


interface Props {
    matricula: any;
    opacity: number;
}

export default function StatsCursos({ matricula, opacity }: Props) {
    const checkRequisitos = (tiempoAcceso: boolean): boolean => {
        if (tiempoAcceso === false) {
            if (
                matricula?.puntuacionMedia >= 75 &&
                matricula?.progresoLecciones >= 75 &&
                matricula?.progresoEntregables >= 75
            ) return true;
            else
                return false;
        }

        if (
            matricula?.puntuacionMedia >= 75 &&
            matricula?.progresoLecciones >= 75 &&
            matricula?.progresoEntregables >= 75 &&
            parseInt(fmtSnds(matricula?.tiempoAcceso)) >= (matricula?.grupo?.curso?.duracion * 75) / 100
        ) return true;

        return false;
    }


    return (
        <Flex
            opacity={`${opacity}`}
            transition="ease-in-out 300ms"
            direction="column"
            gap="40px"
            w="100%"
        >
            <Flex
                flex="1"
                align="center"
                gap="10px"
            >
                <Image
                    boxSize="32px"
                    src={`data:image/svg+xml;utf8,${matricula?.grupo?.curso?.icono}`}
                />
                <Text
                    fontSize="21px"
                    fontWeight="600"
                    color="font"
                >
                    {`${matricula?.grupo?.curso?.nombre} - ${matricula?.grupo?.nombre}`}
                </Text>
            </Flex>

            <Flex
                flexWrap="wrap"
                gap="20px"
                flex="1"
            >
                {matricula?.estado === EstadoTypeEnum.ACTIVO &&
                    <CardModulo
                        stats={matricula}
                    />
                }

                {(matricula?.progresoLecciones >= 75 || matricula?.estado === EstadoTypeEnum.INACTIVO) &&
                    <>
                        {(matricula?.grupo?.fundae && matricula?.grupo?.curso?.tutorizado) &&

                            <>

                                {!matricula?.cuestionario &&
                                
                                    <Flex
                                        flex="1"
                                    >
                                        <CardDescarga
                                            type={CardDescargaType.CUESTIONARIO}
                                            tooltip="Realiza el cuestionario para obtener tu diploma."
                                            matricula={matricula}
                                            isDisabled={matricula?.formularioCompletado
                                                ? true
                                                : matricula?.grupo?.permitirDescargaDiploma
                                                    ? !checkRequisitos(false)
                                                    : !checkRequisitos(true)
                                            }
                                            tooltipDisabled="Debes cumplir con todos los requisitos para poder realizar el cuestionario."
                                        />
                                    </Flex>
                                }

                            </>

                        }

                        {matricula?.grupo?.curso?.tutorizado &&
                            <>
                                <Flex
                                    flex="1"
                                >
                                    <CardDescarga
                                        type={CardDescargaType.DIPLOMA}
                                        tooltip="Descarga tu diploma"
                                        matricula={matricula}
                                        tooltipDisabled={
                                            matricula?.grupo?.fundae
                                                ? "Debes completar el cuestionario para poder descargar el diploma."
                                                : "Debes cumplir con todos los requisitos para poder descargar el diploma."
                                        }
                                        isDisabled={
                                            matricula?.grupo?.fundae
                                                ? !matricula?.formularioCompletado
                                                : !checkRequisitos(false)
                                        }
                                    />
                                </Flex>

                                <CardRequisitosRestantes
                                    matricula={matricula}
                                />
                            </>
                        }
                    </>
                }

                {
                    matricula?.grupo?.curso?.tutorizado &&
                    <>

                        <CardObjetivoStats
                            value={matricula?.puntuacionMedia?.toFixed(0)}
                            valuePorcentaje={matricula?.puntuacionMedia}
                            label="Nota media"
                            tooltipLabel="Se debe de tener más de un 75 de nota media"
                        />

                        <CardObjetivoStats
                            value={parseInt((matricula?.progresoEntregables).toFixed(0))}
                            valuePorcentaje={parseInt((matricula?.progresoEntregables).toFixed(0))}
                            label="Ejercicios completados"
                            tooltipLabel="Se debe de realizar el 75% de las ejercicios"
                        />

                    </>
                }

                <CardObjetivoStats
                    value={parseInt((matricula?.progresoLecciones).toFixed(0))}
                    valuePorcentaje={parseInt((matricula?.progresoLecciones).toFixed(0))}
                    label="Actividades completadas"
                    tooltipLabel="Se debe de realizar el 75% de las actividades"
                />

                {(matricula?.estado === EstadoTypeEnum.ACTIVO && matricula?.grupo?.curso?.tutorizado) &&
                    <Flex
                        flex="1"
                        align="start"
                        justify="center"
                        direction="column"
                        boxShadow="0px 4px 15px 0px rgba(199,205,255,0.6)"
                        borderRadius="8px"
                        padding="30px"
                        maxW="360px"
                    >
                        <Text
                            size="16px"
                            fontWeight="700"
                            color="font"
                        >
                            Finalizará en:
                        </Text>

                        <CardContador stats={matricula} />
                    </Flex>
                }
            </Flex>
        </Flex>
    );
}
