// React 
import { Navigate, Outlet } from "react-router-dom";
// Hook
import { useAuthContex } from "../../context/user.context";
import { useUrl } from "../../context/url.context";
import { UrlTypeEnum } from "../../utils/Types/UrlTypeEnum";
import { UserRolEnum } from "../../utils/Types/RolEnum";

interface Props {
    isRoleAllowed: boolean;
}

export const RequireAuth = ({ isRoleAllowed = true }: Props) => {
    const { user } = useAuthContex();
    const url = useUrl();

    return (
        !user?.auth
            ? <Navigate to={"/login"} />
            : (user?.auth && isRoleAllowed)
                ?
                <Outlet />
                :
                <Navigate
                    to={
                        url !== UrlTypeEnum.PLATAFORMA
                            ? user?.role?.nombre === UserRolEnum.TUTOR ? "/correcciones" : "/dashboard"
                            : "/aprendizaje"
                    }
                />
    )
};