import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    Flex,
    useToast,
} from '@chakra-ui/react'
import { FeedbackConfigInt } from '../../../../interfaces/FeedbackInt';
import Form from '@rjsf/chakra-ui';
import validator from '@rjsf/validator-ajv8';
import { RJSFSchema } from '@rjsf/utils';
import { useEffect, useState } from 'react';
import { addFeedbackUser } from '../../../../shared/middlewares/feedback.middleware';
import { handleErrors } from '../../../../shared/utils/functions/handleErrors';
import { StatusEnum, notify } from '../../../../shared/utils/functions/notify';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    feedback: FeedbackConfigInt;
}

export const FbModuloModal = ({
    isOpen,
    onClose,
    feedback
}: Props) => {
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [schema, setSchema] = useState<RJSFSchema>({});

    useEffect(() => {
        if(!feedback?.estructura) return onClose();
        
        try {
            setSchema(feedback?.estructura)
        } catch (error) {
            setSchema({})
            return onClose()
        }
    }, [feedback])

    const replaceUi = (schema: RJSFSchema) => {
        const JsonUi: any = {};

        for (const key in schema.properties) {
            if (Object.prototype.hasOwnProperty.call(schema.properties, key)) {
                const property: any = schema.properties[key];
                if (property.type === 'string' && property.enum && property.enum.includes('1') && property.enum.includes('2')) {
                    JsonUi[key] = {
                        "ui:widget": "radio",
                        "ui:options": {
                            inline: true,
                            style: {
                                display: "flex",
                                flexDirection: "row",
                            },
                        },
                    };
                }
            }
        }

        return JsonUi;
    }

    const handleSubmit = (data: any) => {
        if(!feedback?.id || !feedback?.recursoId || !feedback?.tipo) return onClose();
        setLoading(true)

        const feedbackUser = {
            tipo: feedback?.tipo,
            feedback: data,
            feedbackConfigId: feedback?.id?.toString(),
            recursoId: feedback?.recursoId,
        }

        addFeedbackUser(feedbackUser)
        .then(() => localStorage.removeItem('feedbackModulo'))
        .catch((error: any) => {
            const errors = handleErrors(
                error?.response?.data?.errors,
                error?.response?.status
            )

            errors?.map((error: any) => notify(toast, StatusEnum.error, error?.message))
        })
        .finally(() => {
            setLoading(false)
            setSchema({})
            onClose()
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="3xl"
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />

            <ModalContent
                bg="purewhite"
                p="30px"
            >
                <ModalBody>
                    <Form
                        className='form-cuestionario'
                        schema={schema}
                        uiSchema={replaceUi(schema)}
                        validator={validator}
                        onSubmit={(e: any) => handleSubmit(e?.formData)}
                    >
                        <Flex>
                            <Button
                                isLoading={loading}
                                m="auto"
                                w="fit-content"
                                h="fit-content"
                                bg="secondary"
                                p="10px 25px"
                                color="purewhite"
                                fontSize="16px"
                                fontWeight="500"
                                _hover={{ bg: "secondary_variant" }}
                                type='submit'
                            >
                                Enviar
                            </Button>
                        </Flex>
                    </Form>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}