import { Flex, Text, Image, Box } from "@chakra-ui/react";
import { usePreguntas } from "../../../../shared/middlewares/foro.middlewate";
import { PreguntaForoInt } from "../../../../interfaces/ForoInt";
import { CardMensajes } from "./CardMensajes";
import { useMensajes } from "../../../../shared/middlewares/messages.middleware";
import { useEffect } from "react";
import CardSinActividad from "../../../Foro/Components/CardSinActividad";
import { useNavigate } from "react-router-dom";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../../shared/components/Customs/CardSinInformacion";

interface Props {
  cursoId: string | undefined;
}

export default function Mensajes({ cursoId }: Props) {
  const { mensajes } = useMensajes({})
  const navigate = useNavigate();

  return (
    <Flex
      w="100%"
      bg="#FFF"
      p="30px"
      borderRadius="8px"
      gap="20px"
      direction="column"
      minH="300px"
    >

      <Text
        color="font"
        fontSize="18px"
        fontWeight="600"
      >
        Nuevos mensajes
      </Text>

      {mensajes && mensajes?.length > 0 ?
        mensajes?.slice(0, 2).map((mensaje: PreguntaForoInt, index: number) => (
          <CardMensajes
            key={index}
            mensaje={mensaje}
            onClick={() => navigate(`/mensajeria/${mensaje.id}`)}
          />
        ))
        :
        <CardSinInformacion type={TypeCardSinInformacion.MENSAJES} isCard/>
      }


    </Flex>
  )
}
