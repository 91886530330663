import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Icon, Text } from "@chakra-ui/react"
import { CursosInt, LeccionInt, ModulosInt } from "../../../../interfaces/CoursesInt"
import { useProgressContex } from "../../../../shared/context/progress.context";
import { BiCheck } from "react-icons/bi";
import { BarProgress } from "../../../../shared/components/Progress/BarProgress";
import { BsPlayCircle } from "react-icons/bs";
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../shared/utils/Types/RolEnum";
import { useAuthContex } from "../../../../shared/context/user.context";
import { LessonTypeEnum } from "../../../../shared/utils/Types/LessonEnum";
import { sortByOrder, validateProgressModule } from "../../../../shared/utils/functions/validateProgress";
import { HiOutlineCloudDownload, HiOutlinePencilAlt, HiOutlinePresentationChartBar } from "react-icons/hi";

interface Props {
    curso: CursosInt | undefined;
}

export const TabContenidoInactivo = ({ curso }: Props) => {
    const { user } = useAuthContex();
    const { cursoProgress } = useProgressContex();

    const checkLessonComplete = (leccionId: string) => {

        if (Reflect.has(cursoProgress?.data || {}, leccionId)) {
            if (cursoProgress?.data[leccionId]?.estado === "finalizacion") {
                return true
            }
        }

        return false
    };

    return (
        <Flex
            display="flex"
            direction="column"
            transition="all 200ms"
            gap="40px"
            mt="40px"
        >
            <Accordion
                allowMultiple
            >
                {sortByOrder(curso?.modulos)?.map((modulo: ModulosInt, index: number) => (
                    <AccordionItem
                        key={index}
                        border={index === 0 ? "none" : ""}
                    >
                        <AccordionButton
                            p="30px 20px"
                            display="flex"
                            justifyContent="space-between"
                            bg="purewhite"
                            gap="20px"
                        >

                            <Flex
                                alignItems="center"
                                gap="10px"
                                w="70%">
                                <AccordionIcon />
                                <Text
                                    color="font"
                                    fontSize="18px"
                                    fontWeight="600"
                                >
                                    {modulo?.nombre}
                                </Text>
                            </Flex>

                            <Flex
                                alignItems="center"
                                gap="10px"
                            >
                                <Text
                                    color="dark_grey"
                                    fontSize="16px"
                                    fontWeight="400"
                                    whiteSpace="nowrap"
                                >
                                    {modulo?.lecciones?.length} clases
                                </Text>
                                <Text
                                    color="dark_grey"
                                >
                                    ·
                                </Text>
                                <Flex
                                    gap="30px"
                                    alignItems="center"
                                    boxSize="20px"
                                    w="full"
                                >

                                    <Text
                                        color="dark_grey"
                                    >
                                        Curso finalizado
                                    </Text>

                                    <Icon
                                        color="#fff"
                                        bg="secondary"
                                        boxSize="20px"
                                        rounded="full"
                                        as={BiCheck}
                                    />

                                </Flex>
                            </Flex>

                        </AccordionButton>

                        <AccordionPanel
                            display="flex"
                            bg="purewhite"
                        >
                            <Flex
                                flexDirection="column"
                                w="100%"
                            >
                                {sortByOrder(modulo?.lecciones)?.map((leccion: LeccionInt, index: number) => (
                                    <Flex
                                        key={index}
                                        justifyContent="space-between"
                                        p="10px 20px"
                                        mb="10px"
                                    >
                                        <Flex
                                            alignItems="center"
                                            gap="10px"
                                        >
                                            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                                                <Icon
                                                    color={
                                                        checkLessonComplete(leccion?.id)
                                                            ? "#8694FF"
                                                            : "font"
                                                    }
                                                    as={
                                                        leccion?.tipo === LessonTypeEnum.VIDEO
                                                            ? BsPlayCircle
                                                            : leccion?.tipo === LessonTypeEnum.ENTREGABLE
                                                                ? HiOutlinePencilAlt
                                                                : leccion?.tipo === LessonTypeEnum.RECURSO
                                                                    ? HiOutlineCloudDownload
                                                                    : HiOutlinePresentationChartBar
                                                    }
                                                    boxSize="20px"
                                                />
                                            }

                                            <Text
                                                fontSize="17px"
                                                fontWeight="500"
                                            >
                                                {leccion?.nombre}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Flex>
    )
}