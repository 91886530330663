import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { BiSearch } from 'react-icons/bi';

interface Props {
  name?: string;
  value: string | number;
  onChange: (e: any) => void;
  placeholder?: string;
  type?: "text" | "number" | "date";
  min?: number;
  max?: number;
  step?: number;
  isSearch?: boolean;
}

export const FilterInput = ({
  name,
  value,
  onChange,
  placeholder,
  type = 'text',
  min = undefined,
  max = undefined,
  step = undefined,
  isSearch = false,
}: Props) => {
  return (
    <InputGroup>
      <Input
        name={name}
        min={min}
        max={max}
        type={type}
        step={step}
        value={value}
        onChange={(e: any) => onChange(e)}
        placeholder={placeholder}
        bg="#FFF"
        border="1px solid #E2E8F0"
        borderRadius="5px"
      />

      {isSearch &&
      <InputLeftElement>
        <Icon as={BiSearch} boxSize="22" />
      </InputLeftElement>
      }
    </InputGroup>
  );
};

