import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Icon, Img, Text } from "@chakra-ui/react"
import { CursosInt, LeccionInt, ModulosInt } from "../../../../interfaces/CoursesInt"
import { useProgressContex } from "../../../../shared/context/progress.context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BiCheck } from "react-icons/bi";
import { BsCheck, BsPencilSquare, BsXCircle } from "react-icons/bs";
import { LessonTypeEnum } from "../../../../shared/utils/Types/LessonEnum";
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../shared/utils/Types/RolEnum";
import { useAuthContex } from "../../../../shared/context/user.context";
import { BarProgress } from "../../../../shared/components/Progress/BarProgress";
import { useEffect, useState } from "react";
import { sortByOrder, validateProgressModule } from "../../../../shared/utils/functions/validateProgress";
import CardHeaderCursoFinalizado from "../../Components/Card/CardCursoFinalizado";
import { EntregableEstadoEnum } from "../../../../shared/utils/Types/EntregableEstadoEnum";

interface Props {
    curso: CursosInt | undefined;
}

export const TabEjercicio = ({ curso }: Props) => {
    const { user } = useAuthContex();
    const { cursoProgress } = useProgressContex();
    const progress = Math.round(cursoProgress?.porcentajeCurso || 0)
    const navigate = useNavigate();
    const [thumbnail, setThumbnail] = useState();
    const [search] = useSearchParams()
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""


    const checkLessonComplete = (leccionId: string) => {
        if (Reflect.has(cursoProgress?.data || {}, leccionId)) {
            if (cursoProgress?.data[leccionId]?.estado === "finalizacion") {
                return true
            }
        }

        return false
    };

    const checkLessonStart = (leccionId: string) => {
        if (Reflect.has(cursoProgress?.data || {}, leccionId)) {
            return true
        }

        return false
    };

    const filterModules = (modulos: ModulosInt[] | undefined) => {
        const modulosFilter: ModulosInt[] = []

        if (!modulos) return modulosFilter

        modulos.map((modulo: ModulosInt) => {
            for (let i = 0; i <= modulo?.lecciones?.length; i++) {
                if (modulo?.lecciones[i]?.tipo === LessonTypeEnum.ENTREGABLE) {
                    return modulosFilter.push(modulo)
                }
            }
        })

        return sortByOrder(modulosFilter)
    }

    const filterEntregables = (lecciones: LeccionInt[]) => {
        const filter = lecciones?.filter((leccion: LeccionInt) => leccion.tipo === LessonTypeEnum.ENTREGABLE)

        return sortByOrder(filter)
    }

    const onContinueLeccion = () => {
        let leccionSlug;
        let selectedModulo: any;

        curso?.modulos?.find((modulo: any) =>
            modulo.lecciones?.find((leccion: any) => {
                if (leccion?.tipo === LessonTypeEnum.ENTREGABLE && !checkLessonComplete(leccion?.id)) {
                    leccionSlug = leccion?.slug;
                    selectedModulo = modulo

                    return true;
                }

                return false;
            })
        );

        return {
            redirectLeccion: leccionSlug,
            modulo: selectedModulo
        }
    }


    return (
        <Flex
            display="flex"
            direction="column"
            transition="all 200ms"
            gap="40px"
            mt="40px"
        >
            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                (filterModules(curso?.modulos)?.length > 0 ?

                    !onContinueLeccion()?.modulo

                        ? <CardHeaderCursoFinalizado
                            image={curso && curso?.imagen?.url}
                        />

                        : <Flex
                            w="100%"
                            direction="column"
                            gap="20px"
                            bg="purewhite"
                            p="30px"
                            rounded="5px"
                        >
                            <Text
                                fontSize="18px"
                                fontWeight="600"
                                color="font"
                            >
                                Reanuda tu última tarea
                            </Text>

                            <Flex
                                gap="30px"
                            >
                                <Img
                                    rounded="5px"
                                    boxSize="100px"
                                    w="40%"
                                    maxW="650px"
                                    minW="500px"
                                    maxH="260px"
                                    height="80%"
                                    objectFit="cover"
                                    src={curso?.imagen?.url}
                                />

                                <Flex
                                    direction="column"
                                    gap="15px"

                                >
                                    <Text
                                        fontSize="16px"
                                        fontWeight="500"
                                        color="dark_grey"
                                    >
                                        Módulo: {onContinueLeccion()?.modulo?.nombre}
                                    </Text>

                                    <Flex
                                        alignItems="center"
                                        gap="10px"
                                    >
                                        <Icon
                                            as={BsPencilSquare}
                                            boxSize="24px"
                                            color="secondary"
                                        />
                                        <Text
                                            fontSize="18px"
                                            fontWeight="600"
                                            color="font"
                                        >
                                            {onContinueLeccion()?.modulo?.nombre}
                                        </Text>
                                    </Flex>

                                    <BarProgress
                                        height="5px"
                                        progress={30}
                                    />

                                    <Text
                                        color="dark_grey"
                                        fontSize="16px"
                                        fontWeight="500"
                                    >
                                        {curso?.meta?.totalEntregables} entregables
                                    </Text>

                                    {/* <Text
                                            color="font"
                                            fontSize="14px"
                                            fontWeight="500"
                                        >
                                            Crea soluciones efectivas para tu proyecto aprendiendo a usar las hojas de cálculo
                                        </Text> */}

                                    <Button
                                        bg="secondary"
                                        w="fit-content"
                                        color="purewhite"
                                        fontSize="16px"
                                        fontWeight="500"
                                        p="10px 16px"
                                        rounded="5px"
                                        h="fit-content"
                                        // rightIcon={<Icon as={BsArrowRight} />}
                                        _hover={{ bg: "secondary_variant" }}
                                        onClick={() => navigate(`/leccion/${onContinueLeccion().redirectLeccion}${addRedirect}`)}
                                        mt="auto"
                                    >
                                        Continuar
                                    </Button>
                                </Flex>

                            </Flex>
                        </Flex>
                    :
                    <Flex
                        w="100%"
                        direction="column"
                        gap="20px"
                        bg="purewhite"
                        p="20px"
                        rounded="5px"

                    >
                        <Text
                            fontSize="24px"
                            fontWeight="600"
                            color="black"
                        >
                            Comienza una nueva tarea
                        </Text>

                        <Flex
                            gap="20px"
                        >
                            <Img
                                rounded="5px"
                                boxSize="100px"
                                w="40%"
                                maxW="650px"
                                minW="500px"
                                maxH="260px"
                                height="80%"
                                objectFit="cover"
                                src={curso?.imagen?.url}
                            />

                            <Flex
                                direction="column"
                                gap="15px"
                                justifyContent="space-between"
                            >
                                <Text
                                    fontSize="16px"
                                    fontWeight="500"
                                    color="dark_grey"
                                >
                                    Módulo: {curso?.modulos[0]?.nombre}
                                </Text>

                                <Flex
                                    alignItems="center"
                                    gap="10px"
                                >
                                    <Icon
                                        as={BsPencilSquare}
                                        boxSize="24px"
                                        color="main"
                                    />
                                    <Text
                                        fontSize="24px"
                                        fontWeight="600"
                                        color="font"
                                    >
                                        Tablas y gráficas
                                    </Text>
                                </Flex>

                                <BarProgress
                                    height="5px"
                                    progress={0}
                                />

                                <Text
                                    color="#959596"
                                    fontSize="16px"
                                    fontWeight="500"
                                >
                                    {curso?.meta?.totalEntregables} entregables
                                </Text>

                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="500"
                                    mt="10px"
                                >
                                    Crea soluciones efectivas para tu proyecto aprendiendo a usar las hojas de cálculo, herramientas...
                                </Text>

                                <Button
                                    bg="secondary"
                                    w="30%"
                                    color="purewhite"
                                    fontSize="20px"
                                    fontWeight="500"
                                    p="10px 16px"
                                    rounded="5px"
                                    h="fit-content"
                                    _hover={{ bg: "secondary_variant" }}
                                    onClick={() => navigate(`/leccion/${curso?.modulos[0]?.lecciones[0]?.slug}${addRedirect}`)}
                                    mt="auto"
                                >
                                    Comenzar
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                )}

            <Accordion
                allowMultiple
            >
                {filterModules(curso?.modulos)?.map((modulo: ModulosInt, index: number) => (
                    <AccordionItem
                        key={index}
                        border={index === 0 ? "none" : ""}
                    >
                        <AccordionButton
                            p="30px 20px"
                            display="flex"
                            justifyContent="space-between"
                            bg="purewhite"
                            gap="20px"
                        >

                            <Flex
                                alignItems="center"
                                gap="10px"
                                w="70%">
                                <AccordionIcon />
                                <Text
                                    color="font"
                                    fontSize="18px"
                                    fontWeight="600"
                                >
                                    {modulo?.nombre}
                                </Text>
                            </Flex>

                            <Flex
                                alignItems="center"
                                gap="10px"
                                w="50%"
                            >
                                <Text
                                    color="dark_grey"
                                    fontSize="16px"
                                    fontWeight="400"
                                    whiteSpace="nowrap"
                                >
                                    {modulo?.lecciones?.filter(leccion => leccion.tipo === 'entregable')?.length} {modulo?.lecciones?.filter(leccion => leccion.tipo === 'entregable')?.length > 1 ? 'ejercicios' : 'ejercicio'}
                                </Text>
                                <Text color="dark_grey">·</Text>
                                <Text
                                    color="dark_grey"
                                    fontSize="16px"
                                    fontWeight="400"
                                    whiteSpace="nowrap"
                                >
                                    {modulo?.lecciones[0]?.duracion}
                                </Text>

                                <Flex
                                    mx="10px"
                                    w="100%"
                                >
                                    <BarProgress
                                        height="6px"
                                        progress={validateProgressModule(cursoProgress, modulo)}
                                    />
                                </Flex>

                                <Text
                                    color="dark_grey"
                                    fontSize="16px"
                                    fontWeight="400"
                                    minW="50px"
                                >
                                    {validateProgressModule(cursoProgress, modulo)?.toFixed(0)} %
                                </Text>

                                <Flex gap="30px" boxSize="20px">
                                    {validateProgressModule(cursoProgress, modulo) > 99 && (
                                        <Icon
                                            color="#fff"
                                            bg="secondary"
                                            boxSize="20px"
                                            rounded="full"
                                            as={BiCheck}
                                        />
                                    )}
                                </Flex>
                            </Flex>
                        </AccordionButton>

                        <AccordionPanel
                            display="flex"
                            bg="purewhite"
                        >
                            <Flex
                                flexDirection="column"
                                w="100%"
                            >
                                {filterEntregables(modulo?.lecciones)?.map((leccion: LeccionInt, index: number) => (
                                    <Flex
                                        key={index}
                                        justifyContent="space-between"
                                        p="10px 20px"
                                        mb="10px"
                                        cursor="pointer"
                                        // _hover={{ bg: "secondary_variant", borderRadius: "10px" }}
                                        onClick={() => navigate(`/leccion/${leccion?.slug}${addRedirect}`)}
                                    >
                                        <Flex
                                            alignItems="center"
                                            gap="10px"
                                        >
                                            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                                                <Icon
                                                    color={
                                                        checkLessonComplete(leccion?.id)
                                                            ? "#8694FF"
                                                            : "font"
                                                    }
                                                    as={
                                                        BsPencilSquare
                                                    }
                                                    boxSize="20px"
                                                />
                                            }

                                            <Text
                                                color={
                                                    checkLessonStart(leccion?.id)
                                                        ? "secondary"
                                                        : "font"
                                                }
                                                fontSize="17px"
                                                fontWeight="500"
                                            >
                                                {leccion?.nombre}
                                            </Text>
                                        </Flex>

                                        {/* {<Flex
                                            justify="center"
                                        >
                                            <Text
                                                fontFamily="inter"
                                                p="10px 15px"
                                                rounded="full"
                                                fontSize="12px"
                                                fontWeight="500"
                                                textTransform="capitalize"
                                                color={
                                                    leccion?.entregables && leccion?.entregables[0]?.estado === EntregableEstadoEnum.ERROR ? 'fail'
                                                        : leccion?.entregables && leccion?.entregables[0]?.estado === EntregableEstadoEnum.CORRECTO ? 'success_variant'
                                                            : 'warning_variant'
                                                }
                                                bg={
                                                    leccion?.entregables && leccion?.entregables[0]?.estado === EntregableEstadoEnum.ERROR ? 'fail_bg'
                                                        : leccion?.entregables && leccion?.entregables[0]?.estado === EntregableEstadoEnum.CORRECTO ? 'success_bg'
                                                            : 'warning'
                                                }
                                            >
                                                {
                                                    leccion?.entregables && leccion?.entregables[0]?.estado === EntregableEstadoEnum.ERROR ? 'incorrecto'
                                                        : leccion?.entregables && leccion?.entregables[0]?.estado === EntregableEstadoEnum.CORRECTO ? 'correcto'
                                                            : "pendiente"
                                                }
                                            </Text>
                                        </Flex>
                                        } */}
                                    </Flex>
                                ))}
                            </Flex>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Flex>
    )
}