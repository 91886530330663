import { useEffect, useState } from 'react';

import { AiOutlineCloudSync } from 'react-icons/ai';
import { Flex, Input, Spinner, Icon, Box, FormLabel, useToast } from '@chakra-ui/react';

export interface InformationInputProps {
    name: string;
    label?: string;
    type?: string;
    noHead?: boolean;
    isDisabled?: boolean;
    defaultValue?: any;
    placeholder?: string;
    validator?: string;
    validatorDNI?: boolean;
    isInvalidMessage?: string;
    style?: React.CSSProperties;
    autoComplete?: 'on' | 'off';
    isRequired?: boolean;
    min?: number;
    max?: number;
    step?: number;
    inputStyles?: React.CSSProperties;
    updateValue: (e?: any) => any;
    onNullValue?: (e?: any) => any;
}

export const InformationInput = ({
    noHead,
    name,
    label,
    isDisabled = false,
    defaultValue,
    type = 'text',
    placeholder = '',
    min,
    max,
    step,
    validator,
    autoComplete,
    style,
    updateValue,
    onNullValue,
    validatorDNI = false,
    isRequired = false,
    isInvalidMessage = "El valor ingresado es incorrecto",
    inputStyles = {}
}: InformationInputProps) => {
    const toast = useToast();
    const [value, setValue] = useState<any>(defaultValue);
    const [isInvalid, setIsInvalid] = useState(false);

    const [timeoutkey, setTimeoutkey] = useState<any>();
    const [update, setUpdate] = useState<'idle' | 'editing' | 'loading'>('idle');

    useEffect(() => {
        if (timeoutkey) {
            clearTimeout(timeoutkey);
            setTimeoutkey(undefined);
        }

        defaultValue &&
            setValue(defaultValue);
    }, [defaultValue]);

    function onChange(event: any) {
        const value = event.target.value;
        if (update === 'idle') setUpdate('editing');

        setIsInvalid(false);
        setValue(value);
    }

    function onBlur() {
        setUpdate('loading');

        function treatValue(_value: string) {
            if ((!_value || _value === '') && onNullValue) return onNullValue();
            else return _value;
        }

        if (validator && !value?.startsWith(validator)) {
            setIsInvalid(true);
            setUpdate('idle');

            return;
        }

        updateValue({ [name]: treatValue(value) })
            .then((res: any) => setUpdate('idle'))
            .catch((error: any) => {
                console.error({ error });
                setUpdate('idle');
            });
    }

    return (
        <Flex style={style} w="100%" direction="column" gap="5px">
            {!noHead && (
                <Flex>
                    <FormLabel
                        color="#67748E"
                        fontSize="14px"
                        fontWeight="600"
                        letter-spacing="-0.387px"
                    >
                        {label}
                        {isRequired && <Box>*</Box>}

                        {update === 'editing' ? (
                            <Icon as={AiOutlineCloudSync} ml="2" boxSize="14px" />
                        ) : update === 'loading' ? (
                            <Spinner ml="2" boxSize="14px" />
                        ) : null}
                    </FormLabel>
                </Flex>
            )}

            <Flex direction="column" gap="5px">
                <Input
                    color="dark_blue"
                    fontSize="14px"
                    fontWeight="600"
                    border="1px solid"
                    borderColor="light_grey"
                    _focusVisible={{ border: "none", boxShadow: "none"}}
                    min={min}
                    max={max}
                    type={type}
                    step={step}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    isInvalid={isInvalid}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    isDisabled={isDisabled || update === 'loading'}
                    style={inputStyles}
                    _disabled={{ color: "dark_blue", cursor:"not-allowed" }}
                />

                {isInvalid && (
                    <Box color="crimson">
                        {isInvalidMessage}
                    </Box>
                )}
            </Flex>
        </Flex>
    );
};
