import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react"
import { PreguntaForoInt } from "../../../interfaces/ForoInt";
import { CustomAvatar } from "../../../shared/components/Avatar/CustomAvatar";
import { format, formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { truncate } from "lodash";
import { BiChat, BiCommentDetail } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

interface Props {
    pregunta: PreguntaForoInt | undefined;
}

export const CardGeneral = ({
    pregunta,
}: Props) => {
    const navigate = useNavigate();

    const handleClick = (temaId: string | undefined, preguntaId: string | undefined) => {
        if (temaId && preguntaId) {
            navigate(`/foro/${temaId}/pregunta/${preguntaId}`);
        }
    }
    // const imageUrlArray = pregunta?.contenido.split(`src="`) || undefined;
    // const imageURL = imageUrlArray ? imageUrlArray[1]?.split(`"></p`)[0] : undefined;

    
    return (
        <Flex
            direction="column"
            maxW="390px"
            bg="purewhite"
            p="30px"
            gap="20px"
            minH="300px"
            borderRadius="5px"
            rounded="5px"
            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
            cursor="pointer"
            onClick={() => handleClick(pregunta?.temaId, pregunta?.id)}
            _hover={{ boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)' }}
            transition=" 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
        >
            <Flex
                gap="12px"
            >
                <CustomAvatar
                    name={pregunta?.user?.username || ""}
                    src={pregunta?.user?.avatar?.url}
                    size="40px"
                    rounded="50px"
                />

                <Flex
                    direction="column"
                    gap="6px"
                >
                    <Text
                        color="font"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="500"
                        lineHeight="normal"
                    >
                        {pregunta?.user?.username}
                    </Text>

                    <Text
                        color="dark_grey"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="normal"
                    >
                        {pregunta?.createdAt && format(new Date(pregunta?.createdAt), "dd/MM/yyyy HH:mm")}
                    </Text>
                </Flex>
            </Flex>

        
        <Flex
        direction="column"
        gap="20px">

            <Text
                color="font"
                fontSize="21px"
                fontWeight="700"
                lineHeight="25.41px"
                pr="30px"
            >
                {pregunta?.titulo}
            </Text>

             {/* {imageURL &&

                <Flex maxW="100%" >
                    <Image
                        objectFit="cover"
                        minW="200px"
                        src={imageURL}

                    />
                </Flex>
            }
              // la imagen hace que crezcan todas las cards */}

                <MarkdownPreview
                    source={truncate(pregunta?.contenido, { length: 100 }) || '...'}
                    style={{
                        color: "#959596",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        background: "transparent",
                        fontFamily: "Inter",
                        lineHeight: "16.94px"
                        
                    }}
                />
        </Flex>

            <Flex
                alignItems="center"
                justifyContent="space-between"
                mt="auto"
            >
                <Flex
                    color="subtitle_font"
                    fontSize="10px"
                    fontWeight="400"
                    letterSpacing="0.5px"
                    gap="10px"
                >
                    <Text
                        p="5px 10px"
                        rounded="full"
                        bg="light_grey"
                        color="dark_grey"
                    >
                        {pregunta?.tipo}
                    </Text>
                </Flex>

                <Flex 
                pos="relative">
                    <Icon 
                    as={BiCommentDetail} 
                    color="secondary_variant_font" 
                    boxSize="23" />

                    <Text
                        color="purewhite"
                        fontSize="11px"
                        fontWeight="500"
                        letterSpacing="0.5px"
                        lineHeight="16px"
                        bg="fail"
                        rounded="50px"
                        px="4px"
                        pos="absolute"
                        top="-5px"
                        right="-5px"
                    >
                        {pregunta?.meta?.totalRespuestas || 0}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}