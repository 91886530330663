// React
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
// Components
import { RequireAuth } from "../RequireAuth/RequireAuth";
import { Signin } from "../../../pages/Login/Signin";
import { Aprendizaje } from "../../../pages/Aprendizaje/Aprendizaje";
import { Dashboard } from "../../../pages/Dashboard/Dashboard";
// Interface
import { isRoleAllowed } from "../../utils/functions/validateRol";
import { UserRolEnum } from "../../utils/Types/RolEnum";
import { InformationCurso } from "../../../pages/Aprendizaje/views/Information";
import { Lesson } from "../../../pages/Lessons/Lessons";
import { Entregables } from "../../../pages/Entregables/Entregables";
import { Perfil } from "../../../pages/Perfil/Perfil";
import { useAuthContex } from "../../context/user.context";
import { Mensajes } from "../../../pages/Messages/Mensajes";
import { Recovery } from "../../../pages/Recovery/Recovery";
import { Foro } from "../../../pages/Foro/Foro";
import { Notas } from "../../../pages/Notas/Notas";
import { Correcciones } from "../../../pages/Correcciones/Correcciones";
import { Matriculas } from "../../../pages/Matriculas/Matriculas";
import { Facturacion } from "../../../pages/Facturacion/Facturacion";
import { useUrl } from "../../context/url.context";
import { UrlTypeEnum } from "../../utils/Types/UrlTypeEnum";
import { Cuestionario } from "../../../pages/Cuestionario/Cuestionario";


export const RouterController = () => {
    const { user } = useAuthContex()
    const [search, setSearch] = useSearchParams();
    const url = useUrl();

    return (
        <Routes>
            <Route
                path='recovery/:hash'
                element={
                    !user?.auth
                        ? <Recovery />
                        : user?.role?.nombre === UserRolEnum.TUTOR
                            ? <Navigate to={"/correcciones"} />
                            : <Navigate to={"/dashboard"} />
                }
            />

            <Route
                path='login/*'
                element={
                    (search.has("loginById") && search.has("token"))
                        ? <Signin />
                        : !user?.auth
                            ? <Signin />
                            : user?.role?.nombre === UserRolEnum.TUTOR
                                ? <Navigate to={"/correcciones"} />
                                : <Navigate to={"/dashboard"} />
                }
            />

            <Route
                path='*'
                element={
                    !user?.auth
                        ? <Navigate to={"/login"} />
                        : user?.role?.nombre === UserRolEnum.TUTOR
                            ? <Navigate to={"/correcciones"} />
                            : <Navigate to={"/dashboard"} />
                }
            />

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre) && url !== UrlTypeEnum.PLATAFORMA
                    }
                    />
                }
            >
                <Route path='dashboard/*' element={<Dashboard />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='perfil/*' element={<Perfil />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='aprendizaje/*' element={<Aprendizaje />} />
                <Route path='aprendizaje/:slug' element={<InformationCurso />} />
                <Route path='leccion/:slug' element={<Lesson />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='entregables/*' element={<Entregables />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                        ], user?.role?.nombre)  && url !== UrlTypeEnum.PLATAFORMA}
                    />
                }
            >
                <Route path='facturacion/*' element={<Facturacion />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                        ], user?.role?.nombre)  && url !== UrlTypeEnum.PLATAFORMA}
                    />
                }
            >
                <Route path='matriculas/*' element={<Matriculas />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                        ], user?.role?.nombre)  && url !== UrlTypeEnum.PLATAFORMA}
                    />
                }
            >
                <Route path='correcciones/*' element={<Correcciones />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)  && url !== UrlTypeEnum.PLATAFORMA}
                    />
                }
            >
                <Route path='mensajeria/*' element={<Mensajes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)  && url !== UrlTypeEnum.PLATAFORMA}
                    />
                }
            >

                <Route path='foro/*' element={<Foro />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >

                <Route path='notas/*' element={<Notas />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >

                <Route path='cuestionario/*' element={<Cuestionario />} />
            </Route>
        </Routes>
    );
};