import { Avatar, Badge, Box, Flex, Icon, Text, flexbox } from "@chakra-ui/react";
import { DataTable } from "primereact/datatable";
import { EntregablesInt } from "../../../interfaces/Entregables";
import { useNavigate } from "react-router-dom";
import { Column } from "primereact/column";
import { EntregableEstadoEnum } from "../../utils/Types/EntregableEstadoEnum";
import { formatDate } from "../../utils/functions/formatDate";
import MarkdownPreview from "@uiw/react-markdown-preview"
import { HiOutlineAnnotation, HiOutlineCheck, HiOutlineX } from "react-icons/hi";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { EstadoTypeEnum } from "../../utils/Types/EstadoTypeEnum";


interface Props {
    data: EntregablesInt[];
}

export const CardEntregable = ({ data }: Props) => {
    const navigate = useNavigate();

    const handleRowClick = (e: any) => {
        const { data } = e;
        const grupoSlug = data?.matricula?.grupo?.slug

        data?.matricula?.estado === EstadoTypeEnum.ACTIVO
            ? navigate(`/leccion/${data?.leccion?.slug}?grupo=${grupoSlug}`)
            : null
    };

    const contenidoBodyTemplate = (entregables: EntregablesInt) => {

        return (
            <Text
                fontFamily="inter"
                color="font"
                fontSize="14px"
                fontWeight="400"
                fontStyle="normal"
                lineHeight="150%"
            >
                {entregables?.leccion?.nombre}
            </Text>
        );
    };

    const tutorBodyTemplate = (entregables: EntregablesInt) => {
        return (
            entregables?.matricula?.tutor?.avatar?.url ?
                <Flex
                    align="center"
                    justify="center"
                >
                    <Avatar boxSize="24px" name={entregables?.matricula?.tutor?.avatar?.nombre} src={entregables?.matricula?.tutor?.avatar?.url} />
                </Flex>
                :
                <Flex
                    justify="center"
                >
                    <Text
                        fontFamily="inter"
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                        fontStyle="normal"
                        lineHeight="150%"
                        textAlign="center"
                    >
                        {entregables?.matricula?.tutor?.nombre}
                    </Text>
                </Flex>
        )
    }

    const estadoBodyTemplate = (entregables: EntregablesInt) => {
        return (
            <Flex
                justify="center"
            >
                <Text
                    fontFamily="inter"
                    p="10px 15px"
                    rounded="full"
                    fontSize="12px"
                    fontWeight="500"
                    textTransform="capitalize"
                    color={
                        entregables?.estado === EntregableEstadoEnum.ERROR ? 'fail'
                            : entregables?.estado === EntregableEstadoEnum.CORRECTO ? 'success_variant'
                                : 'warning_variant'
                    }
                    bg={
                        entregables?.estado === EntregableEstadoEnum.ERROR ? 'fail_bg'
                            : entregables?.estado === EntregableEstadoEnum.CORRECTO ? 'success_bg'
                                : 'warning'
                    }
                >
                    {
                        entregables?.estado === EntregableEstadoEnum.ERROR ? 'incorrecta'
                            : entregables?.estado === EntregableEstadoEnum.CORRECTO ? 'correcto'
                                : "pendiente"
                    }
                </Text>
            </Flex>
        );
    }

    const observacionesBodyTemplate = (entregables: EntregablesInt) => {
        return (
            <Flex
                justify="center"
                align="center"
                gap="10px"
            >
                <Icon
                    as={HiOutlineAnnotation}
                    boxSize="16px"
                    color="secondary"
                />
                <MarkdownPreview
                    source={entregables?.correccion?.texto ? 'Ver comentarios' : 'Aun no tienes observaciones'}
                    style={{
                        backgroundColor: "#fff",
                        fontFamily: "Inter",
                        color: "#252F40",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "150%",

                    }}
                />
            </Flex>
        )
    };

    const puntuacionBodyTemplate = (entregables: EntregablesInt) => {
        return (
            entregables?.correccion?.puntuacion ?
                <Flex
                    textAlign="center"
                    justify="center"
                    align="center"
                    rounded="5px"
                    fontFamily="inter"
                    fontSize="14px"
                    fontWeight="400"
                    gap="3px"
                    color="dark_grey"
                >
                    <Icon
                        as={entregables?.estado === EntregableEstadoEnum.CORRECTO ? HiOutlineCheck
                            : entregables?.estado === EntregableEstadoEnum.ERROR ? HiOutlineX
                                : ""
                        }
                        color={entregables?.estado === EntregableEstadoEnum.CORRECTO ? "success"
                            : entregables?.estado === EntregableEstadoEnum.ERROR ? "fail"
                                : ""}


                    />
                    {entregables?.correccion?.puntuacion ? `${entregables?.correccion?.puntuacion}/100` : "0/100"}
                </Flex>
                :
                <Text
                    fontFamily="inter"
                    fontSize="12"
                    fontWeight="700"
                    lineHeight="150%"
                    textAlign="center"
                >
                    ---
                </Text>
        )
    };

    const fechaBodyTemplate = (entregables: EntregablesInt) => {
        return (
            <Flex
                justify="center"
            >
                <Text
                    paddingX="15px"
                    py="5px"
                    borderRadius="50px"
                    bgColor="bg_color"
                    fontFamily="inter"
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                    fontStyle="normal"
                    lineHeight="150%"
                >
                    {format(new Date(entregables?.fechaEntrega), "dd MMM yyyy", { locale: es }).toUpperCase()}

                </Text>
            </Flex>

        )
    };

    const columns = [

        <Column
            className="customFirst"
            key="leccion"
            field="leccion"
            header="Tarea"
            body={contenidoBodyTemplate}
        />,
        <Column
            key="tutor"
            field="Tutor"
            header="Tutor"
            body={tutorBodyTemplate}
        />,
        <Column
            key="estado"
            field="estado"
            header="Estado"
            body={estadoBodyTemplate}
        />,
        <Column
            key="observaciones"
            field="observaciones"
            header="Observaciones"
            body={observacionesBodyTemplate}
        />,
        <Column
            key="puntuacion"
            field="puntuacion"
            header="Nota media"
            body={puntuacionBodyTemplate}
        />,
        <Column
            key="fechaEntrega"
            field="Fecha Entrega"
            header="Fecha Entrega"
            body={(rowData) => fechaBodyTemplate(rowData)}
            style={{ minWidth: "200px", textAlign: "center" }}
        />,
    ]

    return (
        <Flex
            w="100%"
            bg="purewhite"
            h="fit-content"
            gap="30px"
            rounded="15px"
            direction="column"
        >
            <DataTable
                value={data}
                rows={10}
                className="custom"
                onRowClick={handleRowClick}
            >
                {columns}
            </DataTable>
        </Flex>
    );
}