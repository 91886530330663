import { Button, Flex, Icon } from "@chakra-ui/react";
import { ProgressCourse } from "../components/ProgressCourse";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useProgressContex } from "../../../context/progress.context";
import { BiLeftArrowAlt } from "react-icons/bi";

export const TopbarLeccion = () => {
    const { cursoProgress } = useProgressContex();
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const url = search.has("grupo") ? `/aprendizaje/${cursoProgress?.cursoSlug}?grupo=${search.get("grupo")}` : `/aprendizaje/${cursoProgress?.cursoSlug}`

    return (
        <Flex
            alignItems="center"
            w="100%"
            p="30px 70px"
            gap="40px"
        >
            <Flex>
                <Button
                    colorScheme="blackAlpha"
                    display="flex"
                    alignItems="center"
                    borderRadius="5px"
                    bg="secondary"
                    color="purewhite"
                    p="10px"
                    h="auto"
                    fontSize="13px"
                    fontWeight="500"
                    lineHeight="16px"
                    letterSpacing="-0.078px"
                    leftIcon={<Icon as={BiLeftArrowAlt} boxSize="16px" />}
                    _hover={{ bg: "secondary_variant" }}
                    onClick={() => navigate(url)}
                >
                    Salir de la lección
                </Button>
            </Flex>

            <Flex >
                <ProgressCourse 
                    cursoProgress={cursoProgress} 
                    url={url}
                />
            </Flex>
        </Flex>
    )
}