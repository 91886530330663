import { Flex, Text } from "@chakra-ui/react";
import { formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar";

interface Props {
    mensaje?: any;
    onClick?: any;
}

export const CardMensajes = ({ mensaje, onClick }: Props) => {

    return (
        <Flex
            onClick={onClick}
            bg="purewhite"
            borderRadius="10px"
            w="100%"
        >
            <Flex
                w="100%"
                borderRadius="10px"
                bg="purewhite"
                borderWidth="1px"
                borderColor="grey"
                p="20px"
                justifyContent="space-between"
                boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.40);"
                _hover={{ backgroundColor: "main_variant" }}
                cursor="pointer"
            >
                <Flex
                    w="100%"
                    justifyContent="space-between"
                >
                    <Flex
                        direction="column"
                        gap="10px"
                    >

                        <Flex
                            alignItems="center"
                            gap="6px"
                        >
                            <Flex>
                                <CustomAvatar src={""} name="Agustin Alonso" size="20px" rounded="50px" />
                            </Flex>

                            <Text
                                color="font"
                                fontSize="16px"
                                fontWeight="500"
                                letterSpacing="-0.15px"
                            >
                                {mensaje?.emisor?.nombre}
                            </Text>
                        </Flex>

                        <Text
                            color="secondary_variant_font"
                            fontSize="14px"
                            fontWeight="400"
                            textTransform="capitalize"
                        >
                            {mensaje?.asunto}
                        </Text>
                    </Flex>

                    <Text
                        color="dark_grey"
                        fontSize="12px"
                        fontWeight="400"
                        gap="5px"
                        textTransform="capitalize"
                    >
                        {mensaje?.createdAt && formatDistance(new Date(mensaje?.createdAt), new Date(), { locale: es })}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
}