import { Flex, Skeleton } from "@chakra-ui/react";

export const SkeletonChats = () => {

    return (
        <Flex direction="column" borderRight="1px solid" borderColor=" light_grey" w="25%">
            <Flex
                direction="column"
                h="100px"
                borderBottom="1px solid"
                borderColor="light_grey"
                p="12px 15px"
                gap="8px"
            >
                <Flex alignItems="center" justifyContent="space-between">
                    <Skeleton
                        h="20px"
                        w="40px"
                        rounded="5px"
                    />

                    <Skeleton
                        h="30px"
                        w="120px"
                        rounded="5px"
                    />
                </Flex>

                <Flex mb="5px">
                    <Skeleton
                        h="40px"
                        w="100%"
                        rounded="5px"
                    />
                </Flex>
            </Flex>

            <Flex direction="column" overflow="auto" gap="5px" p="5px" h="100%">
                {[0, 1, 2, 3, 4, 5, 6].map((s: any) => (
                    <Skeleton
                        key={s}
                        h="16%"
                        rounded="5px"
                    />
                ))}
            </Flex>
        </Flex>
    );
};