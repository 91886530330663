import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box, Text, Flex, Button, useToast } from "@chakra-ui/react";
import { notify, StatusEnum } from "../../../utils/functions/notify";
import { updateReadNotification } from "../../../middlewares/notification.middleware";
import { formatDate } from "../../../utils/functions/formatDate";

interface Props {
    notificaciones: any;
    setRefreshNotification: (action: boolean) => void;
}

export const AccordeonInbox = (props: Props) => {
    const { notificaciones, setRefreshNotification } = props;
    const toast = useToast();

    const readNotification = (id: string) => {
        updateReadNotification(id)
            .then(() => {
                notify(toast, StatusEnum.success, "Notificacion leída!")
                setRefreshNotification(true)
            })
    }

    return (
        <Accordion defaultIndex={[0]} allowMultiple w={"100%"} style={{ overflow: "auto" }} className="scroll-bar">
            <AccordionItem>
                <AccordionButton>
                    <Text as="span" flex='1' textAlign='left' fontWeight={"semibold"} fontSize="20px">
                        No leídos
                    </Text>
                    <AccordionIcon />
                </AccordionButton>

                {notificaciones?.map((notificacion: any, index: number) => (
                    !notificacion?.vista &&
                    <AccordionPanel pb={index === notificaciones?.length - 1 ? "10px" : 0} key={index}>
                        <Button
                            display="flex"
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            gap="20px"
                            p="10px"
                            h="100%"
                            bg="inherit"
                            fontWeight="normal"
                            whiteSpace={"inherit"}
                            _hover={{ bg: "rgba(53, 252, 3, .2)", p: "10px" }}
                            _active={{ transform: "scale(0.9)" }}
                            onClick={() => readNotification(notificacion?.id)}
                        >
                            <Flex flexDirection="column">
                                <Text as="span" flex='1' textAlign='left' fontSize="15px" fontWeight="semibold" mb="3px">
                                    {notificacion?.asunto}
                                </Text>

                                <Text as="span" flex='1' textAlign='left' fontSize="13px">
                                    {notificacion?.mensaje}
                                </Text>
                            </Flex>

                            <Flex>
                                <Text as="span" flex='1' textAlign='left' fontSize="13px">
                                    {formatDate(notificacion?.createdAt)}
                                </Text>
                            </Flex>
                        </Button>
                    </AccordionPanel>
                ))}
            </AccordionItem>

            <AccordionItem>
                <AccordionButton>
                    <Text as="span" flex='1' textAlign='left' fontWeight={"semibold"} fontSize="20px">
                        Importantes
                    </Text>
                    <AccordionIcon />
                </AccordionButton>

                {notificaciones?.map((notificacion: any, index: number) => (
                    (notificacion?.vista && notificacion?.importante) &&
                    <AccordionPanel pb={index === notificaciones?.length - 1 ? "10px" : 0} key={index}>
                        <Flex justifyContent="space-between" alignItems="center" gap="20px" p="10px" whiteSpace={"inherit"}>
                            <Flex flexDirection="column">
                                <Text as="span" flex='1' textAlign='left' fontSize="15px" fontWeight="semibold" mb="3px">
                                    {notificacion?.asunto}
                                </Text>

                                <Text as="span" flex='1' textAlign='left' fontSize="13px">
                                    {notificacion?.mensaje}
                                </Text>
                            </Flex>

                            <Flex>

                                <Text as="span" flex='1' textAlign='left' fontSize="13px">
                                    {formatDate(notificacion?.createdAt)}
                                </Text>
                            </Flex>
                        </Flex>
                    </AccordionPanel>
                ))}
            </AccordionItem>

            <AccordionItem>
                <AccordionButton>
                    <Text as="span" flex='1' textAlign='left' fontWeight={"semibold"} fontSize="20px">
                        Todos
                    </Text>
                    <AccordionIcon />
                </AccordionButton>

                {notificaciones?.map((notificacion: any, index: number) => (
                    (notificacion?.vista && !notificacion?.importante) &&
                    <AccordionPanel pb={index === notificaciones?.length - 1 ? "10px" : 0} key={index}>
                        <Flex justifyContent="space-between" alignItems="center" gap="20px" p="10px" whiteSpace={"inherit"}>
                            <Flex flexDirection="column">
                                <Text as="span" flex='1' textAlign='left' fontSize="15px" fontWeight="semibold" mb="3px">
                                    {notificacion?.asunto}
                                </Text>

                                <Text as="span" flex='1' textAlign='left' fontSize="13px">
                                    {notificacion?.mensaje}
                                </Text>
                            </Flex>

                            <Flex>

                                <Text as="span" flex='1' textAlign='left' fontSize="13px">
                                    {formatDate(notificacion?.createdAt)}
                                </Text>
                            </Flex>
                        </Flex>
                    </AccordionPanel>
                ))}
            </AccordionItem>
        </Accordion>
    );
} 