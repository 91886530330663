import { format } from "date-fns";
import _axios from "../services/http.service";
import useSWR from "swr";
import tenantInstance from "../services/tenantInstance.service";
import { UserInt } from "../../interfaces/UserInt";
import { UserRolEnum } from "../utils/Types/RolEnum";

const { tenant, client } = await tenantInstance();

const ENDPOINT_MATRICULAS = "matriculas"
const ENDPOINT_MATRICULAS_ALUMNOS = "matriculasAlumno"
const ENDPOINT_MATRICULAS_PROXIMAS = "proximasMatriculasAlumno"
const ENDPOINT_MATRICULAS_TUTOR = "matriculasTutor"

// Middlewares GET y Hooks SWR => EMPRESAS
export const getMatriculas = async (endpoint: string) => await _axios.get(endpoint).then((response: any) => response)

export const useMatriculasTutor = (query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_MATRICULAS_TUTOR + query;
    const { isLoading, data, mutate, error } = useSWR(endpoint, getMatriculas);

    return {
        matriculas: data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

export const useMatriculasAlumno = ({
    user,
    query = ""
}: {
    user: UserInt | null;
    query?: string;
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_MATRICULAS_ALUMNOS + query;
    const { isLoading, data, mutate, error } = useSWR(
        (user?.auth && user?.role?.nombre === UserRolEnum.ALUMNO) ? endpoint : null,
        getMatriculas
    );

    return {
        matriculas: data?.data?.data,
        campusTutorizado: data?.data?.campusTutorizado,
        campusFUNDAE: data?.data?.campusFUNDAE,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

export const useMatriculasProximas = (query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_MATRICULAS_PROXIMAS + query;
    const { isLoading, data, mutate, error } = useSWR(endpoint, getMatriculas);

    return {
        matriculas: data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

export const useMatriculaId = (id: string | undefined, query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_MATRICULAS + `/${id}${query}`
    const { isLoading, data, mutate } = useSWR(endpoint, getMatriculas);

    return {
        matricula: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

// TODO: Middlewares POST y PUT, pendientes de refactorizar con mutate SWR
export const addMatriculaToGrupo = async (
    data: {
        userId: string;
        grupoId: string;
        tutorId: string;
    }
) => {

    return await _axios.post(
        `${tenant}/${client}/matriculas`,
        data,
        {}
    ).then((response: any) => response);
};

export const addCuestionarioMatricula = async ({
    id,
    data
}: {
    id: string;
    data: any;
}) => {

    return await _axios.put(
        `${tenant}/${client}/matriculas/${id}`,
        { cuestionario: data },
        {}
    ).then((response: any) => response);
};

export const removeMatricula = async (id: string) => {

    return await _axios.put(
        `${tenant}/${client}/matriculas/${id}`,
        {
            fechaBaja: format(new Date(), 'yyy-MM-dd')
        },
        {}
    ).then((response: any) => response);
};