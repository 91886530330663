import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import AsyncSelect from 'react-select/async';
import { filterSelectStyles } from '../../../ui/Select/filterSelectStyles';

interface Props {
    value: any;
    loadOptions: any;
    defaultValue?: any;
    onChange: (e: any) => void;
    placeholder?: string;
    isMulti?: boolean;
    isClearable?: boolean;
}

export const FilterAsyncSelect = ({
  value,
  isMulti,
  onChange,
  defaultValue = null,
  loadOptions,
  isClearable = true,
  placeholder = 'Escribe para buscar',
}: Props) => {
  const [_value, setValue] = useState(value);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if(defaultValue)
      if (isMulti) setValue(defaultValue);
      else setValue({ label: defaultValue?.label, value: defaultValue?.value });

}, [defaultValue]);

  useEffect(() => {
    if (value !== undefined) setValue(value);
  }, [value]);

  const handleChange = (e: any) => {
    setValue(e);
    onChange(e);
  };

  
  const _loadOptions = useCallback(
    debounce((inputValue, callback) => {
      loadOptions(inputValue).then((options: any) => callback(options));
    }, 100),
    []
  );

  return (
    <AsyncSelect
      value={_value}
      isMulti={isMulti}
      onChange={handleChange}
      placeholder={placeholder}
      isClearable={isClearable}
      defaultOptions
      loadOptions={_loadOptions}
      styles={filterSelectStyles}
      closeMenuOnSelect={!isMulti}
      onInputChange={setInputValue}
      noOptionsMessage={() => (inputValue !== '' ? 'No se encuentran resultados' : 'Escribe para mostrar opciones...')}
    />
  );
};