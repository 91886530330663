import { As, Button, Flex, Grid, Icon, Skeleton, Text } from "@chakra-ui/react"
import { PreguntaForoInt, TemaForoInt } from "../../../interfaces/ForoInt";
import { BsArrowUpRight, BsClock } from "react-icons/bs";
import { usePreguntasQuery, useTemaId } from "../../../shared/middlewares/foro.middlewate";
import { CardGeneral } from "../Components/CardGeneral";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../shared/components/Customs/CardSinInformacion";
import { GrupoInt, MatriculasInt } from "../../../interfaces/MatriculasInt";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContex } from "../../../shared/context/user.context";
import { UserRolEnum } from "../../../shared/utils/Types/RolEnum";
import foroVacio from "../../../assets/img/foroVacio.png"
import { SkeletonTema } from "../Components/utils/SkeletonTema";
import { EstadoTypeEnum } from "../../../shared/utils/Types/EstadoTypeEnum";

interface TagList {
    label: string,
    icon: As,
    query: string
}
interface Props {
    grupos: GrupoInt[];
    temas: TemaForoInt[];
    matriculas: MatriculasInt[];
    setSelectedGrupo: (grupo: GrupoInt) => void;
    selectedGrupo: GrupoInt | undefined;
    matricula: MatriculasInt | undefined;
    onOpen: () => void;
    cursoId?: string | undefined;
}

export const Tema = ({ selectedGrupo, matricula, cursoId }: Props) => {
    const { temaId } = useParams();
    const { user } = useAuthContex();
    const [query, setQuery] = useState<string>("?sortBy=createdAt&order=desc")
    const { tema, loading } = useTemaId({
        id: temaId,
        query: query,
        ignoreRequest: user?.role?.nombre === UserRolEnum.ALUMNO ? (!matricula?.id ? true : false) : false,
        track: user?.role?.nombre === UserRolEnum.ALUMNO ?
            {
                track: user?.tracking ? true : false,
                matricula: matricula?.estado === EstadoTypeEnum.ACTIVO ? matricula?.id : undefined,
            } : undefined
    });
    const { preguntas } = usePreguntasQuery({
        query:
            cursoId
                ? `?curso=${cursoId}&sortBy=createdAt&order=desc&limit=1`
                : undefined
    })

    const TagList: TagList[] = [
        {
            label: "Nuevo",
            icon: BsClock,
            query: "?sortBy=createdAt&order=desc"
        },
        {
            label: "Top",
            icon: BsArrowUpRight,
            query: "?sortBy=totalRespuestas&order=desc"
        }
    ]

    return (
        loading ? <SkeletonTema/>
        :
        <Flex
            direction="column"
            w="100%"
        >

            {selectedGrupo &&
                <Text
                    color="font"
                    fontSize="28px"
                    fontWeight="700"
                    textTransform="capitalize"
                >
                    {selectedGrupo?.curso?.nombre}
                </Text>}
                
            {selectedGrupo &&
                <Text
                    fontSize="18px"
                    fontWeight="500"
                >
                    {tema?.titulo}
                </Text>
            }


            <Flex
                gap="10px"
                alignItems="center"
                my="30px"
            >


                {TagList?.map((tag: any, index: number) => (
                    <Flex
                        key={index}
                        alignItems="center"
                        gap="5px"
                        p="5px 10px"
                        rounded="100px"
                        bg={query === tag?.query ? "secondary" : "purewhite"}
                        cursor="pointer"
                        onClick={() => setQuery(tag?.query)}
                    >
                        <Icon
                            as={tag?.icon}
                            boxSize="16px"
                            color={query === tag?.query ? "purewhite" : "secondary"}
                        />

                        <Text
                            color={query === tag?.query ? "purewhite" : "secondary"}
                            fontSize="16px"
                            fontWeight="400"
                            lineHeight="19.36px"
                            textTransform="capitalize"
                        >
                            {tag.label}
                        </Text>
                    </Flex>
                ))}
            </Flex>


            {tema?.preguntas?.length === 0 ?
                <Flex
                    h="85vh"
                >
                    <CardSinInformacion
                        type={TypeCardSinInformacion.FORO}
                    />
                </Flex>
                :
                <Grid
                    gap="40px"
                    gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
                >
                    {tema?.preguntas?.map((pregunta: PreguntaForoInt, index: number) => (
                        <CardGeneral pregunta={pregunta} key={index} />
                    ))}
                </Grid>
            }

        </Flex>

    )
}