import { EstadoMensaje } from './../utils/Types/MensajeriaEnum';
import { ChatInt, MensajesInt, UpdateChatInt } from "../../interfaces/MessagesInt";
import _axios, { TRACK_HEADERS } from "../services/http.service";
import useSWR from "swr";
import tenantInstance from "../services/tenantInstance.service";

const { tenant, client } = await tenantInstance();

const ENDPOINT_MENSAJERIA = "mensajeria/conversaciones"

// Middlewares y Hooks generales y SWR => MENSAJERIA
export const getMensajes = async (
    endpoint: string, 
    track: { 
        track: boolean, 
        matricula: string | undefined 
    } = {
            track: false,
            matricula: undefined
        }
) => 
await _axios.get(
    endpoint, 
    (track?.track && track?.matricula !== undefined) 
    ? { headers: { "track": true, "matricula": track?.matricula }}  
    : {}
)
.then((response: any) => response);

export const useMensajes = ({ 
    query = ""
} : { 
    query?: string; 
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_MENSAJERIA + query
    const { isLoading, data, mutate, error } = useSWR(endpoint, getMensajes);
        
    return {
        mensajes: data?.data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

export const useMensajeId = ({ 
    id, 
    ignoreRequest = false,
    track = {track: false, matricula: undefined} 
} : { 
    id: string | undefined, 
    ignoreRequest?: boolean;
    track?: { track: boolean, matricula: string | undefined } 
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_MENSAJERIA + `/${id}`
    const { isLoading, data, mutate } = useSWR((id && !ignoreRequest) ? [endpoint, track] : null, ([url, track]) => getMensajes(url, track));
        
    return {
        mensaje: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

//TODO: METODOS POST => FALTA REALIZAR MUTACION SWR 
export const createChat = async (newChat: ChatInt) => {
    
    return await _axios.post(
        `${tenant}/${client}/mensajeria/conversaciones`,
        newChat,
        {}
    );
};

export const updateChat = async ({idChat, updateChat}: {idChat: string, updateChat: UpdateChatInt}) => {
    
    return await _axios.put(
        `${tenant}/${client}/mensajeria/conversaciones/${idChat}`,
        updateChat,
        {}
    ).then((response: any) => response);
};

export const createMessage = async (newMessage: MensajesInt) => {
    
    return await _axios.post(
        `${tenant}/${client}/mensajeria/mensajes`,
        newMessage,
        {}
    ).then((response: any) => response);
};

export const readMessage = async (id: string) => {
    
    return await _axios.put(
        `${tenant}/${client}/mensajeria/mensajes/${id}`,
        {
            estado: EstadoMensaje.LEIDO
        },
        {}
    ).then((response: any) => response);
};