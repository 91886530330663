import { Flex, Text } from '@chakra-ui/react'

interface Props {
    label: string;
    value: string;
}
export default function CardProgreso({ label, value }: Props) {
    
    return (
        <Flex
            direction="column"
            gap="5px"
            boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
            p="9px 15px"
            alignItems="center"
            minW="110px"
            rounded="5px"
        >
            <Text
                color="font"
                fontSize="16px"
                fontWeight="700"
            >
                {value}
            </Text>
            <Text
                color="dark_grey"
                fontSize="14px"
                fontWeight="400"
                whiteSpace="nowrap"
            >
                {label}
            </Text>
        </Flex>
    )
}
