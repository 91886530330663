import { Box } from '@chakra-ui/react';
import { useAuthContex } from '../../shared/context/user.context';
import { AsignacionesTable } from './views/AsignacionesTable';
import { MatriculasTable } from './views/MatriculasTable';

export const Matriculas = () => {
    const { user } = useAuthContex();

    return (
        <Box
            w="100%"
        >
            {
                !user?.tutorFreelance
                ? <MatriculasTable />
                : <AsignacionesTable />
            }
        </Box>
    );
}; 