import { Box, Button, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { LeccionInt } from "../../../interfaces/CoursesInt";
import { BiEditAlt, BiPencil, BiTime } from "react-icons/bi";
import { useSessionTimeContext } from "../../../shared/context/sessionTime.context";
import NotasModal from "../Components/NotasModal";
import { useEffect, useState } from "react";
import { useOutWindowContext } from "../../../shared/context/outWindow.context";
import { useAuthContex } from "../../../shared/context/user.context";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../shared/utils/Types/RolEnum";

interface Props {
    leccion: LeccionInt | null;
}

export const RecursoLesson = ({ leccion }: Props) => {
    const { user } = useAuthContex();
    const { outWindow } = useOutWindowContext();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const secondsToTime = (value: number) => {
        let hour: number | string;
        let minute: number | string;
        let seconds: number | string;

        hour = Math.floor(value / 3600);
        hour = (hour < 10) ? `0${hour}` : hour;

        minute = Math.floor((value / 60) % 60);
        minute = (minute < 10) ? `0${minute}` : minute;

        seconds = value % 60;
        seconds = (seconds < 10) ? `0${seconds}` : seconds;

        return `${hour}:${minute}:${seconds}`
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        if (outWindow)
            exitFullScreen();
    }, [outWindow])

    return (
        <Flex
            direction="column"
            boxSize="100%"
            gap="40px"
        >
            <Flex
                p="30px"
                justifyContent="space-between"
                alignItems="center"
                rounded="5px"
                bg="purewhite"
            >
                <Flex
                    gap="25px"
                    alignItems="center"
                >
                    <Text
                        color="font"
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="140%"
                    >
                        {leccion?.nombre}
                    </Text>
                </Flex>

                {isRoleAllowed([UserRolEnum.ALUMNO, UserRolEnum.ADMIN], user?.role?.nombre) &&
                    <Button
                        position="relative"
                        bg="purewhite"
                        _hover={{ bg: "secondary_variant" }}
                        onClick={openModal}
                        color="secondary"
                        borderWidth="1px"
                        borderColor="secondary"
                        fontSize="15px"
                        fontWeight="500"
                        boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.04)"
                        rightIcon={<BiPencil fontSize="22px" />}
                    >
                        Hacer anotación
                    </Button>
                }

                <NotasModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    leccionId={leccion?.id}
                />
            </Flex>

            <Flex
                p="30px"
                direction="column"
                rounded="5px"
                gap="20px"
                bg="purewhite"
            >
                <Text
                    color="font"
                    fontSize="17px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="140%"
                >
                    Aquí encontrarás los recursos necesarios para las lecciones o el curso. Por favor, haz clic en el botón de abajo para descargarlos:
                </Text>

                <Button
                    onClick={() => window.open(leccion?.contenido, "_blank")}
                    w="fit-content"
                    color="purewhite"
                    bg="secondary"
                    _hover={{ bg: "secondary_variant" }}
                    fontSize="14px"
                    fontWeight="500"
                    rounded="5px"
                    p="10px 25px"
                >
                    Descargar
                </Button>
            </Flex>
        </Flex>
    );
};