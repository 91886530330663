import { Flex, Icon, Progress, Text, Tooltip } from "@chakra-ui/react"
import { truncate } from "lodash";
import { BiFile } from "react-icons/bi"
import { BsFileImage } from "react-icons/bs";

interface Props {
    files: any[];
    isUploading?: boolean;
}

export const FileLoad = ({ files, isUploading }: Props) => {
    return (
        <>
            {files?.map((file: File, index: number) => (
                <Flex
                    w="100%"
                    alignItems="start"
                    key={index}
                    p="20px"
                    borderRadius="5px"
                    border="1px solid grey"
                    gap="10px"
                    direction="column"
                >
                    <Icon color="main" boxSize="25px" as={BsFileImage} />


                    <Tooltip label={file.name} hasArrow>
                    <Text
                        color="font"
                        fontSize="12px"
                        fontWeight="700"
                        lineHeight="96.9%"
                    >
                        {truncate(file.name, {length: 20})}
                    </Text>
                    </Tooltip>


                    <Text
                        color="secondary_variant_font"
                        fontSize="12px"
                        fontWeight="700"
                        lineHeight="96.9%"
                    >
                        {(file.size / 1000)?.toFixed(0)} KB
                    </Text>

                    {isUploading &&
                        <Progress sx={{ '& > div': { background: "main" } }} rounded="20px" w="100%" isIndeterminate h="8px" />
                    }
                </Flex>
            ))}
        </>
    )
}