import { Badge, Flex, Icon, Text, Tooltip } from "@chakra-ui/react"
import { NotasInt } from "../../../interfaces/NotasInt"
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { truncate } from "lodash";
import { BiTime } from "react-icons/bi";
interface Props {
    nota: NotasInt;
    notaId: string | undefined;
    setRefresh: () => void;
}

export const CardNotas = ({ nota, notaId, setRefresh }: Props) => {
    const navigate = useNavigate();

    const handleClick = (id: any) => {
        if (!notaId) {
            setRefresh()
            navigate(`/notas/${id}`)

            return
        }

        if (notaId == id) return null
        else {
            setRefresh()
            navigate(`/notas/${id}`)
        }
    }


    return (
        <Flex
            direction="column"
            p="30px"
            bg="purewhite"
            borderLeft={notaId == nota?.id ? "3px solid" : "2px solid"}
            borderLeftColor={notaId == nota?.id ? "main" : "purewhite"}
            gap="16px"
            rounded="5px"
            cursor="pointer"
            onClick={() => handleClick(nota?.id)}
            minW="330px"
            transition="all 0.3s ease"
            _hover={{boxShadow: "0px 4px 15px 0px rgba(199, 205, 255, 0.60)"}}
        >
            <Tooltip placement="right" label={nota?.titulo} hasArrow>
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="600"
                >
                    {truncate(nota?.titulo, { length: 25, omission: ".." })}
                </Text>
            </Tooltip>

            {/* <Text
                color="secondary_variant_font"
                fontSize="16px"
                fontWeight="400"
            >
                {nota?.leccion?.nombre}
            </Text> */}

            <Flex
                justifyContent="space-between"
            >
                <Flex
                    bg="secondary_variant"
                    p="5px 10px"
                    rounded="full"
                >
                    <Text
                        fontSize="12px"
                        fontWeight="400"
                        color="font"
                    >
                        {nota?.leccion?.nombre}
                    </Text>
                </Flex>

                <Flex
                    alignItems="center"
                    gap="5px"
                    color="dark_grey"
                >
                    <Icon boxSize="16px" as={BiTime} />

                    <Text
                        color="secondary_variant_font"
                        fontSize="16px"
                        fontWeight="400"
                    >
                        {nota?.createdAt && format(new Date(nota?.createdAt), "dd/MM/yy")}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}