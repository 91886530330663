import { Flex, Text, useToast } from "@chakra-ui/react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { InformationNotas } from "./views/InformationNotas";
import { useEffect, useState } from "react";
import { useNotas } from "../../shared/middlewares/notas.middleware";
import { CardSinInformacion, TypeCardSinInformacion } from "../../shared/components/Customs/CardSinInformacion";
import { useAuthContex } from "../../shared/context/user.context";
import { handleUnauthorized } from "../../shared/utils/functions/handleErrors";

export const Notas = () => {
    const { logout } = useAuthContex();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const [query, setQuery] = useState<string>("")
    const { notas, loading, error, status, errorsList } = useNotas(query)

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

    useEffect(() => {
        if (!location.pathname.startsWith("/notas/") && !loading)
            notas?.length > 0 && navigate(`/notas/${notas[0]?.id}`)
    }, [notas, location.pathname])

    return (
        <Flex
            direction="column"
            w="100%"
            px="50px"
        >

            <Flex
                direction="column"

                h="85vh"
                w="100%"
            >
                <Routes>

                    <Route
                        index
                        element={
                            notas?.length == 0 &&
                            <Flex
                                gap="20px"
                                height="100%"
                            >
                                <Flex
                                    bg="purewhite"
                                    rounded="5px"
                                    w="300px"
                                    h="140px"
                                    direction="column"
                                    p="30px"
                                    gap="10px"
                                >
                                    <Text
                                        fontSize="21px"
                                        fontWeight="600"
                                    >
                                        Toma una nota...
                                    </Text>
                                    <Text
                                        fontSize="14px"
                                        fontWeight="400"
                                    >
                                        Aquí aparecerán las notas que tomes durantes tus lecciones.
                                    </Text>
                                </Flex>

                                <CardSinInformacion
                                    type={TypeCardSinInformacion.NOTAS}
                                />
                            </Flex>
                        }
                    />

                    <Route
                        path="/:notasId"
                        element={
                            <InformationNotas
                                notas={notas}
                                setQuery={setQuery}
                                query={query}
                            />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    );
}