import { Flex, Skeleton } from '@chakra-ui/react'

export default function SkeletonDashboard() {
    return (
        <Flex direction="column" h="100vh" w="100%" gap="40px">
            <Flex w="100%" gap="40px">
                <Skeleton rounded="8px" w="100%" h="370px"/>
            </Flex>

            <Flex direction="column" w="100%" gap="40px">
                <Flex
                    w="100%"
                    gap="40px"
                >
                    <Skeleton rounded="8px" flex="1" h="350px"/>
                    <Skeleton rounded="8px" flex="1" h="350px"/>
                </Flex>

                <Flex
                    w="100%"
                    gap="40px"
                >
                    <Skeleton rounded="8px" flex="1" h="300px"/>
                    <Skeleton rounded="8px" flex="1" h="300px"/>
                </Flex>

            </Flex>
        </Flex>
    )
}
