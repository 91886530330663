import { Box } from '@chakra-ui/react';
import { Editor } from 'primereact/editor';
// import "./CustomQuill.scss"

interface Props {
    value: any;
    setValue: (value: any) => void;
    onBlur?: (e?: any) => void;
    isDisabled?: boolean;
    placeholder?: string;
    editorStyle?: React.CSSProperties;
}

export const CustomQuill = ({
    value,
    setValue,
    onBlur = (e: any) => { },
    isDisabled = false,
    placeholder = "",
    editorStyle = {}
}: Props) => {

    return (
        <Box w="100%" className='custom-quill'>
            <Editor
                readOnly={isDisabled}
                theme="snow"
                value={value}
                onTextChange={(e) => setValue(e.htmlValue)}
                onBlur={onBlur}
                style={editorStyle}
                placeholder={placeholder}
            />
        </Box>
    )
}