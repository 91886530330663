// Chakra UI
import { Flex, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
// Interfaces
import { EntregablesInt } from '../../../interfaces/Entregables';
import { EntregableEstadoEnum } from '../../../shared/utils/Types/EntregableEstadoEnum';
import { CardEntregable } from '../../../shared/components/Cards/CardEntregable';
import { useState } from 'react';
import { HiOutlineClock, HiX, HiOutlinePaperAirplane } from "react-icons/hi"
import { BiCheckCircle, BiLoader, BiXCircle } from 'react-icons/bi';
import { SkeletonEntregables } from '../Components/utils/SkeletonEntregables';
import { CardSinInformacion, TypeCardSinInformacion } from '../../../shared/components/Customs/CardSinInformacion';
import entregablesVacio from "../../../assets/img/entregablesVacio.png"

interface Props {
    entregables: any;
    loading: boolean;
}

export const EntregablesTable = ({ entregables, loading }: Props) => {
    const [typeTab, setTypeTab] = useState<"pendientes" | "incorrectos" | "entregados" | "correctos">("pendientes")
    const [tabIndex, setTabIndex] = useState<number>(typeTab === "pendientes" ? 0 : typeTab === "incorrectos" ? 1 : typeTab === "entregados" ? 2 : 3)

    const handleTabsChange = (index: number) => setTabIndex(index)

    // Funcion de filtro para mostrar en las cards la informacion de cada entregables
    const handleEntregablesFilter = (
        entregables: EntregablesInt[],
        filterType: "incorrectos" | "pendientes" | "correctos" | "puntuacion" | "entregados"
    ): any => {
        let entregablesFilter: EntregablesInt[] = [];
        let puntuacionMedia = 0;

        if (!entregables) return filterType === "puntuacion" ? puntuacionMedia : entregablesFilter

        if (filterType === "pendientes") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.PENDIENTE_ENTREGA)
        if (filterType === "incorrectos") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.ERROR)
        if (filterType === "entregados") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION)
        if (filterType === "correctos") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.CORRECTO)
        if (filterType === "puntuacion") {
            let puntuacion = 0
            let count = 0

            entregables?.forEach((e: EntregablesInt) => {
                if (e?.correccion) {
                    puntuacion = puntuacion + e?.correccion?.puntuacion

                    count++
                }
            })

            puntuacionMedia = puntuacion / count;
        }

        return filterType === "puntuacion" ? puntuacionMedia : entregablesFilter
    }


    return (
        loading ? <SkeletonEntregables />
            :
            <Flex
                direction="column"
                gap="30px"
                bg="purewhite"
                p="30px"
                rounded="5px"
                h="full"
            >

                <Tabs w="100%" index={tabIndex} onChange={handleTabsChange}>
                    <TabList
                        fontSize="15px"
                        fontWeight="400"
                        color="font"
                        lineHeight="22px"
                        gap="20px"
                        mb="30px"
                    >

                        <Tab
                            gap="10px"
                            px="40px"
                            alignItems="center"
                            onClick={() => setTypeTab("pendientes")}
                            color="dark_grey"
                            _selected={{ borderBottomWidth: "2px", borderBottomColor: "secondary", color: "secondary" }}
                        >
                            <Icon as={HiOutlineClock} boxSize="20px" />

                            Pendientes

                        </Tab>

                        <Tab gap="10px"
                            px="40px"
                            alignItems="center"
                            onClick={() => setTypeTab("correctos")}
                            color="dark_grey"
                            _selected={{ borderBottomWidth: "2px", borderBottomColor: "secondary", color: "secondary" }}
                        >
                            <Icon as={BiCheckCircle} boxSize="20px" />

                            Correctos
                        </Tab>

                        <Tab
                            gap="10px"
                            px="40px"
                            alignItems="center"
                            onClick={() => setTypeTab("incorrectos")}
                            color="dark_grey"
                            _selected={{ borderBottomWidth: "2px", borderBottomColor: "secondary", color: "secondary" }}
                        >
                            <Icon as={BiXCircle} boxSize="20px" />

                            Incorrectos

                        </Tab>


                    </TabList>

                    <TabPanels>
                        <TabPanel 
                            p="0"
                        >
                            {handleEntregablesFilter(entregables, "entregados")?.length > 0 ?
                                <CardEntregable
                                    data={handleEntregablesFilter(entregables, "entregados")}
                                />
                                :
                                <Flex p="30px"><CardSinInformacion type={TypeCardSinInformacion.ENTREGABLES} /></Flex>
                            }
                        </TabPanel>

                        <TabPanel 
                            p="0"
                        >
                            {handleEntregablesFilter(entregables, "correctos")?.length > 0 ?
                                <CardEntregable
                                    data={handleEntregablesFilter(entregables, "correctos")}
                                />
                                :
                                <Flex p="30px"><CardSinInformacion type={TypeCardSinInformacion.ENTREGABLES} /></Flex>
                            }
                        </TabPanel>

                        <TabPanel 
                            p="0"
                        >
                            {handleEntregablesFilter(entregables, "incorrectos")?.length > 0 ?
                                <CardEntregable
                                    data={handleEntregablesFilter(entregables, "incorrectos")}
                                />
                                :
                                <Flex p="30px"><CardSinInformacion type={TypeCardSinInformacion.ENTREGABLES} /></Flex>
                            }
                        </TabPanel>
                    </TabPanels>

                </Tabs>
            </Flex>
    );
};


