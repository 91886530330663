export enum EndpointTypes {
    ANUNCIOS = "/anuncios",
    CURSOS = "/cursos",
    ENTREGABLES = "/entregables",
    ASIGNACIONES = "/asignaciones",
    LIQUIDACIONES = "/liquidaciones",
    GRUPOS = "/grupos",
    MATRICULAS = "/matriculas",
    MENSAJERIA = "/mensajeria/mensajes",
    USUARIOS = "/users",
    FEEDBACK_CONFIG = "/feedbackConfigs",
    PROGRESOS = "/progresos",
}

export enum RelationshipTypes {

}