import { Badge, Flex, Progress, Text, useToast } from "@chakra-ui/react";
import { ColumnProps, CustomTable } from "../../../shared/components/CustomTable/CustomTable";
import { FiltersTable } from "../../../shared/components/TableElements/FiltersTable";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../shared/components/Customs/CardSinInformacion";
import { useAuthContex } from "../../../shared/context/user.context";
import { useNavigate } from "react-router-dom";
import { handleUnauthorized } from "../../../shared/utils/functions/handleErrors";
import { useMatriculasTutor } from "../../../shared/middlewares/matriculas.middleware";


export const MatriculasTable = () => {
    const { logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const [page, setPage] = useState<number>(1)
    const [statusData, setStatusData] = useState<"loading" | "idle" | "success">("loading")
    const [queryTable, setQueryTable] = useState<string>(`?page=${page}`);
    const [query, setQuery] = useState<string>("");
    const { matriculas, loading, error, status, errorsList } = useMatriculasTutor(queryTable + query)

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

    useEffect(() => {
        if (loading) setStatusData("loading")
        if (!loading && matriculas?.data && matriculas?.data?.length > 0) setStatusData("success")
        if (!loading && matriculas?.data && matriculas?.data?.length === 0) setStatusData("idle")
    }, [matriculas, loading])

    const columns: ColumnProps[] = [
        {
            header: 'Creación',
            field: 'matriculas.created_at',
            key: 'createdAt',
            sortable: true,
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                    gap="5px"
                    color="dark_blue"
                    fontSize="14px"
                    fontWeight="400"
                >
                    {rowData?.createdAt && <Text>{format(new Date(rowData?.createdAt), 'dd/MM/yyy HH:mm')}</Text>}
                </Flex>
            )
        },
        {
            header: 'alumno',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <Text
                    color="dark_blue"
                    fontSize="14px"
                    fontWeight="500"
                >
                    {rowData?.user?.nombre} {rowData?.user?.apellidos}
                </Text>
            )
        },
        {
            header: 'Matricula',
            field: 'estado',
            key: 'estado',
            sortable: true,
            body: (rowData: any) => (
                <Flex direction="column" alignItems="flex-start">
                    <Badge
                        color={
                            rowData?.matricula?.estado == "2" ? "dark_blue" :
                                rowData?.matricula?.estado == "1" ? "purewhite"
                                    : "#FFF"
                        }
                        bg={
                            rowData?.matricula?.estado == "2" ? "#E6E6EA" :
                                rowData?.matricula?.estado == "1" ? "black"
                                    : "main"
                        }
                        fontSize="11px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="2px 5px"
                    >
                        {
                            rowData?.matricula?.estado == "2" ? "Inactivo" :
                                rowData?.matricula?.estado == "1" ? "Próximo"
                                    : "Activo"
                        }
                    </Badge>

                    {(rowData?.fechaInicio && rowData?.fechaFin) &&
                        <Flex
                            alignItems="center"
                            gap="5px"
                            color="dark_blue"
                            fontSize="13px"
                            fontWeight="400"
                        >
                            <Text>{format(new Date(rowData?.fechaInicio), 'dd/MM/yyy')}</Text>
                            <Text mb="2px">-</Text>
                            <Text>{format(new Date(rowData?.fechaFin), 'dd/MM/yyy')}</Text>
                        </Flex>}
                </Flex>
            )
        },
        {
            header: 'grupo',
            field: 'grupo',
            key: 'grupo',
            sortable: true,
            body: (rowData: any) => (
                <Flex direction="column">
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {rowData?.grupo?.nombre}
                    </Text>
                    <Text
                        color="#7A7D81"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {rowData?.grupo?.curso?.nombre}
                    </Text>
                </Flex>
            )
        },
        {
            header: 'Progreso',
            field: 'progresoLecciones',
            key: 'progresoLecciones',
            sortable: true,
            body: (rowData: any) => (
                <Flex gap="5px" alignItems="center">
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {parseInt(rowData?.progresoLecciones) || 0} %
                    </Text>

                    <Progress value={rowData?.progresoLecciones || 0} flex="1" h="7px" rounded="10px" sx={{ '& > div': { background: "main" } }} />
                </Flex>
            )
        },
    ];

    if (statusData === "idle") 
        return (
            <Flex 
                p="10px 50px"
            >
                <CardSinInformacion type={TypeCardSinInformacion.MATRICULAS} />
            </Flex>
        )

    return (
        <Flex
            direction="column"
            p="10px 50px"
            gap="30px"
        >
            <FiltersTable
                query={query}
                setQuery={setQuery}
                filterElements={[
                    {
                        label: "Estado",
                        type: "estadoGrupo",
                    },
                    {
                        label: "Fechas",
                        type: "fecha"
                    },
                    {
                        label: "Curso",
                        type: "curso"
                    },
                ]}
            />

            <CustomTable
                data={matriculas?.data || []}
                columns={columns}
                isLoading={loading}
                currentPage={page}
                onPageChange={setPage}
                onQueryChange={setQueryTable}
                total={matriculas?.meta?.total || 0}
                height="calc(100vh - 380px)"
                defaultSortBy="matriculas.created_at"
                defaultOrder={1}
                skeletonHeight={40}
            />
        </Flex>
    )
};