import { useAuthContex } from "../../shared/context/user.context";
import { UserRolEnum } from "../../shared/utils/Types/RolEnum";
import { AprendizajeCursos } from "./views/AprendizajeCursos";
import { AprendizajeMatriculas } from "./views/AprendizajeMatriculas";

export const Aprendizaje = () => {
    const { user } = useAuthContex();

    return (
        (user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN)
            ? <AprendizajeCursos />
            : <AprendizajeMatriculas />
    );
}; 