// Chakra UI
import { Box, Flex, Image, Text } from "@chakra-ui/react";
// Components
import { NavLink } from "../NavLink/NavLink";
// React
import { useLocation } from "react-router-dom";
// Icons
import { BiNote } from "react-icons/bi"
import { isRoleAllowed } from "../../utils/functions/validateRol";
import { UserRolEnum } from "../../utils/Types/RolEnum";
import { useAuthContex } from "../../context/user.context";
import { UrlTypeEnum } from "../../utils/Types/UrlTypeEnum";
import { useUrl } from "../../context/url.context";
import { BottomSidebar } from "./components/BottomSidebar";
import { useState } from "react";
import { HiOutlineBookOpen, HiOutlineCalendar, HiOutlineChat, HiOutlineDocumentDuplicate, HiOutlineUserGroup, HiTrendingUp } from "react-icons/hi";
import { LogoEmpresa } from "./components/LogoEmpresa";
import { useMatriculasAlumno } from "../../middlewares/matriculas.middleware";

export const Sidebar = () => {
    const { user } = useAuthContex();
    const url = useUrl();
    const location = useLocation();
    const pathLeccion = location.pathname.includes("/leccion/");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { campusTutorizado } = useMatriculasAlumno({ user: user });

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const activePath = (text: string): boolean => {
        if (text === "/") {
            if (location.pathname === "/")
                return true
            return false
        } else if (text === "/aprendizaje") {
            if (location.pathname.includes(text) || location.pathname.startsWith("/leccion"))
                return true
            return false
        } else {
            if (location.pathname.includes(text)) { return true }
            else { return false }
        }
    };

    return (
        !pathLeccion ?
            <Flex
                h="100vh"
                bg="main"
                p="10px"
                borderRightRadius="10px"
            >

                {isSidebarOpen && (
                    <Flex
                        position="fixed"
                        top="0"
                        left="0"
                        w="100%"
                        h="100%"
                        bg="main"
                        zIndex="1000"
                        flexDirection="column"
                    >
                    </Flex>
                )}
                <Flex
                    direction="column"
                >
                    <Flex
                        py="30px"
                        display={{ base: "flex", md: "flex" }}
                        justifyContent="start"
                        alignItems="center"
                        ml="15px"
                    >
                        <LogoEmpresa
                            url={url}
                            iconoEmpresa={user?.empresa?.icono}
                        />
                    </Flex>

                    <Flex
                        direction="column"
                        p="10px"
                        w="100%"
                        gap="10px"
                    >


                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                            (url !== UrlTypeEnum.PLATAFORMA) &&
                            <NavLink path={"/dashboard"} icon={HiTrendingUp} label={"Resumen"} active={activePath("/dashboard")} />
                        }


                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            <NavLink path={"/aprendizaje"} icon={HiOutlineBookOpen} label={"Cursos"} active={activePath("/aprendizaje")} />
                        }


                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            (url !== UrlTypeEnum.PLATAFORMA && user?.tutorFreelance) &&
                            <NavLink path={"/facturacion"} icon={HiOutlineDocumentDuplicate} label={"Facturación"} active={activePath("/facturacion")} />
                        }


                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            (url !== UrlTypeEnum.PLATAFORMA) &&
                            <NavLink path={"/matriculas"} icon={HiOutlineUserGroup} label={"Matrículas"} active={activePath("/matriculas")} />
                        }

                        {user?.role?.nombre === UserRolEnum.ALUMNO ?
                            campusTutorizado &&
                                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                                    (url !== UrlTypeEnum.PLATAFORMA) &&
                                        <NavLink path={"/entregables"} icon={HiOutlineDocumentDuplicate} label={"Entregas"} active={activePath("/entregables")} />

                                :
                                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                                    (url !== UrlTypeEnum.PLATAFORMA) &&
                                        <NavLink path={"/entregables"} icon={HiOutlineDocumentDuplicate} label={"Entregas"} active={activePath("/entregables")} />
                        }

                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            (url !== UrlTypeEnum.PLATAFORMA) &&
                            <NavLink path={"/correcciones"} icon={HiOutlineCalendar} label={"Correcciones"} active={activePath("/correcciones")} />
                        }

                        {user?.role?.nombre === UserRolEnum.ALUMNO ?
                            campusTutorizado &&
                                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                                    (url !== UrlTypeEnum.PLATAFORMA) &&
                                        <NavLink path={"/foro"} icon={HiOutlineUserGroup} label={"Foro"} active={activePath("/foro")} />
                            :
                            isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                                    (url !== UrlTypeEnum.PLATAFORMA) &&
                                        <NavLink path={"/foro"} icon={HiOutlineUserGroup} label={"Foro"} active={activePath("/foro")} />
                                
                        }

                        {user?.role?.nombre === UserRolEnum.ALUMNO ?
                            campusTutorizado &&
                                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                                    (url !== UrlTypeEnum.PLATAFORMA) &&
                                        <NavLink path={"/mensajeria"} icon={HiOutlineChat} label={"Mensajería"} active={activePath("/mensajeria")} />
                                :
                                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                                    (url !== UrlTypeEnum.PLATAFORMA) &&
                                        <NavLink path={"/mensajeria"} icon={HiOutlineChat} label={"Mensajería"} active={activePath("/mensajeria")} />
                        }

                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                            <NavLink path={"/notas"} icon={BiNote} label={"Notas"} active={activePath("/notas")} />
                        }

                    </Flex>

                    <Flex
                        alignItems="center"
                        direction="column"
                        mt="auto"
                        w="100%"
                    >
                        <BottomSidebar title={""} />
                    </Flex>
                </Flex>
            </Flex>

            :

            <Box display="none" />
    );
};