import { Button, CircularProgress, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { HiOutlineExclamation } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { fmtSnds } from "../../../../shared/utils/functions/formatters";
import { EstadoTypeEnum } from "../../../../shared/utils/Types/EstadoTypeEnum";

interface Props {
    matricula?: any;
}

export default function CardRequisitosRestantes({ matricula }: Props) {
    const navigate = useNavigate();

    const isCompleted = matricula?.puntuacionMedia >= 75

    const checkFinal = () => {
        if (matricula?.grupo?.permitirDescargaDiploma === true) {
            return true;
        } else if (
            matricula?.grupo?.permitirDescargaDiploma === false &&
            parseInt(fmtSnds(matricula?.tiempoAcceso)) >= (matricula?.grupo?.curso?.duracion * 75) / 100
        ) {
            return true;
        }
        return false;
    }

    return (
        <Tooltip
            label="Pulse aquí para ver todos los requisitos y su progreso"
            hasArrow
            rounded="5px"
            p="10px"
            textAlign="center"
            isDisabled={isCompleted && checkFinal()}
        >
            <Flex
                display={isCompleted && checkFinal() ? "none" : "flex"}
                boxShadow="0px 4px 15px 0px rgba(199,205,255,0.6)"
                borderRadius="8px"
                maxWidth="fit-content"
                align="start"
                p="20px"
                w="100%"
                direction="column"
                gap="20px"
                onClick={() => {
                    matricula?.estado === EstadoTypeEnum.ACTIVO 
                    ? navigate(`/aprendizaje/${matricula?.grupo?.curso?.slug}?grupo=${matricula?.grupo?.slug}&tab=requisitos-fundae`)
                    : null
                }}
            >
                <Flex
                    gap="20px"
                >
                    <Flex
                        align="center"
                        gap="6px"
                    >
                        <Icon
                            as={HiOutlineExclamation}
                            color="fail"
                            boxSize="24px"
                        />
                        <Text
                            color="font"
                            fontSize="16px"
                            fontWeight="700"
                        >
                            {matricula?.estado === EstadoTypeEnum.ACTIVO
                                ? "¿Qué te queda por hacer?"
                                : "No cumplidos"
                            }
                        </Text>
                    </Flex>

                    {matricula?.estado === EstadoTypeEnum.ACTIVO &&
                    <Button
                        bgGradient="linear(135deg, #4E5FE4 0%, #8694FF 100%)"
                        _hover={{ bg: "secondary_variant" }}
                        p="10px"
                        rounded="10px"
                        fontSize="14px"
                        fontWeight="700"
                        color="purewhite"
                        onClick={() => navigate(`/aprendizaje/${matricula?.grupo?.curso?.slug}?grupo=${matricula?.grupo?.slug}&tab=requisitos-fundae`)}
                    >
                        Ver
                    </Button>
                    }
                </Flex>

                <Flex
                    direction="column"
                    gap="8px"
                >
                    {!isCompleted && (
                        <Flex
                            gap="10px"
                        >
                            <CircularProgress
                                value={parseInt(matricula?.puntuacionMedia)}
                                size="25px"
                                thickness='15px'
                                color="secondary"
                            />
                            <Text>
                                Tener más de un 75% en nota media
                            </Text>
                        </Flex>
                    )}

                    {!checkFinal() &&

                        <Flex
                            gap="10px"
                        >
                            <CircularProgress
                                value={Math.round(matricula?.tiempoAcceso / 3600)}
                                size="25px"
                                thickness='15px'
                                color="secondary"
                            />
                            <Text>
                                Tener un tiempo de conexión superior a 45h
                            </Text>
                        </Flex>
                    }
                </Flex>
            </Flex>
        </Tooltip>
    )
}
