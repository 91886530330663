import {  Flex, Grid, Icon, Text } from "@chakra-ui/react"
import { PreguntaForoInt, TemaForoInt } from "../../../interfaces/ForoInt";
import { BsArrowUpRight, BsClock } from "react-icons/bs";
import { CardGeneral } from "../Components/CardGeneral";
import { GrupoInt, MatriculasInt } from "../../../interfaces/MatriculasInt";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../shared/components/Customs/CardSinInformacion";
import foroVacio from "../../../assets/img/foroVacio.png"

interface TagList {
    label: string,
    icon: JSX.Element,
    query: string
}

interface Props {
    grupos: GrupoInt[];
    temas: TemaForoInt[];
    matriculas: MatriculasInt[];
    setSelectedGrupo: (grupo: GrupoInt) => void;
    selectedGrupo: GrupoInt | undefined;
    onOpen: () => void;
    preguntas: PreguntaForoInt[];
    query: string;
    setQuery: (query: string) => void;
}

export const GruposForo = ({ preguntas, query, setQuery, selectedGrupo }: Props) => {
    const TagList: TagList[] = [
        {
            label: "Nuevo",
            icon: BsClock,
            query: "?sortBy=createdAt&order=desc"
        },
        {
            label: "Top",
            icon: BsArrowUpRight,
            query: "?sortBy=totalRespuestas&order=desc"
        }
    ]

    return (
        
        <Flex
            direction="column"
            w="100%"
            h="85vh"
        >
           
            {selectedGrupo &&
                <Text
                    color="font"
                    fontSize="28px"
                    fontWeight="700"
                    textTransform="capitalize"
                    
                >
                    {selectedGrupo?.curso?.nombre}
                </Text>}

            <Flex
                gap="10px"
                alignItems="center"
                my="30px"
            >
                {TagList?.map((tag: any, index: number) => (
                    <Flex
                        key={index}
                        alignItems="center"
                        gap="5px"
                        p="5px 10px"
                        rounded="100px"
                        bg={query === tag?.query ? "secondary" : "purewhite"}
                        cursor="pointer"
                        onClick={() => setQuery(tag?.query)}
                    >
                        <Icon
                            as={tag?.icon}
                            boxSize="16px"
                            color={query === tag?.query ? "purewhite" : "secondary"}
                        />

                        <Text
                            color={query === tag?.query ? "purewhite" : "secondary"}
                            fontSize="16px"
                            fontWeight="400"
                            lineHeight="19.36px"
                            textTransform="capitalize"
                        >
                            {tag.label}
                        </Text>
                    </Flex>
                ))}
            </Flex>
           


            {preguntas?.length === 0 ?
                <Flex
                    h="85vh">
                    <CardSinInformacion
                        type={TypeCardSinInformacion.FORO}
                    />
                </Flex>
                :
                <Grid
                    gap="40px"
                    gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
                >
                    {preguntas?.map((pregunta: PreguntaForoInt, index: number) => (
                        <CardGeneral
                            pregunta={pregunta}
                            key={index} />
                    ))}
                </Grid>
            }
        </Flex>

    )
}