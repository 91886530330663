import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { LeccionInt } from '../../../interfaces/CoursesInt';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { EntregablesInt } from '../../../interfaces/Entregables';
import { EntregableEstadoEnum } from '../../../shared/utils/Types/EntregableEstadoEnum';
import { BiDownload, BiFile, BiUpload } from 'react-icons/bi';
import { EntregableHeader } from './EntregableHeader';
import { isRoleAllowed } from '../../../shared/utils/functions/validateRol';
import { UserRolEnum } from '../../../shared/utils/Types/RolEnum';
import { useAuthContex } from '../../../shared/context/user.context';
import { HiOutlineDocumentText, HiOutlineDownload } from 'react-icons/hi';

interface Props {
    leccion: LeccionInt | null;
    entregables: EntregablesInt[];
    estado: any;
    entregable: any
    onOpen: () => void;
}

export const CorreccionItem = ({ leccion, entregables, estado, entregable, onOpen }: Props) => {
    const { user } = useAuthContex();

    return (
        leccion &&
        <Flex
            direction="column"
        >
            <Flex
                fontSize="15px"
                direction="column"
                gap="30px"
            >
                {leccion?.descripcion &&
                    <Flex
                        w="100%"
                    >
                        <iframe width="100%" height="600" allowFullScreen
                            src={leccion?.descripcion}
                        />
                    </Flex>
                }

                <EntregableHeader
                    estado={estado}
                    leccion={leccion}
                    entregable={entregable}
                />

                <Accordion
                    defaultIndex={[0]}
                    allowToggle
                >
                    <AccordionItem
                        border="none"
                    >
                        <AccordionButton
                            bg="purewhite"
                            borderRadius="10px"
                            display="flex"
                            justifyContent="space-between"
                            p="20px"
                            _expanded={{ borderRadius: "12px 12px 0 0" }}
                        >
                            <Text
                                color="dark_blue"
                                fontSize="20px"
                                fontWeight="600"
                                lineHeight="140%"
                            >
                                Instrucciones de entrega
                            </Text>
                            <AccordionIcon boxSize="32px" color="dark_grey" />
                        </AccordionButton>

                        <AccordionPanel
                            bg="purewhite"
                            display="flex"
                            borderRadius="0 0 12px 12px"
                        >
                            <MarkdownPreview
                                source={leccion?.contenido || ''}
                                style={{
                                    color: "#252F40",
                                    padding: "10px",
                                    fontSize: '15px',
                                    width: '100%',
                                    maxWidth: '100%',
                                    background: "#FFF"
                                }}
                            />
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>

            {isRoleAllowed([UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <Box
                    p="30px"
                    bg="purewhite"
                    rounded="5px"
                    mt="30px"
                >
                    {(entregable?.estado !== EntregableEstadoEnum.PENDIENTE_CORRECCION && entregable?.correccion?.puntuacion !== 100) ?
                        <>
                            <Text
                                color="font"
                                fontSize="20px"
                                fontWeight="600"
                                lineHeight="140%"
                                mb="10px"
                            >
                                Entrega del ejercicio
                            </Text>

                            <Text
                                color="dark_grey"
                                fontSize="16px"
                                fontWeight="500"
                                letterSpacing="-0.16px"
                                mb="20px"
                                w="80%"
                            >
                                Cuando comiences el ejercicio se te mostrarán las especificaciones para la realización y entrega del mismo.
                                Debe adjuntar un archivo para responder al enunciado propuesto.
                                La entrega del ejercicio deberá realizarse a través de una carpeta .zip empaquetada.
                            </Text>

                            <Flex
                                justifyContent="flex-end"
                            >
                                <Button
                                    mb="20px"
                                    p="10px"
                                    bg="main"
                                    _disabled={{ bg: "main", cursor: "not-allowed" }}
                                    color="#FFF"
                                    borderRadius="5px"
                                    fontSize="14px"
                                    fontWeight="500"
                                    rightIcon={
                                        <Icon as={BiUpload} boxSize="22px" mb="1px" />
                                    }
                                    onClick={() => {
                                        onOpen();
                                    }}
                                >
                                    Subir nuevo ejercicio
                                </Button>
                            </Flex>
                        </>
                        :
                        <Text
                            color="font"
                            fontSize="20px"
                            fontWeight="600"
                            lineHeight="140%"
                            mb="10px"
                        >
                            Ultima entrega realizada
                        </Text>
                    }

                    <Box bg="grey_border" h="1px" />

                    <Flex
                        alignItems="center"
                        gap="50px"
                        py="15px"
                    >
                        <Flex
                            fontWeight="500"
                            lineHeight="100%"
                            letterSpacing="-0.16px"
                            gap="10px"
                            alignItems="center"
                        >
                            <Text
                                color="font"
                                fontSize="16px"
                            >
                                Estado:
                            </Text>

                            <Text
                                fontSize="14px"
                                textTransform="uppercase"
                                p="7px 10px"
                                rounded="5px"
                                color={
                                    entregable?.estado === EntregableEstadoEnum.ERROR ? 'fail'
                                        : entregable?.estado === EntregableEstadoEnum.CORRECTO ? 'blue'
                                            : 'main'
                                }
                                bg={
                                    entregable?.estado === EntregableEstadoEnum.ERROR ? 'fail_bg'
                                        : entregable?.estado === EntregableEstadoEnum.CORRECTO ? 'blue_bg'
                                            : 'secondary_variant'
                                }
                                as="span"
                            >
                                {
                                    entregable?.estado === EntregableEstadoEnum.ERROR ? 'Incorrecta'
                                        : entregable?.estado === EntregableEstadoEnum.CORRECTO ? 'Correcto'
                                            : "Pendiente"
                                }
                            </Text>
                        </Flex>

                        <Text
                            color="font"
                            fontSize="16px"
                            fontWeight="500"
                            lineHeight="100%"
                            letterSpacing="-0.16px"
                        >
                            Puntuación: {entregable?.correccion?.puntuacion || "-"} / 100
                        </Text>
                    </Flex>

                    {entregable?.correccion &&
                        <>
                            <Flex
                                bg="#F2F2FD"
                                p="10px"
                                rounded="5px"
                                gap="20px"
                                align="center"
                                justify="space-between"
                                mt="10px"
                            >
                                <Flex
                                    align="center"
                                    gap="20px"
                                >
                                    <Icon boxSize="24px" as={HiOutlineDocumentText} />

                                    <Flex
                                        direction="column"
                                    >
                                        <Text
                                            fontSize="12px"
                                            fontWeight="400"
                                            color="font"
                                        >
                                            {entregable?.adjunto?.name}
                                        </Text>

                                        <Text
                                            fontSize="12px"
                                            fontWeight="400"
                                            color="dark_grey"
                                        >
                                            {(entregable?.adjunto?.size / 1000)?.toFixed(0)} KB
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Button
                                    color="secondary"
                                    bg="transparent"
                                    border="1px solid"
                                    borderColor="secondary"
                                    p="7px 10px"
                                    h="fit-content"
                                    w="fit-content"
                                    fontSize="14px"
                                    _hover={{ bg: "secondary_variant" }}
                                    boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.04)"
                                    fontWeight="500"
                                    rightIcon={<Icon boxSize="16px" as={HiOutlineDownload} />}
                                    onClick={() => window.location.href = entregable?.adjunto?.url}
                                >
                                    Descargar
                                </Button>
                            </Flex>

                            <Flex
                                direction="column"
                                gap="10px"
                                mt="15px"
                            >
                                <Text
                                    color="font"
                                    fontSize="16px"
                                    fontWeight="700"
                                >
                                    Corrección del tutor
                                </Text>

                                <Flex>
                                    <MarkdownPreview
                                        source={entregable?.correccion?.texto || 'No hay comentarios del tutor'}
                                        style={{
                                            fontFamily: "Inter",
                                            fontWeight: "400",
                                            fontSize: '15px',
                                            width: '100%',
                                            maxWidth: '100%',
                                            color: "#252F40",
                                            background: "#FFFFFF"
                                        }}
                                    />
                                </Flex>
                            </Flex>
                        </>
                    }

                    <Box bg="light_grey" h="1px" mt="20px" />

                    <Flex
                        direction="column"
                    >
                        <Text
                            color="dark_grey"
                            mt="20px"
                            fontSize="20px"
                            fontWeight="600"
                            lineHeight="140%"
                        >
                            Entregas anteriores
                        </Text>

                        {(entregables && entregables?.length > 0) ?
                            <Flex
                                direction="column"
                            >
                                {entregables?.map((entregable: any, index: number) => (
                                    <Accordion
                                        allowToggle
                                        mt="20px"
                                        key={index}
                                    >
                                        <AccordionItem
                                            border="none"
                                        >
                                            <AccordionButton
                                                bg="grey"
                                                borderRadius="10px"
                                                display="flex"
                                                justifyContent="space-between"
                                                p="20px"
                                                _expanded={{ borderRadius: "12px 12px 0 0" }}
                                            >
                                                <Flex gap="15px" alignItems="center">
                                                    <Text
                                                        p="7px 10px"
                                                        rounded="5px"
                                                        fontSize="14px"
                                                        fontWeight="500"
                                                        letterSpacing="-0.14px"
                                                        lineHeight="100%"
                                                        color={
                                                            entregable?.correccion?.puntuacion < 75 ? 'fail'
                                                                : entregable?.correccion?.puntuacion > 75 ? 'success'
                                                                    : "dark_grey"
                                                        }
                                                        bg={
                                                            entregable?.correccion?.puntuacion < 75 ? 'fail_bg'
                                                                : entregable?.correccion?.puntuacion >= 75 ? 'success_bg'
                                                                    : "purewhite"
                                                        }
                                                    >
                                                        {entregable?.correccion?.puntuacion || "--"} / 100
                                                    </Text>

                                                    <Text
                                                        color="font"
                                                        fontSize="16px"
                                                        fontWeight="500"
                                                        letterSpacing="-0.16px"
                                                    >
                                                        Entrega {entregables.length - index}
                                                    </Text>
                                                </Flex>
                                                <AccordionIcon boxSize="32px" color="dark_grey" />
                                            </AccordionButton>

                                            <AccordionPanel
                                                bg="grey"
                                                display="flex"
                                                flexDirection="column"
                                                gap="15px"
                                                borderRadius="0 0 12px 12px"
                                            >
                                                <Flex h="60px" w="100%" p="10px 15px" bg="dark_grey" borderRadius="10px" alignItems="center">
                                                    <Icon color="#FFF" boxSize="22px" as={BiFile} />

                                                    <Flex w="100%">
                                                        <Flex
                                                            direction="column"
                                                            w="100%"
                                                            px="10px"
                                                            color="#FFF"
                                                            fontSize="12px"
                                                            fontWeight="500"
                                                        >
                                                            <Text p="0px">
                                                                {entregable?.adjunto?.name}
                                                            </Text>

                                                            <Text p="0px">
                                                                {(entregable?.adjunto?.size / 1000)?.toFixed(0)} KB
                                                            </Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Button
                                                        color="purewhite"
                                                        fontSize="12px"
                                                        fontWeight="500"
                                                        letterSpacing="-0.12px"
                                                        h="auto"
                                                        bg="inherit"
                                                        border="1px solid"
                                                        borderColor="purewhite"
                                                        borderRadius="5px"
                                                        p="5px 10px"
                                                        onClick={() => location.href = entregable?.adjunto?.url}
                                                        leftIcon={<Icon as={BiDownload} boxSize="14px" />}
                                                    >
                                                        Descargar
                                                    </Button>
                                                </Flex>

                                                <Box bg="grey_border" h="1px" />

                                                <Text
                                                    color="scondary_font"
                                                    fontSize="15px"
                                                    fontWeight="400"
                                                    letterSpacing="-0.16px"
                                                >
                                                    Comentario del tutor
                                                </Text>

                                                <Flex
                                                    direction="column"
                                                    bg="purewhite"
                                                    p="20px"
                                                    rounded="10px"
                                                    gap="10px"
                                                >
                                                    <MarkdownPreview
                                                        source={entregable?.correccion?.texto || 'No hay comentarios del tutor'}
                                                        style={{
                                                            fontFamily: "Inter",
                                                            fontWeight: "400",
                                                            fontSize: '15px',
                                                            width: '100%',
                                                            maxWidth: '100%',
                                                            color: "#252F40",
                                                            background: "#FFFFFF"
                                                        }}
                                                    />
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                ))}
                            </Flex>
                            :
                            <Text
                                mt="20px"
                                color="fail"
                                fontSize="16px"
                                fontWeight="500"
                            >
                                No hay entregas anteriores
                            </Text>
                        }
                    </Flex>
                </Box>
            }
        </Flex>
    );
};