import { Box, Flex, Skeleton } from "@chakra-ui/react";

export const SkeletonLeccion = () => {
    return (
        <Flex
            px="70px"
            gap="40px"
        >
            <Flex
                direction="column"
                w="77.5%"
                alignItems="center"
                gap="30px"
                h="880px"
            >
                <Flex
                    w="100%"
                >
                    <Skeleton
                        w="100%"
                        h="49vh"
                        rounded="5px"
                    />
                </Flex>

                <Flex
                    w="100%"
                >
                    <Skeleton
                        w="100%"
                        h="101px"
                        rounded="5px"
                    />
                </Flex>
                
                <Flex
                    w="100%"
                >
                    <Skeleton
                        w="100%"
                        h="140px"
                        rounded="5px"
                    />
                </Flex>

                <Flex
                    w="100%"
                >
                    <Skeleton
                        w="100%"
                        h="270px"
                        rounded="5px"
                    />
                </Flex>

                <Flex
                    width="100%"
                    justify="space-between"
                >
                    <Skeleton
                        rounded="5px"
                        w="200px"
                        h="50px"
                    />

                    <Skeleton
                        rounded="5px"
                        w="200px"
                        h="50px"
                    />
                </Flex>
                
            </Flex>

            <Flex
                h="calc(100vh - 160px)"
                w="22.5%"
            >
                <Skeleton
                    w="100%"
                    h="calc(100vh - 160px)"
                    rounded="5px"
                />
            </Flex>
        </Flex>
    );
}