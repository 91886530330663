import { useState } from 'react';
import './selectorcurso.scss';
import { Flex, Icon } from '@chakra-ui/react';
import { BiChevronLeft } from 'react-icons/bi';
import { CursosInt } from '../../../interfaces/CoursesInt';
import { IconBox } from '../../../shared/components/Icons/IconBox';
import { MatriculasInt } from '../../../interfaces/MatriculasInt';
import { truncate } from 'lodash';

interface CustomSelectProps {
  onSelectMatricula: (matricula: MatriculasInt) => void;
  matriculas: MatriculasInt[];
  selectedMatricula: MatriculasInt | undefined | any;
}

const SelectorCurso = ({ onSelectMatricula, matriculas, selectedMatricula }: CustomSelectProps) => {
  const [isActive, setIsActive] = useState(false);

  const handleSelect = (matricula: MatriculasInt) => {
    onSelectMatricula(matricula);
    setIsActive(false);
  };

  const selectedMatriculaArray = selectedMatricula


  return (
    <div
      className={`custom-select ${isActive ? 'active' : ''}`}
    >
      <Flex
        className="select-button"
        onClick={() => setIsActive(!isActive)}
      >

        <Flex
          gap="20px"
        >

          <IconBox
            icon={
              selectedMatricula && selectedMatricula.length > 0
                ?
                selectedMatricula && selectedMatricula[0]?.grupo?.curso?.icono
                :
                selectedMatricula && selectedMatricula?.grupo?.curso?.icono
            }
            padding="0"
            border="none"
            size="24px"
            bg='none'
          />
          <span
            className="selected-value"
          >
            {
              selectedMatricula && selectedMatricula.length > 0
                ?
                `${truncate(selectedMatricula[0]?.grupo?.curso?.nombre, { length: 23 })} (${selectedMatricula[0]?.grupo?.nombre})`
                : selectedMatricula
                  ? `${truncate(selectedMatricula?.grupo?.curso?.nombre, { length: 23 })} (${selectedMatricula?.grupo?.nombre})`
                  : 'Selecciona un curso'
            }
          </span>

        </Flex>

        <Icon
          className='arrow'
          as={BiChevronLeft}
          boxSize="24px"
        />

      </Flex>

      <ul
        className="select-dropdown"
      >
        {matriculas?.map((matricula: MatriculasInt, index: number) => (
          <li key={index} onClick={() => handleSelect(matricula)}>
            <label>
              <IconBox
                icon={matricula?.grupo?.curso.icono}
                padding="0"
                border="none"
                size="28px"
                bg='none'
              />
              <input
                type="radio"
                name="label"
              />
              {matricula?.grupo?.curso?.nombre} ({matricula?.grupo?.nombre})
            </label>
          </li>
        ))}
      </ul>

    </div>
  );
};

export default SelectorCurso;
