import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { BiEditAlt, BiPencil, BiTime } from "react-icons/bi";
import { LeccionInt } from "../../../interfaces/CoursesInt";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useSessionTimeContext } from "../../../shared/context/sessionTime.context";
import NotasModal from "../Components/NotasModal";
import { useState } from "react";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../shared/utils/Types/RolEnum";
import { useAuthContex } from "../../../shared/context/user.context";

interface Props {
    leccion: LeccionInt | null;
}

export const MarkdownLesson = ({ leccion }: Props) => {
    const { user } = useAuthContex();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const secondsToTime = (value: number) => {
        let hour: number | string;
        let minute: number | string;
        let seconds: number | string;

        hour = Math.floor(value / 3600);
        hour = (hour < 10) ? `0${hour}` : hour;

        minute = Math.floor((value / 60) % 60);
        minute = (minute < 10) ? `0${minute}` : minute;

        seconds = value % 60;
        seconds = (seconds < 10) ? `0${seconds}` : seconds;

        return `${hour}:${minute}:${seconds}`
    };

    return (
        <Flex direction="column" boxSize="100%" gap="40px">
            <Flex p="30px" justifyContent="space-between" alignItems="center">
                <Flex gap="25px" alignItems="center">
                    <Text
                        color="font"
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="140%"
                    >
                        {leccion?.nombre}
                    </Text>
                </Flex>

                {isRoleAllowed([UserRolEnum.ALUMNO, UserRolEnum.ADMIN], user?.role?.nombre) &&
                    <Button
                        position="relative"
                        bg="purewhite"
                        _hover={{ bg: "secondary_variant" }}
                        onClick={openModal}
                        color="secondary"
                        borderWidth="1px"
                        borderColor="secondary"
                        fontSize="15px"
                        fontWeight="500"
                        boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.04)"
                        rightIcon={<BiPencil fontSize="22px" />}
                    >
                        Hacer anotación
                    </Button>
                }

                <NotasModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    leccionId={leccion?.id}
                />
            </Flex>

            <Box bg="grey_border" h="1px" mx="30px" />

            <Flex direction="column" align="start" justify="center" overflow="auto" className="scroll-bar" h={"500px"}>
                <MarkdownPreview source={leccion?.contenido || ''} style={{ padding: "20px", fontSize: '15px', width: '100%', maxWidth: '100%', color: "#252F40", background: "#fff" }} />
            </Flex>
        </Flex>
    );
};