import { Box, Button, Flex, Text, Tooltip, useToast } from "@chakra-ui/react";
import { useAuthContex } from "../../../shared/context/user.context";
import { MessagesItem } from "../components/MessagesItem";
import { useEffect, useState } from "react";
import { ChatInt } from "../../../interfaces/MessagesInt";
import { createMessage, readMessage } from "../../../shared/middlewares/messages.middleware";
import { EstadoMensaje } from "../../../shared/utils/Types/MensajeriaEnum";
import { StatusEnum, notify } from "../../../shared/utils/functions/notify";
import { CustomQuill } from "../../../shared/components/Customs/CustomQuill";
import { UserRolEnum } from "../../../shared/utils/Types/RolEnum";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { EstadoTypeEnum } from "../../../shared/utils/Types/EstadoTypeEnum";

interface Props {
    selectedChat: ChatInt | undefined;
    refreshMensaje?: () => void;
    matricula: MatriculasInt | undefined;
}

export const Panel = ({ selectedChat, refreshMensaje = () => { }, matricula }: Props) => {
    const { user } = useAuthContex();
    const toast = useToast();
    const [message, setMessage] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)

    const sendMessage = () => {
        if (selectedChat?.id && message && user?.id) {
            setLoading(true)

            const newMessage = {
                conversacionId: selectedChat?.id,
                emisorId: user?.id,
                receptorId: user?.id !== selectedChat?.receptorId ? selectedChat?.receptorId : selectedChat?.emisorId,
                texto: message,
                estado: EstadoMensaje.NO_LEIDO
            }

            createMessage(newMessage)
                .then(() => {
                    setMessage("");
                    { user?.role?.nombre === UserRolEnum.TUTOR && onReadMessage() }
                    refreshMensaje();
                })
                .catch(() => notify(toast, StatusEnum.error, "Error al enviar mensaje"))
                .finally(() => setLoading(false))

        }
    }

    const onReadMessage = () => {
        selectedChat?.mensajes?.filter((mensaje: any) => mensaje?.receptorId === user?.id).map(async (mensaje: any) => {
            if (mensaje?.estado === EstadoMensaje.NO_LEIDO) {
                await readMessage(mensaje?.id).catch((error) => console.log(error))
            }
        })
    }

    useEffect(() => {
        {
            UserRolEnum.ALUMNO === user?.role?.nombre &&
                onReadMessage()
            refreshMensaje()
        }
    }, [])

    useEffect(() => {
        if(message === "<p><br></p>") {
            setMessage("");
        }
      }, [message]);

    return (
        selectedChat ?
            <Flex direction="column" w="75%">
                <Flex
                    p="10px 15px"
                    justifyContent="center"
                    direction="column"
                    gap="3px"
                    h="100px"
                    borderBottom="1px solid"
                    borderColor="light_grey"
                >
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="600"
                        letterSpacing="-0.2px"
                    >
                        {selectedChat?.asunto}
                    </Text>

                    <Flex
                        alignItems="center"
                        gap="5px"
                    >
                        <Box bg="main" h="6px" w="6px" rounded="50px" />
                        <Text
                            color="secondary_variant_font"
                            fontSize="12px"
                            fontWeight="500"
                            lineHeight="140%"
                        >
                            {selectedChat?.leccion?.nombre} | {selectedChat?.leccion?.modulo?.curso?.nombre}
                        </Text>
                    </Flex>
                </Flex>

                {selectedChat?.mensajes &&
                    // ref={messageRef}
                    <Flex flex="1" overflow="auto" bg="purewhite" className={"scroll-bar"}>
                        <MessagesItem chat={selectedChat} />
                    </Flex>
                }

                <Tooltip
                    label="Solo puedes enviar mensajes si el curso no ha finalizado"
                    isDisabled={matricula?.estado === EstadoTypeEnum.ACTIVO || user?.role?.nombre === UserRolEnum.TUTOR}
                    hasArrow
                    placement="top"
                >
                    <Flex
                        p="20px"
                        gap="15px"
                        direction="column"
                        borderColor="light_grey"
                    >
                        <Flex
                            pos="relative"
                        >
                            <CustomQuill
                                isDisabled={matricula?.estado !== EstadoTypeEnum.ACTIVO && user?.role?.nombre === UserRolEnum.ALUMNO}
                                value={message}
                                setValue={setMessage}
                                editorStyle={{
                                    minHeight: '100px',
                                    backgroundColor: "#FFFFFF",
                                    border: "1px solid #e2e8f0",
                                    borderRadius: "5px",
                                    cursor: (matricula?.estado !== EstadoTypeEnum.ACTIVO && user?.role?.nombre === UserRolEnum.ALUMNO) ? "not-allowed" : "text",
                                }}
                            />

                        </Flex>

                        <Button
                            ml="auto"
                            maxW="200px"
                            fontSize="16px"
                            bg="secondary"
                            color="purewhite"
                            onClick={sendMessage}
                            isLoading={loading}
                            cursor="pointer"
                            py="2px"
                            px="10px"
                            fontWeight="500"
                            isDisabled={matricula?.estado !== EstadoTypeEnum.ACTIVO && user?.role?.nombre === UserRolEnum.ALUMNO || message === "" || !message}
                            _hover={{ bg: "secondary_variant" }}
                        >
                            ENVIAR MENSAJE
                        </Button>
                    </Flex>
                </Tooltip>
            </Flex>
            :

            <Box />
    );
};