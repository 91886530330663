import { Flex, Text } from "@chakra-ui/react"

interface Props {
    label: string;
    info: string;
    bg?: string;
    infoNotColored?:boolean
}

export const CardInfo = ({ label, info, bg = "secondary_variant",infoNotColored }: Props) => {
    return (
        <Flex
            p="8px"
            alignItems="center"
            bg={bg}
            rounded="5px"
            w="100%"
            gap="40px"
            justifyContent="space-around"
        >
            <Text
                whiteSpace="nowrap"
                ml="20px"
                color="font"
                fontSize="14px"
                fontWeight="400"
            >
                {label}
            </Text>

            <Text
                color={!infoNotColored ? "success" : "font"}
                fontSize="22px"
                fontWeight="700"
                whiteSpace="nowrap"
                mr="20px"
                minW="30px"
            >
                {info !== 'NaN' ? info : 0}
            </Text>
        </Flex>
    )
}