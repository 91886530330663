import { useEffect, useState } from 'react';

import { Field } from 'formik';
import Select from 'react-select';
import { FormLabel, FormControl, FormErrorMessage, Box, Icon } from '@chakra-ui/react';
import { formSelectStyles } from '../../../ui/Select/formSelectStyles';
import { BiInfoCircle } from 'react-icons/bi';

type FormSelectProps = {
    name: string;
    label?: string;
    placeholder?: string;
    defaultValue?: any;
    options?: any[];
    isMulti?: boolean;
    isSearchable?: boolean;
    isRequired?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    isOptionDisabled?: any;
    style?: React.CSSProperties;
    controlStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    noHead?: boolean;
};

export const FormSelect = ({
    name,
    label,
    options,
    placeholder,
    isMulti = false,
    defaultValue,
    isRequired = false,
    isSearchable = true,
    isDisabled = false,
    isClearable = false,
    controlStyle = {},
    isOptionDisabled,
    style = {},
    labelStyle = {
        fontSize: "14px",
        fontWeight: "400"
    },
    noHead = false
}: FormSelectProps) => {

    const [value, setValue] = useState();

    useEffect(() => {
        setValue(defaultValue);

    }, []);

    const onChange = (e: any, form: any) => {
        if (isMulti) form.setFieldValue(name, [...e.map((item: any) => item.value)]);
        else {
            form.setFieldValue(name, e !== null ? e.value : null);
            setValue(e);
        }
    };

    return (
        <Field name={name}>
            {({ field, form }: { field: any; form: any }) => (
                <FormControl style={controlStyle} isInvalid={form.errors[name] && form.touched[name]}>
                    {!noHead &&
                        <FormLabel
                            htmlFor={name}
                            display="flex"
                            gap="3px"
                            color="dark_blue"
                            style={labelStyle}
                        >
                            {label}
                        </FormLabel>
                    }

                    <Box
                        pos="relative"
                    >
                        <Select
                            name={name}
                            isMulti={isMulti}
                            options={options}
                            styles={formSelectStyles}
                            isDisabled={isDisabled}
                            isClearable={isClearable}
                            placeholder={placeholder}
                            isSearchable={isSearchable}
                            closeMenuOnSelect={!isMulti}
                            isOptionDisabled={isOptionDisabled}
                            onChange={(option: any) => onChange(option, form)}
                            value={
                                defaultValue ? value :
                                options ? options.find((option: any) => option.value === field.value) || null : ''
                            }
                        />

                        {isRequired &&
                            <Icon
                                zIndex="999"
                                as={BiInfoCircle}
                                boxSize="15px"
                                color={(form.errors[name] && form.touched[name]) ? "red" : "dark_blue"} 
                                pos="absolute"
                                right="-7px"
                                top="-7px"
                            />
                        }
                    </Box>
                    <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
                </FormControl>
            )}
        </Field>
    );
};