import { Flex } from "@chakra-ui/react"
import { ContadorItem } from "../utils/ContadorItem";

interface Props {
    stats: any;
}

export const CardContador = ({ stats }: Props) => {

    return (
        <Flex
            direction="column"
            gap="10px"
            bg="purewhite"
            rounded="5px"
            h="fit-content"
        >
            <ContadorItem
                stats={stats}
            />
        </Flex>
    )
}