import React, { useContext } from "react";

export interface ProgressInt {
    matriculaId: string | undefined;
    cursoId: string | null;
    cursoNombre: string | null;
    cursoSlug: string | null;
    porcentajeCurso: number | null;
    totalLecciones: string | null;
    data: any;
}

export interface ProgressContextInt {
    cursoProgress: ProgressInt;
    setCursoProgress: (cursoProgress: any) => any;
    refreshProgress: () => any;
}

export const ProgressContext = React.createContext<ProgressContextInt>({
    cursoProgress: {
        matriculaId: undefined,
        cursoId: null,
        cursoNombre: null,
        cursoSlug: null,
        porcentajeCurso: null,
        totalLecciones: null,
        data: null,
    },
    setCursoProgress: (cursoProgress: any) => { },
    refreshProgress: () => { }
});

export const useProgressContex = () => {
    return useContext(ProgressContext);
}