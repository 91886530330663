// Chakra UI
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { BarProgress } from "../../Progress/BarProgress";
import { useLocation, useMatch, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLeccionSlug } from "../../../middlewares/lessons.middleware";
import { UserRolEnum } from "../../../utils/Types/RolEnum";
import { split } from "lodash";

interface Props {
    cursoProgress: any;
    url: string;
}

export const ProgressCourse = ({ cursoProgress, url }: Props) => {
    const navigate = useNavigate()
        
    const slug = (useLocation()?.pathname?.split("/")[2]?.split("-"))

    if (!isNaN(parseInt(slug[slug.length-1]))){
    
        slug.pop()
    }

    const tema = slug?.splice(0,2)?.join(" ")
    const leccion = slug?.join(" ")
    
    
    
    

    return (
        !cursoProgress?.cursoId
            ?
            <Flex
                direction="column"
                align="center"
                justify="center"
                gap="9px"
                w="100%"
                maxW="600px"
            >
                <Spinner
                    h="50px"
                    w="50px"
                    color="purewhite"
                />
            </Flex>
            :
            <Flex
                align="center"
                justify="center"
                gap="10px"
                w="100%"
                maxW="700px"
            >
                <Text
                    color="font"
                    textAlign="center"
                    fontSize="15px"
                    fontWeight="600"
                    onClick={() => navigate(url)}
                    cursor="pointer"
                >
                    Aprender
                </Text>

                <Text
                    color="font"
                    textAlign="center"
                    fontSize="15px"
                    fontWeight="600"
                >
                    &gt;
                </Text>

                <Text
                    color="font"
                    textAlign="center"
                    fontSize="15px"
                    fontWeight="600"
                    onClick={() => navigate(url)}
                    cursor="pointer"
                >
                    {cursoProgress?.cursoNombre}
                </Text>

                <Text
                    color="font"
                    textAlign="center"
                    fontSize="15px"
                    fontWeight="600"
                >
                    &gt;
                </Text>

                <Text
                    color="font"
                    textAlign="center"
                    fontSize="15px"
                    fontWeight="600"
                >
                    {tema[0]?.toUpperCase() + tema?.slice(1)} - {leccion[0]?.toUpperCase() + leccion?.slice(1)}
                </Text>
            </Flex>
    );
};