import { Progress } from "@chakra-ui/react";

interface Props {
    progress: number | null;
    bg?: string;
    bgProgress?: string
    height?: string;
    max?: number;
}

export const BarProgress = ({ 
    progress, 
    bg = "#EAECFF",
    bgProgress = "secondary", 
    height = "12px",
    max = 100
}: Props) => {

    return (
        <Progress
            
            w="100%"
            bg={bg}
            maxW="100%"
            rounded="full"
            max={max}
            h={height}
            value={progress || 0}
            sx={{ '& > div': { background: bgProgress } }}
        />
    );
};