import { Flex, Icon, Text } from "@chakra-ui/react";
import { MdSpeakerNotesOff } from "react-icons/md";
import { AccordeonInbox } from "./Components/AccordeonInbox";
import { useEffect, useState } from "react";
import { getAllNotification } from "../../middlewares/notification.middleware";

export const Notifications = () => {
    const [notificaciones, setNotificaciones] = useState<any>([])
    const [refreshNotification, setRefreshNotification] = useState<boolean>(true)

    useEffect(() => {
        refreshNotification &&
            getAllNotification()
            .then((response: any) => {
                setNotificaciones(response?.data?.data?.data)
                setRefreshNotification(false)
            })

    }, [refreshNotification])

    return (
        <>
            <Text px="25px" mt={8} mb={10} fontSize={25} fontWeight="semibold">Notificaciones</Text>

            
            {notificaciones && notificaciones?.length == 0
            ?       
                <Flex flexDir="column" alignItems="center" justifyContent="center" gap="10px" mt="35px" px="10px">
                    <Icon h="72px" w="72px" as={MdSpeakerNotesOff} />
                    <Text fontSize={16} fontWeight="semibold" textAlign="center">No tienes notificaciones</Text>
                    <Text fontSize={15} color="#8c909c" textAlign="center">Mantente informado. Las notificaciones sobre tu actividad, novedades del campus e interacciones del foro se mostrarán aquí.</Text>
                </Flex> 
            :
                <AccordeonInbox notificaciones={notificaciones}  setRefreshNotification={setRefreshNotification} />
            }
        </>
    )
};