// Chakra UI
import { Button, Flex, Icon, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
// Interfaces
import { CursosInt, LeccionInt } from "../../interfaces/CoursesInt";
// React
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
// Axios
// Functions
import { notify, StatusEnum } from "../../shared/utils/functions/notify";
// Icons
import { BiCheck, BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
// Components
import { VideoLesson } from "./views/VideoLesson";
// Context
import { useAuthContex } from "../../shared/context/user.context";
import { useProgressContex } from "../../shared/context/progress.context";
// Middlewares
import { uploadProgress } from "../../shared/middlewares/progress.middleware";
import { LessonModeEnum, LessonTypeEnum } from "../../shared/utils/Types/LessonEnum";
import { SlideLesson } from "./views/SlideLesson";
import { MarkdownLesson } from "./views/MarkdownLesson";
import { EntregableLesson } from "./views/EntregableLesson";
import { EntregableEstadoEnum } from "../../shared/utils/Types/EntregableEstadoEnum";
import { AccordeonModules } from "./Components/Accordeon/AccordeonModules";
import { MatriculasInt } from "../../interfaces/MatriculasInt";
import { SkeletonLeccion } from "./Components/utils/SkeletonLecciones";
import { useCursoSlug } from "../../shared/middlewares/courses.middleware";
import { useLeccionSlug } from "../../shared/middlewares/lessons.middleware";
import { isRoleAllowed } from "../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../shared/utils/Types/RolEnum";
import { RecursoLesson } from "./views/RecursoLesson";
import { useData } from "../../shared/middlewares/middlewares";
import { EndpointTypes } from "../../shared/utils/Types/EndpointTypes";
import { FbCursoModal } from "./Components/Modals/FbCursoModal";
import { FbModuloModal } from "./Components/Modals/FbModuloModal";
import { FbLeccionModal } from "./Components/Modals/FbLeccionModal";
import { FeedbackTypeEnum } from "../../shared/utils/Types/FeedbackTypeEnum";
import { FeedbackConfigInt } from "../../interfaces/FeedbackInt";
import { EstadoTypeEnum } from "../../shared/utils/Types/EstadoTypeEnum";
import { getUsersInactividad } from "../../shared/middlewares/users.middleware";

export const Lesson = () => {
    const { slug } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const { user } = useAuthContex();
    const [matriculaActual, setMatriculaActual] = useState<MatriculasInt>();
    const { setCursoProgress, cursoProgress, refreshProgress } = useProgressContex();
    const { leccion, loading } = useLeccionSlug({
        slug: slug,
        ignoreRequest: user?.role?.nombre === UserRolEnum.ALUMNO ? (!matriculaActual?.id ? true : false) : false,
        track: user?.role?.nombre === UserRolEnum.ALUMNO ?
            {
                track: user?.tracking ? true : false,
                matricula: matriculaActual?.estado === EstadoTypeEnum.ACTIVO ? matriculaActual?.id : undefined,
            } : undefined
    })
    const { curso: dataCurso } = useCursoSlug(leccion?.modulo?.curso?.slug)
    const [mode, setMode] = useState<LessonModeEnum>(LessonModeEnum.CARGANDO);
    const [estadoEntregable, setEstadoEntregable] = useState<EntregableEstadoEnum>(EntregableEstadoEnum.PENDIENTE_ENTREGA);
    const [isCompleted, setIsCompleted] = useState<boolean | null>(false);
    const [nextIsBlocked, setNextIsBlocked] = useState<boolean>(false);
    const [prevIsBlocked, setPrevIsBlocked] = useState<boolean>(false);
    const [endPrev, setEndPrev] = useState<boolean>(true);
    const [search] = useSearchParams()
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""

    /** Declaramos los modales para los feedback y el Ref para seleccionar el feedback que vamos a pasar al modal  */
    const [feedbackBlock, setFeedbackBlock] = useState<boolean>(true)
    const { isOpen: isOpenFbLeccion, onOpen: onOpenFbLeccion, onClose: onCloseFbLeccion } = useDisclosure();
    const { isOpen: isOpenFbModulo, onOpen: onOpenFbModulo, onClose: onCloseFbModulo } = useDisclosure();
    const { isOpen: isOpenFbCurso, onOpen: onOpenFbCurso, onClose: onCloseFbCurso } = useDisclosure();
    const fbLeccionRef = useRef<any>(null)
    const fbModuloRef = useRef<any>(null)
    const fbCursoRef = useRef<any>(null)

    useEffect(() => {
        const intervar = setInterval(() => {
            getUsersInactividad()
            .then((response) => { console.log(response)})
        }, 10000);

        return () => clearInterval(intervar)    
    }, [])

    useEffect(() => {
        if (matriculaActual?.estado === EstadoTypeEnum.INACTIVO) {
            navigate(`/aprendizaje`)
            return notify(toast, StatusEnum.error, 'La matrícula no está activa.')
        }
    }, [matriculaActual])

    /** Buscamos todos los ids configurados dentro del feedbackConfig del curso y los preparamos para la query */
    const getFeedbacksIds = () => {
        if (!dataCurso?.feedbackConfig || Object.keys(dataCurso?.feedbackConfig).length === 0) return null;

        const ids: string[] = []

        Object?.entries(dataCurso?.feedbackConfig).forEach(([keys, values]: [string, any]) => {
            if (keys === FeedbackTypeEnum.LECCION) {
                Object?.values(values).forEach((value: any) => {
                    ids.push(value as string)
                })
            } else {
                ids.push(values as string)
            }
        })

        return ids.join(",")
    }

    /** Solicitamos con los ids obtenidos la data a la API y si no tenemos IDS ignoramos la request */
    const { data: feedback } = useData({
        endpoint: EndpointTypes.FEEDBACK_CONFIG,
        query: `?activo=true&ids=${getFeedbacksIds()}`,
        ignoreRequest: !dataCurso?.feedbackConfig || getFeedbacksIds() === null
    })

    const ejecutarFeedback = (feedbacks: FeedbackTypeEnum[], tipoLeccion: LessonTypeEnum) => {
        feedback?.data?.map((fe: FeedbackConfigInt) => {
            if (fe?.tipo === FeedbackTypeEnum?.LECCION && feedbacks?.includes(FeedbackTypeEnum?.LECCION)) {
                if (fe?.subtipo === tipoLeccion){
                    fbLeccionRef.current = { ...fe, recursoId: leccion?.id }
                    !localStorage.getItem("feedbackLeccion") && localStorage.setItem("feedbackLeccion", "pending")
                    onOpenFbLeccion()
                }
            }
            if (fe?.tipo === FeedbackTypeEnum?.MODULO && feedbacks?.includes(FeedbackTypeEnum?.MODULO)) {
                fbModuloRef.current = { ...fe, recursoId: leccion?.moduloId }
                !localStorage.getItem("feedbackModulo") && localStorage.setItem("feedbackModulo", "pending")
                onOpenFbModulo()
            }
            if (fe?.tipo === FeedbackTypeEnum?.CURSO && feedbacks?.includes(FeedbackTypeEnum?.CURSO)) {
                fbCursoRef.current = { ...fe, recursoId: dataCurso?.id }
                !localStorage.getItem("feedbackCurso") && localStorage.setItem("feedbackCurso", "pending")
                onOpenFbCurso()
            }
        })
    }

    useEffect(() => {
        // Si es la primer leccion del curso bloqueamos el boton de leccion anterior
        onPrevLeccion(false, dataCurso)
    }, [leccion?.id, dataCurso])

    useEffect(() => {
        if (!search.has("grupo")) return;

        user?.matriculas?.map((matricula: MatriculasInt) => {
            if (matricula?.grupo?.slug === search.get("grupo") && matricula?.id) setMatriculaActual(matricula)
        })

    }, [search, user?.matriculas])

    useEffect(() => {
        setCursoProgress((prev: any) => ({
            ...prev,
            matriculaId: matriculaActual?.id,
            cursoId: leccion?.modulo?.cursoId,
            cursoSlug: leccion?.modulo?.curso?.slug,
            cursoNombre: leccion?.modulo?.curso?.nombre,
            totalLecciones: leccion?.modulo?.curso?.meta?.totalLecciones,
        }))

        refreshProgress();

    }, [leccion])

    useEffect(() => {
        // Cada vez que cambie el estado del entregable desde una lección entregable,
        checkBlockedByEstadoEntregable();
    }, [estadoEntregable, leccion?.tipo]);

    /** Detectamos si la lección está completada */
    useEffect(() => {
        if (!leccion) return;

        if (checkLessonComplete(leccion?.id)) {
            setIsCompleted(true)
            setFeedbackBlock(true)
        } else {
            setIsCompleted(false)
            setFeedbackBlock(false)
        }

    }, [leccion, cursoProgress]);

    useEffect(() => {
        if(matriculaActual && matriculaActual?.grupo?.fundae === false) return setEndPrev(true)

        if (!leccion || !dataCurso) return setEndPrev(false);

        if (checkPrevLessonComplete(leccion, dataCurso)) {
            setEndPrev(true)
        } else {
            setEndPrev(false)
        }

    }, [cursoProgress, dataCurso, matriculaActual, leccion]);

    useEffect(() => {
        if (!feedback || feedback?.data?.length === 0 || !dataCurso) return;

        if (isCompleted && feedbackBlock) {
            if (localStorage.getItem("feedbackLeccion")) {
                ejecutarFeedback([FeedbackTypeEnum.LECCION], leccion?.tipo)
            }
            if (localStorage.getItem("feedbackModulo")) {
                ejecutarFeedback([FeedbackTypeEnum.MODULO, FeedbackTypeEnum.LECCION], leccion?.tipo)
            }
            if (localStorage.getItem("feedbackCurso")) {
                ejecutarFeedback([FeedbackTypeEnum.CURSO, FeedbackTypeEnum.MODULO, FeedbackTypeEnum.LECCION], leccion?.tipo)
            }
        }
    }, [isCompleted, feedback, dataCurso])

    useEffect(() => {
        const nextLeccion: LeccionInt | undefined | 'end' = getNextLeccion(dataCurso, false);

        nextLeccion === 'end'
            ? setMode(LessonModeEnum.END)
            : isCompleted
                ? setMode(LessonModeEnum.SIGUIENTE)
                : setMode(LessonModeEnum.BLOQUEADA);

    }, [isCompleted, dataCurso]);

    const checkLessonComplete = (leccionId: string) => {
        if (Reflect.has(cursoProgress?.data || {}, leccionId)) {
            if (cursoProgress?.data[leccionId]?.estado === "finalizacion") {
                return true
            }
        }

        return false
    };

    const checkPrevLessonComplete = (leccion: LeccionInt, curso: CursosInt) => {
        let prevLeccion: any;

        curso?.modulos?.find((modulo: any, index: number) => {
            if (leccion?.moduloId === modulo?.id) {
                if (leccion?.id === modulo?.lecciones[0]?.id) {
                    const prevModulo = curso?.modulos ? curso?.modulos[index - 1] : undefined;

                    if (prevModulo)
                        prevLeccion = prevModulo?.lecciones ? prevModulo?.lecciones[prevModulo.lecciones.length - 1] : undefined;

                } else {
                    const leccionIndex = modulo.lecciones?.findIndex((l: any) => l?.id === leccion?.id);

                    if (leccionIndex > -1) prevLeccion = modulo.lecciones[leccionIndex - 1];
                }
            }
        });

        if (!prevLeccion) {
            return true
        }

        if (prevLeccion) {
            return checkLessonComplete(prevLeccion?.id)
        }
    };

    const onLeccionStarted = async () => {
        if (user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) return

        if (user?.id && leccion?.modulo?.cursoId && leccion?.id && leccion?.moduloId)
            if (!cursoProgress?.data[leccion?.id] && cursoProgress?.matriculaId) {
                await uploadProgress({
                    matriculaId: cursoProgress?.matriculaId,
                    cursoId: leccion?.modulo?.cursoId,
                    moduloId: leccion?.moduloId,
                    leccion: {
                        id: leccion?.id,
                        estado: "acceso",
                        segundos: 0,
                    }
                }).then(() => refreshProgress())
                    .catch((error) => console.log(error))
            }
    };

    const onLeccionCompleted = async (leccion: LeccionInt) => {
        if (user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) return

        if (user?.id && leccion?.modulo?.cursoId && leccion?.id && leccion?.moduloId)
            if (cursoProgress?.matriculaId) {
                await uploadProgress({
                    matriculaId: cursoProgress?.matriculaId,
                    cursoId: leccion?.modulo?.cursoId,
                    moduloId: leccion?.moduloId,
                    leccion: {
                        id: leccion?.id,
                        estado: "finalizacion",
                        segundos: 0,
                    }
                }).then(() => {
                    setIsCompleted(true);
                    refreshProgress();

                }).catch((error) => console.log(error))
            }
    };

    // OBTENEMOS LA LECCION ANTERIOR
    const onPrevLeccion = (redirec = true, curso: CursosInt) => {
        if (!leccion) return;

        let prevLeccion: any;

        curso?.modulos?.find((modulo: any, index: number) => {
            // BUSCAMOS EL MODULO DE LA LECCION
            if (leccion?.moduloId === modulo.id) {
                // SI ES LA PRIMERA LECCION DEL MODULO SELECCIONAMOS EL ANTERIOR
                if (leccion?.id === modulo.lecciones[0].id) {
                    const prevModulo = curso?.modulos ? curso?.modulos[index - 1] : undefined;

                    if (prevModulo)
                        prevLeccion = prevModulo?.lecciones ? prevModulo?.lecciones[prevModulo.lecciones.length - 1] : undefined;
                } else {
                    // SI NO ES LA PRIMERA SELECCIONAMOS LA ANTERIOR
                    const index = modulo.lecciones?.findIndex((l: any) => l.id === leccion.id);

                    if (index > -1) prevLeccion = modulo.lecciones[index - 1];
                }
            }

            // Si la lección anterior no existe, bloqueamos el botón.
            if (!prevLeccion) setPrevIsBlocked(true);

            // Si la lección siguiente estaba bloquedada y hemos podido pasar de lección,
            // desbloqueamos el botón de la lección anterior
            if (nextIsBlocked && prevLeccion) setNextIsBlocked(false);

            return prevLeccion !== undefined;
        });

        if (prevLeccion && redirec) navigate(`/leccion/${prevLeccion?.slug}${addRedirect}`);
    };

    /** 
    * Devuelve la siguiente lección o 'end' si no hay más lecciones.
    */
    const getNextLeccion = (curso: CursosInt, feedback: boolean): LeccionInt | undefined | 'end' => {
        if (!leccion) return undefined;

        let nextLeccion: LeccionInt | undefined | 'end';

        /** 
        * Confirmamos que la leccion no sea de otro tipo
        */
        if (checkBlockedByEstadoEntregable()) return undefined;

        curso?.modulos?.find((modulo: any, index: number) => {
            /** 
            * Buecamos el modulo de la leccion
            */
            if (leccion?.moduloId === modulo?.id) {
                /** 
                 * Si es la última lección del módulo, pasamos al siguiente módulo.
                 */
                if (leccion?.id === modulo.lecciones[modulo.lecciones?.length - 1].id) {
                    /** 
                        * Si es la última lección del modulo, volvemos a setear el feedback a leccion para el siguiente modulo
                    */
                    const nextModulo = curso?.modulos && index + 1 < curso?.modulos?.length ? curso?.modulos[index + 1] : undefined;

                    if (nextModulo) {
                        if (mode === LessonModeEnum.BLOQUEADA && !feedbackBlock && feedback) {
                            ejecutarFeedback([FeedbackTypeEnum.MODULO, FeedbackTypeEnum.LECCION], leccion?.tipo)
                        }

                        nextLeccion = nextModulo?.lecciones ? nextModulo?.lecciones[0] : undefined;
                        return;
                    }
                    else {
                        /** 
                         * Si es la última lección del curso, lo damos por terminado.
                         */
                        if (mode === LessonModeEnum.BLOQUEADA && !feedbackBlock && feedback) {
                            ejecutarFeedback([FeedbackTypeEnum.CURSO, FeedbackTypeEnum.MODULO, FeedbackTypeEnum.LECCION], leccion?.tipo)
                        }

                        nextLeccion = 'end'
                        return;
                    }
                } else {
                    /** 
                    * Pasamos a la siguiente.
                    */
                    if (mode === LessonModeEnum.BLOQUEADA && !feedbackBlock && feedback) {
                        ejecutarFeedback([FeedbackTypeEnum.LECCION], leccion?.tipo)
                    }

                    const indexFind = modulo.lecciones?.findIndex((l: any) => l.id === leccion?.id);

                    if (indexFind > -1) nextLeccion = modulo.lecciones[indexFind + 1];
                }
            }

            if (nextLeccion === 'end') return true;
            if (prevIsBlocked && nextLeccion) setPrevIsBlocked(false);

            return nextLeccion !== undefined;
        });

        return nextLeccion;
    };

    // NAVEGAMOS A LA SIGUIENTE LECCION 
    const onNextLeccion = async (curso: CursosInt, feedback: boolean) => {
        setMode(LessonModeEnum.CARGANDO);

        const nextLeccion: LeccionInt | undefined | 'end' = getNextLeccion(curso, feedback);

        if (nextLeccion === 'end') {
            if (leccion?.id) {
                notify(toast, StatusEnum.success, '¡Enhorabuena, Has terminado el curso!');
                setMode(LessonModeEnum.END);

                onLeccionCompleted(leccion);
            }
        } else if (isCompleted) {
            setMode(LessonModeEnum.SIGUIENTE);

            navigate(`/leccion/${nextLeccion?.slug}${addRedirect}`);
        } else if (!isCompleted && !!nextLeccion) {
            if (leccion?.id) {
                onLeccionCompleted(leccion);
                setNextIsBlocked(false)
            }
        } else setMode(LessonModeEnum.SIGUIENTE);
    };

    /** Devolvemos TRUE si bloqueamos la siguiente lección hasta completar ejercicio. */
    const checkBlockedByEstadoEntregable = () => {
        // Si la lección es del tipo Entregable y no la ha completado, no pasamos a la siguiente.
        const testEntregable = leccion?.tipo === LessonTypeEnum.ENTREGABLE && estadoEntregable === EntregableEstadoEnum.PENDIENTE_ENTREGA;


        if (testEntregable) {
            // Bloqueamos el botón para que no puedan seguir.
            setNextIsBlocked(true);

            return true;
        } else {
            // Desbloqueamos el botón
            setNextIsBlocked(false);

            return false;
        }
    };

    return (
        loading ? <SkeletonLeccion />
            :
            <Flex
                bg="bg_color"
                w="100%"
                gap="40px"
                px="70px"
            >

                <Flex
                    flexDirection="column"
                    w="100%"
                    alignItems="center"
                    gap="30px"
                >
                    <Flex
                        w="100%"
                        rounded="5px"
                    >
                        {leccion?.tipo === LessonTypeEnum.VIDEO
                            ?
                            <VideoLesson
                                leccion={leccion}
                                onLeccionStarted={onLeccionStarted}
                            />
                            : leccion?.tipo === LessonTypeEnum.DIAPOSITIVA || leccion?.tipo === LessonTypeEnum.ENUNCIADO
                                ? <SlideLesson
                                    leccion={leccion}
                                />
                                : leccion?.tipo === LessonTypeEnum.ENTREGABLE
                                    ? <EntregableLesson
                                        leccion={leccion}
                                        setEstadoEntregable={setEstadoEntregable}
                                        endPrev={endPrev}
                                        matriculaActual={matriculaActual}
                                    />
                                    : leccion?.tipo === LessonTypeEnum.MARKDOWN
                                        ? <MarkdownLesson
                                            leccion={leccion}
                                        />
                                        : leccion?.tipo === LessonTypeEnum.RECURSO
                                            ? <RecursoLesson
                                                leccion={leccion}
                                            />
                                            : <Flex h="500px" />
                        }

                    </Flex>


                    {isRoleAllowed([UserRolEnum.ALUMNO], user?.role?.nombre) &&
                        <Flex
                            w="100%"
                            pb="40px"
                            gap="20px"
                            maxW="100%"
                            align="center"
                            justify="space-between"
                            display={{ base: 'none', sm: 'flex' }}
                        >
                            <Button
                                h="52px"
                                bg="purewhite"
                                rounded="10px"
                                color="font"
                                w="fit-content"
                                fontSize="18px"
                                fontWeight="500"
                                onClick={() => onPrevLeccion(true, dataCurso)}
                                isDisabled={prevIsBlocked}
                                leftIcon={<Icon as={BiLeftArrowAlt} boxSize="24px" />}
                            >
                                Lección anterior
                            </Button>

                            <Tooltip
                                hasArrow
                                rounded="5px"
                                padding="10px"
                                label={
                                    <Text
                                        textAlign="center"
                                        fontSize="16px"
                                    >
                                        Debes subir el ejercicio para continuar
                                    </Text>
                                }
                                isDisabled={LessonModeEnum.BLOQUEADA && !nextIsBlocked}
                            >
                                <Button
                                    h="52px"
                                    rounded="10px"
                                    w="fit-content"
                                    onClick={() => {
                                        const feedback = mode === LessonModeEnum.BLOQUEADA ? true : mode === LessonModeEnum.SIGUIENTE ? false : true

                                        mode === LessonModeEnum.END
                                            ? navigate(`/aprendizaje/${dataCurso?.slug}${addRedirect}`)
                                            : onNextLeccion(dataCurso, feedback)
                                    }}
                                    isDisabled={nextIsBlocked || !endPrev}
                                    _hover={{ opacity: '0.8' }}
                                    isLoading={mode === LessonModeEnum.CARGANDO}
                                    bg="main"
                                    color="purewhite"
                                    fontSize="18px"
                                    fontWeight="500"
                                >
                                    {mode === LessonModeEnum.BLOQUEADA ?
                                        <Flex
                                            align="center" gap="13px">
                                            Marcar como completada
                                            <Icon as={BiCheck} boxSize="24px" />
                                        </Flex>
                                        : mode === LessonModeEnum.SIGUIENTE ?
                                            <Flex align="center" gap="13px">
                                                Siguiente Lección
                                                <Icon as={BiRightArrowAlt} boxSize="24px" />
                                            </Flex>
                                            :
                                            <Flex align="center" gap="13px">
                                                Finalizar
                                                <Icon as={BiCheck} boxSize="24px" />
                                            </Flex>
                                    }
                                </Button>
                            </Tooltip>
                        </Flex>
                    }
                </Flex>

                <Flex
                    w="30%"
                >
                    <AccordeonModules
                        leccionActual={leccion}
                        dataModulos={dataCurso?.modulos}
                    />
                </Flex>

                {/* <ModalWebinar 
                    isOpen={isOpen}
                    onClose={onClose}
                /> */}

                <FbCursoModal
                    isOpen={isOpenFbCurso}
                    onClose={onCloseFbCurso}
                    feedback={fbCursoRef.current}
                />

                <FbModuloModal
                    isOpen={isOpenFbModulo}
                    onClose={onCloseFbModulo}
                    feedback={fbModuloRef.current}
                />

                <FbLeccionModal
                    isOpen={isOpenFbLeccion}
                    onClose={onCloseFbLeccion}
                    feedback={fbLeccionRef.current}
                />
            </Flex>
    );
};