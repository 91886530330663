import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { HiArrowRight } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";


interface Props {
    curso: any
}

export default function CardEntrega({ curso }: Props) {
    const navigate = useNavigate();
    const [search] = useSearchParams()
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""

    return (
        <Flex
            alignItems="start"
            flexDirection="column"
            bgGradient="linear(135deg, #4E5FE4 0%, #8694FF 100%)"
            rounded="5px"
            p="30px"
            gap="27px"
            w="100%"
        >
            <Flex
                alignItems="center"
                gap="10px"
            >

                <Text
                    color="purewhite"
                    fontSize="19px"
                    fontWeight="600"
                >
                    Próxima entrega
                </Text>
            </Flex>

            <Flex>
                <Text
                    color="purewhite"
                    fontSize="17px"
                    fontWeight="500"
                >
                    Ejercicio | {curso?.modulos[0]?.lecciones[0]?.nombre ? curso?.modulos[0]?.lecciones[0]?.nombre : "No hay ejercicios"}
                </Text>
            </Flex>

            {/* <Flex gap="7px" alignItems="center" w="100%">
                <Text
                    color="purewhite"
                    fontSize="14px"
                    fontWeight="500"
                >
                    80%
                </Text>

                <BarProgress
                    bg="secondary_variant"
                    bgProgress="purewhite"
                    height="5px"
                    progress={0}
                />

                <Text
                    color="purewhite"
                    fontSize="14px"
                    fontWeight="500"
                >
                    1/3
                </Text>

            </Flex> */}

            <Flex mx="auto">
                <Button
                    px="20px"
                    py="9px"
                    color="purewhite"
                    bg="main"
                    isDisabled={!curso?.modulos[0]?.lecciones[0]?.nombre}
                    fontSize="16px"
                    fontWeight="500"
                    rightIcon={<Icon as={HiArrowRight} boxSize="20px" />}
                    _hover={{ bg: "secondary_variant" }}
                    onClick={() => navigate(`/leccion/${curso?.modulos[0]?.lecciones[0]?.slug}${addRedirect}`)}
                >
                    Ir al ejercicio
                </Button>
            </Flex>
        </Flex>
    )
}
