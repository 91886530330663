// Chakra UI
import { Flex, Grid, Text, useToast } from '@chakra-ui/react';
// Components
import { CardCurso } from '../Components/Card/CardCurso';
// Interfaces
import { CursosInt } from '../../../interfaces/CoursesInt';
import { GrupoInt, MatriculasInt } from '../../../interfaces/MatriculasInt';
// Axios
import { SkeletonAprendizajeMatriculas } from '../Components/utils/Skeletons/SkeletonAprendizajeMatriculas';
import { CardSinInformacion, TypeCardSinInformacion } from '../../../shared/components/Customs/CardSinInformacion';
import { useEffect, useState } from 'react';
import { handleUnauthorized } from '../../../shared/utils/functions/handleErrors';
import { useAuthContex } from '../../../shared/context/user.context';
import { useNavigate } from 'react-router-dom';
import CardProgresos from '../Components/Card/CardProgresos';
import { CardActividad } from '../../Foro/Components/CardActividad';
import { useMatriculasAlumno, useMatriculasProximas } from '../../../shared/middlewares/matriculas.middleware';
import { CardCursoProximo } from '../Components/Card/CardCursoProximo';
import { useStats } from '../../../shared/middlewares/stats.middleware';
import { usePreguntasQuery } from '../../../shared/middlewares/foro.middlewate';
import { CardContador } from '../../Dashboard/Components/Cards/CardContador';
import { EstadoTypeEnum } from '../../../shared/utils/Types/EstadoTypeEnum';

export const AprendizajeMatriculas = () => {
    const { logout, user } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { matriculas: _matriculas, loading, error, status, errorsList, campusTutorizado } = useMatriculasAlumno({ user: user });
    const [selectedGrupo, setSelectedGrupo] = useState<GrupoInt>();
    const { preguntas } = usePreguntasQuery({
        query:
            selectedGrupo?.id
                ? `?grupo=${selectedGrupo?.id}&sortBy=createdAt&order=desc&limit=1`
                : undefined
    })
    const stats = useStats()

    const matriculas = _matriculas?.data?.filter((matricula: MatriculasInt) => matricula?.estado === EstadoTypeEnum.ACTIVO)
    const matriculasProximas = _matriculas?.data?.filter((matricula: MatriculasInt) => matricula?.estado === EstadoTypeEnum.PROXIMO)
    const matriculasInactivas = _matriculas?.data?.filter((matricula: MatriculasInt) => matricula?.estado === EstadoTypeEnum.INACTIVO)

    useEffect(() => {
        if (!matriculas || matriculas?.length === 0) return

        setSelectedGrupo(matriculas[0]?.grupo)
    }, [matriculas])

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

    return (
        (loading) ?
            <SkeletonAprendizajeMatriculas />
            :
            <Flex
                direction="column"
                gap="30px"
                px="50px"
                pb="40px"
            >
                {_matriculas?.data?.length > 0 ?
                    <Flex>
                        <Flex
                            gap="40px"
                            direction="column"
                        >
                            {matriculas?.length > 0 &&
                                <>
                                    <Text
                                        fontSize="28px"
                                        fontWeight="600"
                                        color="font"

                                    >
                                        Cursos Activos
                                    </Text>
                                    <Flex

                                    >
                                        <Grid
                                            gridTemplateColumns="repeat(2, 1fr)"
                                            gap="40px"
                                            w="100%"
                                            pr="40px"
                                        >

                                            {matriculas?.map((matricula: MatriculasInt, index: number) => (
                                                <CardCurso
                                                    key={index}
                                                    curso={matricula?.grupo?.curso}
                                                    grupoSlug={matricula?.grupo?.slug}
                                                    matricula={matricula}
                                                />
                                            ))

                                            }
                                        </Grid>
                                    </Flex>
                                </>
                            }

                            {matriculasInactivas?.length > 0 &&
                                <>
                                    <Text
                                        fontSize="28px"
                                        fontWeight="600"
                                        color="font"

                                    >
                                        Cursos finalizados
                                    </Text>
                                    <Flex

                                    >
                                        <Grid
                                            gridTemplateColumns="repeat(2, 1fr)"
                                            gap="40px"
                                            w="100%"
                                            pr="40px"
                                        >

                                            {matriculasInactivas?.map((matricula: MatriculasInt, index: number) => (
                                                <CardCurso
                                                    key={index}
                                                    curso={matricula?.grupo?.curso}
                                                    grupoSlug={matricula?.grupo?.slug}
                                                    matricula={matricula}
                                                />
                                            ))

                                            }
                                        </Grid>
                                    </Flex>
                                </>
                            }

                            {matriculasProximas?.length > 0 &&
                                <>
                                    <Text
                                        fontSize="28px"
                                        fontWeight="600"
                                        color="font"

                                    >
                                        Cursos por empezar
                                    </Text>
                                    <Flex

                                    >
                                        <Grid
                                            gridTemplateColumns="repeat(2, 1fr)"
                                            gap="40px"
                                            w="100%"
                                            pr="40px"
                                        >

                                            {matriculasProximas?.map((matricula: { fechas: any, grupo: { curso: CursosInt } }, index: number) => (
                                                <CardCursoProximo
                                                    key={index}
                                                    curso={matricula?.grupo?.curso}
                                                    fechas={matricula}
                                                />
                                            ))

                                            }
                                        </Grid>

                                    </Flex>
                                </>
                            }
                        </Flex>

                        {matriculas?.length > 0 &&
                            <Flex
                                mt="80px"
                                direction="column"
                                gap="40px"
                            >
                                {matriculas?.map((matricula: any, index: number) => (
                                    <Flex
                                        key={index}
                                        bg="purewhite"
                                        padding="30px"
                                        direction="column"
                                        borderRadius="8px"
                                    >
                                        <Text
                                            color="font"
                                            fontSize="14px"
                                            fontWeight="600"
                                        >
                                            Tu grupo {matricula?.grupo?.curso?.nombre} finalizará en:
                                        </Text>

                                        <CardContador
                                            stats={matricula}
                                        />
                                    </Flex>
                                ))}

                                {matriculas?.filter((matricula: MatriculasInt) => matricula?.grupo?.fundae === true)?.length > 0 &&
                                    <CardProgresos
                                        stats={stats?.data || []}
                                        button={true}
                                    />
                                }

                                {
                                    campusTutorizado &&

                                    <CardActividad
                                        preguntas={preguntas}
                                    />
                                }

                            </Flex>
                        }
                    </Flex>
                    :
                    <CardSinInformacion type={TypeCardSinInformacion.CURSOS} />
                }
            </Flex>
    );
}