import { Flex, Skeleton } from "@chakra-ui/react";

export default function SkeletonCarouselStats(){
    return(
        <Flex
            direction="column"
            justify="center"
            bg="purewhite"
            borderRadius="8px"
            pt="38px"
            pb="20px"
            px="20px"
            gap="40px"
            minW="100%"
            minH="370px"
        >
            <Flex
                gap="10px"
                pl="64px"
            >
                <Skeleton rounded="8px" boxSize="32px" />
                <Skeleton rounded="8px" h="32px" w="280px" />
            </Flex>

            <Flex
                gap="50px"
                w="100%"
                direction="column"
            >
                <Flex
                    gap="20px"
                    align="center"
                    justify="flex-start"
                    w="100%"
                >

                    <Skeleton boxSize="24px" rounded="8px" />

                    <Flex
                        flex="1"
                        h="180px"
                        pl="20px"
                        gap="20px"
                        
                    >
                        <Skeleton flex="1" minW="365px" rounded="8px" />
                        <Skeleton flex="1" minW="240px" rounded="8px" />
                        <Skeleton flex="1" minW="240px" rounded="8px" />
                        <Skeleton flex="1" minW="240px" rounded="8px" />
                        <Skeleton flex="1" minW="355px" rounded="8px" />
                    </Flex>

                    <Skeleton boxSize="24px" rounded="8px" />
                </Flex>

                <Flex
                    justify="center"
                    align="center"
                    gap="15px"
                >
                    <Skeleton boxSize="10px" rounded="50px" />
                    <Skeleton boxSize="10px" rounded="50px" />
                    <Skeleton boxSize="10px" rounded="50px" />

                </Flex>

            </Flex>

            
            
        </Flex>
    )
}