// Chakra UI
import { Box, Button, Flex, Heading, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react";
// Components
import { OpenPlayer } from "../../../ui/OpenPlayer/OpenPlayer";
// Interfaces
import { LeccionInt } from "../../../interfaces/CoursesInt";
// React
import { useEffect, useRef, useState } from "react";
import { notify } from "../../../shared/utils/functions/notify";
import { StatusEnum } from "../../../shared/utils/functions/notify";
import { EVENTO_VIDEO_PAUSE, EVENTO_VIDEO_PLAY } from "../../../shared/utils/Types/sesionControllerTypes";
import { useProgressContex } from "../../../shared/context/progress.context";
import { uploadProgress } from "../../../shared/middlewares/progress.middleware";
import { useSessionTimeContext } from "../../../shared/context/sessionTime.context";
import { BiEditAlt, BiPencil, BiTime } from "react-icons/bi";
import NotasModal from "../Components/NotasModal";
import { UserRolEnum } from "../../../shared/utils/Types/RolEnum";
import { useAuthContex } from "../../../shared/context/user.context";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";


interface Props {
    leccion: LeccionInt | null;
    enableKeyboard?: boolean;
    onLeccionStarted: (e?: any) => any;
}

export const VideoLesson = ({
    leccion,
    enableKeyboard,
    onLeccionStarted,
}: Props) => {
    const { user } = useAuthContex();
    const toast = useToast();
    const playerRef = useRef<any>();
    const [playedSeconds, setPlayedSeconds] = useState<number>(0);
    const { cursoProgress, refreshProgress } = useProgressContex();
    const [segundosLeccion, setSegundosLeccion] = useState<number>(0)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (leccion?.id && cursoProgress?.data)
            setSegundosLeccion(cursoProgress?.data[leccion?.id]?.segundos)

    }, [leccion?.id, cursoProgress])

    async function updateProgresoVideo() {
        if (user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) return

        if (
            leccion &&
            cursoProgress?.data[leccion?.id] &&
            leccion?.modulo?.cursoId &&
            cursoProgress?.matriculaId
        ) {
            if (cursoProgress?.data[leccion?.id]?.estado !== "finalizacion")
                await uploadProgress({
                    matriculaId: cursoProgress?.matriculaId,
                    cursoId: leccion?.modulo?.cursoId,
                    moduloId: leccion?.moduloId,
                    leccion: {
                        id: leccion?.id,
                        estado: "acceso",
                        segundos: Math.round(playedSeconds),
                    }
                }).then(() => refreshProgress())
                    .catch((error) => console.log(error))
        }
    }

    const handleOnStart = async () => {
        if (leccion) {
            onLeccionStarted(leccion);

        } else {
            notify(
                toast,
                StatusEnum.error,
                'La lección es indefinida. Actualice la página y contacte con soporte si el error persiste..'
            );
        }
    };

    function onPlay() {
        const onPlayEvent = new Event(EVENTO_VIDEO_PLAY);

        window.dispatchEvent(onPlayEvent);
    }

    function onPause() {
        const onPlayEvent = new Event(EVENTO_VIDEO_PAUSE);

        window.dispatchEvent(onPlayEvent);
    }

    const secondsToTime = (value: number) => {
        let hour: number | string;
        let minute: number | string;
        let seconds: number | string;

        hour = Math.floor(value / 3600);
        hour = (hour < 10) ? `0${hour}` : hour;

        minute = Math.floor((value / 60) % 60);
        minute = (minute < 10) ? `0${minute}` : minute;

        seconds = value % 60;
        seconds = (seconds < 10) ? `0${seconds}` : seconds;

        return `${hour}:${minute}:${seconds}`
    };

    useEffect(() => {
        const url = leccion?.contenido; // Asigna la URL a una variable

        if (url) {
            // Utiliza una expresión regular para extraer el número final
            const match = url.match(/\/(\d+)$/);

            if (match) {
                const numeroFinal = match[1];
                // console.log(numeroFinal);
            } else {
                // console.error('No se pudo extraer el número de la URL de Vimeo.');
            }
        }
    }, []);

    useEffect(() => {
        const obtenerIdVimeo = async () => {
            const url = leccion?.contenido;

            if (url) {
                const match = url.match(/\/(\d+)$/);

                if (match) {
                    const numeroFinal = match[1];

                    // API de Vimeo
                    const apiUrl = `https://api.vimeo.com/videos/${numeroFinal}`;

                    try {
                        // Request API de Vimeo
                        const response = await fetch(apiUrl, {
                            headers: {
                                Authorization: 'Bearer d440518350ead472fab0b41882993076',
                            },
                        });

                        if (response.ok) {
                            const data = await response.json();
                            // console.log(data.pictures.sizes[5].link_with_play_button);
                            const thumbnail = data.pictures.sizes[5].link_with_play_button
                        } else {
                            console.error('Error al hacer la solicitud a la API de Vimeo:', response.statusText);
                        }
                    } catch (error) {
                        console.error('Error en la solicitud a la API de Vimeo:', error);
                    }
                } else {
                    // console.error('No se pudo extraer el número de la URL de Vimeo.');
                }
            }
        };

        obtenerIdVimeo();
    }, []);



    return (
        <Flex direction="column" boxSize="100%">

            <Box px="1.5px">
                <OpenPlayer
                    player={playerRef}
                    leccion={leccion}
                    maxSpeed={2}
                    minSpeed={0.25}
                    onPlay={onPlay}
                    onPause={onPause}
                    onStart={handleOnStart}
                    onUpdate={updateProgresoVideo}
                    setPlayedSeconds={setPlayedSeconds}
                    playedSeconds={playedSeconds}
                    enableKeyboard={enableKeyboard}
                    src={leccion?.contenido || undefined}
                    segundosLeccion={segundosLeccion}
                />
            </Box>

            <Flex p="30px" justifyContent="space-between" alignItems="center" borderBottom="1px solid #EAEAEA">
                <Flex gap="25px" alignItems="center">
                    <Text
                        color="font"
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="140%"
                    >
                        {leccion?.nombre}
                    </Text>
                </Flex>

                {isRoleAllowed([UserRolEnum.ALUMNO, UserRolEnum.ADMIN], user?.role?.nombre) &&
                    <Button
                        position="relative"
                        bg="purewhite"
                        _hover={{ bg: "secondary_variant" }}
                        onClick={openModal}
                        color="secondary"
                        borderWidth="1px"
                        borderColor="secondary"
                        fontSize="15px"
                        fontWeight="500"
                        boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.04)"
                        rightIcon={<BiPencil fontSize="22px" />}
                    >
                        Hacer anotación
                    </Button>
                }

                <NotasModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    leccionId={leccion?.id}
                />
            </Flex>
        </Flex>
    )
};