import React, { useState, useEffect } from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
import { Chats } from "../views/Chats";
import { Panel } from "../views/Panel";
import { NewChatModal } from "../components/NewChatModal";
import { SkeletonChats } from "../components/utils/SkeletonChats";
import { SkeletonPanel } from "../components/utils/SkeletonPanel";
import { useMensajeId } from "../../../shared/middlewares/messages.middleware";
import { useAuthContex } from "../../../shared/context/user.context";
import { useParams } from "react-router-dom";
import { MensajesInt } from "../../../interfaces/MessagesInt";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { UserRolEnum } from "../../../shared/utils/Types/RolEnum";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../shared/components/Customs/CardSinInformacion";
import { EstadoTypeEnum } from "../../../shared/utils/Types/EstadoTypeEnum";

interface Props {
    mensajes: MensajesInt[];
    Refresh: any;
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    loading: boolean;
}

export const Mensajeria = ({
    mensajes,
    loading,
    Refresh,
    isOpen,
    onClose,
    onOpen,
}: Props) => {
    const { mensajeId } = useParams();
    const { user } = useAuthContex();
    const [matricula, setMatricula] = useState<MatriculasInt>();
    const { mensaje, Refresh: refreshMensaje } = useMensajeId({
        id: mensajeId,
        ignoreRequest:
            user?.role?.nombre === UserRolEnum.ALUMNO
                ? matricula?.id ? false : true
                : false,
        track:
            user?.role?.nombre === UserRolEnum.ALUMNO
                ? {
                    track: user?.tracking ? true : false,
                    matricula: matricula?.estado === EstadoTypeEnum.ACTIVO ? matricula?.id : undefined,
                }
                : undefined,
    });

    useEffect(() => {
        const mensajeSelected = mensajes?.find(
            (mensaje: any) => mensaje?.id === mensajeId
        );
            
        const matriculaId = mensajeSelected?.matriculaId

        user?.matriculas?.forEach((matricula: MatriculasInt) => {
            if (matricula && matricula?.id === matriculaId)
                setMatricula(matricula);
        });
    }, [mensajeId, mensajes]);

    return (
        <Flex direction="column" pt="20px">
            {!loading ? (
                <Flex w="100%">
                    <Flex bg="purewhite" h="85vh" borderRadius="5px" w="100%">
                        <Chats selected={mensaje} allChats={mensajes} onOpen={onOpen} />

                        {mensaje && (
                            <Panel selectedChat={mensaje} refreshMensaje={refreshMensaje} matricula={matricula} />
                        )}


                        {!mensaje && (
                            <CardSinInformacion type={TypeCardSinInformacion.MENSAJES} />
                        )}

                    </Flex>

                    <NewChatModal isOpen={isOpen} onClose={onClose} setRefreshChats={Refresh} />
                </Flex>
            ) : (
                <Flex direction="column" h="85vh" borderRadius="5px" w="100%">
                    <Flex bg="purewhite" h="100%" borderRadius="20px">
                        <SkeletonChats />

                        <SkeletonPanel />
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
