import { useCursoSlug } from "../../../shared/middlewares/courses.middleware";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Flex, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from "@chakra-ui/react";
import { useProgressContex } from "../../../shared/context/progress.context";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { useAuthContex } from "../../../shared/context/user.context";
import { TabContenido } from "./TabItems/TabContenido";
import { TabEjercicio } from "./TabItems/TabEjercicio";
import { SkeletonInformation } from "../Components/utils/Skeletons/SkeletonInformation";
import { TabInformacion } from "./TabItems/TabInformacion";
import CardObjetivos from "../Components/utils/CardObjetivos";
import CardEntrega from "../Components/utils/CardEntrega";
import { CardActividad } from "../../Foro/Components/CardActividad";
import { useMatriculasAlumno } from "../../../shared/middlewares/matriculas.middleware";
import { useStats } from "../../../shared/middlewares/stats.middleware";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { usePreguntasQuery } from "../../../shared/middlewares/foro.middlewate";
import { handleUnauthorized } from "../../../shared/utils/functions/handleErrors";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../shared/utils/Types/RolEnum";
import { EstadoTypeEnum } from "../../../shared/utils/Types/EstadoTypeEnum";
import { TabContenidoInactivo } from "./TabItems/TabContenidoInactivo";
import CardDescarga, { CardDescargaType } from "../../Dashboard/Components/Cards/CardDescarga";
import { fmtSnds } from "../../../shared/utils/functions/formatters";
import { CardRespuesta } from "../../Foro/Components/CardRespuesta";
import { useMensajes } from "../../../shared/middlewares/messages.middleware";
import { ca } from "date-fns/locale";

enum TabsInt {
    CONTENIDO = "contenido",
    REQUISITOS_FUNDAE = "requisitos-fundae",
    ACTIVIDADES = "actividades"
}

export const InformationCurso = () => {
    const { slug } = useParams();
    const { user, logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { setCursoProgress, refreshProgress } = useProgressContex();
    const { curso, loading, error, status, errorsList } = useCursoSlug(slug)
    const { matriculas, campusTutorizado } = useMatriculasAlumno({ user: user });
    const [search, setSearch] = useSearchParams()
    const [matriculaActual, setMatriculaActual] = useState<MatriculasInt>()
    const { mensajes } = useMensajes({
        query: "?sortBy=updatedAt&order=desc&limit=100"
    })
    const { preguntas } = usePreguntasQuery({
        query:
            matriculaActual?.grupoId
                ? `?grupo=${matriculaActual?.grupoId}&sortBy=createdAt&order=desc&limit=1`
                : undefined
    })
    const stats = useStats()
    const [typeTab, setTypeTab] = useState<"informacion" | "contenido" | "ejercicios">("informacion")
    const [tabIndex, setTabIndex] = useState<number>(typeTab === "informacion" ? 0 : typeTab === "contenido" ? 1 : 2)


    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast,
                redirectTo: "/aprendizaje"
            })
        }
    }, [error])

    useEffect(() => {
        if (search.has("tab"))
            if (search.get("tab") === TabsInt.CONTENIDO) setTabIndex(0)
            else if (search.get("tab") === TabsInt.REQUISITOS_FUNDAE) setTabIndex(1)
            else if (search.get("tab") === TabsInt.ACTIVIDADES) setTabIndex(2)

    }, [search])

    const handleTabsChange = (index: number) => {
        setTabIndex(index)
        search.set(
            "tab", index === 0 ? TabsInt.CONTENIDO
            : index === 1 ? TabsInt.REQUISITOS_FUNDAE
                : TabsInt.ACTIVIDADES
        )
        setSearch(search)
    }


    useEffect(() => {
        if (!search.has("grupo")) return;

        let matriculaCurso: { id: string, estado: EstadoTypeEnum } | undefined = undefined

        matriculas?.data?.map((matricula: MatriculasInt) => {
            if (matricula?.grupo?.slug === search.get("grupo") && matricula?.id) {
                setMatriculaActual(matricula)
                return matriculaCurso = { id: matricula?.id, estado: matricula?.estado as EstadoTypeEnum }
            }
        })

        if (
            matriculaCurso &&
            matriculaCurso['estado'] === EstadoTypeEnum.ACTIVO &&
            user?.role?.nombre === UserRolEnum.ALUMNO
        ) {
            setCursoProgress((prev: any) => ({
                ...prev,
                matriculaId: matriculaCurso?.id,
                cursoId: curso?.id,
                cursoSlug: curso?.slug,
                cursoNombre: curso?.nombre,
                totalLecciones: curso?.meta?.totalLecciones,
            }))

            refreshProgress()
        }
    }, [curso, search, matriculas])

    const checkRequisitos = (tiempoAcceso: boolean): boolean => {
        if (tiempoAcceso === false) {
            if (
                matriculaActual?.puntuacionMedia && matriculaActual?.puntuacionMedia >= 75 &&
                matriculaActual?.progresoEntregables && matriculaActual?.progresoEntregables >= 75 &&
                matriculaActual?.progresoEntregables && matriculaActual?.progresoEntregables >= 75
            ) return true;
            else
                return false;
        }

        if (
            matriculaActual?.puntuacionMedia && matriculaActual?.puntuacionMedia >= 75 &&
            matriculaActual?.progresoEntregables && matriculaActual?.progresoEntregables >= 75 &&
            matriculaActual?.progresoEntregables >= 75 &&
            parseInt(fmtSnds(matriculaActual?.tiempoAcceso)) >= (Number(matriculaActual?.grupo?.curso?.duracion) * 75) / 100
        ) return true;

        return false;
    }


    return (
        loading ? <SkeletonInformation />
            :
            <Flex
                //TODO: Campiar p altura a 20px
                direction="column"
                mb="40px"
                w="100%"
                px={{ base: "50px", md: "50px" }}
                pb="70px"
            >
                <Flex
                    gap="10px"
                    align="center"
                >

                    <Icon
                        alignItems="center"
                        color="font"
                        boxSize="24px"
                        cursor="pointer"
                        onClick={() => navigate("/aprendizaje")}
                        as={HiOutlineChevronLeft}
                        w="fit-content"
                    />

                    <Text
                        fontSize="28px"
                        fontWeight="600"
                        color="font"
                    >
                        {curso?.nombre}
                    </Text>
                </Flex>


                <Flex
                    rounded="15px"
                >
                    <Tabs
                        w="100%"
                        variant="soft-rounded"
                        index={tabIndex}
                        onChange={handleTabsChange}
                    >
                        <TabList
                            fontSize={{ base: "12px", md: "15px" }}
                            fontWeight="400"
                            color="main"
                            lineHeight="22px"
                            gap="40px"
                            rounded="full"
                            my={{ base: "20px", md: "40px" }}

                        >
                            <Flex
                                bg="main"
                                rounded="full"
                                gap="5px"
                                position="fixed"
                                boxShadow='0px 4px 15px 0px rgba(199, 205, 255, 0.60)'
                            >
                                <Tab
                                    fontWeight="500"
                                    gap="10px"
                                    px="16px"
                                    fontSize="14px"
                                    alignItems="center"
                                    onClick={() => setTypeTab("contenido")}
                                    border="2px solid"
                                    borderColor="main"
                                    color="purewhite"
                                    _selected={{ color: "main", bg: "purewhite" }}
                                >
                                    Contenido
                                </Tab>

                                {matriculaActual?.grupo?.fundae &&
                                    <Tab
                                        fontWeight="500"
                                        gap="10px"
                                        px="16px"
                                        fontSize="14px"
                                        alignItems="center"
                                        onClick={() => setTypeTab("informacion")}
                                        border="2px solid"
                                        borderColor="main"
                                        color="purewhite"
                                        _selected={{ color: "main", bg: "purewhite" }}
                                    >
                                        {matriculaActual?.grupo?.fundae ? "Requisitos FUNDAE" : "Información"}
                                    </Tab>
                                }

                                {matriculaActual?.meta?.tutorizado &&

                                    <>
                                        {(matriculaActual?.estado === EstadoTypeEnum.ACTIVO || user?.role?.nombre !== UserRolEnum.ALUMNO) &&
                                            <Tab
                                                fontWeight="500"
                                                gap="10px"
                                                px="18px"
                                                fontSize="14px"
                                                alignItems="center"
                                                onClick={() => setTypeTab("ejercicios")}
                                                border="2px solid"
                                                borderColor="main"
                                                color="purewhite"
                                                _selected={{ color: "main", bg: "purewhite" }}

                                            >
                                                Actividades
                                            </Tab>
                                        }
                                    </>
                                }

                            </Flex>
                        </TabList>

                        <Flex
                            gap="40px"
                        >
                            <TabPanels>
                                <TabPanel p="0">
                                    {/* <Webinar
                                    onContinueLeccion={onContinueLeccion}
                                    curso={curso}
                                /> */}

                                    {(matriculaActual?.estado === EstadoTypeEnum.ACTIVO || user?.role?.nombre !== UserRolEnum.ALUMNO) ?
                                        <TabContenido
                                            curso={curso}
                                        />
                                        :
                                        <TabContenidoInactivo
                                            curso={curso}
                                        />
                                    }
                                </TabPanel>

                                {matriculaActual?.grupo?.fundae &&

                                    <TabPanel p="0">

                                        {
                                            <TabInformacion
                                                pregunta={preguntas}
                                                matricula={matriculaActual}
                                                curso={curso}
                                            />
                                        }
                                    </TabPanel>
                                }

                                {
                                    matriculaActual?.meta?.tutorizado &&

                                    <TabPanel p="0">
                                        {(matriculaActual?.estado === EstadoTypeEnum.ACTIVO || user?.role?.nombre !== UserRolEnum.ALUMNO) &&
                                            <TabEjercicio
                                                curso={curso}
                                            />
                                        }
                                    </TabPanel>

                                }

                            </TabPanels>

                            {isRoleAllowed([UserRolEnum.ALUMNO, UserRolEnum.ADMIN], user?.role?.nombre) &&
                                <Flex
                                    direction="column"
                                    gap="40px"
                                    w="30%"
                                    mt="40px"
                                    h="fit-content">

                                    {!matriculaActual?.grupo?.fundae &&

                                        <CardDescarga
                                            type={CardDescargaType.DIPLOMA}
                                            tooltip="Descarga tu diploma"
                                            matricula={matriculaActual}
                                            isDisabled={!checkRequisitos(false)}
                                        />
                                    }

                                    {
                                        matriculaActual?.meta?.tutorizado &&
                                        <>
                                            {(matriculaActual?.estado === EstadoTypeEnum.ACTIVO || user?.role?.nombre !== UserRolEnum.ALUMNO) &&
                                                <Flex h="100%">
                                                    <CardEntrega
                                                        curso={curso}
                                                    />
                                                </Flex>
                                            }
                                        </>
                                    }
                                    {

                                    }

                                    {
                                        matriculaActual?.meta?.tutorizado &&
                                        <>
                                            {(matriculaActual?.estado === EstadoTypeEnum.ACTIVO || user?.role?.nombre !== UserRolEnum.ALUMNO) &&
                                                <CardActividad
                                                    preguntas={preguntas}
                                                />
                                            }
                                        </>
                                    }

                                    {/* {
                                        !matriculaActual?.grupo?.fundae &&

                                        mensajes?.map((mensaje: any, index: number) => (
                                            <CardRespuesta
                                                key={index}
                                                respuesta={mensaje}
                                            />

                                        ))

                                    } */}
                                </Flex>
                            }
                        </Flex>
                    </Tabs>
                </Flex>
            </Flex>
    )
};