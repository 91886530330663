import { Flex, Text } from "@chakra-ui/react";
import { DrawerAlert } from "../../Drawer/DrawerAlert";
import { useState } from "react";
import { useAuthContex } from "../../../context/user.context";

interface Props {
    title: string;
}

export const BottomSidebar = ({ title }: Props) => {
    const { user } = useAuthContex();
    const [openCalendar, setOpenCalendar] = useState<boolean>(false)
    const [openAlert, setOpenAlert] = useState<boolean>(false)

    return (
        <Flex
            alignItems="center"
        >
            <Flex
                display="inline-block"
                bgGradient="linear(90deg, main 0%, secondary_variant 100%)"
                bgClip="text"
            >
                <Text
                    fontSize="22px"
                    fontWeight="700"
                    color="transparent"
                    display="inline-block"
                >
                    {title}
                </Text>
            </Flex>

            <Flex
                gap="20px"
                alignItems="center"
                py="15px"
                direction="column"
            >


            </Flex>

            <DrawerAlert isOpen={openCalendar} setIsOpen={setOpenCalendar} callComponent="calendar" />
            <DrawerAlert isOpen={openAlert} setIsOpen={setOpenAlert} callComponent="alert" />
        </Flex>
    );
}