import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";
import { FeedbackTypeEnum } from "../utils/Types/FeedbackTypeEnum";

export const addFeedbackUser = async (feedbackUser: {
    tipo: FeedbackTypeEnum,
    feedback: object,
    feedbackConfigId: string,
    recursoId: string,
}) => {
    const { tenant, client } = await tenantInstance();
    
    return await _axios.post(
        `${tenant}/${client}/feedbackUsers`,
        feedbackUser,
        {}
    ).then((response: any) => response);
};