import { Flex, Text } from "@chakra-ui/react";
import userPerfil from "../../../assets/img/Avatar.png"
import { ChatInt, MensajesInt } from "../../../interfaces/MessagesInt";
import { useAuthContex } from "../../../shared/context/user.context";
import MarkdownPreview from '@uiw/react-markdown-preview';
import Markdown from 'markdown-to-jsx';
import { format } from "date-fns";
import { CustomAvatar } from "../../../shared/components/Avatar/CustomAvatar";
import { useEffect, useRef, useState } from "react";

interface Props {
    chat: ChatInt;
}

export const MessagesItem = ({ chat }: Props) => {
    const { user } = useAuthContex();
    /*
**  Obtenemos el tamaño de la ventana para ajustar el ancho de la tabla
*/
    const isClient = typeof window === 'object';
    const lastWidth = useRef<any>();
    const getSize = () => isClient ? window.innerWidth : undefined;
    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        if (!isClient) return

        const handleResize = () => {
            if (window?.innerWidth !== lastWidth.current) {
                const width = getSize();
                lastWidth.current = width;
                setWindowSize(width)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <Flex direction="column" w="100%" pos="relative">
            {chat?.mensajes?.map((mensaje: MensajesInt, index: number) => {
                if (user?.id === mensaje?.receptorId) {
                    return (
                        <Flex
                            key={index}
                            direction="column"
                            color="font"
                            bg="purewhite"
                            borderBottom="1px solid"
                            borderColor="light_grey"
                            p="25px 30px"
                        >
                            <Flex
                                justifyContent="space-between"
                            >
                                <Flex
                                    gap="10px"
                                >
                                    <CustomAvatar
                                        isHover={false}
                                        src={mensaje?.emisor?.avatar?.url || userPerfil}
                                        name={mensaje?.emisor?.nombre || ""}
                                        rounded="50px"
                                        size="35px"
                                    />

                                    <Text
                                        color="black"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        {mensaje?.emisor && mensaje?.emisor?.nombre + mensaje?.emisor?.apellidos}
                                    </Text>
                                </Flex>

                                <Text
                                    color="secondary_variant_font"
                                    fontSize="11px"
                                    fontWeight="500"
                                >
                                    {mensaje?.createdAt && format(new Date(mensaje?.createdAt), "dd/MM/yy HH:mm")}
                                </Text>

                            </Flex>

                            <Flex>
                                {/* <MarkdownPreview
                                    components={{
                                        code({ children, ...props }) {
                                            return (
                                                <code {...props} style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap', maxWidth: "500px" }}>
                                                    {children}
                                                </code>
                                            )
                                        },
                                        pre({ children, ...props }) {
                                            return (
                                                <code {...props} style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap', maxWidth: "500px" }}>
                                                    {children}
                                                </code>
                                            )
                                        }
                                    }}
                                    source={mensaje?.texto || ''}
                                    style={{
                                        padding: "10px",
                                        fontWeight: "400",
                                        fontSize: '16px',
                                        width: '100%',
                                        background: "#FFFFFF",
                                        color: "#252F40",
                                        maxWidth: windowSize ? `${windowSize - 670}px` : "100%"
                                    }}
                                /> */}
                                <Markdown
                                    style={{
                                        padding: "10px",
                                        fontWeight: "400",
                                        fontSize: '16px',
                                        width: '100%',
                                        background: "#FFFFFF",
                                        color: "#252F40",
                                        maxWidth: windowSize ? `${windowSize - 670}px` : "100%"
                                    }}
                                >
                                    {mensaje?.texto || ''}
                                </Markdown>
                            </Flex>
                        </Flex>
                    );
                }

                if (user?.id === mensaje?.emisorId) {
                    return (
                        <Flex
                            key={index}
                            direction="column"
                            color="font"
                            bg="purewhite"
                            borderBottom="1px solid"
                            borderColor="light_grey"
                            p="25px 30px"
                        >
                            <Flex
                                justifyContent="space-between"
                            >
                                <Flex
                                    gap="10px"
                                >

                                    <CustomAvatar
                                        isHover={false}
                                        src={mensaje?.emisor?.avatar?.url || userPerfil}
                                        name={mensaje?.emisor?.nombre || ""}
                                        rounded="50px"
                                        size="35px"
                                    />

                                    <Text
                                        color="black"
                                        fontSize="14px"
                                        fontWeight="600"
                                        letterSpacing="-0.093px"
                                    >
                                        {mensaje?.emisor && mensaje?.emisor?.nombre + " " + mensaje?.emisor?.apellidos}
                                    </Text>
                                </Flex>


                                <Text
                                    color="secondary_variant_font"
                                    fontSize="11px"
                                    fontWeight="500"
                                >
                                    {mensaje?.createdAt && format(new Date(mensaje?.createdAt), "dd/MM/yy HH:mm")}
                                </Text>
                            </Flex>

                            <Flex>
                                {/* <MarkdownPreview
                                    components={{
                                        code({ children, ...props }) {
                                            return (
                                                <code {...props} style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap', maxWidth: "500px" }}>
                                                    {children}
                                                </code>
                                            )
                                        },
                                        pre({ children, ...props }) {
                                            return (
                                                <code {...props} style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap', maxWidth: "500px" }}>
                                                    {children}
                                                </code>
                                            )
                                        }
                                    }}
                                    source={mensaje?.texto || ''}
                                    style={{
                                        padding: "10px",
                                        fontWeight: "400",
                                        fontSize: '16px',
                                        width: '100%',
                                        background: "#FFFFFF",
                                        color: "#252F40",
                                        maxWidth: windowSize ? `${windowSize - 670}px` : "100%"
                                    }}
                                /> */}
                                <Markdown
                                    style={{
                                        padding: "10px",
                                        fontWeight: "400",
                                        fontSize: '16px',
                                        width: '100%',
                                        background: "#FFFFFF",
                                        color: "#252F40",
                                        maxWidth: windowSize ? `${windowSize - 670}px` : "100%"
                                    }}
                                >
                                    {mensaje?.texto}
                                </Markdown>
                            </Flex>
                        </Flex>
                    );
                }
            })}
        </Flex>
    );
};