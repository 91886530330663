import { Flex } from "@chakra-ui/react";
import Cursos from "../Components/Cursos/Cursos";
import { useMatriculasAlumno } from "../../../shared/middlewares/matriculas.middleware";
import { useAuthContex } from "../../../shared/context/user.context";
import SkeletonDashboard from "../Components/utils/SkeletonDashboard";
import { GrupoInt } from "../../../interfaces/MatriculasInt";
import { useEffect, useState } from "react";
import { usePreguntasQuery } from "../../../shared/middlewares/foro.middlewate";
import Mensajes from "../../Aprendizaje/Components/utils/Mensajes";
import Notificaciones from "../../Aprendizaje/Components/utils/Notificaciones";
import CarouselStatsCursos from "../Components/CarouselStatsCursos";
import CarouselAnuncios from "../Components/CarouselAnuncios";

export default function Information() {
    const { user } = useAuthContex();
    const { matriculas, loading, campusTutorizado } = useMatriculasAlumno({ user: user });
    const [selectedGrupo, setSelectedGrupo] = useState<GrupoInt>();
    const { preguntas } = usePreguntasQuery({
        query: selectedGrupo?.id
            ? `?grupo=${selectedGrupo?.id}&sortBy=createdAt&order=desc&limit=2`
            : undefined,
    });

    useEffect(() => {
        if (!matriculas) return;

        setSelectedGrupo(matriculas?.data[0]?.grupo);
    }, [matriculas]);

    return loading ? (
        <SkeletonDashboard />
    ) : (
        <Flex
            flex="1"
            gap="30px"
            direction="column"
        >
            <Flex
                flex="1"
            >
                <CarouselStatsCursos />
            </Flex>

            <Flex
                flex="1"
                direction="column"
                gap="30px"
            >
                <Flex
                    flex="1"
                    gap="30px"
                >
                    <Flex
                        width="50%"
                    >
                        <CarouselAnuncios />
                    </Flex>

                    <Flex
                        width="50%"
                    >
                        <Cursos
                            matriculas={matriculas}
                        />
                    </Flex>
                </Flex>

                {
                    campusTutorizado &&

                    <Flex
                        flex="1"
                        gap="30px"
                    >
                        <Flex
                            width="50%"
                        >
                            <Notificaciones
                                preguntas={preguntas}
                            />
                        </Flex>

                        <Flex
                            width="50%"
                        >
                            <Mensajes
                                cursoId={matriculas?.curso?.id}
                            />
                        </Flex>
                    </Flex>

                }

            </Flex>
        </Flex>
    );
}
