// Chakra Ui
import { Box, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
// Icons
import { BiLeftIndent, BiRightIndent } from "react-icons/bi";
import { useProgressContex } from "../../../../shared/context/progress.context";
import { BarProgress } from "../../../../shared/components/Progress/BarProgress";

interface Props {
    inTop: boolean;
}

export const AccordeonHeader = ({ inTop }: Props) => {
    const { cursoProgress } = useProgressContex();
    const progress = Math.round(cursoProgress?.porcentajeCurso || 0)

    return (
        progress > 0
            ?
            <Flex
                w="100%"
                gap="8px"
                p="32px 32px 18px"
                direction="column"
                transition="box-shadow 0.3s"
                boxShadow={inTop ? '0px 4px 18px rgba(18, 22, 37, 0.1)' : 'unset'}
            >
                <Flex
                    justify="center"
                    align="center"
                    w="100%"
                >
                    <Flex
                        gap="4px"
                        align="center"
                    >

                        <Box
                            fontWeight="600"
                            fontSize="16px"
                            color="font"
                        >
                            Contenido del curso
                        </Box>
                    </Flex>
                </Flex>

                <Flex
                    align="center"
                    gap="10px"
                >
                    <BarProgress
                        height="6px"
                        progress={progress}
                    />
                    <Box fontWeight="500" fontSize="12px" color="dark_grey">
                        {progress}%
                    </Box>
                </Flex>

            </Flex>
            :
            <Flex
                w="100%"
                transition="box-shadow 0.3s"
                boxShadow={inTop ? '0px 4px 18px rgba(18, 22, 37, 0.1)' : 'unset'}
                pt="20px"
                justify="center"
                alignItems="center"
            >
                <Text
                    fontWeight="600"
                    fontSize="16px"
                    color="font"
                >
                    Contenido Del Curso
                </Text>
            </Flex>
    );
};