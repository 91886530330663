import { Button, Flex, Icon, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { BiBookmarks, BiDownload } from "react-icons/bi";
import { HiOutlineClipboardList } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getDiploma } from "../../../../shared/middlewares/users.middleware";
import { StatusEnum, customNotify, notify } from "../../../../shared/utils/functions/notify";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import InfoDiplomaModal from "../Modales/InfoDiplomaModal";

export enum CardDescargaType {
    CUESTIONARIO = "cuestionario",
    DIPLOMA = "diploma"
}

interface Props {
    matricula: any;
    type: CardDescargaType;
    isDisabled?: boolean;
    tooltip: string;
    tooltipDisabled?: string;
}

export default function CardDescarga({
    matricula,
    type,
    isDisabled = false,
    tooltip,
    tooltipDisabled
}: Props) {
    const navigate = useNavigate();
    const toast = useToast();
    const { onOpen, isOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState<boolean>(false);

    const handleDiploma = (matriculaId: string | undefined) => {
        if (!matriculaId) return;
        setLoading(true)

        getDiploma(matriculaId)
            .then((response: any) => {
                toast.closeAll()
                isOpen && onClose()

                customNotify({
                    toast: toast,
                    status: StatusEnum.success,
                    title:
                        <Flex
                            alignItems="center"
                            gap="15px"
                        >
                            <Text
                                fontWeight="500"
                            >
                                Diploma generado correctamente
                            </Text>

                            <Button
                                h="fit-content"
                                w="fit-content"
                                p="5px 15px"
                                fontSize="13px"
                                fontWeight="500px"
                                border="1px solid"
                                borderColor="purewhite"
                                color="purewhite"
                                leftIcon={<Icon as={BiDownload} boxSize="20px" />}
                                bg="inherit"
                                rounded="5px"
                                _hover={{ bg: "inherit" }}
                                _active={{ transform: "scale(0.9)" }}
                                onClick={() => {
                                    window.open(response?.data?.data, '_blank')
                                    toast.closeAll()
                                }}
                            >
                                Descargar
                            </Button>
                        </Flex>
                })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => notify(toast, StatusEnum.error, error?.message))

                toast.closeAll()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleCuestionario = () => {
        if (!matricula) return;

        if (!matricula?.formularioCompletado) return navigate(`/cuestionario?curso=${matricula?.grupo?.curso?.slug}&grupo=${matricula?.grupo?.slug}`)

        return handleDiploma(matricula?.id);
    }

    return (
        <Tooltip
            label={!isDisabled ? tooltip : tooltipDisabled}
            hasArrow
            placement="bottom"
            rounded="5px"
            p="10px"
            textAlign="center"
        >
            <Flex
                direction="column"
                p="20px"
                bg="purewhite"
                justify="space-between"
                rounded="8px"
                border="2px solid"
                borderColor="secondary"
                minW="220px"
                w="full"
                boxShadow="0px 4px 15px 0px rgba(199,205,255,0.6)"
                opacity={isDisabled ? 0.5 : 1}
                cursor={isDisabled ? "not-allowed" : "default"}
            >
                <Flex
                    align="center"
                    justify="space-between"
                    gap="20px"
                >
                    <Flex
                        direction={{ xs: "column", xl: "row" }}
                        gap={{ base: "10px", md: "none" }}
                    >
                        <Text
                            fontSize="16px"
                            color="font"
                            fontWeight="600"
                        >
                            {type === CardDescargaType.CUESTIONARIO ? "Realizar" : "Descargar"}
                        </Text>

                        {type === CardDescargaType.CUESTIONARIO && <Text color={"font"} fontSize="16px" fontWeight="700">CUESTIONARIO</Text>}
                        {type === CardDescargaType.DIPLOMA && <Text color={"font"} fontSize="16px" fontWeight="700">DIPLOMA</Text>}
                    </Flex>

                    <Icon
                        color="secondary"
                        boxSize="32px"
                        as={type === CardDescargaType.CUESTIONARIO
                            ? HiOutlineClipboardList
                            : BiBookmarks
                        }
                    />

                </Flex>

                <Button
                    fontSize="14px"
                    fontWeight="500"
                    color="purewhite"
                    bg="secondary"
                    w="fit-content"
                    mt="10px"
                    isLoading={(type === CardDescargaType.DIPLOMA && matricula?.diplomaDescargado && matricula?.fechaDescargaDiploma) ? loading : undefined}
                    isDisabled={isDisabled}
                    onClick={() => {
                        if (type === CardDescargaType.CUESTIONARIO) return handleCuestionario();
                        if (type === CardDescargaType.DIPLOMA) {
                            if(matricula?.diplomaDescargado && matricula?.fechaDescargaDiploma) 
                                handleDiploma(matricula?.id);
                            else onOpen();
                        }
                    }}
                >
                    {type === CardDescargaType.CUESTIONARIO ? "Realizar" : "Descargar"}
                </Button>

                <InfoDiplomaModal
                    isOpen={isOpen}
                    onClose={onClose}
                    handleDiploma={handleDiploma}
                    matricula={matricula}
                    loading={loading}
                />
            </ Flex >
        </Tooltip>
    )
}
