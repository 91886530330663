import _axios from "../services/http.service";
import useSWR from "swr";
import tenantInstance from "../services/tenantInstance.service";

const { tenant, client } = await tenantInstance();

const ENDPOINT_DASHBOARD = "statsDashboard"
const ENDPOINT_SESIONES_DASHBOARD = "statsSesionesDashboard"

export const getStats = async (endpoint: string) => await _axios.get(endpoint).then((response: any) => response)

export const useStats = (query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_DASHBOARD + query;
    const { isLoading, data, mutate, error } = useSWR(endpoint, getStats);
        
    return {
        data: data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

export const useStatsSesiones = (query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_SESIONES_DASHBOARD + query;
    const { isLoading, data, mutate, error } = useSWR(endpoint, getStats);
        
    return {
        data: data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}