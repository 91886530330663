import { Flex, Text } from "@chakra-ui/react";
import CardSinActividad from "../../../pages/Foro/Components/CardSinActividad";
import CardActualizacion from "./CardActualizacion";
import espacioVacio from "../../../assets/img/espacioVacio.png";

interface Props {
    correccion?: any;
    entregables?: any;
}


export default function CardActualizaciones({ correccion }: Props) {

    const ultimasCorrecciones = correccion?.slice(0, 3);

    return (
        <Flex
            direction="column"
            gap="30px"
            bg="purewhite"
            pt="30px"
            px="20px"
            pb="20px"
            rounded="5px"
            h="fit-content"
        >
            <Text
                fontSize="16px"
                fontWeight="700"
                color="font"
                px="10px"
            >
                Actualizaciones
            </Text>


        
    
            {ultimasCorrecciones?.map((correccion:any, index:number) => (
                <CardActualizacion
                    key={index}
                    correccion={correccion}
                />
            ))}

            
            {!ultimasCorrecciones?.length && (
                <CardSinActividad
                    titulo="Este espacio está vacío"
                    mensaje="Aquí podrás consultar las últimas actualizaciones de tus entregables."
                    src={espacioVacio}
                />
            )}
        </Flex>
    )
}
