import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import { useData } from "../../../shared/middlewares/middlewares";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import CardAnuncio from "./Cards/cardAnuncio";



export default function CarouselAnuncios() {
    const { data: anuncios } = useData({
        endpoint: EndpointTypes.ANUNCIOS,
      })
    const [translate, setTranslate] = useState(0)

    return (
        <Flex
            flex="1"
            borderRadius="8px"
            overflow="hidden"
          >
            {anuncios?.data?.length > 0 ?
              anuncios?.data?.map((anuncio:any,index:number)=>(
                <Flex
                  minW="100%"
                  key={index}
                  transform={`translateX(${translate}%)`}
                  transition="ease 300ms"
                >
                  <CardAnuncio
                    translateAnuncios={translate} 
                    index={index} 
                    anuncio={anuncio} 
                    setTranslateAnuncios={setTranslate}
                    maxTranslate={-(anuncios?.data?.length-1)*100}
                    countAnuncion={anuncios?.data?.length}
                    />
                </Flex>
              ))
              :
              <Flex
                minW="100%"
              >
                <CardAnuncio 
                  isEmpty
                  countAnuncion={anuncios?.data?.length}
                />
              </Flex>
            }
            
          </Flex>
    )
}