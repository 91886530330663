import { Flex, useDisclosure, useToast, Button } from "@chakra-ui/react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { Tema } from "./Views/Tema"
import { Pregunta } from "./Views/Pregunta"
import { useEffect, useState } from "react"
import { usePreguntas, useTemasGrupos } from "../../shared/middlewares/foro.middlewate"
import { CardSinInformacion, TypeCardSinInformacion } from "../../shared/components/Customs/CardSinInformacion"
import { useAuthContex } from "../../shared/context/user.context"
import { GrupoInt, MatriculasInt } from "../../interfaces/MatriculasInt"
import { handleUnauthorized } from "../../shared/utils/functions/handleErrors"
import { GruposForo } from "./Views/Grupo"
import { NuevaPreguntaModal } from "./Components/NuevaPreguntaModal"
import { useMatriculasAlumno } from "../../shared/middlewares/matriculas.middleware"
import { useGruposTutor } from "../../shared/middlewares/grupos.middleware"
import { UserRolEnum } from "../../shared/utils/Types/RolEnum"
import { SekeletonForo } from "./Components/utils/SkeletonForo"
import { CardActividad } from "./Components/CardActividad"
import { TemasListTutor } from "./Components/TemasList/TemasListTutor"
import { TemasListAlumno } from "./Components/TemasList/TemasListAlumno"
import { SkeletonCards } from "./Components/utils/SkeletonCards"
import { EstadoTypeEnum } from "../../shared/utils/Types/EstadoTypeEnum"

export const Foro = () => {
    const { user, logout } = useAuthContex();
    const toast = useToast();
    const navigate = useNavigate();
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [query, setQuery] = useState<string>("?sortBy=createdAt&order=desc")
    const [selectedGrupo, setSelectedGrupo] = useState<GrupoInt>();
    const [matricula, setMatricula] = useState<MatriculasInt>()
    const { matriculas, error, status, errorsList } = useMatriculasAlumno({ user: user });
    const { grupos, error: errorTutor, status: statusTutor, errorsList: errorsListTutor } = useGruposTutor({ user: user });
    

    const { temas, loading: loadingTemas } = useTemasGrupos({
        grupos: grupos,
        matriculas: matriculas?.data,
        role: user?.role?.nombre,
        query: "&limit=100"
    })

    const { preguntas, Refresh } = usePreguntas({
        query: query + (selectedGrupo?.id ? `&grupo=${selectedGrupo?.id}` : ""),
        ignoreRequest: user?.role?.nombre === UserRolEnum.ALUMNO ? (!matricula?.id ? true : false) : false,
        track: user?.role?.nombre === UserRolEnum.ALUMNO ?
            {
                track: user?.tracking ? true : false,
                matricula: matricula?.estado === EstadoTypeEnum.ACTIVO ? matricula?.id : undefined,
            } : undefined
    });

    const { preguntas: preguntasTotal } = usePreguntas({
        query: query + ("&limit=4"),
        ignoreRequest: user?.role?.nombre === UserRolEnum.ALUMNO ? (!matricula?.id ? true : false) : false,
        track: user?.role?.nombre === UserRolEnum.ALUMNO ?
            {
                track: user?.tracking ? true : false,
                matricula: matricula?.estado === EstadoTypeEnum.ACTIVO ? matricula?.id : undefined,
            } : undefined
    });


    useEffect(() => {
        if (error || errorTutor) {
            handleUnauthorized({
                errors: errorsList || errorsListTutor,
                status: status || statusTutor,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error, errorTutor])

    useEffect(() => {
        if (user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) {
            if (!grupos) return

            setSelectedGrupo(grupos[0])
        } else {
            if (!matriculas) return
            
            setSelectedGrupo(matriculas?.data[0]?.grupo)
        }
    }, [matriculas, grupos])

    useEffect(() => {
        matriculas?.data?.map((matricula: MatriculasInt) => {
            if (matricula?.grupoId === selectedGrupo?.id && matricula?.id) setMatricula(matricula)
        })

    }, [selectedGrupo])

    return (
        <Flex
            px="50px"
            gap="38px"
        >
            <Flex
                w="100%">
                <Routes>
                    <Route
                        index
                        element={
                            loadingTemas ? <SekeletonForo />
                                :
                                ((matriculas?.data && matriculas?.data?.length > 0) || (grupos && grupos?.length > 0))
                                    ?
                                    <GruposForo
                                        grupos={grupos}
                                        matriculas={matriculas?.data}
                                        temas={temas}
                                        setSelectedGrupo={setSelectedGrupo}
                                        selectedGrupo={selectedGrupo}
                                        preguntas={preguntas}
                                        onOpen={onOpen}
                                        query={query}
                                        setQuery={setQuery}
                                    />
                                    :
                                    <CardSinInformacion
                                        type={TypeCardSinInformacion.FORO}
                                    />
                        }
                    />

                    <Route
                        path="/:temaId"
                        element={
                            <Tema
                                matriculas={matriculas?.data}
                                temas={temas}
                                setSelectedGrupo={setSelectedGrupo}
                                selectedGrupo={selectedGrupo}
                                matricula={matricula}
                                onOpen={onOpen}
                                grupos={grupos}
                            />
                        }
                    />

                    <Route
                        path="/:temaId/pregunta/:preguntaId"
                        element={
                            <Pregunta
                                grupos={grupos}
                                matriculas={matriculas?.data}
                                temas={temas}
                                setSelectedGrupo={setSelectedGrupo}
                                selectedGrupo={selectedGrupo}
                                matricula={matricula}
                                onOpen={onOpen}
                            />
                        }
                    />

                    <Route path="*" element={<Navigate to={"/foro"} />} />
                </Routes>
            </Flex>


        {loadingTemas ? <SkeletonCards /> :
            <Flex
                direction="column"
                gap="40px"
                mt="30px">
                <Button
                    color="purewhite"
                    bg="secondary"
                    rounded="5px"
                    fontSize="15px"
                    fontWeight="500"
                    p="16px 35px"
                    onClick={onOpen}
                    isDisabled={selectedGrupo?.estado === EstadoTypeEnum.INACTIVO && user?.role?.nombre === UserRolEnum.ALUMNO}
                    _hover={{ backgroundColor: "secondary_variant" }}
                >
                    Nuevo post
                </Button>

                {user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN
                    ?
                    <TemasListTutor
                        grupos={grupos}
                        temas={temas}
                        setSelectedGrupo={setSelectedGrupo}
                        selectedGrupo={selectedGrupo}
                    />
                    :
                    <TemasListAlumno
                        matriculas={matriculas}
                        temas={temas}
                        setSelectedGrupo={setSelectedGrupo}
                        selectedGrupo={selectedGrupo}
                    />
                }

                <CardActividad
                    preguntas={preguntasTotal} />
            </Flex>
            }

            <NuevaPreguntaModal
                isOpen={isOpen}
                onClose={onClose}
                temas={temas}
                matriculas={matriculas?.data}
                selectedGrupo={selectedGrupo}
                Refresh={Refresh}
            />
        </Flex>
    )
}
