import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Icon, Text } from "@chakra-ui/react";
import { TemaForoInt } from "../../../../interfaces/ForoInt";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IconBox } from "../../../../shared/components/Icons/IconBox";
import { GrupoInt, MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../../shared/components/Customs/CardSinInformacion";

interface Props {
    grupos: GrupoInt[];
    temas: TemaForoInt[];
    temaId?: string | undefined;
    setSelectedGrupo: (grupo: GrupoInt) => void;
    selectedGrupo: GrupoInt | undefined;
}

export const TemasListTutor = ({ grupos, temas, temaId, setSelectedGrupo, selectedGrupo }: Props) => {
    const navigate = useNavigate();
    const [indexExpanded, setIndexExpanded] = useState<number>()

    useEffect(() => {
        if (!temaId) return

        const selectedTema = temas?.find((tema: TemaForoInt) => tema?.id == temaId)

        for (let i = 0; i < grupos?.length; i++) {
            if (selectedTema?.grupoId === grupos[i]?.id) {
                setSelectedGrupo(grupos[i])
            }
        }
    }, [grupos, temas])

    useEffect(() => {
        grupos?.map((grupo: GrupoInt, i: number) => grupo?.id == selectedGrupo?.id && setIndexExpanded(i))

    }, [selectedGrupo])



    return (
        <Flex
            bg="purewhite"
            p="30px"
            rounded="6px"
            direction="column"
            gap="15px"
            border="0.5px solid"
            borderColor="light_grey"
            maxH="550px"
            overflowY="scroll"
            className="scroll-bar"
        >
            <Flex
                alignItems="center"
                gap="10px"
            >
                {/* <Icon as={BiEditAlt} boxSize="23px" /> */}

                <Text
                    color="font"
                    fontSize="16px"
                    fontWeight="700"
                >
                    Grupos matriculados
                </Text>
            </Flex>

            <Accordion
                allowToggle
                gap="10px"
                display="flex"
                flexDirection="column"
                index={indexExpanded}
                onChange={(index: number) => setIndexExpanded(index)}
            >
                {grupos && grupos.length > 0 ?
                grupos?.map((grupo: GrupoInt, index: number) => (
                    <AccordionItem
                        border="none"
                        key={index}
                    >
                        <AccordionButton
                            bg="purewhite"
                            rounded="8px"
                            color="font"
                            display="flex"
                            justifyContent="space-between"
                            p="10px 12px"
                            _hover={{ bg: "secondary_variant", color: "font" }}
                            _expanded={{ color: "font", boxShadow: "0px 4px 15px 0px rgba(199, 205, 255, 0.60)" }}
                            onClick={() => {
                                setSelectedGrupo(grupo)
                                navigate('/foro')
                            }}
                        >
                            <Flex
                                alignItems="center"
                                gap="10px"
                            >
                                <IconBox icon={grupo?.curso.icono} padding="0" border="none" size="28px" bg='none' />
                                <Flex
                                    direction="column"
                                    alignItems="flex-start"
                                >
                                    <Text
                                        fontSize="16px"
                                        fontWeight="500"
                                        letterSpacing="-0.28px"
                                        lineHeight="20px"
                                    >
                                        {grupo?.curso?.nombre}
                                    </Text>
                                    <Text fontSize="12px">( {grupo?.nombre})</Text>
                                </Flex>
                            </Flex>

                            <AccordionIcon boxSize="32px" color="dark_grey" />
                        </AccordionButton>

                        <AccordionPanel
                        >
                            <Flex
                                direction="column"
                                borderLeft="2px solid"
                                borderColor="light_grey"
                                pl="15px"
                                ml="15px"
                            >
                                {
                                    temas?.map((tema: TemaForoInt, i: number) => (
                                        tema?.grupoId === grupo?.id &&
                                        <Flex
                                            mt="10px"
                                            bg="inherit"
                                            key={i}
                                            color={tema?.id == temaId ? "main" : "secondary_variant_font"}
                                            p="8px 12px"
                                            rounded="8px"
                                            _hover={{ color: "secondary_variant" }}
                                            cursor="pointer"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            onClick={() => {
                                                tema?.grupo && setSelectedGrupo(tema?.grupo)
                                                navigate(`/foro/${tema?.id}`)
                                            }}
                                        >
                                            <Text
                                                fontSize="16px"
                                                fontWeight="500"
                                                lineHeight="16px"
                                                letterSpacing="-0.24px"
                                            >
                                                {tema?.titulo}
                                            </Text>
                                        </Flex>
                                    ))
                                }
                            </Flex>
                        </AccordionPanel>
                    </AccordionItem>
                ))
            :
            <CardSinInformacion type={TypeCardSinInformacion.GRUPOS} isCard />
            }
            </Accordion>
        </Flex >
    );
}