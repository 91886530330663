import _axios from "../services/http.service";
import useSWR from "swr";
import useSWRImmutable from 'swr/immutable'
import tenantInstance from '../services/tenantInstance.service';
import { EndpointTypes, RelationshipTypes } from '../utils/Types/EndpointTypes';

const { tenant, client } = await tenantInstance();

export const getData = async (
    endpoint: string, 
    track: { 
        track: boolean, 
        matricula: string | undefined 
    } = {
            track: false,
            matricula: undefined
        }
) => await _axios.get(
    endpoint,
    (track?.track && track?.matricula !== undefined) 
    ? { headers: { "track": true, "matricula": track?.matricula }}  
    : {}
).then((response: any) => response)

export const useData = ({
    endpoint,
    query = "",
    ignoreRequest = false,
    track = {track: false, matricula: undefined},
}: {
    endpoint: EndpointTypes;
    query?: string;
    ignoreRequest?: boolean;
    track?: { track: boolean, matricula: string | undefined };
}) => {
    const finalEnpoint = tenant + "/" + client + endpoint + query
    const { isLoading, data, mutate, error } = useSWR(!ignoreRequest ? [finalEnpoint, track] : null, ([url, track]) => getData(url, track));

    return {
        data: data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

export const useDataId = ({ 
    id,
    endpoint,
    query = "",
    ignoreRequest = false,
    track = {track: false, matricula: undefined},
} : { 
    id: string | undefined;
    endpoint: EndpointTypes;
    query?: string;
    ignoreRequest?: boolean;
    track?: { track: boolean, matricula: string | undefined };
}) => {
    const finalEnpoint = tenant + "/" + client + endpoint + "/" + id + query
    const { isLoading, data, mutate } = useSWR((id && !ignoreRequest) ? [finalEnpoint, track] : null, ([url, track]) => getData(url, track));
        
    return {
        data: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const useDataRelationship = ({ 
    id,
    endpoint,
    relationship,
    query = "",
    ignoreRequest = false,
    track = {track: false, matricula: undefined},
} : { 
    id: string | undefined;
    endpoint: EndpointTypes;
    relationship: RelationshipTypes;
    query?: string;
    ignoreRequest?: boolean;
    track?: { track: boolean, matricula: string | undefined };
}) => {
    const finalEnpoint = tenant + "/" + client + endpoint + "/" + id + relationship + query
    const { isLoading, data, mutate } = useSWR((id && !ignoreRequest) ? [finalEnpoint, track] : null, ([url, track]) => getData(url, track));
        
    return {
        data: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const useImmutableData = ({
    endpoint,
    query = "",
    ignoreRequest = false,
    track = {track: false, matricula: undefined},
}: {
    endpoint: EndpointTypes;
    query?: string;
    ignoreRequest?: boolean;
    track?: { track: boolean, matricula: string | undefined };
}) => {
    const finalEnpoint = tenant + "/" + client + endpoint + query
    const { isLoading, data, mutate, error } = useSWRImmutable(!ignoreRequest ? [finalEnpoint, track] : null, ([url, track]) => getData(url, track));

    return {
        data: data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}
