import { Box, Flex, Icon } from "@chakra-ui/react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import StatsCursos from "./StatsCursos";
import { useState } from "react";
import { useStats } from "../../../shared/middlewares/stats.middleware";
import SkeletonCarouselStats from "./utils/SkeletonCarouselStatsCurso";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../shared/components/Customs/CardSinInformacion";

export default function CarouselStatsCursos() {
    const [translate, setTranslate] = useState(0);
    const [cursoActual, setCursoActual] = useState(0);
    const { data: stats, loading } = useStats();


    return (
        loading ? <SkeletonCarouselStats />
            :
            <Flex
                direction="column"
                justify="center"
                bg="purewhite"
                borderRadius="8px"
                p="20px"
                gap="30px"
                minW="100%"
                minH="370px"
            >
                {!stats || stats?.length === 0 ?
                    <CardSinInformacion type={TypeCardSinInformacion.STATS} isCard isRow/>
                    :
                    <>
                        <Flex
                            align="center"
                            justify="start"
                            minW="100%"
                            gap="20px"
                        >
                            <Icon
                                opacity={stats?.length > 1 ? 1 : 0}
                                cursor="pointer"
                                marginTop="72px"
                                boxSize="24px"
                                as={HiChevronLeft}
                                onClick={() => {
                                    if (cursoActual === 0) {
                                        setTranslate((stats?.length - 1) * -100);
                                        setCursoActual(stats?.length - 1);
                                    } else {
                                        setTranslate(translate + 100);
                                        setCursoActual(cursoActual - 1);
                                    }
                                }}
                            />

                            <Flex
                                align="center"
                                justifyItems="start"
                                flex="1"
                                maxW="calc(100vw - 500px)"
                                overflow="hidden"
                                p="20px"
                                justify="start"
                                minW={0}
                            >
                                {stats?.map((statsMatricula: any, index: number) => (
                                    <Flex
                                        minW="100%"
                                        key={index}
                                        transform={`translateX(${translate}%)`}
                                        transition="ease 300ms"
                                    >
                                        <StatsCursos
                                            opacity={cursoActual === index ? 1 : 0}
                                            matricula={statsMatricula}
                                        />
                                    </Flex>
                                ))}
                            </Flex>

                            <Icon
                                opacity={stats?.length > 1 ? 1 : 0}
                                cursor="pointer"
                                marginTop="72px"
                                boxSize="24px"
                                as={HiChevronRight}
                                onClick={() => {
                                    if (stats?.length - 1 === cursoActual) {
                                        setTranslate(0);
                                        setCursoActual(0);
                                    } else {
                                        setTranslate(translate - 100);
                                        setCursoActual(cursoActual + 1);
                                    }
                                }}
                            />
                        </Flex>

                        <Flex
                            alignSelf="center"
                            gap="15px"
                            opacity={stats?.length > 1 ? 1 : 0}
                        >
                            {(() => {
                                const dots = [];
                                for (let index = 0; index < stats?.length; index++) {
                                    dots.push(
                                        <Box
                                            key={index}
                                            onClick={() => {
                                                setTranslate(-(index * 100));
                                                setCursoActual(index);
                                            }}
                                            h="10px"
                                            w="10px"
                                            rounded="50px"
                                            bg="secondary"
                                            opacity={cursoActual === index ? "1" : "0.5"}
                                            cursor="pointer"
                                        />
                                    );
                                }
                                return dots;
                            })()}
                        </Flex>
                    </>
                }
            </Flex>
    );
}
