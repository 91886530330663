import { Flex, Text } from "@chakra-ui/react";
import TablaCursos from "./TablaCursos";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../../shared/components/Customs/CardSinInformacion";

interface Props {
    matriculas: any;
}

export default function Cursos({ matriculas }: Props) {
    
    return (
        <Flex
            bgColor="purewhite"
            rounded="8px"
            p="30px"
            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
            direction="column"
            w="100%"
            gap="15px"
        >
            <Flex
                direction="column"
                mb="10px"
            >
                <Text
                    color="font"
                    fontSize="21px"
                    fontStyle="normal"
                    fontWeight="600"
                >
                    Cursos
                </Text>
            </Flex>

            <Flex
                flex="1"
            >
                {!matriculas?.data || matriculas?.data?.length === 0 ?
                    <CardSinInformacion
                        type={TypeCardSinInformacion.CURSOS}
                        isRow
                        isCard
                    />
                    :
                    <TablaCursos
                        matriculas={matriculas}
                    />
                }
            </Flex>
        </Flex>
    );
}
