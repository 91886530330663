import { UserRolEnum } from './../utils/Types/RolEnum';
import { PreguntaForoInt, RespuestaForoInt, TemaForoInt, UpdatePreguntaForoInt, UpdateRespuestaForoInt, UpdateTemaForoInt } from "../../interfaces/ForoInt";
import useSWR from "swr";
import _axios, { TRACK_HEADERS } from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";
import { GrupoInt, MatriculasInt } from "../../interfaces/MatriculasInt";

const { tenant, client } = await tenantInstance();

const ENDPOINT_TEMA = "foro/temas"
const ENDPOINT_PREGUNTAS = "foro/preguntas"

// Middlewares Especificos sin SWR => TEMAS
export const getForoTemasByGrupo = async (grupoId: string) => {
    
    return await _axios.get(`${tenant}/${client}/${ENDPOINT_TEMA}?grupos=${grupoId}`).then((response: any) => response);
}
export const getTemasCursos = async (Ids: string) => {
    
    return await _axios.get(`${tenant}/${client}/${ENDPOINT_TEMA}?cursos=${Ids}`).then((response: any) => response);
}

// Middlewares y Hooks generales y SWR => TEMAS
export const getForoTemas = async (
    endpoint: string, 
    track: { 
        track: boolean, 
        matricula: string | undefined 
    } = {
            track: false,
            matricula: undefined
        }
) => 
await _axios.get(
    endpoint, 
    (track?.track && track?.matricula !== undefined) 
    ? { headers: { "track": true, "matricula": track?.matricula }}  
    : {}
)
.then((response: any) => response)

export const useTemas = ({ 
    query = "", 
    ignoreRequest = false,
    track = {track: false, matricula: undefined}
} : { 
    query?: string, 
    ignoreRequest?: boolean;
    track?: { track: boolean, matricula: string | undefined }  
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_TEMA + query
    const { isLoading, data, mutate } = useSWR(!ignoreRequest ? [endpoint, track] : null, ([url, track]) =>  getForoTemas(url, track));
        
    return {
        temas: data?.data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const useTemasGrupos = ({
    grupos, 
    matriculas, 
    role,
    query = ""
} : { 
    grupos: GrupoInt[];
    matriculas: MatriculasInt[];
    role: string | undefined;
    query?: string;
}) => {

    let queryGrupos = null;
    const gruposIds: string[] = []

    if(role === UserRolEnum.TUTOR || role === UserRolEnum.ADMIN) 
        grupos?.map((g: GrupoInt) => { if(g?.id) return gruposIds.push(g?.id) })
    else
        matriculas?.map((m: MatriculasInt) => { if(m?.grupoId) return gruposIds.push(m?.grupoId) })
        
    queryGrupos = gruposIds.join(",")

    const endpoint = `${tenant}/${client}/` + ENDPOINT_TEMA + `?grupos=${queryGrupos}` + query?.replaceAll("?", "&");
    const { isLoading, data, mutate } = useSWR(
        (queryGrupos !== null || (matriculas && matriculas?.length > 0) || (grupos && grupos?.length > 0))
        ? endpoint : null, 
        getForoTemas
    );
        
    return {
        temas: data?.data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const useTemaId = ({ 
    id, 
    query = "", 
    ignoreRequest = false,
    track = {track: false, matricula: undefined}
} : { 
    id: string | undefined, 
    query?: string, 
    ignoreRequest?: boolean;
    track?: { track: boolean, matricula: string | undefined }  
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_TEMA + `/${id}${query}`
    const { isLoading, data, mutate } = useSWR((id && !ignoreRequest) ? [endpoint, track] : null, ([url, track]) =>  getForoTemas(url, track));
        
    return {
        tema: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}


// Middlewares y Hooks generales y SWR => PREGUNTAS
export const getForoPregunta = async (
    endpoint: string, 
    track: { 
        track: boolean, 
        matricula: string | undefined 
    } = {
            track: false,
            matricula: undefined
        }
) => 
await _axios.get(
    endpoint, 
    (track?.track && track?.matricula !== undefined) 
    ? { headers: { "track": true, "matricula": track?.matricula }}  
    : {}
)
.then((response: any) => response);

export const usePreguntas = ({ 
    query = "", 
    ignoreRequest = false,
    track = {track: false, matricula: undefined}
} : { 
    query: string, 
    ignoreRequest?: boolean;
    track?: { track: boolean, matricula: string | undefined }  
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_PREGUNTAS + query
    const { isLoading, data, mutate } = useSWR(!ignoreRequest ? [endpoint, track] : null, ([url, track]) => getForoPregunta(url, track));
        
    return {
        preguntas: data?.data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const usePreguntasQuery = ({ 
    query
} : { 
    query: string | undefined, 
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_PREGUNTAS + query
    const { isLoading, data, mutate } = useSWR(query ? endpoint : null, getForoPregunta);
        
    return {
        preguntas: data?.data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const usePreguntaId = ({ 
    id, 
    ignoreRequest,
    track = {track: false, matricula: undefined}
} : { 
    id: string | undefined, 
    ignoreRequest?: boolean;
    track?: { track: boolean, matricula: string | undefined }  
}) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_PREGUNTAS + `/${id}`
    const { isLoading, data, mutate } = useSWR((id && !ignoreRequest) ? [endpoint, track] : null, ([url, track]) => getForoPregunta(url, track));
        
    return {
        pregunta: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}


//TODO: METODOS POST => FALTA REALIZAR MUTACION SWR 
export const addForoTema = async (newTema: any) => {
    
    return await _axios.post(
        `${tenant}/${client}/foro/temas`,
        newTema,
        {}
    ).then((response: any) => response);
};

export const addForoPregunta = async (newPregunta: PreguntaForoInt) => {
    
    return await _axios.post(
        `${tenant}/${client}/foro/preguntas`,
        newPregunta,
        {}
    ).then((response: any) => response);
};

export const addForoRespuesta = async (newRespuesta: RespuestaForoInt) => {
    
    return await _axios.post(
        `${tenant}/${client}/foro/respuestas`,
        newRespuesta,
        {}
    ).then((response: any) => response);
};