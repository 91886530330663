import React, { useState } from 'react';

import { Field } from 'formik';
import { FormLabel, FormControl, Icon, Input, InputGroup, InputRightElement, FormErrorMessage, Box, Text, Flex } from '@chakra-ui/react';
import { BiHide, BiLock, BiShow, BiInfoCircle } from 'react-icons/bi';

export const FormInput = ({
    name,
    label,
    placeholder,
    min,
    max,
    step,
    type = 'text',
    style = {},
    controlStyle = {},
    onBlur = () => { },
    onFocus = () => { },
    isDisabled = false,
    isRequired = false,
    labelStyle = {
        fontSize: "14px",
        fontWeight: "400"
    },
}: {
    label?: string;
    name: string;
    min?: number;
    max?: number;
    step?: number;
    placeholder?: string;
    type?: string;
    onBlur?: (e?: any) => any;
    onFocus?: (e?: any) => any;
    defaultValue?: any;
    isRequired?: boolean;
    isDisabled?: boolean;
    style?: React.CSSProperties;
    controlStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
}) => {
    const [show, setShow] = useState(false);

    return (
        <Field name={name}>
            {({ field, form }: { field: any; form: any }) => (
                <FormControl style={controlStyle} isInvalid={form.errors[name] && form.touched[name]}>
                    {label &&
                        <FormLabel
                            htmlFor={name}
                            display="flex"
                            gap="3px"
                            color="dark_blue"
                            fontSize="14px"
                            fontWeight="400"
                            textTransform="capitalize"
                            style={labelStyle}
                        >
                            {label}
                        </FormLabel>
                    }

                    <InputGroup pos="relative">
                        <Input
                            {...field}
                            style={style}
                            min={min}
                            max={max}
                            step={step}
                            borderRadius="5px"
                            color="black"
                            _invalid={{
                                borderColor: "none",
                                boxShadow: "none"
                            }}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            isDisabled={isDisabled}
                            borderColor="border"
                            placeholder={placeholder}
                            value={field.value || ''}
                            type={type === 'password' ? (show ? 'text' : 'password') : type}
                            _placeholder={{
                                color: "dark_grey",
                                fontSize: "13px",
                                fontWeight: "500",
                                lineHeight: "16px",
                                letterSpacing: "-0.078px",
                            }}
                            _disabled={{
                                color: "black",
                                cursor: "not-allowed"
                            }}
                        />

                        {type === 'password' && (
                            <InputRightElement>
                                <Icon
                                    as={show ? BiHide : BiShow}
                                    boxSize="24px"
                                    cursor="pointer"
                                    onClick={() => setShow(!show)}
                                    title={show ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                                />
                            </InputRightElement>
                        )}

                        {(type !== 'password' && isDisabled) && (
                            <InputRightElement>
                                <Icon
                                    as={BiLock}
                                    boxSize="24px"
                                    cursor="not-allowed"
                                />
                            </InputRightElement>
                        )}

                        {isRequired &&
                            <Icon
                                zIndex="999"
                                as={BiInfoCircle}
                                boxSize="15px"
                                color={(form.errors[name] && form.touched[name]) ? "red" : "dark_blue"}
                                pos="absolute"
                                right="-7px"
                                top="-7px"
                            />
                        }
                    </InputGroup>

                    <Box h="18px" w="100%" mt="2px">
                        <Text
                            color="fail"
                            fontSize="13px"
                            fontWeight="400"
                        >
                            {form.touched[name] && form.errors[name] ? form.errors[name] : ""}
                        </Text>
                    </Box>
                </FormControl>
            )}
        </Field>
    );
};
