import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { RespuestaForoInt } from "../../../interfaces/ForoInt";
import { CustomAvatar } from "../../../shared/components/Avatar/CustomAvatar";
import { format } from "date-fns";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { useState } from "react";

interface Props {
    respuesta: RespuestaForoInt;
}


export const CardRespuesta = ({ respuesta }: Props) => {
    const [showRespuesta, setShowRespuesta] = useState<boolean>(true)

    return (
        <Flex>
            {/* <Box h="100%" w="5px" bg="main" rounded="5px 0 0 5px" /> */}

            <Flex
                direction="column"
                bg="purewhite"
                p="25px 30px"
                rounded="6px"
                gap="15px"
                w="100%"
                h="fit-content"
            >
                <Flex
                    gap="15px"
                    alignItems="center"
                >
                    <CustomAvatar
                        name={respuesta?.user?.username || ""}
                        src={respuesta?.user?.avatar?.url}
                        size="40px"
                        rounded="full"
                    />
                    <Flex
                        direction="column"
                        alignItems="start"
                        gap="7px"
                    >
                        <Text
                            color="font"
                            fontSize="13px"
                            fontStyle="normal"
                            fontWeight="400"
                            lineHeight="normal"
                        >
                            {respuesta?.user?.username}
                        </Text>

                        <Text
                            color="secondary_variant_font"
                            fontSize="11px"
                            fontWeight="400"
                            lineHeight="normal"
                        >
                            {respuesta?.createdAt && format(new Date(respuesta?.createdAt), "dd/MM/yyyy HH:mm")}
                        </Text>
                    </Flex>
                </Flex>

                <Flex
                    direction="column"
                    px="55px"
                    gap="20px"
                >
                    <Box>
                        <MarkdownPreview
                            source={respuesta?.contenido || ''}
                            style={{
                                color: "#252F40",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "25px",
                                backgroundColor: "#FFFFFF"
                            }}
                        />
                    </Box>

                    {/* <Flex
                        gap="16px"
                    >
                        <Icon
                            as={BiShare}
                            boxSize="24px"
                            color="#595959"
                        />
                    </Flex> */}

                </Flex>

                <Box bg="border" h="1px" />
            </Flex>
        </Flex>
    );
}