// Chakra UI
import { Flex } from "@chakra-ui/react";
// Hook
import Information from "./Views/Information";

export const Dashboard = () => {

    return(
        <Flex w="100%" px="50px" pb="40px">
            <Information/>
        </Flex>
    );
};