import { CircularProgress, CircularProgressLabel, Flex, SkeletonCircle, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { GrupoInt } from "../../../../interfaces/MatriculasInt"
import { differenceInDays } from "date-fns";

interface Props {
    stats: GrupoInt;
    
    
}

export const ContadorItem = ({ stats }: Props) => {
    const [time, setTime] = useState<{
        time: any
        months: any
        days: any
        hours: any
        minutes: any
        seconds: any
    }>()
    const diasTotalesGrupo = stats?.fechaInicio && stats?.fechaFin ? differenceInDays(new Date(stats?.fechaFin),new Date(stats?.fechaInicio)) : 0
    const diasAvanzadosGrupo = stats?.fechaInicio ? differenceInDays(new Date(),new Date(stats?.fechaInicio)) : 0

    useEffect(() => {
        if (!stats?.fechaFin) return
        const t = getCount(stats.fechaFin);

        setTime(t);

    }, [stats])

    const getCount = (fechaFin: any) => {
        if (!fechaFin) return;
    
        const inicio: any = new Date();
        const fin: any = new Date(fechaFin);
        const time: any = (fin - inicio + 1000) / 1000;
    
        const months: number = Math.floor(time / (30 * 24 * 60 * 60));
        const days: number = Math.floor((time % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
        const hours: number = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
        const minutes: number = Math.floor((time % (60 * 60)) / 60);
        const seconds: number = Math.floor(time % 60);
    
        return {
            time,
            months,
            days,
            hours,
            minutes,
            seconds
        };
    };

    return (
        <Flex
            direction="column"
            gap="10px"

        >
            {(stats) &&
                <Flex
                    direction="column"
                >
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="600"
                    >
                        
                    </Text>
                </Flex>
            }

            <Flex
                bg="#FFFFFF"
                rounded="5px"
                color="#3D3F65"
                gap="20px"
                justify="center"
                
            >
                {time ?
                   
                        <CircularProgress capIsRound value={(diasAvanzadosGrupo/diasTotalesGrupo)*100} color='#8694FF' trackColor="#EAECFF" size="85px">
                            <CircularProgressLabel
                                fontSize="20px"
                                fontWeight="600"
                                lineHeight="90%"

                            >
                                {time?.months}
                                
                                <Text
                                    fontSize="10px"
                                    fontWeight="400"
                                >
                                    {time?.months == "1" ? "MES" : "MESES"}

                                </Text>
                            </CircularProgressLabel>
                        </CircularProgress>
                        
                  
                    : <SkeletonCircle size="85px" />}

                {time ?
                     <CircularProgress capIsRound value={(diasAvanzadosGrupo/diasTotalesGrupo)*100} color='#8694FF' trackColor="#EAECFF" size="85px">
                        <CircularProgressLabel
                            fontSize="20px"
                            fontWeight="600"
                            lineHeight="90%"
                        >
                            {time?.days}

                        <Text
                            fontSize="10px"
                            fontWeight="400"
                        >
                            {time?.days == "1" ? "DÍA" : "DÍAS"}

                        </Text>
                        </CircularProgressLabel>
                    </CircularProgress>
                    : <SkeletonCircle size="85px" />}

                {time ?
                    <CircularProgress capIsRound value={(diasAvanzadosGrupo/diasTotalesGrupo)*100} color='#8694FF' trackColor="#EAECFF" size="85px">
                        <CircularProgressLabel
                            fontSize="20px"
                            fontWeight="600"
                            lineHeight="90%"
                        >
                            {time?.hours}

                        <Text
                            fontSize="10px"
                            fontWeight="400"
                        >
                            {time?.hours == "1" ? "HORA" : "HORAS"}

                        </Text>
                        </CircularProgressLabel>
                    </CircularProgress>
                    : <SkeletonCircle size="85px" />}
            </Flex>
        </Flex>
    )
}