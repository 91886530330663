import _axios from "../services/http.service";
import useSWR from "swr";
import tenantInstance from "../services/tenantInstance.service";

const { tenant, client } = await tenantInstance();

const ENDPOINT_ASIGNACIONES = "asignaciones"
const ENDPOINT_LIQUIDACIONES = "liquidaciones"

// Middlewares GET y Hooks SWR => EMPRESAS
export const getData = async (endpoint: string) => await _axios.get(endpoint).then((response: any) => response)

export const useLiquidaciones = (query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_LIQUIDACIONES + query
    const { isLoading, data, mutate } = useSWR(endpoint, getData);
        
    return {
        liquidaciones: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const useLiquidacionId = (id: string | undefined, query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_LIQUIDACIONES + `/${id}${query}`
    const { isLoading, data, mutate } = useSWR(id ? endpoint : null, getData);
        
    return {
        liquidacion: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const useAsignaciones = (query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_ASIGNACIONES + query
    const { isLoading, data, mutate, error } = useSWR(endpoint, getData);
        
    return {
        asignaciones: data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

// TODO: Middlewares POST y PUT, pendientes de refactorizar con mutate SWR
export const addLiquidacion = async (
    data: {
        userId: string;
        asignaciones: string[];
    }
) => {

    return await _axios.post(
        `${tenant}/${client}/liquidaciones`,
        data,
        {}
    ).then((response: any) => response);
};

export const updateLiquidacion = async ({ id, data }: { id: string, data: any }) => {

    return await _axios.put(
        `${tenant}/${client}/liquidaciones/${id}`,
        data,
        {}
    ).then((response: any) => response);
};