import { Flex, Text, Image, Button, Icon } from "@chakra-ui/react";
import { useCursoSlug } from "../../../../shared/middlewares/courses.middleware";
import { useNavigate } from "react-router-dom";
import { LeccionInt, ModulosInt } from "../../../../interfaces/CoursesInt";
import { BarProgress } from "../../../../shared/components/Progress/BarProgress";
import { BsPencilSquare } from "react-icons/bs";
import { useProgresos } from "../../../../shared/middlewares/progress.middleware";
import { sortByOrder } from "../../../../shared/utils/functions/validateProgress";
import CardRequisitosRestantes from "./CardRequisitosRestantes";
import { fmtSnds } from "../../../../shared/utils/functions/formatters";

interface Props {
    stats: any
}

export default function CardModulo({ stats }: Props) {
    const navigate = useNavigate();
    const { curso } = useCursoSlug(stats?.grupo?.curso?.slug)
    const { progreso } = useProgresos({
        cursoId: stats?.grupo?.cursoId,
        matriculaId: stats?.id
    })

    const onContinueLeccion = (): { redirectLeccion: string | undefined, modulo: ModulosInt } => {
        let leccionSlug;
        let selectedModulo: any;

        sortByOrder(curso?.modulos)?.find((modulo: any) =>
            sortByOrder(modulo.lecciones)?.find((leccion: any) => {
                if (!checkLessonComplete(leccion?.id)) {
                    leccionSlug = leccion?.slug;
                    selectedModulo = modulo
                    return true;
                }
                return false;
            })
        );

        return {
            redirectLeccion: leccionSlug,
            modulo: selectedModulo
        }
    }

    const checkLessonComplete = (leccionId: string) => {
        if (!progreso || progreso?.length === 0) return false

        if (Reflect.has(progreso[0]?.progresoLecciones || {}, leccionId)) {
            if (progreso[0]?.progresoLecciones[leccionId]?.estado === "finalizacion") {
                return true
            }
        }

        return false
    };

    const getLeccionActual = () => onContinueLeccion().modulo?.lecciones?.find((leccion: LeccionInt) => leccion.slug === onContinueLeccion().redirectLeccion);

    return (
        <Flex
            display={onContinueLeccion().modulo ? "flex" : "none"}
            boxShadow="0px 4px 15px 0px rgba(199,205,255,0.6)"
            borderRadius="8px"
            maxWidth="fit-content"
        >
            <Image
                roundedLeft="5px"
                minH={0}
                maxW="138px"
                height="100%"
                objectFit="cover"
                src={stats?.grupo?.curso?.imagen?.url}
            />

            <Flex
                maxWidth="fit-content"
                padding="20px"
                direction="column"
                justify="space-between"
            >
                <Flex
                    direction="column"
                    gap="12px"
                >
                    <Flex
                        width="fit-content"
                        direction="column"
                        gap="10px"
                    >

                        <Text
                            maxW="190px"
                            fontSize="14px"
                            fontWeight="400"
                            color="#959596"
                        >
                            {onContinueLeccion().modulo?.nombre ? onContinueLeccion().modulo?.nombre : "Este curso ha sido finalizado"}
                        </Text>

                        <Flex
                            alignItems="center"
                            gap="6px"
                            w="fit-content"
                        >
                            <Icon
                                as={BsPencilSquare}
                                boxSize="20px"
                                color="secondary"
                            />
                            <Text
                                fontSize="16px"
                                fontWeight="700"
                                color="font"
                            >
                                {
                                    getLeccionActual()?.nombre?.includes("-")
                                        ? getLeccionActual()?.nombre?.split("-")[1]
                                        : getLeccionActual()?.nombre
                                }
                            </Text>
                        </Flex>
                    </Flex>


                    <BarProgress
                        height="5px"
                        progress={stats?.progresoLecciones}
                    />
                </Flex>
                <Button
                    bg="secondary"
                    w="fit-content"
                    color="purewhite"
                    fontSize="14px"
                    fontWeight="600"
                    p="12px 12px"
                    borderRadius="8px"
                    h="32px"
                    _hover={{ bg: "secondary_variant" }}
                    onClick={() => navigate(`/leccion/${onContinueLeccion().redirectLeccion}?grupo=${stats?.grupo?.slug}`)}
                    mt="auto"
                >
                    Continuar
                </Button>
            </Flex>
        </Flex>
    )
}