// Chakra UI
import { Text, Flex, Image } from '@chakra-ui/react';
// Lodash
import { CursosInt } from '../../../../interfaces/CoursesInt';
import { useEffect, useState } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { format } from 'date-fns';

interface Props {
    curso: CursosInt;
    fechas: any;
}

export const CardCursoProximo = ({ curso, fechas }: Props) => {
    const [time, setTime] = useState<any>()


    useEffect(() => {
        if (!fechas?.fechaInicio) return

        const timerUpdate = setInterval(() => {
            const t = gerCount(fechas?.fechaInicio);

            setTime(t);

            if (t.time <= 1) {
                clearInterval(timerUpdate)
            }
        }, 1000)

        return () => {
            clearInterval(timerUpdate)
        }

    }, [time])

    const gerCount = (date: any) => {
        const dateNow: any = new Date();
        const formatDate: any = new Date(date);
        const time: any = (formatDate - dateNow + 1000) / 1000;
        const seconds: any = ("0" + Math.floor(time % 60)).slice(-2);
        const minutes: any = ("0" + Math.floor(time / 60 % 60)).slice(-2);
        const hours: any = ("0" + Math.floor(time / 3600 % 24)).slice(-2);
        const days: any = Math.floor(time / (3600 * 24));

        return {
            time,
            seconds,
            minutes,
            hours,
            days
        }
    }

    return (
        <Flex
            direction="column"
            bg="purewhite"
            _hover={{
                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
            }}
            transition=" 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
            rounded="5px"
            maxW="630px"
        >
            <Flex
                w="100%"
                maxH="300px"
                minH="300px"
                overflow="hidden"
                roundedTop="5px"
            >
                <Image
                    src={curso?.imagen?.url}
                    objectFit="cover"
                    w="100%"
                    h="100%"
                />
            </Flex>

            <Flex
                direction="column"
                p="30px"
                gap="30px"
                h="100%"
            >
                <Flex
                    gap="150px"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text
                        color="font"
                        fontSize="24px"
                        fontWeight="700"
                    >
                        {curso?.nombre}
                    </Text>

                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {curso?.duracion}h
                    </Text>
                </Flex>

                <Flex
                    gap="30px"
                >
                    <Flex
                        gap="10px"
                    >
                        <Text
                            color="secondary"
                        >
                            Fecha inicio:
                        </Text>

                        <Text>
                            {format(new Date(fechas?.fechaInicio), 'dd-MM-yyy')}
                        </Text>

                    </Flex>

                    <Flex
                        gap="10px"
                    >
                        <Text
                            color="secondary"
                        >
                            Fecha fin:
                        </Text>

                        <Text>
                            {format(new Date(fechas?.fechaFin), 'dd-MM-yyy')}
                        </Text>

                    </Flex>
                </Flex>

                {time &&
                    <Flex
                        alignItems="center"
                        gap="14px"
                        bg="secondary"
                        rounded="3px"
                        p="5px"
                    >
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            gap="15px"
                            w="100%"
                            color="purewhite"
                            fontWeight="500"
                            fontSize="16px"
                        >
                            <Flex gap="3px" fontSize="18px">
                                <Text>Inicio en {time.days}</Text>
                                <Text>días</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                }

                <MarkdownPreview
                    source={curso?.descripcion || ''}
                    style={{
                        color: "#202020",
                        width: '100%',
                        maxWidth: '100%',
                        background: "inherit",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                    }}
                />
            </Flex>
        </Flex>
    );
};