import { format } from "date-fns";
import { es } from "date-fns/locale";

export const formatDate = (date: any, dateTime = false) => {
    if(!dateTime){
        if (date) return format(new Date(date), "P", { locale: es });
        else return '';
    } else {
        if (date) return format(new Date(date),"dd 'de' LLLL 'del' yyyy' ' HH:mm", { locale: es });
        else return '';
    }
};