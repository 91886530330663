const transformToSlug = (text: string, type?: 'curso' | 'categoria'): string => {
  if (!text) return '';

  const textFormatted = text
    ?.toLocaleLowerCase()
    .replaceAll(' ', '-')
    .replaceAll('/', '-')
    .replaceAll(/[.,¿?!¡]/gm, '')
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');

  return type === 'curso' ? `curso-${textFormatted}` : textFormatted;
};

const regexUncommonChars = (text: string) => {
  text = text.replaceAll('+', '%2B');
  text = text.replaceAll('#', '%23');

  return text;
};

const capitalizeFirst = (string = '') => string.substring(0, 1).toUpperCase() + string.substring(1);

export { transformToSlug, regexUncommonChars, capitalizeFirst };
