import { Flex, Checkbox, Text, Icon } from "@chakra-ui/react"
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { BarProgress } from "../../../../shared/components/Progress/BarProgress";
import { BsCheckCircleFill, BsCircleFill } from "react-icons/bs";

interface Props {
    label: string;
    value: string | number;
    expected?: string | number;
    type: "porcentaje" | "hora" | "total";
    isCheck: boolean;
    progress: number;
    max?: number;
    checkValue?: number;
}

export const CheckItems = ({
    label,
    value,
    expected,
    type,
    isCheck,
    progress,
    max = 100,
    checkValue = 100
}: Props) => {
    return (
        <Flex
            alignItems="center"
            justifyContent="space-between"
            gap="20px"
        >
            <Flex gap="10px" alignItems="center" w="50%">
                {isCheck
                    ?
                    <Icon
                        as={BsCheckCircleFill}
                        color="secondary"
                        boxSize="24px"
                    />
                    :
                    <Icon
                        as={BsCircleFill}
                        color="#EAEAEAEE"
                        boxSize="24px"
                    />
                }

                <Text
                    color="font"
                    fontSize="16px"
                    fontWeight="500"
                >
                    {label}
                </Text>
            </Flex>

            <Flex flex="1" alignItems="center" gap="8px">
                <Flex w="25%" justifyContent="flex-end">
                    <Text
                        color="dark_grey"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {type === "total"
                            ? `${value}/${expected}`
                            : type === "porcentaje"
                                ? `${value}%`
                                : `${value}`
                        }
                    </Text>
                </Flex>

                <Flex w="75%" >
                    <BarProgress
                        progress={progress}
                        bgProgress={progress >= checkValue ? "success" : "secondary"}
                        height="6px"
                        max={max}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}