import { JSXElementConstructor, ReactElement, ReactPortal } from "react";
import { ToastPosition } from "@chakra-ui/react";

type ONLY_FOR_FORMAT = string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactPortal

export const notify = (toast: any, status: any, title: string ) => {
    
    toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
    })
};

export enum StatusEnum {
    success = "success",
    error = "error",
    warning = "warning",
    info = "info"
}

export const customNotify = ({
    toast,
    title,
    status,
    duration = null,
    isClosable = true,
    position = "bottom"
} : {
    toast: any,
    title: ONLY_FOR_FORMAT,
    status: StatusEnum,
    duration?: number | null,
    isClosable?: boolean,
    position?: ToastPosition
}) => {

    toast({
        title: title,
        status: status,
        duration: duration,
        isClosable: isClosable,
        position: position
    })
};