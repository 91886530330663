import { useState } from "react";
import { useAuthContex } from "../../../shared/context/user.context";
import { Button, Flex } from "@chakra-ui/react";
import { addForoRespuesta } from "../../../shared/middlewares/foro.middlewate";
import { CustomQuill } from "../../../shared/components/Customs/CustomQuill";
import { CustomAvatar } from "../../../shared/components/Avatar/CustomAvatar";
import { PreguntaForoInt } from "../../../interfaces/ForoInt";

interface Props {
    refresh: () => void;
    preguntaId?: string;
    pregunta: PreguntaForoInt | undefined;
}
export const NuevaRespuesta = ({ refresh, preguntaId, pregunta }: Props) => {
    const { user } = useAuthContex();
    const [contenido, setContenido] = useState<string>('');

    const subirRespuesta = () => {
        if (!preguntaId || !user?.id) return;

        const respuesta = {
            preguntaId: preguntaId,
            contenido: contenido,
            userId: user?.id,
            fijado: false,
        }

        addForoRespuesta(respuesta).then(() => {
            refresh();
            setContenido("")
        });
    };

    return (
        <Flex
            w="100%"
            gap="20px"
            bg="purewhite"
            direction="column"
            padding="30px 40px"
            rounded="5px"
        >
            <Flex
                gap="12px"
            >
                <CustomAvatar
                    name={pregunta?.user?.username || ""}
                    src={pregunta?.user?.avatar?.url || ""}
                    size="40px"
                    rounded="full"
                />
                <CustomQuill
                    value={contenido}
                    setValue={setContenido}
                    editorStyle={{
                        minHeight: '124px',
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #e2e8f0",
                        borderRadius: "5px",
                    }}
                />

            </Flex>

            <Flex justifyContent="flex-end">
                <Button
                    id="nueva_respuesta"
                    bg="main"
                    color="purewhite"
                    fontWeight="500"
                    // leftIcon={<Icon as={BiMessageSquare} boxSize="15px" />}
                    rounded="5px"
                    lineHeight="normal"
                    p="10px 16px"
                    onClick={subirRespuesta}
                    _hover={{ bg: "#C7CDFF", color: "white" }}
                    _disabled={{ bg: "#C7CDFF", cursor: "not-allowed" }}
                    isDisabled={!contenido}
                >
                    Responder
                </Button>
            </Flex>
        </Flex>
    );
};
