import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Icon, Text } from "@chakra-ui/react";
import { TemaForoInt } from "../../../../interfaces/ForoInt";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IconBox } from "../../../../shared/components/Icons/IconBox";
import { GrupoInt, MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../../shared/components/Customs/CardSinInformacion";
import { EstadoTypeEnum } from "../../../../shared/utils/Types/EstadoTypeEnum";
import { UserRolEnum } from "../../../../shared/utils/Types/RolEnum";
import { useAuthContex } from "../../../../shared/context/user.context";

interface Props {
    matriculas?: any;
    temas?: any;
    temaId?: string | undefined;
    setSelectedGrupo: (grupo: GrupoInt) => void;
    selectedGrupo: GrupoInt | undefined;
}

export const TemasListAlumno = ({ matriculas, temas, temaId, setSelectedGrupo, selectedGrupo }: Props) => {
    const { user } = useAuthContex();
    const navigate = useNavigate();
    const [indexExpanded, setIndexExpanded] = useState<number | undefined>(undefined);

    const matriculasTutorizadas = matriculas?.data?.filter((matricula: MatriculasInt | undefined) => matricula?.meta?.tutorizado);

    useEffect(() => {
        if (!matriculas || !Array.isArray(matriculas)) return;

        const expandedIndex = matriculas.findIndex((matricula: any) => matricula?.grupoId === selectedGrupo?.id);
        setIndexExpanded(expandedIndex !== -1 ? expandedIndex : undefined);
    }, [matriculas, selectedGrupo]);

    return (
        <Flex
            bg="purewhite"
            p="30px"
            rounded="6px"
            direction="column"
            gap="15px"
            border="0.5px solid"
            borderColor="light_grey"
        >
            <Flex
                alignItems="center"
                gap="10px"
            >
                <Text
                    color="font"
                    fontSize="16px"
                    fontWeight="700"
                >
                    Grupos matriculados
                </Text>
            </Flex>

            <Accordion
                allowToggle
                gap="10px"
                display="flex"
                flexDirection="column"
                index={indexExpanded}
                onChange={(index: number) => setIndexExpanded(index)}
            >
                {(matriculasTutorizadas && matriculasTutorizadas?.length > 0) ?
                    matriculasTutorizadas?.map((matricula: any, index: number) => (
                        <AccordionItem
                            border="none"
                            key={index}
                        >
                            <AccordionButton
                                bg="purewhite"
                                rounded="8px"
                                color="font"
                                display="flex"
                                justifyContent="space-between"
                                p="10px 12px"
                                _hover={{ bg: "secondary_variant", color: "font" }}
                                _expanded={{ color: "font", boxShadow: "0px 4px 15px 0px rgba(199, 205, 255, 0.60)" }}
                                onClick={() => {
                                    setSelectedGrupo(matricula?.grupo)
                                    navigate('/foro')
                                }}
                            >
                                <Flex
                                    alignItems="center"
                                    gap="10px"
                                >
                                    <IconBox icon={matricula?.grupo?.curso.icono} padding="0" border="none" size="28px" bg='none' />
                                    <Flex
                                        direction="column"
                                        alignItems="flex-start"
                                    >
                                        <Text
                                            fontSize="16px"
                                            fontWeight="500"
                                            lineHeight="19.36px"
                                            noOfLines={1}
                                        >
                                            Curso de {matricula?.grupo?.curso?.nombre}
                                        </Text>

                                        {(matricula?.estado === EstadoTypeEnum.INACTIVO && user?.role?.nombre === UserRolEnum.ALUMNO) &&
                                            <Text
                                                as="span"
                                                color="fail"
                                                fontSize="12px"
                                            >
                                                Finalizado
                                            </Text>
                                        }
                                    </Flex>
                                </Flex>

                                <AccordionIcon boxSize="32px" color="dark_grey" />
                            </AccordionButton>

                            <AccordionPanel>
                                <Flex
                                    direction="column"
                                    borderLeft="2px solid"
                                    borderColor="light_grey"
                                    pl="15px"
                                    ml="15px"
                                >
                                    {temas?.map((tema: any, i: number) => (
                                        tema?.grupoId === matricula?.grupoId &&
                                        <Flex
                                            mt="10px"
                                            bg="inherit"
                                            key={i}
                                            color={tema?.id == temaId ? "main" : "secondary_variant_font"}
                                            p="8px 12px"
                                            rounded="8px"
                                            _hover={{ color: "secondary_variant" }}
                                            cursor="pointer"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            onClick={() => {
                                                tema?.grupo && setSelectedGrupo(tema?.grupo)
                                                navigate(`/foro/${tema?.id}`)
                                            }}
                                        >
                                            <Text
                                                fontSize="16px"
                                                fontWeight="500"
                                                lineHeight="16px"
                                            >
                                                {tema?.titulo}
                                            </Text>
                                        </Flex>
                                    ))
                                    }
                                </Flex>
                            </AccordionPanel>
                        </AccordionItem>
                    ))
                    :
                    <CardSinInformacion type={TypeCardSinInformacion.GRUPOS} isCard />
                }
            </Accordion>
        </Flex >
    );
}
