import { EntregablesInt, UpdateEntregablesInt } from "../../interfaces/Entregables";
import { EntregableEstadoEnum } from "../utils/Types/EntregableEstadoEnum";
import useSWR from "swr"
import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

const { tenant, client } = await tenantInstance();

const ENDPOINT = "entregables"

// Middlewares y Hooks generales y SWR => ENTREGABLES
export const getAllEntregables = async (endpoint: string) => await _axios.get(endpoint).then((response: any) => response)

export const useEntregables = (query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT + query
    const { isLoading, data, mutate, error } = useSWR(endpoint, getAllEntregables);

        
    return {
        entregables: data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

export const useEntregableId = (id: string | undefined, query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT + `/${id}${query}`
    const { isLoading, data, mutate } = useSWR(id ? endpoint : null, getAllEntregables);


    return {
        entregable: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const useEntregablesMatricula = (query= "", matriculaId: string | undefined) => {
    const endpoint =  `${tenant}/${client}/` + ENDPOINT + query + `&matricula=${matriculaId}`
    const { isLoading, data, mutate, error } = useSWR(matriculaId ? endpoint : null, getAllEntregables);

        
    return {
        entregables: data?.data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

export const useEntregablesLeccion = ({ leccionId, matriculaId } : { leccionId: string | undefined; matriculaId: string | undefined }) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT + `?leccion=${leccionId}&matricula=${matriculaId}`
    const { isLoading, data, mutate } = useSWR((leccionId && matriculaId) ? endpoint : null, getAllEntregables, {
        onSuccess: data => data?.data?.data?.data?.sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt).getTime();
            const dateB = new Date(b.createdAt).getTime();
    
            return dateA > dateB ? -1 : 1;
        })
    });

    return {
        entregables: data?.data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

// Middlewares Especificos sin SWR => ENTREGABLES
export const getEntregableByLeccion = async (id: string) => {
    
    return await _axios.get(`${tenant}/${client}/entregables?leccion=${id}`).then((response: any) => response);
};

export const uploadEntregables = async (data: EntregablesInt) => {
    
    return await _axios.post(
        `${tenant}/${client}/entregables`,
        data,
        {}
    ).then((response: any) => response);
};

export const updateEntregables = async ({ id, data }: { id: string, data: UpdateEntregablesInt}) => {
    
    return await _axios.put(
        `${tenant}/${client}/entregables/${id}`,
        data,
        {}
    ).then((response: any) => response);
};

export const uploadAdjuntoEntregables = async ({id, adjunto}: {id: string, adjunto: File}) => {
    const form = new FormData()

    form.append('adjunto', adjunto)
    form.append('estado', EntregableEstadoEnum.PENDIENTE_CORRECCION);
    
    return await _axios.put(
        `${tenant}/${client}/entregables/${id}`,
        form,
        {}
    ).then((response: any) => response);
};