// Chakra UI
import { Flex, Text } from '@chakra-ui/react';
// Interfaces
import { CursosInt, LeccionInt } from '../../interfaces/CoursesInt';
// Enums
import { ThumbnailSizeEnum } from '../../shared/utils/Types/ThumbnailSizeEnum';
// Background IMG
import bgImagina from "../../assets/img/WebinarPowerBISeptiembre2023.png"
import { IconBox } from '../../shared/components/Icons/IconBox';

interface Props {
  size: any;
  curso?: CursosInt | null;
  leccion?: LeccionInt | null;
  moduloNumber?: number;
  leccionNumber?: number;
}

export const ThumbnailLeccion = ({
  size,
  curso,
  leccion,
  moduloNumber,
  leccionNumber,
}: Props) => {

  return (
    <Flex
      mt="1px"
      id="thumbnail_leccion"
      boxSize="100%"
      align="center"
      justify="center"
      bg="radial-gradient(51.23% 84.58% at 50% 50%, #262B54 0%, #0F1122 100%);"
    >
      <Flex direction="column" justify="center" align="center" boxSize="100%" gap="60px">
        <IconBox
          icon={curso?.icono}
          bg='inherit'
          padding='0'
          border='none'
          size='200px'
          styles={{
            filter: "drop-shadow(0px 0px 7.547px rgba(134, 148, 255, 0.81))",
        }}
      />

        <Flex
          direction="column"
          gap="0"
        >
          <Text
            color="#FFFF"
            fontWeight="bold"
            textAlign="center"
            fontSize={size === ThumbnailSizeEnum.FULL ? '' : { base: '18px', md: '12px' }}
          >
            {curso?.nombre || ''}
          </Text>

          <Text
            color="#FFFF"
            fontWeight="bold"
            textAlign="center"
            fontSize={size === ThumbnailSizeEnum.FULL ? '' : { base: '18px', md: '12px' }}
          >
            {leccion?.nombre || ''}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};