import { NotasInt } from "../../interfaces/NotasInt";
import useSWR from "swr";
import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

const { tenant, client } = await tenantInstance();

const ENDPOINT_NOTAS = "notas"

// Middlewares y Hooks generales y SWR => NOTAS
export const getNotas = async (endpoint: string) => await _axios.get(endpoint).then((response: any) => response);

export const useNotas = (query = "") => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_NOTAS + query
    const { isLoading, data, mutate, error } = useSWR(endpoint, getNotas, {
        onSuccess: data => data?.data?.data?.data?.sort((a: any, b: any) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())
    });
        
    return {
        notas: data?.data?.data?.data,
        error: error?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: error?.response?.status,
        errorsList: error?.response?.data?.errors
    }
}

export const useNotaId = (id: string | undefined) => {
    const endpoint = `${tenant}/${client}/` + ENDPOINT_NOTAS + `/${id}`
    const { isLoading, data, mutate } = useSWR(id ? endpoint : null, getNotas);
        
    return {
        nota: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

export const useCursosNotas = () => {
    const endpoint = `${tenant}/${client}/` + "cursosConNotas"
    const { isLoading, data, mutate } = useSWR(endpoint, getNotas);
        
    return {
        cursos: data?.data?.data,
        error: !data?.isAxiosError ? false : data?.isAxiosError,
        loading: isLoading,
        Refresh: mutate,
        status: !data?.isAxiosError ? data?.status : data?.response?.status,
    }
}

//TODO: METODOS POST => FALTA REALIZAR MUTACION SWR 

export const addNota = async (data: NotasInt) => {
    
    return await _axios.post(
        `${tenant}/${client}/notas`,
        data,
        {}
    );
};

export const updateNota = async ({ id, data }: { id: string | number, data: NotasInt }) => {
    
    return await _axios.put(
        `${tenant}/${client}/notas/${id}`,
        data,
        {}
    );
};