// Chakra UI
import { Text, Flex, Icon, Button, Image } from '@chakra-ui/react';
// Lodash
import { useNavigate } from 'react-router-dom';
import { CursosInt } from '../../../../interfaces/CoursesInt';
import { MatriculasInt } from '../../../../interfaces/MatriculasInt';
import { BarProgress } from '../../../../shared/components/Progress/BarProgress';
import { BsCheck } from 'react-icons/bs';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { EstadoTypeEnum } from '../../../../shared/utils/Types/EstadoTypeEnum';
import { UserRolEnum } from '../../../../shared/utils/Types/RolEnum';
import { useAuthContex } from '../../../../shared/context/user.context';

interface Props {
    curso: CursosInt;
    matricula?: MatriculasInt;
    grupoSlug: string | undefined;
}

export const CardCurso = ({ curso, matricula, grupoSlug }: Props) => {
    const navigate = useNavigate();
    const { user } = useAuthContex();
    const url = grupoSlug ? `/aprendizaje/${curso?.slug}?grupo=${grupoSlug}` : `/aprendizaje/${curso?.slug}`

    return (
        <Flex
            direction="column"
            cursor="pointer"
            bg="purewhite"
            onClick={() => navigate(url)}
            _hover={{
                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
            }}
            transition=" 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
            rounded="5px"
            maxH="750px"
        >
            <Flex
                w="100%"
                maxH="600px"

                overflow="hidden"
                roundedTop="5px"
            >
                <Image
                    src={curso?.imagen?.url}
                    objectFit="cover"
                    w="100%"
                    h="100%"
                />
            </Flex>

            <Flex
                direction="column"
                p="30px"
                gap="20px"
                h="100%"
            >
                <Flex
                    alignItems="start"
                    justifyContent="space-between"
                    direction="column"
                    gap="20px"
                >
                    <Flex
                        direction="column"
                        gap="10px"
                    >

                        <Text
                            color="font"
                            fontSize="24px"
                            fontWeight="700"
                        >
                            {curso?.nombre}
                        </Text>

                        {matricula &&
                            <Text
                                color="font"
                                fontSize="16px"
                                fontWeight="500"
                            >
                                {matricula?.grupo?.nombre}
                            </Text>
                        }
                    </Flex>

                    <Flex
                        alignItems="center"
                        gap="12px"
                        w="100%"
                    >
                        <Text
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {curso?.duracion}h
                        </Text>

                        {matricula &&
                            <>
                                <Flex
                                    w="100%"
                                >
                                    <BarProgress progress={matricula?.progresoLecciones || 0} height="6px" />
                                </Flex>


                                <Text>
                                    {matricula?.progresoLecciones?.toFixed(0)}%
                                </Text>

                                <Icon as={BsCheck} boxSize="24px" color="success" />
                            </>
                        }
                    </Flex>
                </Flex>

                <MarkdownPreview
                    source={curso?.descripcion || ''}
                    style={{
                        color: "#202020",
                        width: '100%',
                        maxWidth: '100%',
                        background: "inherit",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                    }}
                />

                <Button
                    mt="auto"
                    ml="auto"
                    p="11px 16px"
                    bg="secondary"
                    color="purewhite"
                    fontSize="16px"
                    fontWeight="500"
                    rounded="5px"
                    _hover={{ bg: "secondary_variant" }}
                    cursor="pointer"
                >
                    {(matricula?.estado === EstadoTypeEnum.INACTIVO || user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) ? "Información" : "Continuar"}
                </Button>
            </Flex>
        </Flex>
    );
};