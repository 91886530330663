import { Button, Flex, Icon, Text, Tooltip, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { fmtSnds } from "../../utils/functions/formatters";
import { getDiploma } from "../../middlewares/users.middleware";
import { handleErrors } from "../../utils/functions/handleErrors";
import { StatusEnum, customNotify, notify } from "../../utils/functions/notify";
import { BiDownload } from "react-icons/bi";
import { useState } from "react";

interface Props {
    matricula: any;
}

export const DowloadDiploma = ({ matricula }: Props) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false);

    const checkRequisitos = (isFundae: boolean): boolean => {
        if (!isFundae || matricula?.grupo?.permitirDescargaDiploma) {
            if (
                matricula?.puntuacionMedia >= 75 &&
                matricula?.progresoEntregables >= 75 &&
                matricula?.progresoEntregables >= 75
            ) return true;
            else
                return false;
        }

        if (
            matricula?.puntuacionMedia >= 75 &&
            matricula?.progresoEntregables >= 75 &&
            matricula?.progresoEntregables >= 75 &&
            parseInt(fmtSnds(matricula?.tiempoAcceso)) >= (matricula?.grupo?.curso?.duracion * 75) / 100
        ) return true;

        return false;
    }

    const handleCuestionario = () => {
        if (!matricula) return;

        if (!matricula?.formularioCompletado) return navigate(`/cuestionario?curso=${matricula?.grupo?.curso?.slug}&grupo=${matricula?.grupo?.slug}`)

        return handleDiploma(matricula?.id);
    }

    const handleDiploma = (matriculaId: string) => {
        if (!matriculaId) return;
        setLoading(true)

        getDiploma(matriculaId)
            .then((response: any) => {
                toast.closeAll()

                customNotify({
                    toast: toast,
                    status: StatusEnum.success,
                    title:
                        <Flex
                            alignItems="center"
                            gap="15px"
                        >
                            <Text
                                fontWeight="500"
                            >
                                Diploma generado correctamente
                            </Text>

                            <Button
                                h="fit-content"
                                w="fit-content"
                                p="5px 15px"
                                fontSize="13px"
                                fontWeight="500px"
                                border="1px solid"
                                borderColor="purewhite"
                                color="purewhite"
                                leftIcon={<Icon as={BiDownload} boxSize="20px" />}
                                bg="inherit"
                                rounded="5px"
                                _hover={{ bg: "inherit" }}
                                _active={{ transform: "scale(0.9)" }}
                                onClick={() => {
                                    window.open(response?.data?.data, '_blank')
                                    toast.closeAll()
                                }}
                            >
                                Descargar
                            </Button>
                        </Flex>
                })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => notify(toast, StatusEnum.error, error?.message))

                toast.closeAll()
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            {(!matricula?.grupo?.fundae && checkRequisitos(false)) ?
                <Button
                    isLoading={loading}
                    ml="auto"
                    w="fit-content"
                    h="fit-content"
                    bg="secondary"
                    p="10px 20px"
                    color="purewhite"
                    fontSize="16px"
                    fontWeight="500"
                    isDisabled={!checkRequisitos(false)}
                    _hover={{ bg: "secondary_variant" }}
                    onClick={() => handleDiploma(matricula?.id)}
                >
                    Descargar diploma
                </Button>
                :
                (matricula?.grupo?.fundae && checkRequisitos(true)) ?
                    <Flex
                        direction="column"
                        gap="20px"
                    >
                        <Text>
                            Si cumples con todos los requisitos podrás descargar el diploma, recuerda que antes de descargar el diploma deberas realizar el cuestionario correspondiente.
                        </Text>

                        <Tooltip
                            label="Debes cumplir con todos los requisitos para poder realizar el cuestionario"
                            hasArrow
                            isDisabled={checkRequisitos(true)}
                            rounded="5px"
                            p="10px"
                            textAlign="center"
                        >
                            <Button
                                isLoading={loading}
                                ml="auto"
                                w="fit-content"
                                h="fit-content"
                                bg="secondary"
                                p="10px 20px"
                                color="purewhite"
                                fontSize="16px"
                                fontWeight="500"
                                isDisabled={!checkRequisitos(true)}
                                _hover={{ bg: "secondary_variant" }}
                                onClick={handleCuestionario}
                            >
                                {!matricula?.formularioCompletado ? "Realizar cuestionario" : "Descargar diploma"}
                            </Button>
                        </Tooltip>
                    </Flex>
                    :
                    <></>
            }
        </>
    )
}