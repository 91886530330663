import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { BiFile, BiFileFind, BiPlus, BiTrash } from 'react-icons/bi';
import { Box, Button, Flex, Icon, Progress, Text, useToast } from '@chakra-ui/react';
import { notify, StatusEnum } from '../../utils/functions/notify';
import { passTypeFile } from '../../utils/functions/typeFileUploader';
import { BsFileEarmarkArrowUp } from 'react-icons/bs';

interface FileUploaderProps {
  files: File[];
  maxFiles?: number;
  maxSize?: string;
  supportedFormats?: string[];
  isUploading?: boolean;
  hideDropZone?: boolean;
  onDeleteFile?: (file: File) => void;
  onLoadedFiles?: (files: File[]) => void;
}

const emptyFunc = () => { };
const regex = /(^\d+)([T|G|M|K])B/;

export const FileUploader = ({
  files,
  maxFiles = 1,
  maxSize = '50MB',
  supportedFormats = ['.png'],
  isUploading = false,
  onDeleteFile = emptyFunc,
  onLoadedFiles = emptyFunc,
  hideDropZone = false,
}: FileUploaderProps) => {
  const toast = useToast();

  const onDrop = useCallback(async (acceptedFiles: any, fileRejections: any) => {
    fileRejections.forEach((file: any) => {
      file.errors.forEach((err: any) => {
        if (err.code === 'file-too-large') {
          notify(toast, StatusEnum.error, 'El tamaño máximo de los archivos es de ' + maxSize);
        }

        if (err.code === 'file-invalid-type') {
          notify(
            toast,
            StatusEnum.error,
            'No es un tipo de archivo valido. Archivos válidos: ' + supportedFormats.join(',')
          );
        }
      });
    });

    await onLoadedFiles(acceptedFiles);
  }, []);

  const parseSize = (size: string) => {
    const match = size.match(regex);
    let magnitude = 1;

    if (match)
      switch (match[2]) {
        case 'T':
          magnitude = 1000000000000;
          break;
        case 'G':
          magnitude = 1000000000;
          break;
        case 'M':
          magnitude = 1000000;
          break;
        case 'K':
          magnitude = 1000;
          break;
      }

    if (match) return parseInt(match[1]) * magnitude;
    else return 0;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: passTypeFile(supportedFormats),
    maxFiles: maxFiles,
    maxSize: parseSize(maxSize),
  });

  return (
    <Flex gap="10px" direction="column" w="100%">
      {!hideDropZone && (
        <Flex w="100%" rounded="15px" {...getRootProps()} direction="column" cursor="pointer">
          <Flex 
            w="100%" 
            p="30px 20px" 
            alignItems="center" 
            justifyContent="center" 
            borderRadius="5px" 
            border="1px dashed grey" 
            gap="8px"
            direction="column"
          >
            <Icon boxSize="30px" color="dark_grey" as={BsFileEarmarkArrowUp} />

            <Text
              color="#0596FF"
              fontSize="13px"
              fontWeight="700"
              lineHeight="96.9%"
              textDecoration="underline"
            >
              Clica para subir
            </Text>

            <Text
              color="dark_grey"
              fontSize="13px"
              fontWeight="700"
              lineHeight="96.9%"
            >
              o arrastra y suelta
            </Text>
          </Flex>

          <input {...getInputProps()} />
        </Flex>
      )}
    </Flex>
  );
};