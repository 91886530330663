// Chakra UI
import {
  Flex,
  Menu,
  Tooltip,
  MenuButton,
  Icon,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger as OrigPopoverTrigger,
  PopoverContent,
  PopoverBody,
  IconButton,
} from '@chakra-ui/react';
// Icons
import {
  BiPause,
  BiPlay,
  BiRotateLeft,
  BiRotateRight,
  BiCog,
  BiVolumeMute,
  BiVolumeFull,
  BiExitFullscreen,
  BiFullscreen,
} from 'react-icons/bi';
import { BsPip } from 'react-icons/bs';
// Functions
import { fmtSnds } from '../../shared/utils/functions/formatters';
import { capitalizeFirst } from '../../shared/utils/functions/text-parsers';
import { Quality } from '../../shared/utils/Types/videoTypes';

const PopoverTrigger: React.FC<{ children: React.ReactNode }> = OrigPopoverTrigger;

const VideoSpeed = [
  { value: 0.25, label: 'x0.25' },
  { value: 0.5, label: 'x0.5' },
  { value: 0.75, label: 'x0.75' },
  { value: 1, label: 'x1' },
  { value: 1.25, label: 'x1.25' },
  { value: 1.5, label: 'x1.5' },
  { value: 2, label: 'x2' },
];

export const Controls = ({
  volume = 0,
  maxSpeed = 0,
  minSpeed = 0,
  duration = 0,
  quality = 'auto',
  isMuted = false,
  isPlaying = false,
  playedSeconds = 0,
  playbackSpeed = 0,
  isMobilePlayer = false,
  areControlsVisible = false,
  fullscreenHandler = {},
  seekTo,
  setIsMuted,
  setVolume,
  showControls,
  setIsPlaying,
  handleOnEnterPIP,
  setPlaybackSpeed,
  handleChangeQuality,
}: {
  volume: number;
  quality: Quality;
  maxSpeed: number;
  minSpeed: number;
  duration: number;
  isMuted: boolean;
  isPlaying: boolean;
  playbackSpeed: number;
  playedSeconds: number;
  fullscreenHandler: any;
  isMobilePlayer: boolean;
  areControlsVisible: boolean;
  seekTo: (e?: any) => void;
  setIsMuted: (e?: any) => void;
  setVolume: (e?: any) => void;
  showControls: (e?: any) => void;
  setIsPlaying: (e?: any) => void;
  setPlaybackSpeed: (e?: any) => void;
  handleOnEnterPIP: (e?: any) => void;
  handleChangeQuality: (e?: any) => void;
}) => {
  const handleVolumeChange = (volume: number) => {
    setVolume(volume);

    if (volume === 0) setIsMuted(true);
    else setIsMuted(false);
  };

  /** Retrocedemos 10 segundos en el vídeo */
  const onPrev10Seconds = () => {
    /** Verificamos que no vayamos a navegar a unos segundos del video incorrectos. */
    if (playedSeconds !== undefined && playedSeconds >= 0) seekTo(playedSeconds - 10);
  };

  /** Adelantamos 10 segundos en el vídeo */
  const onNext10Seconds = () => {
    /** Verificamos que no vayamos a navegar a unos segundos del video incorrectos. */
    if (playedSeconds !== undefined && playedSeconds >= 0) seekTo(playedSeconds + 10);
  };

  function qualitySelect(quality: string) {
    if (quality === '1080p') handleChangeQuality('1080p');
    else if (quality === '720p') handleChangeQuality('720p');
    else if (quality === '360p') handleChangeQuality('360p');
    else handleChangeQuality('auto');
  }

  const updateTime = async (time: number) => {
    await seekTo(time);
  };

  return (
    <Flex
      px="16px"
      h="54px"
      left="0px"
      right="0px"
      bottom="0px"
      color="white"
      align="center"
      position="absolute"
      bg="rgb(0, 0, 0, 0.6)"
      onMouseEnter={() => showControls(false)}
      style={{ backdropFilter: 'blur(100px)' }}
      visibility={!isPlaying || areControlsVisible ? 'visible' : 'hidden'}
    >
      <Menu placement="top">
        <Tooltip
          bg="black"
          placement="top"
          label={
            <span style={{ fontWeight: 'bold', color: 'var(--chakra-colors-white)' }}>{isPlaying ? 'Pause' : 'Play'}</span>
          }
        >
          <MenuButton
            p="0px"
            w="fit-content"
            bg="transparent"
            minW="fit-content"
            paddingInline="0px"
            color="whiteAlpha.800"
            transition="all 0.2s ease"
            _active={{ bg: 'transparent' }}
            _hover={{ bg: 'transparent', color: '#FFF' }}
            onClick={() => setIsPlaying(!isPlaying)}
          >
            <Icon
              boxSize="34px"
              cursor="pointer"
              color="whiteAlpha.800"
              transition="all 0.2s ease"
              as={isPlaying ? BiPause : BiPlay}
              _hover={{ color: '#FFF' }}
            />
          </MenuButton>
        </Tooltip>
      </Menu>

      <Menu placement="top">
        <Tooltip
          placement="top"
          bg="black"
          label={<span style={{ fontWeight: 'bold', color: 'var(--chakra-colors-white)' }}>Retroceder 10s</span>}
        >
          <MenuButton
            p="0px"
            w="fit-content"
            bg="transparent"
            minW="fit-content"
            paddingInline="0px"
            color="whiteAlpha.800"
            transition="all 0.2s ease"
            _active={{ bg: 'transparent' }}
            _hover={{ bg: 'transparent', color: '#FFF' }}
            onClick={onPrev10Seconds}
          >
            <Icon
              boxSize="24px"
              cursor="pointer"
              color="whiteAlpha.800"
              transition="all 0.2s ease"
              as={BiRotateLeft}
              _hover={{ color: '#FFF' }}
            />
          </MenuButton>
        </Tooltip>
      </Menu>

      <Menu placement="top">
        <Tooltip
          placement="top"
          bg="black"
          label={
            <span
              style={{
                fontWeight: 'bold',
                color: 'var(--chakra-colors-white)',
              }}
            >
              Avanzar 10s
            </span>
          }
        >
          <MenuButton
            mr="10px"
            w="fit-content"
            bg="transparent"
            minW="fit-content"
            paddingInline="0px"
            color="whiteAlpha.800"
            transition="all 0.2s ease"
            _active={{ bg: 'transparent' }}
            _hover={{ bg: 'transparent', color: '#FFF' }}
            onClick={onNext10Seconds}
          >
            <Icon
              boxSize="24px"
              cursor="pointer"
              color="whiteAlpha.800"
              transition="all 0.2s ease"
              as={BiRotateRight}
              _hover={{ color: '#FFF' }}
            />
          </MenuButton>
        </Tooltip>
      </Menu>

      <Flex w="100%" px="8px" align="center" gap="8px" tabIndex={-1}>
        <Slider
          h="5px"
          tabIndex={-1}
          max={duration || 100}
          value={playedSeconds}
          focusThumbOnChange={false}
          onChange={updateTime}
          aria-label="video-playedSeconds"
        >
          <SliderTrack bgColor="whiteAlpha.300">
            <SliderFilledTrack bgColor="whiteAlpha.800" />
          </SliderTrack>

          <Tooltip
            color="#000"
            placement="top"
            bgColor="white"
            label={fmtSnds(playedSeconds)}
            visibility={isPlaying ? 'hidden' : 'visible'}
          >
            <SliderThumb tabIndex={-1} bg="whiteAlpha.900" />
          </Tooltip>
        </Slider>

        <Flex data-cy="time_rest" color="whiteAlpha.800">{`${duration - playedSeconds > 0 ? '-' : ''}${fmtSnds(
          duration - playedSeconds
        )}`}</Flex>

        {!isMobilePlayer && (
          <>
            <Menu placement="top">
              <Tooltip
                placement="top"
                bg="black"
                label={
                  <span style={{ fontWeight: 'bold', color: 'var(--chakra-colors-white)' }}>Velocidad de reproducción</span>
                }
              >
                <MenuButton
                  as={Button}
                  p="0px"
                  w="fit-content"
                  bg="transparent"
                  minW="fit-content"
                  paddingInline="0px"
                  color="whiteAlpha.800"
                  transition="all 0.2s ease"
                  _active={{ bg: 'transparent' }}
                  _hover={{ bg: 'transparent', color: '#FFF' }}
                >
                  {playbackSpeed === 1 && <Icon boxSize="25px" cursor="pointer" as={BiCog} _hover={{ color: '#FFF' }} />}
                  {playbackSpeed !== 1 && <Flex>{`x${playbackSpeed}`}</Flex>}
                </MenuButton>
              </Tooltip>

              <MenuList title="Velocidad de reproducción" color="black">
                {VideoSpeed.filter((speed) => speed.value <= maxSpeed && speed.value >= minSpeed).map((speed, index) => (
                  <MenuItem key={index} onClick={() => setPlaybackSpeed(speed.value)}>
                    {speed.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>

            <Menu placement="top">
              <Tooltip
                placement="top"
                label={<span style={{ fontWeight: 'bold', color: 'var(--chakra-colors-white)' }}>Calidad de reproducción</span>}
                bg="black"
              >
                <MenuButton
                  as={Button}
                  p="0px"
                  pl="1px"
                  m="0px"
                  bg="transparent"
                  justifyContent="center"
                  alignContent="center"
                  color="whiteAlpha.800"
                  transition="all 0.2s ease"
                  _active={{ bg: 'transparent' }}
                  _hover={{ bg: 'transparent', color: '#FFF' }}
                >
                  <Flex _hover={{ color: '#FFF' }}>{capitalizeFirst(quality)}</Flex>
                </MenuButton>
              </Tooltip>

              <MenuList title="Calidad de reproducción" color="black">
                {['1080p', '720p', '360p', 'auto'].map((quality, index) => (
                  <MenuItem key={index} onClick={() => qualitySelect(quality)}>
                    {quality}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </>
        )}

        {isMobilePlayer && (
          <Menu placement="top">
            <Tooltip
              bg="black"
              placement="top"
              label={<span style={{ fontWeight: 'bold', color: 'var(--chakra-colors-white)' }}>Ajustes</span>}
            >
              <MenuButton
                as={Button}
                p="0px"
                w="fit-content"
                bg="transparent"
                minW="fit-content"
                paddingInline="0px"
                color="whiteAlpha.800"
                _hover={{ bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
              >
                <Icon boxSize="25px" cursor="pointer" as={BiCog} />
              </MenuButton>
            </Tooltip>

            <MenuList
              p="5px"
              color="#000"
              w="fit-content"
              display="flex"
              bg="whiteAlpha.900"
              flexDirection="column"
              title="Velocidad de reproducción"
            >
              <Menu>
                <MenuButton
                  as={Button}
                  p="0px"
                  w="fit-content"
                  color="#5b5b5b"
                  bg="transparent"
                  minW="fit-content"
                  paddingInline="0px"
                  _hover={{ bg: 'transparent' }}
                  _active={{ bg: 'transparent' }}
                >
                  Velocidad
                </MenuButton>

                <MenuList title="Velocidad de reproducción" color="#000">
                  {VideoSpeed.filter((speed) => speed.value <= maxSpeed && speed.value >= minSpeed).map((speed, index) => (
                    <MenuItem key={index} onClick={() => setPlaybackSpeed(speed.value)}>
                      {speed.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>

              <Menu placement="top">
                <Tooltip
                  bg="black"
                  placement="top"
                  label={
                    <span style={{ fontWeight: 'bold', color: 'var(--chakra-colors-white)' }}>Calidad de reproducción</span>
                  }
                >
                  <MenuButton
                    as={Button}
                    m="0px"
                    color="#5b5b5b"
                    bg="transparent"
                    p="3px 0px 0px 1px"
                    textTransform="capitalize"
                    _hover={{ bg: 'transparent', color: '#FFF' }}
                    _active={{ bg: 'transparent' }}
                  >
                    {quality}
                  </MenuButton>
                </Tooltip>

                <MenuList title="Calidad de reproducción" color="black">
                  {['1080p', '720p', '360p', 'auto'].map((quality, index) => (
                    <MenuItem key={index} textTransform="capitalize" onClick={() => qualitySelect(quality)}>
                      {quality}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </MenuList>
          </Menu>
        )}

        {!isMobilePlayer && (
          <>
            <Icon
              cursor="pointer"
              fontSize="25px"
              color="whiteAlpha.800"
              transition="all 0.2s ease"
              onClick={() => setIsMuted(!isMuted)}
              as={isMuted ? BiVolumeMute : BiVolumeFull}
              _hover={{ color: '#FFF' }}
            />

            <Slider
              aria-label="video-playedSeconds"
              w="120px"
              h="5px"
              min={0}
              max={100}
              value={isMuted ? 0 : volume}
              onChange={handleVolumeChange}
            >
              <SliderTrack bgColor="whiteAlpha.200">
                <SliderFilledTrack bgColor="whiteAlpha.800" />
              </SliderTrack>

              <SliderThumb bg="whiteAlpha.900" />
            </Slider>
          </>
        )}

        {isMobilePlayer && (
          <Popover placement="top">
            <PopoverTrigger>
              <Button
                p="0px"
                outline="none"
                bg="transparent"
                fontSize="25px"
                w="fit-content"
                minW="fit-content"
                aria-label="sonido"
                paddingInline="0px"
                color="whiteAlpha.800"
                _active={{ border: 'none' }}
              >
                <Icon boxSize="25px" as={isMuted ? BiVolumeMute : BiVolumeFull} />
              </Button>
            </PopoverTrigger>

            <PopoverContent w="fit-content" bg="transparent" border="none" _active={{ outline: 'none' }}>
              <PopoverBody>
                <Slider
                  w="5px"
                  h="120px"
                  min={0}
                  max={100}
                  orientation="vertical"
                  aria-label="video-playedSeconds"
                  value={isMuted ? 0 : volume}
                  onChange={(v: any) => handleVolumeChange(v)}
                >
                  <SliderTrack bgColor="whiteAlpha.300">
                    <SliderFilledTrack bgColor="whiteAlpha.800" />
                  </SliderTrack>

                  <SliderThumb bg="whiteAlpha.900" />
                </Slider>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}

        <Tooltip
          bg="black"
          placement="top"
          shouldWrapChildren
          label={<span style={{ fontWeight: 'bold', color: 'var(--chakra-colors-white)' }}>PIP</span>}
        >
          <IconButton
            ml="8px"
            p="0px"
            w="30px"
            h="30px"
            bg="transparent"
            color="whiteAlpha.800"
            aria-label="Activar PIP"
            transition="all 0.2s ease"
            _hover={{ color: '#FFF' }}
            onClick={handleOnEnterPIP}
            icon={<Icon as={BsPip} boxSize="26px" />}
            isDisabled={!document.pictureInPictureEnabled}
            title={!document.pictureInPictureEnabled ? 'Tu navegador no admite PIP' : ''}
          />
        </Tooltip>

        <Tooltip
          bg="black"
          placement="top"
          shouldWrapChildren
          label={
            fullscreenHandler.active ? (
              <span style={{ fontWeight: 'bold', color: 'var(--chakra-colors-white)' }}>Exit fullscreen</span>
            ) : (
              <span style={{ fontWeight: 'bold', color: 'var(--chakra-colors-white)' }}>Fullscreen</span>
            )
          }
        >
          <Icon
            ml="8px"
            pt="5px"
            boxSize="30px"
            fontSize="20px"
            cursor="pointer"
            color="whiteAlpha.800"
            transition="all 0.2s ease"
            _hover={{ color: '#FFF' }}
            as={fullscreenHandler.active ? BiExitFullscreen : BiFullscreen}
            onClick={fullscreenHandler.active ? fullscreenHandler.exit : fullscreenHandler.enter}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
