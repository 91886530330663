import { Badge, Box, Flex, Text, useToast } from "@chakra-ui/react";
import { useEntregablesMatricula } from "../../shared/middlewares/entregables.middleware";
import { useEffect, useState } from "react";
import { EntregablesTable } from "./views/EntegablesTable";
import TopEntregables from "./Components/TopEntregables";
import { SkeletonEntregables } from "./Components/utils/SkeletonEntregables";
import { CardSinInformacion, TypeCardSinInformacion } from "../../shared/components/Customs/CardSinInformacion";
import { handleUnauthorized } from "../../shared/utils/functions/handleErrors";
import { useAuthContex } from "../../shared/context/user.context";
import { useNavigate, useSearchParams } from "react-router-dom";
import CardActualizaciones from "../../shared/components/Cards/CardActualizaciones";
import { useMatriculasAlumno } from "../../shared/middlewares/matriculas.middleware";
import { MatriculasInt } from "../../interfaces/MatriculasInt";
import { EstadoTypeEnum } from "../../shared/utils/Types/EstadoTypeEnum";

export const Entregables = () => {
    const { logout, user } = useAuthContex();
    const { matriculas, error, status, errorsList } = useMatriculasAlumno({ user: user });
    const navigate = useNavigate();
    const toast = useToast();
    const [search, setSearch] = useSearchParams()
    const matriculasTutorizadas = matriculas?.data?.filter((matricula: MatriculasInt | undefined) => matricula?.meta?.tutorizado);
    const [selectedMatricula, setSelectedMatricula] = useState<MatriculasInt>(matriculasTutorizadas[0]);
    const { loading, entregables } = useEntregablesMatricula(
        `?limit=100`, selectedMatricula?.id,
    );


    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

    /* useEffect(() => {
        if (!matriculas) return

        setSelectedMatricula(matriculasTutorizadas)
        search.set("matricula", `${matriculasTutorizadas?.id}`);
        setSearch(search);


    }, []) */

    const handleSelectMatricula = (matriculasTutorizadas: MatriculasInt) => {
        setSelectedMatricula(matriculasTutorizadas);

        search.set("matricula", `${matriculasTutorizadas?.id}`);
        setSearch(search);
    };


    return (
        loading ?
            <SkeletonEntregables />
            :

            <Flex
                direction="column"
            >

                <Flex
                    px="50px"
                    direction="column"
                >
                    <Flex
                        gap="20px"
                        mb="40px"
                        align="center"
                    >
                        <Text
                            fontSize="28px"
                            fontWeight="600"
                            color="font"
                        >
                            {selectedMatricula?.grupo?.curso?.nombre}
                        </Text>

                        {selectedMatricula?.estado === EstadoTypeEnum.INACTIVO &&
                            <Badge
                                w="fit-content"
                                bg="fail_bg"
                                color="fail"
                                rounded="5px"
                                fontSize="14px"
                                px="10px"
                                h="fit-content"
                            >
                                Finalizado
                            </Badge>
                        }
                    </Flex>

                    <Flex
                        w="100%"
                        gap="40px"
                        justifyContent="space-between"
                    >

                        <Flex
                            direction="column"
                            w="100%"
                            h="fit-content"
                            gap="40px"
                            rounded="5px"
                        >

                            <TopEntregables
                                onSelectMatricula={handleSelectMatricula}
                                entregables={entregables}
                                loading={loading}
                                matriculas={matriculasTutorizadas}
                                selectedMatricula={selectedMatricula}
                            />

                            {entregables &&
                                <EntregablesTable
                                    entregables={entregables}
                                    loading={loading}
                                />
                            }


                            {!entregables && (
                                <Flex
                                    direction="column"
                                    h="85vh"
                                    w="100%">
                                    <CardSinInformacion type={TypeCardSinInformacion.ENTREGABLES} />
                                </Flex>
                            )}


                        </Flex>


                        <CardActualizaciones correccion={entregables} />

                    </Flex>
                </Flex>
            </Flex >
    )
};