// Chakra UI
import { Box, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
// Components
import { Calendar } from "../Calendar/Calendar";
import { Notifications } from "../Notifications/Notifications";

interface Props {
    isOpen: boolean;
    setIsOpen: (action: boolean) => void;
    callComponent: string;
}

export const DrawerAlert = (props: Props) => {
    const { isOpen, setIsOpen, callComponent } = props;
    
    return(
        <Drawer placement='right' onClose={() => setIsOpen(false)} isOpen={isOpen}>
            <DrawerOverlay />

            <DrawerContent>
                { callComponent === "calendar" &&
                <DrawerBody>
                    <Calendar />

                    <Box w="100%" h="1px" bg="#f0f0f3" my="34px"></Box>
                </DrawerBody>
                ||
                callComponent === "alert" &&
                <DrawerBody w="100%" p="10px 0">
                    <Notifications />
                </DrawerBody>
                }
            </DrawerContent>
        </Drawer>
    );
};