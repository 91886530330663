import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { BarProgress } from "../../../../shared/components/Progress/BarProgress";

interface Props {
    porcentaje?: boolean;
    value: number;
    label: string;
    tooltipLabel?: string;
    valuePorcentaje?: any;
    valueCentered?: boolean;
}

export default function CardObjetivoStats({ value, label, valuePorcentaje, porcentaje, tooltipLabel }: Props) {

    return (
        <Tooltip
            hasArrow
            label={tooltipLabel}
            rounded="5px"
            p="10px"
            textAlign="center"
        >
            <Flex
                flex="1"
                align="start"
                boxShadow="0px 4px 15px 0px rgba(199,205,255,0.6)"
                borderRadius="8px"
                p="20px"
                w="100%"
            >
                <Flex
                    direction="column"
                    gap="5px"
                    w="100%"
                    justify="space-between"
                    h="full"
                >

                    <Text
                        fontSize="16px"
                        fontWeight="700"
                        color="font"
                        mx="auto"
                        textAlign="center"
                    >
                        {label}
                    </Text>

                    <Flex
                        mb="0px"
                        direction="column"
                    >

                        <Text
                            fontSize="36px"
                            fontWeight="600"
                            color="rgba(61,63,101,1)"
                            mx="auto"
                        >
                            {value + "%"}
                        </Text>

                        <Flex
                            h="24px"
                            gap="8px"
                            align="center"

                        >
                            <BarProgress
                                bgProgress={value < 75 ? "secondary" : "success"}
                                height='6px'
                                progress={valuePorcentaje}

                            />
                        </Flex>
                    </Flex>

                </Flex>
            </Flex>
        </Tooltip>
    )
}
